import { useSelector } from 'react-redux';

import { Badge, Alert } from 'reactstrap';

import { useFetchGetLocationSettings } from '../../../utils/Api/FetchLocationSettings.jsx';
import WidgetPackLinge from '../WigdetPackLinge.jsx';
import WidgetPackMenage from '../WigdetPackMenage.jsx';

export default function ListingDetailsVillaRose() {
    const translate = useSelector((store) => store.Template.language);
    const { dataLocationSettings } = useFetchGetLocationSettings();
    return (
        <div className="locations__description">
            {translate === 'French' && (
                <h2 className="fs-1">Composition du Logement</h2>
            )}
            {translate === 'English' && (
                <h2 className="fs-1">Housing Composition</h2>
            )}

            <div className="d-flex flex-row flex-wrap">
                <div className="d-flex flex-column col-12 col-lg-5 mx-auto">
                    <div className="listing__arrive">
                        {translate === 'French' && (
                            <h3 className="fs-2">
                                <u>Pour votre Arrivé :</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Inclus
                                </Badge>
                            </h3>
                        )}
                        {translate === 'English' && (
                            <h3 className="fs-2">
                                <u>For your Arrival:</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Included
                                </Badge>
                            </h3>
                        )}

                        <div className="composition__logement">
                            <div className="composition__logement_text">
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-key"></i>{' '}
                                    {translate === 'French' && (
                                        <span>Arrivée en boîte à clé</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>Arrival in key box</span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-car"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Vous pourrez vous garer dans la
                                            cours intérieure
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            You can park in the courtyard.
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listing__sejour">
                        {translate === 'French' && (
                            <h3 className="fs-2">
                                <u>Lors de votre séjour :</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Inclus
                                </Badge>
                            </h3>
                        )}
                        {translate === 'English' && (
                            <h3 className="fs-2">
                                <u>During your stay:</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Included
                                </Badge>
                            </h3>
                        )}

                        <div className="composition__logement">
                            <div className="composition__logement_text">
                                <div>
                                    <i className="fa-solid fa-house"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Maison complète avec terrain de
                                            800m2 et terrasse vue mer
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Complete house with 800m2 land and
                                            sea view terrace
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-map-pin me-2"></i>
                                    {translate === 'French' && (
                                        <span>
                                            En quartier calme de La Plaine Sur
                                            Mer
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            In a quiet area of La Plaine Sur Mer
                                        </span>
                                    )}
                                </div>

                                <div>
                                    <i className="fa-solid fa-panorama"></i>{' '}
                                    {translate === 'French' && (
                                        <span>Vue dégagée</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>Open View</span>
                                    )}
                                </div>
                                {translate === 'French' && (
                                    <Badge className="" color="primary" pill>
                                        Au Rez de Chaussée
                                    </Badge>
                                )}
                                {translate === 'English' && (
                                    <Badge className="" color="primary" pill>
                                        On the ground floor
                                    </Badge>
                                )}
                                <div>
                                    <i className="fa-solid fa-house-signal"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Un salon séjour spacieux et lumineux
                                            vue sur mer. Le canapé convertible
                                            est neuf de 2023. Le matelas est
                                            comparable à un lit classique, de
                                            bonne qualité. Deux baies vitrées
                                            s’ouvrent sur une grande terrasse
                                            équipée d'un salon de jardin et
                                            transats pour profiter du soleil et
                                            de la vue sur mer tout au long de la
                                            journée. Wifi disponible
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            A spacious and bright living room
                                            with sea view. The convertible sofa
                                            is new from 2023. The mattress is
                                            comparable to a classic bed, of good
                                            quality. Two bay windows open onto a
                                            large terrace equipped with garden
                                            furniture and deckchairs to enjoy
                                            the sun and the sea view all day
                                            long. Wifi Available
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-kitchen-set"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Une cuisine toute équipée neuve de
                                            2022 (cafetière bosch neuve,
                                            bouilloire, four neuf, plaque de
                                            cuisson neuve, lave vaisselle, lave
                                            linge, micro ondes et
                                            réfrigérateur),
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            A new fully equipped kitchen from
                                            2022 (new Bosch coffee maker,
                                            kettle, new oven, new hob,
                                            dishwasher, washing machine,
                                            microwave and refrigerator),
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Chambre Vue Mer en rez-de-chaussée
                                            - avec placard intégré. Literie de
                                            2022 en 160x190
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 Sea View Bedroom on the ground
                                            floor - with built-in cupboard. 2022
                                            bedding in 160x190
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Chambre Vue hortensias en
                                            rez-de-chaussée - avec placard
                                            intégré. Literie en 140x190
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 Bedroom Hydrangea view on the
                                            ground floor - with cupboard
                                            integrated. Bedding in 140x190
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-shower"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Grande Salle d’eau avec douche à
                                            l’italienne neuve avec doubles
                                            vasques et WC suspendu indépendant
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Grande Bathroom with new walk-in
                                            shower with double sinks and
                                            wall-mounted toilet independent
                                        </span>
                                    )}
                                </div>
                                {translate === 'French' && (
                                    <Badge className="" color="primary" pill>
                                        A l'étage
                                    </Badge>
                                )}
                                {translate === 'English' && (
                                    <Badge className="" color="primary" pill>
                                        Upstairs
                                    </Badge>
                                )}
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Une chambre mansardée lumineuse
                                            Literie de 2021 140x190
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            A bright attic bedroom Bedding of
                                            2021 140x190
                                        </span>
                                    )}
                                </div>

                                <div>
                                    {' '}
                                    <i className="fa-solid fa-shower"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Une salle d’eau douche à l’italienne
                                            et wc suspendu refait à neuf.{' '}
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            A bathroom with Italian shower and
                                            renovated wall-mounted toilet.
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-hand-holding-heart"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Un palier avec coin bureau et lit
                                            d'appoint 1 personne
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            A landing with office area and extra
                                            bed for 1 person
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column col-12 col-lg-5 mx-auto">
                    <WidgetPackLinge
                        dataLocationSettings={dataLocationSettings}
                    />

                    <Alert
                        className="my-3 text-center mx-auto"
                        color="primary"
                        isOpen={true}
                    >
                        <i className="fa-solid fa-circle-arrow-right"></i>
                        {translate === 'French' && (
                            <span>
                                Vous pourrez reserver nos différents services
                                lors de la réservation du logement.
                            </span>
                        )}
                        {translate === 'English' && (
                            <span>
                                You can book our different services when booking
                                accommodation.
                            </span>
                        )}
                    </Alert>
                </div>
            </div>
        </div>
    );
}
