import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import CalendarBooking from '../../../components/Reservations/CalendarBooking.jsx';
import Carrousel from '../../../components/Logements/Caroussel.jsx';
import { getApartments } from '../../../Store/Actions/smoobu.actions';
import ListingDetailsMaisonCharmeGourmalon from '../../../components/Logements/MaisonCharmeGourmalon/ListingDetailsMaisonCharmeGourmalon.jsx';

export default function PageMaisonCharmeGourmalon(props) {
    document.title = `Location '${props.title}' à Pornic 44210 | Ma Maison Sereine`;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const translate = useSelector((store) => store.Template.language);
    const dispatch = useDispatch();
    const dataApartments = useSelector((store) => store.Smoobu.dataApartments);
    useEffect(() => {
        setTimeout(() => {
            dispatch(getApartments());
        }, 1000);
    }, []);

    return (
        <main className="page_logement">
            <article className="page_logement__content">
                <Carrousel images={props.images} />
                <img
                    src={props.logo}
                    alt={props.title}
                    title={props.title}
                    loading="lazy"
                    width={'160px'}
                    className="card-location-logo"
                />
                <div className="fs-2">
                    <h1>
                        {translate === 'French' && (
                            <>Location - {props.title}</>
                        )}
                        {translate === 'English' && <>Rental - {props.title}</>}
                    </h1>
                    <h2>
                        {translate === 'French' && (
                            <>
                                Logement Complet pour 6 voyageurs - 3 chambres -
                                4 lits - 1 salle de Douche
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                Complete House for 6 travelers - 3 bedrooms - 4
                                beds - 1 shower room
                            </>
                        )}
                    </h2>
                </div>
                <div className="bg-primary hr-h my-4"></div>
                {translate === 'French' && (
                    <>
                        <p>
                            Jolie maison de 80m2 dans le quartier prisé de
                            Gourmalon à 5mn à pied de l'océan, de la thalasso et
                            à 10mn à pied du centre ville.
                        </p>
                        <p>
                            Vous serez conquis par sa décoration soignée et son
                            emplacement idéal.
                        </p>
                        <p>
                            Vous pourrez profiter d'un jardin clos, idéal pour
                            les enfants en bas âge. Un barbecue est à votre
                            disposition pour les amateurs, ainsi que des
                            équipements pour enfants (baignoire bébé, chaise
                            haute, lit parapluie,..)
                        </p>
                        <p>
                            Vous pourrez stationner gratuitement un véhicule
                            devant le portail de la maison.{' '}
                        </p>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <p>
                            Pretty 80m2 house in the popular area of Gourmalon 5
                            minutes walk from the ocean, thalassotherapy and 10
                            minutes walk from the city center.
                        </p>
                        <p>
                            You will be won over by its careful decoration and
                            its ideal location.
                        </p>
                        <p>
                            You can enjoy an enclosed garden, ideal for young
                            children. A barbecue is at your disposal provision
                            for amateurs, as well as equipment for children
                            (baby bath, chair high, travel cot, etc.)
                        </p>
                        <p>
                            You can park a vehicle for free in front of the gate
                            of the house.{' '}
                        </p>
                    </>
                )}
                <div className="bg-primary hr-h my-4"></div>
                <ListingDetailsMaisonCharmeGourmalon />
                <div className="bg-primary hr-h my-4"></div>
                <Carrousel images={props.images} active={true} />
                <div className="bg-primary hr-h my-4"></div>
                <div className="d-flex flex-row flex-wrap">
                    <div className="d-block d-md-none bg-primary hr-h my-4"></div>
                    <div className="calendarWidget locations__localisation mx-auto">
                        <h2 className="fs-1 mb-3 text-center">
                            {translate === 'French' && (
                                <>Calendrier de Réservation</>
                            )}
                            {translate === 'English' && <>Booking Calendar</>}
                        </h2>
                        {dataApartments !== null &&
                            dataApartments.map(
                                (appart) =>
                                    appart.id === props.id && (
                                        <div key={appart.id}>
                                            <h2>
                                                <i className="fa-solid fa-map-location-dot"></i>{' '}
                                                {appart.name}
                                            </h2>
                                            <CalendarBooking
                                                apartment={appart}
                                                channel={'airbnb'}
                                                linkAirbnb={
                                                    'https://www.airbnb.fr/rooms/42650147'
                                                }
                                            />
                                        </div>
                                    )
                            )}
                    </div>
                </div>
            </article>

            <section className="sect__widgets">
                <CardHomeWidgetsBottom />
            </section>
        </main>
    );
}
