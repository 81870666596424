import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

export default function Carrousel(props) {
    const translate = useSelector((store) => store.Template.language);

    const [indexImg, setIndexImg] = useState(0);
    const totalImg = props.images.length;

    const [imgToDisplay, setImgToDisplay] = useState(
        props.images[indexImg].original
    );
    const handleChangeLeft = (e) => {
        if (indexImg === 0) {
            setIndexImg(totalImg - 1);
        } else {
            setIndexImg(indexImg - 1);
        }
    };
    const handleChangeRight = (e) => {
        if (indexImg === totalImg - 1) {
            setIndexImg(0);
        } else {
            setIndexImg(indexImg + 1);
        }
    };
    const navigationKey = (event) => {
        if (event.key === 'ArrowLeft') {
            handleChangeLeft();
        } else if (event.key === 'ArrowRight') {
            handleChangeRight();
        }
    };

    useEffect(() => {
        setImgToDisplay(props.images[indexImg].original);
    }, [indexImg]);
    const refcarroussel = useRef(null);

    useEffect(() => {
        refcarroussel.current.focus();
    }, [refcarroussel]);
    return (
        <>
            <div className="carroussel" onKeyDown={navigationKey}>
                <div className="carroussel-content">
                    {props.active === true ? (
                        <img
                            src={imgToDisplay}
                            className="carroussel__cover_active"
                            loading="lazy"
                        />
                    ) : (
                        <img
                            src={imgToDisplay}
                            className="carroussel__cover"
                            loading="lazy"
                        />
                    )}
                </div>

                {props.active === true ? (
                    <div className="d-flex flew-row justify-content-between w-75 mx-auto">
                        {totalImg > 1 && props.active === true && (
                            <div className="carroussel__navigation ">
                                <button
                                    className="carroussel__navigation-button arrow-left"
                                    aria-label="Boutton Flèche Gauche pour accéder à la Photo Précédente"
                                    onClick={handleChangeLeft}
                                    ref={refcarroussel}
                                    data-testid="carroussel__navigation-button-left"
                                >
                                    <strong className="fa-solid fa-chevron-left"></strong>
                                </button>
                            </div>
                        )}
                        <div className="carroussel__legend">
                            <p className="carroussel__title ">
                                {translate === 'French' &&
                                    props.images[indexImg].description}
                                {translate === 'English' &&
                                    props.images[indexImg].descriptionEn}
                            </p>
                            <p className="carroussel__pagination ">
                                {indexImg + 1}/{totalImg}
                            </p>
                        </div>
                        {totalImg > 1 && props.active === true && (
                            <div className="carroussel__navigation ">
                                <button
                                    className="carroussel__navigation-button arrow-right"
                                    aria-label="Boutton Flèche Droite pour accéder à la Photo Suivante"
                                    onClick={handleChangeRight}
                                    data-testid="carroussel__navigation-button-right"
                                >
                                    <strong className="fa-solid fa-chevron-right"></strong>
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div ref={refcarroussel}></div>
                )}
            </div>
        </>
    );
}
