import { useSelector } from 'react-redux';
import { FaCloudMoon } from 'react-icons/fa6';
import { RiLightbulbLine } from 'react-icons/ri';

export default function JardinBienvenue(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className={props.className}>
            <h2 className="text-center m-3">
                <span className="m-1">
                    <FaCloudMoon />
                </span>
                {translate === 'French' && <>Vie en Communauté et Extérieur</>}
                {translate === 'English' && <>Life in community</>}
            </h2>

            <div>
                {translate === 'French' && <>{props.jardinFr}</>}
                {translate === 'English' && <>{props.jardinEn}</>}
            </div>
            {props.light === true && (
                <>
                    {' '}
                    <h2 className="text-center m-3">
                        <span className="m-1">
                            <RiLightbulbLine />
                        </span>
                        {translate === 'French' && <>L'éclairage Extérieur</>}
                        {translate === 'English' && <>L'éclairage Extérieur</>}
                    </h2>
                    {translate === 'French' && <>{props.lightFr}</>}
                    {translate === 'English' && <>{props.lightEn}</>}
                </>
            )}
        </div>
    );
}
