import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaInfoCircle } from 'react-icons/fa';

export default function HelpSVoyageur() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <FaInfoCircle /> Informations complémentaires
            </Button>

            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Services & Options supplémentaires pour les Voyageurs
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>Localisation de linge Blanc via le pressing</h2>
                    <p>
                        Prêt et ramassage du linge blanc de qualité hôtelière
                        pour vos voyageurs. Composition par personne :
                    </p>
                    <ul>
                        <li>1 serviette</li>
                        <li>1 taie</li>
                        <li>1 drap housse</li>
                        <li>1 housse de couette</li>
                    </ul>
                    <p>
                        Par Cuisine : un essuie-main et un torchon à vaisselle.
                        <br /> Par Salle de Douche/Bain : 1 tapis de bain.
                        <br />
                        Attention tous les linges volés ou détériorés sont
                        facturés au propriétaire.
                    </p>

                    <hr className="col-6 mx-auto" />
                    <h2>
                        Blanchiment et fourniture du linge de lit de couleur
                    </h2>
                    <p>
                        Prêt, blanchiment et ramassage du linge de couleurs pour
                        vos voyageurs. Composition par personne :
                    </p>
                    <ul>
                        <li>1 serviette</li>
                        <li>1 taie</li>
                        <li>1 drap housse</li>
                        <li>1 housse de couette</li>
                    </ul>
                    <p>
                        Par Cuisine : un essuie-main et un torchon à vaisselle.
                        <br /> Par Salle de Douche/Bain : 1 tapis de bain.
                        <br />
                        Attention tous les linges volés ou détériorés sont
                        facturés au propriétaire.
                    </p>

                    <hr className="col-6 mx-auto" />
                    <h2>Forfait Ménage Logement</h2>
                    <p>
                        Le type de forfait ménage est déterminé lors de la
                        visite du concierge dans votre logement pour un futur
                        partenariat. 2 tarifs : séjour inférieur ou égal à 2
                        jours et ceux de plus de 2 jours (3J et plus).
                    </p>

                    <hr className="col-6 mx-auto" />
                    <h2>
                        Ménage garantie par un professionnel avant votre arrivée
                    </h2>
                    <p>
                        Ménage automatiquement effectué par un membre de
                        l’équipe de la conciergerie même si le précédent
                        voyageur affirme l’avoir fait avant l’arrivée des
                        voyageurs qui a commandé le service.
                    </p>

                    <hr className="col-6 mx-auto" />
                    <h2>Pack Alèse</h2>
                    <p>
                        Fourniture et blanchiment d'alèse de lit et oreiller
                        pour 1 lit
                    </p>

                    <hr className="col-6 mx-auto" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        D'accord
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
