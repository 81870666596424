import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import CalendarBooking from '../../../components/Reservations/CalendarBooking.jsx';
import Carrousel from '../../../components/Logements/Caroussel.jsx';
import { getApartments } from '../../../Store/Actions/smoobu.actions';
import ListingDetailsVillaRose from '../../../components/Logements/VillaRose/ListingDetailsVillaRose.jsx';

export default function PageVillaRose(props) {
    document.title = `Location '${props.title}' à Préfailles 44770 | Ma Maison Sereine`;
    const translate = useSelector((store) => store.Template.language);
    const dispatch = useDispatch();
    const dataApartments = useSelector((store) => store.Smoobu.dataApartments);
    useEffect(() => {
        setTimeout(() => {
            dispatch(getApartments());
        }, 1000);
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <main className="page_logement">
            <article className="page_logement__content">
                <Carrousel images={props.images} />
                <img
                    src={props.logo}
                    alt={props.title}
                    title={props.title}
                    loading="lazy"
                    width={'160px'}
                    className="card-location-logo"
                />
                <div className="fs-2">
                    <h1>
                        {translate === 'French' && (
                            <>Location - {props.title}</>
                        )}
                        {translate === 'English' && <>Rental - {props.title}</>}
                    </h1>
                    <h2>
                        {translate === 'French' && (
                            <>
                                Maison Complète pour 9 voyageurs - 3 chambres -
                                5 lits - 2 salles de douche
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                Complete House for 9 travelers - 3 bedrooms - 5
                                beds - 2 shower rooms
                            </>
                        )}
                    </h2>
                </div>
                <div className="bg-primary hr-h my-4"></div>
                {translate === 'French' && (
                    <>
                        <p>
                            La maison se situe à 200 mètres des plages et
                            criques avec accès par sentier, au calme. Vous
                            entendrez le chant des oiseaux et les vélos passés.
                            Nombreux sentiers pédestres et vtt. La maison est
                            spacieuse et lumineuse . Vous profiterez de sa vue
                            mer. Vous rejoindrez le centre de Préfailles en 2
                            minutes, Pornic en 10 minutes en voiture. Nombreuses
                            animations durant l’été. École de voile, vtt,
                            parcours sportif, labyrinthe. Idéalement située pour
                            profiter de la mer, de la plage en toute sécurité
                        </p>
                        <p>
                            La maison rénovée à neuf de 120m2 peut accueillir 9
                            personnes, idéal pour une famille. Le terrain clos
                            de 800m2 ravira petits et grands pour des parties de
                            jeux pendant que les grands lézardent sur la
                            terrasse vue sur mer sans vis à vis direct.
                        </p>
                        <p>Linge de lit non compris (disponible en option).</p>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <p>
                            The house is located 200 meters from the beaches and
                            coves with access by path, quiet. YOU hear the
                            birdsong and the bicycles passing by. Numerous
                            hiking and mountain biking trails. The house is
                            spacious and bright. You will enjoy its view Wed.
                            You will reach the center of Préfailles in 2
                            minutes, Pornic in 10 minutes by car. Many
                            entertainment during the summer. Sailing school,
                            mountain biking, sports course, labyrinth. Ideally
                            located for enjoy the sea and the beach in complete
                            safety
                        </p>
                        <p>
                            The newly renovated 120m2 house can accommodate 9
                            people, ideal for a family. The enclosed land of
                            800m2 will delight young and old for parties of
                            games while the grown-ups laze on the terrace with
                            sea view, not directly overlooked.
                        </p>
                        <p>Bed linen not included (available as an option).</p>
                    </>
                )}
                <div className="bg-primary hr-h my-4"></div>
                <ListingDetailsVillaRose />
                <div className="bg-primary hr-h my-4"></div>
                <Carrousel images={props.images} active={true} />
                <div className="bg-primary hr-h my-4"></div>
                <div className="d-flex flex-row flex-wrap">
                    <div className="d-block d-md-none bg-primary hr-h my-4"></div>
                    <div className="calendarWidget locations__localisation mx-auto">
                        <h2 className="fs-1 mb-3 text-center">
                            {translate === 'French' && (
                                <>Calendrier de Réservation</>
                            )}
                            {translate === 'English' && <>Booking Calendar</>}
                        </h2>
                        {dataApartments !== null &&
                            dataApartments.map(
                                (appart) =>
                                    appart.id === props.id && (
                                        <div key={appart.id}>
                                            <h2>
                                                <i className="fa-solid fa-map-location-dot"></i>{' '}
                                                {appart.name}
                                            </h2>
                                            <CalendarBooking
                                                apartment={appart}
                                                channel={'airbnb'}
                                                linkAirbnb={
                                                    'https://www.airbnb.fr/rooms/44240750'
                                                }
                                            />
                                        </div>
                                    )
                            )}
                    </div>
                </div>
            </article>
            <section className="sect__widgets">
                <CardHomeWidgetsBottom />
            </section>
        </main>
    );
}
