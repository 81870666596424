import Alert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';

import CheckReservation from './CheckReservation.jsx';

export default function ModuleReservation() {
    const selectedAppart = useSelector((store) => store.Config.selectedAppart);

    return selectedAppart !== null ? (
        <main className="homepage">
            <h1 className="text-center  mb-3">
                Demande de Réservation pour le logement:
                <br />
                {selectedAppart !== undefined && selectedAppart.name}
            </h1>
            <CheckReservation />
        </main>
    ) : (
        <main className="min-vh-100">
            <h1 className="text-center mb-3">Demande de Réservation </h1>
            <Alert variant={'danger'} className="text-center">
                Merci de passer par le calendrier de réservation présent sur la
                page d'un logement pour accéder à cette page.
            </Alert>
        </main>
    );
}
