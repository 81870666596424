import accueil from '../../../assets/img/Pecherie/photo/accueil.jpeg';
import jardin from '../../../assets/img/Pecherie/photo/jardin.jpg';
import jardinClos from '../../../assets/img/Pecherie/photo/jardinClos.jpg';
import maisonRue from '../../../assets/img/Pecherie/photo/maisonCoteRue.jpeg';
import veranda from '../../../assets/img/Pecherie/photo/veranda.jpg';
import terrainBoules from '../../../assets/img/Pecherie/photo/terrainBoules.jpg';
import chambreBebe from '../../../assets/img/Pecherie/photo/chambreBebe.jpg';
import chambreAdulte from '../../../assets/img/Pecherie/photo/chambreAdulte.jpg';
import chambreSup from '../../../assets/img/Pecherie/photo/chambreSup.jpg';
import cuisine from '../../../assets/img/Pecherie/photo/cuisine.jpg';
import cuisine2 from '../../../assets/img/Pecherie/photo/cuisine2.jpg';
import salon from '../../../assets/img/Pecherie/photo/salon.jpg';
import salon2 from '../../../assets/img/Pecherie/photo/salon2.jpg';
import plage from '../../../assets/img/Pecherie/photo/plage.jpg';

export default function imagesPecherie() {
    const listing = [
        {
            original: accueil,
            description: 'Espace Salon Extérieur',
            descriptionEn: 'Outdoor Lounge Area',
        },
        {
            original: maisonRue,
            description: 'Maison Familiale vue de la Rue',
            descriptionEn: 'Family House seen from the Street',
        },
        {
            original: jardin,
            description: 'Jardin aux abords de la Véranda',
            descriptionEn: 'Garden around the Veranda',
        },
        {
            original: jardinClos,
            description: 'Jardin Clos aux abords de la Véranda',
            descriptionEn: 'Garden closed around the Veranda',
        },
        {
            original: terrainBoules,
            description: 'Terrain de Pétanque privatif',
            descriptionEn: 'Private petanque court',
        },
        {
            original: veranda,
            description: 'La Véranda donnant accès au jardin',
            descriptionEn: 'The Veranda giving access to the garden',
        },
        {
            original: chambreBebe,
            description: 'Chambre avec lit bébé avec placard',
            descriptionEn: 'Bedroom with cot with closet',
        },
        {
            original: chambreAdulte,
            description: 'Chambre avec lit double en 140 avec placard',
            descriptionEn: 'Bedroom with double bed 140 with closet',
        },
        {
            original: chambreSup,
            description:
                'Chambre avec lit double en 140 avec que 2 lits 90 superposées',
            descriptionEn:
                'Bedroom with double bed 140 with only 2 bunk beds 90',
        },
        {
            original: salon,
            description: 'Salle à Manger',
            descriptionEn: 'Dining room',
        },
        {
            original: salon2,
            description: 'Salon avec TV',
            descriptionEn: 'Living room with TV',
        },
        {
            original: cuisine,
            description:
                'Cuisine équipée avec plaque gaz, four, micro-ondes, lave-vaisselle, etc.',
            descriptionEn:
                'Kitchen equipped with gas hob, oven, microwave, dishwasher, etc.',
        },
        {
            original: cuisine2,
            description:
                'Cuisine équipée avec plaque gaz, four, micro-ondes, lave-vaisselle, etc.',
            descriptionEn:
                'Kitchen equipped with gas hob, oven, microwave, dishwasher, etc.',
        },
        {
            original: plage,
            description: 'Plage entre le Cormier et Tharon',
            descriptionEn: 'Beach between Le Cormier and Tharon',
        },
    ];
    return listing;
}
