import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ImAirplane } from 'react-icons/im';
import { FaUserTie } from 'react-icons/fa6';

import {
    connectAccountStaff,
    getAuthUser,
} from '../../Store/Actions/user.actions';

function Login() {
    const [inputPhone, setInputPhone] = useState(undefined);
    const [watchBtnCode, setWatchBtnCode] = useState(false);
    const [sendCodeSMS, setSendCodeSMS] = useState(undefined);
    const [inputCodeSMS, setInputCodeSMS] = useState(undefined);
    const [inputPhoneError, setInputPhoneError] = useState(undefined);
    const [mins, setMinutes] = useState(15);
    const [secs, setSeconds] = useState(0);
    document.title = 'Ma Maison Sereine - Interface de Connexion';
    document.description =
        "Page de Connexion à l'espace utilisateur et abonné de Ma Maison Sereine - La Plaine sur Mer";
    const [typeAccount, setTypeAccount] = useState(undefined);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const authUser = useSelector((store) => store.User.authUser);
    const accessToken = useSelector((store) => store.User.accessToken);
    const translate = useSelector((store) => store.Template.language);
    function checkLengthPhone(e) {
        if (e.target.value.length === 10) {
            setWatchBtnCode(true);
        } else {
            setWatchBtnCode(false);
        }
    }

    function checkLengthCodeSms(e) {
        if (e.target.value.length === 8) {
            setInputCodeSMS(e.target.value);
        } else {
            setInputCodeSMS(undefined);
        }
    }
    function checkValuePhone(e) {
        if (!/^[0-9]{10}$/i.test(e.target.value)) {
            {
                translate === 'French' &&
                    toast.error(
                        'Oups 😦 Le format de votre N° de Portable est invalide ! format 0612345678 requis ',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
            {
                translate === 'English' &&
                    toast.error(
                        'Oops 😦 The format of your mobile number is invalid! format 0612345678 required',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }

            setInputPhoneError(true);
        } else {
            setInputPhone(e.target.value);
            setInputPhoneError(undefined);
        }
    }
    function handleSubmitPhone(e) {
        if (inputPhoneError === true) {
            {
                translate === 'French' &&
                    toast.error(
                        'Oups 😦 Le format de votre N° de Portable est invalide ! format 0612345678 requis ',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
            {
                translate === 'English' &&
                    toast.error(
                        'Oops 😦 The format of your mobile number is invalid! format 0612345678 required',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
        } else {
            e.preventDefault();
            async function fetchData(e) {
                try {
                    const response = await fetch(
                        `https://api.mamaisonsereine.fr/api/authentication/phone/connect/${inputPhone}/`,
                        {
                            method: 'get',
                            headers: new Headers({
                                Accept: 'application/json',
                            }),
                        }
                    );
                    const data = await response.json();
                    if (response.status === 202) {
                        {
                            translate === 'French' &&
                                toast.success(
                                    'Code SMS envoyé, merci de patienter quelques instants pour la réception',
                                    {
                                        position: toast.POSITION.BOTTOM_RIGHT,
                                    },
                                    setSendCodeSMS(true),
                                    setMinutes(15),
                                    setSeconds(0)
                                );
                        }
                        {
                            translate === 'English' &&
                                toast.success(
                                    'SMS code sent, please wait a few moments for reception',
                                    {
                                        position: toast.POSITION.BOTTOM_RIGHT,
                                    },
                                    setSendCodeSMS(true),
                                    setMinutes(15),
                                    setSeconds(0)
                                );
                        }
                    } else {
                        toast.error(data.msg, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                } catch (err) {
                    {
                        translate === 'French' &&
                            toast.error(
                                "Oups! Impossible d'envoyer un code SMS pour s'authentifier",
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                }
                            );
                    }
                    {
                        translate === 'English' &&
                            toast.error(
                                'Oops! Unable to send an SMS code to authenticate',
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                }
                            );
                    }
                }
            }
            fetchData();
        }
    }

    function handleSubmitConnexion(e) {
        if (inputCodeSMS === undefined) {
            {
                translate === 'French' &&
                    toast.error(
                        'Oups 😦 Code de validation reçu par SMS requis !',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
            {
                translate === 'English' &&
                    toast.error(
                        'Oops 😦 Validation code received by SMS required!',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
        } else {
            e.preventDefault();
            async function fetchData(e) {
                try {
                    const response = await fetch(
                        `https://api.mamaisonsereine.fr/api/authentication/phone/validation/${inputPhone}/${inputCodeSMS}/`,
                        {
                            method: 'get',
                            headers: new Headers({
                                Accept: 'application/json',
                            }),
                        }
                    );
                    const data = await response.json();
                    if (response.status === 202) {
                        {
                            translate === 'French' &&
                                toast('Bienvenue chez Ma Maison Sereine ! 😎', {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                });
                        }
                        {
                            translate === 'English' &&
                                toast('Welcome to My Serene Home! 😎', {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                });
                        }

                        localStorage.setItem('authVoyageurPort', inputPhone);
                        localStorage.setItem('authVoyageurCode', inputCodeSMS);
                        navigate('/voyageur');
                    } else {
                        toast.error(data.msg, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                } catch (err) {
                    {
                        translate === 'French' &&
                            toast.error(
                                "Oups! Impossible d'envoyer votre demande de connexion avec votre code pour s'authentifier",
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                }
                            );
                    }
                    {
                        translate === 'English' &&
                            toast.error(
                                'Oops! Unable to send your connection request with your code to authenticate',
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                }
                            );
                    }
                }
            }
            fetchData();
        }
    }

    function handleSubmitResendCode(e) {
        if (inputPhoneError === true) {
            {
                translate === 'French' &&
                    toast.error(
                        'Oups 😦 Le format de votre N° de Portable est invalide ! format 0612345678 requis ',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
            {
                translate === 'English' &&
                    toast.error(
                        'Oops 😦 The format of your mobile number is invalid! format 0612345678 required',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
        } else {
            e.preventDefault();
            async function fetchData(e) {
                try {
                    const response = await fetch(
                        `https://api.mamaisonsereine.fr/api/authentication/phone/reconnect/${inputPhone}/`,
                        {
                            method: 'get',
                            headers: new Headers({
                                Accept: 'application/json',
                            }),
                        }
                    );
                    const data = await response.json();
                    if (response.status === 202) {
                        {
                            translate === 'French' &&
                                toast.success(
                                    'Code SMS envoyé, merci de patienter quelques instants pour la réception',
                                    {
                                        position: toast.POSITION.BOTTOM_RIGHT,
                                    },
                                    setSendCodeSMS(true),
                                    setMinutes(15),
                                    setSeconds(0)
                                );
                        }
                        {
                            translate === 'English' &&
                                toast.success(
                                    'SMS code sent, please wait a few moments for reception',
                                    {
                                        position: toast.POSITION.BOTTOM_RIGHT,
                                    },
                                    setSendCodeSMS(true),
                                    setMinutes(15),
                                    setSeconds(0)
                                );
                        }
                    } else {
                        toast.error(data.msg, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                } catch (err) {
                    {
                        translate === 'French' &&
                            toast.error(
                                "Oups! Impossible d'envoyer un code SMS pour s'authentifier",
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                }
                            );
                    }
                    {
                        translate === 'English' &&
                            toast.error(
                                'Oops! Unable to send an SMS code to authenticate',
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                }
                            );
                    }
                }
            }
            fetchData();
        }
    }
    useEffect(() => {
        const sampleInterval = setInterval(() => {
            if (secs > 0) {
                setSeconds(secs - 1);
            }
            if (secs === 0) {
                if (mins === 0) {
                    setSendCodeSMS(undefined);
                    setWatchBtnCode(true);
                    clearInterval(sampleInterval);
                } else {
                    setMinutes(mins - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(sampleInterval);
        };
    });
    const handleSubmit = (e) => {
        e.preventDefault();

        if (
            document.getElementById('email').value.length > 0 &&
            document.getElementById('password').value.length > 0
        ) {
            dispatch(
                connectAccountStaff(
                    document.getElementById('email').value,
                    document.getElementById('password').value,
                    location.state
                )
            );
        }
    };
    function redirectHistory() {
        if (
            location.state === '/' ||
            location.state === '' ||
            location.state === null
        ) {
            navigate('/gestion');
        } else {
            navigate(location.state);
        }
    }
    useEffect(() => {
        if (authUser === null && accessToken !== null) {
            dispatch(getAuthUser(accessToken));
        } else if (authUser !== null) {
            redirectHistory();
        }
    }, [accessToken, authUser, dispatch]);

    return (
        <div className="bg-primary min-vh-100">
            <h1 className="text-white text-center fs-1">Ma Maison Sereine</h1>
            <h2 className="text-or text-center fs-1">
                {translate === 'French' && <>Interface de connexion</>}
                {translate === 'English' && <>Login interface</>}
            </h2>
            <hr className="text-white col-3 mx-auto" />

            <div className="col-12 col-md-8 col-lg-5 my-2 mx-auto">
                <div className="row m-auto d-flex flex-row justify-content-around align-content-around flex-wrap-reverse p-3">
                    <div className="bg-white border border-chic border-1 m-2 p-3 text-center rounded ">
                        <h2 className="fs-1">Se connecter</h2>
                        <hr className="text-chic col-5 mx-auto" />
                        {typeAccount === undefined && (
                            <div>
                                <button
                                    className="btn btn-primary m-4 p-1 px-3 text-white"
                                    onClick={() => setTypeAccount('public')}
                                    data-testid="btnLoginPublic"
                                >
                                    {translate === 'French' && (
                                        <>Je suis un Voyageur</>
                                    )}
                                    {translate === 'English' && (
                                        <>I am a Traveler</>
                                    )}{' '}
                                    <ImAirplane />
                                </button>
                                <button
                                    className="btn btn-primary m-4 p-1 px-3 text-white"
                                    data-testid="btnLoginStaff"
                                    onClick={() => setTypeAccount('staff')}
                                >
                                    {translate === 'French' && (
                                        <> Je suis du Staff</>
                                    )}
                                    {translate === 'English' && (
                                        <>I'm from the Staff</>
                                    )}{' '}
                                    <FaUserTie />
                                </button>
                            </div>
                        )}
                        {typeAccount === 'public' && (
                            <form className="mt-3" onSubmit={handleSubmit}>
                                <div className="d-flex flex-column justify-content-center align-content-around">
                                    {sendCodeSMS === undefined && (
                                        <Alert
                                            className="my-3 text-center mx-auto"
                                            color="primary"
                                        >
                                            <i className="fa-solid fa-circle-arrow-right"></i>
                                            {translate === 'French' && (
                                                <>
                                                    Vous pourrez ajouter des
                                                    services/prestations
                                                    supplémentaires en
                                                    saisissant le N° de Portable
                                                    renseigné dans votre
                                                    réservation afin de vous
                                                    connecter.
                                                    <br /> <br />
                                                    Une vérification par SMS est
                                                    nécessaire pour sécuriser
                                                    l'accès à votre réservation
                                                    et prouver votre identité.
                                                </>
                                            )}
                                            {translate === 'English' && (
                                                <>
                                                    You can add
                                                    services/benefits You can
                                                    add services/benefits
                                                    additional in entering the
                                                    number of Mobile entered in
                                                    your reservation in order to
                                                    connect.
                                                    <br /> <br />
                                                    SMS verification is
                                                    necessary for secure access
                                                    to your reservation and
                                                    prove your identity.
                                                </>
                                            )}
                                        </Alert>
                                    )}
                                    <label
                                        className=" text-primary fs-4 mb-3"
                                        htmlFor="phone"
                                    >
                                        {translate === 'French' && (
                                            <>Votre N° de Portable :</>
                                        )}
                                        {translate === 'English' && (
                                            <>Your Mobile Number:</>
                                        )}
                                    </label>
                                    <input
                                        placeholder="06XXXXXXXX"
                                        type="phone"
                                        id="phone"
                                        name="phone"
                                        className="text-center"
                                        autoComplete="phone"
                                        onChange={(e) => checkLengthPhone(e)}
                                        onBlur={(e) => checkValuePhone(e)}
                                        data-testid="inputPhoneVoyageur"
                                    />

                                    {inputPhone === undefined &&
                                        inputPhoneError !== undefined && (
                                            <button className="btn btn-primary text-white my-3">
                                                {translate === 'French' && (
                                                    <>
                                                        {' '}
                                                        Merci de vérifier votre
                                                        N° de portable
                                                    </>
                                                )}
                                                {translate === 'English' && (
                                                    <>
                                                        Please check your number
                                                        of mobile:
                                                    </>
                                                )}
                                            </button>
                                        )}
                                    {sendCodeSMS === undefined &&
                                        inputPhoneError === undefined &&
                                        watchBtnCode === true && (
                                            <button
                                                className="btn btn-primary text-white my-3"
                                                onClick={(e) =>
                                                    handleSubmitPhone(e)
                                                }
                                                data-testid="btnSubmitPhone"
                                            >
                                                {translate === 'French' && (
                                                    <>
                                                        Recevoir mon code de
                                                        connexion et je donne
                                                        mon consentement pour la
                                                        réception du SMS
                                                    </>
                                                )}
                                                {translate === 'English' && (
                                                    <>
                                                        Receive my login code
                                                        and I give my consent
                                                        for the SMS reception
                                                    </>
                                                )}
                                            </button>
                                        )}
                                    {inputPhone !== undefined &&
                                        sendCodeSMS === true &&
                                        mins !== 0 &&
                                        secs !== 0 && (
                                            <>
                                                <label
                                                    htmlFor="codeSMS"
                                                    className="text-primary fs-4 my-3"
                                                >
                                                    {translate === 'French' && (
                                                        <>
                                                            Votre Code reçu par
                                                            SMS :
                                                        </>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>
                                                            Your Code received
                                                            by text message :
                                                        </>
                                                    )}
                                                </label>
                                                <input
                                                    id="codeSMS"
                                                    type="number"
                                                    name="codeSMS"
                                                    className="text-center"
                                                    onChange={(e) =>
                                                        checkLengthCodeSms(e)
                                                    }
                                                />
                                                <Alert
                                                    className="mt-3 text-center "
                                                    color="warning"
                                                >
                                                    <i className="fa-solid fa-circle-arrow-right"></i>{' '}
                                                    {translate === 'French' && (
                                                        <>
                                                            Validité du code SMS
                                                            restante : {mins}{' '}
                                                            min{' '}
                                                            {secs < 10
                                                                ? `0${secs}`
                                                                : secs}{' '}
                                                            sec
                                                        </>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>
                                                            Code validity SMS
                                                            remaining: {mins}{' '}
                                                            min{' '}
                                                            {secs < 10
                                                                ? `0${secs}`
                                                                : secs}{' '}
                                                            sec
                                                        </>
                                                    )}
                                                </Alert>
                                                <p>
                                                    {translate === 'French' && (
                                                        <>
                                                            Merci de patienter
                                                            au moins 30 secondes
                                                            avant de demander le
                                                            renvoi de votre
                                                            code.
                                                        </>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>
                                                            Please wait at least
                                                            30 seconds before
                                                            requesting the
                                                            resend of your code.
                                                        </>
                                                    )}
                                                </p>
                                                <div className="d-flex flex-row justify-content-around ">
                                                    {' '}
                                                    <button
                                                        className="btn btn-secondary text-white my-3"
                                                        type="submit"
                                                        onClick={(e) =>
                                                            handleSubmitResendCode(
                                                                e
                                                            )
                                                        }
                                                    >
                                                        {translate ===
                                                            'French' && (
                                                            <>
                                                                Renvoyer son
                                                                Code SMS
                                                            </>
                                                        )}
                                                        {translate ===
                                                            'English' && (
                                                            <>
                                                                Send Back my
                                                                code
                                                            </>
                                                        )}
                                                    </button>
                                                    <button
                                                        className="btn btn-primary text-white my-3"
                                                        type="submit"
                                                        onClick={(e) =>
                                                            handleSubmitConnexion(
                                                                e
                                                            )
                                                        }
                                                    >
                                                        {translate ===
                                                            'French' && (
                                                            <> Se connecter</>
                                                        )}
                                                        {translate ===
                                                            'English' && (
                                                            <>Log In</>
                                                        )}
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                </div>
                            </form>
                        )}
                        {typeAccount === 'staff' && (
                            <form className="mt-3" onSubmit={handleSubmit}>
                                <div className="d-flex flex-column justify-content-center align-content-around">
                                    <label
                                        className=" text-chic fs-4 mb-3"
                                        htmlFor="email"
                                    >
                                        {translate === 'French' && (
                                            <>Votre Email :</>
                                        )}
                                        {translate === 'English' && (
                                            <>Your email :</>
                                        )}
                                    </label>
                                    <input
                                        placeholder="adresse@domaine.fr"
                                        type="email"
                                        id="email"
                                        name="email"
                                        autoComplete="email"
                                        data-testid="inputMailStaff"
                                    />

                                    <label
                                        htmlFor="password"
                                        className="text-primary fs-4 mb-3"
                                    >
                                        {translate === 'French' && (
                                            <>Votre Mot de Passe :</>
                                        )}
                                        {translate === 'English' && (
                                            <>Your Password :</>
                                        )}
                                    </label>
                                    <input
                                        id="password"
                                        type="password"
                                        name="password"
                                        autoComplete="current-password"
                                        data-testid="inputPasswordStaff"
                                    />
                                </div>
                                <button
                                    className="btn btn-primary text-white my-3"
                                    type="submit"
                                    data-testid="btnSubmitStaff"
                                >
                                    {translate === 'French' && (
                                        <>Se connecter</>
                                    )}
                                    {translate === 'English' && <>Log In</>}
                                </button>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
