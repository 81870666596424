import { useSelector } from 'react-redux';

export default function TitleHomeSousLesPins(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className="fs-2">
            <h1>
                {translate === 'French' && <>Location - {props.title}</>}
                {translate === 'English' && <>Rental - {props.title}</>}
            </h1>
            <h2>
                {translate === 'French' && (
                    <>
                        Maison Complète pour 6 voyageurs - 3 chambres - 4 lits -
                        2 salle d'eau avec Douche/Toilette
                    </>
                )}
                {translate === 'English' && (
                    <>
                        Complete house for 6 travelers - 3 bedrooms - 4 beds - 2
                        bathroom with Shower/Toilet
                    </>
                )}
            </h2>
        </div>
    );
}
