import { useSelector } from 'react-redux';
import { FaRegTrashCan } from 'react-icons/fa6';

export default function DechetsBienvenue(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className={props.className}>
            <h2 className="text-center m-3">
                <span className="me-2">
                    <FaRegTrashCan />
                </span>
                {translate === 'French' && <>Les Déchets Ménagers</>}
                {translate === 'English' && <>Household Waste</>}
            </h2>

            <div>
                {translate === 'French' && <>{props.dechetsFr}</>}
                {translate === 'English' && <>{props.dechetsEn}</>}
            </div>
        </div>
    );
}
