import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import CalendarBooking from '../../../components/Reservations/CalendarBooking.jsx';
import Carrousel from '../../../components/Logements/Caroussel.jsx';
import ListingDetailsSousLesPins from '../../../components/Logements/SousLesPins/ListingDetails.souslespins.jsx';
import TitleHomeSousLesPins from '../../../components/Logements/SousLesPins/TitleHome.souslespin.jsx';
import DescriptionLogSousLesPinsHome from '../../../components/Logements/SousLesPins/TextDescriptionHome.souslespin.jsx';
import { getApartments } from '../../../Store/Actions/smoobu.actions';

export default function PageSousLesPinHome(props) {
    document.title = `Location '${props.title}' à la Préfailles 44770 | Ma Maison Sereine`;
    const translate = useSelector((store) => store.Template.language);
    const dispatch = useDispatch();
    const dataApartments = useSelector((store) => store.Smoobu.dataApartments);
    useEffect(() => {
        setTimeout(() => {
            dispatch(getApartments());
        }, 1000);
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <main className="page_logement">
            <article className="page_logement__content">
                <Carrousel images={props.images} />
                <img
                    src={props.logo}
                    alt={props.title}
                    title={props.title}
                    loading="lazy"
                    width={'160px'}
                    className="card-location-logo"
                />
                <TitleHomeSousLesPins title={props.title} />
                <div className="bg-primary hr-h my-4"></div>
                <DescriptionLogSousLesPinsHome />
                <div className="bg-primary hr-h my-4"></div>
                <ListingDetailsSousLesPins
                    dataApartments={dataApartments}
                />{' '}
                <div className="bg-primary hr-h my-4"></div>
                <Carrousel images={props.images} active={true} />
                <div className="bg-primary hr-h my-4"></div>
                <div className="d-flex flex-row flex-wrap">
                    <div className="d-block d-md-none bg-primary hr-h my-4"></div>
                    <div className="calendarWidget locations__localisation mx-auto">
                        <h2 className="fs-1 mb-3 text-center">
                            {translate === 'French' && (
                                <>Calendrier de Réservation</>
                            )}
                            {translate === 'English' && <>Booking Calendar</>}
                        </h2>
                        {dataApartments !== null &&
                            dataApartments.map(
                                (appart) =>
                                    appart.id === props.id && (
                                        <div key={appart.id}>
                                            <h2>
                                                <i className="fa-solid fa-map-location-dot"></i>{' '}
                                                {appart.name}
                                            </h2>
                                            <CalendarBooking
                                                apartment={appart}
                                                channel={'airbnb'}
                                                linkAirbnb={
                                                    'https://www.airbnb.fr/rooms/574479848034524875?source_impression_id=p3_1692274502_GQx9lRtXDunSplhX'
                                                }
                                            />
                                        </div>
                                    )
                            )}
                    </div>
                </div>
            </article>
            <section className="sect__widgets">
                <CardHomeWidgetsBottom />
            </section>
        </main>
    );
}
