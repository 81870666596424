import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FiPaperclip } from 'react-icons/fi';
import { GiNewspaper } from 'react-icons/gi';
import logoSer1 from '../../../assets/img/logo/logo__ma-maison-sereine.png';

import marches from '../../../assets/img/villarose/photos/marcheaccess.jpg';
import qrcode_VillaRose from '../../../assets/qrcode/qrcode_villa-rose.png';
import qrcode_wifi_ser1 from '../../../assets/qrcode/qrcode_wifi_mms.png';

import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import Header from '../../../components/Header/Header.jsx';
import TitleBienvenue from '../../../components/Logements/bienvenue/Title.bienvenue.jsx';
import GpsBienvenue from '../../../components/Logements/bienvenue/Gps.bienvenue.jsx';
import ArriveeBienvenue from '../../../components/Logements/bienvenue/Arrivee.bienvenue.jsx';
import BoiteCleBienvenue from '../../../components/Logements/bienvenue/BoiteCle.bienvenue.jsx';
import InternetBienvenue from '../../../components/Logements/bienvenue/Internet.bienvenue.jsx';
import ChauffageBienvenue from '../../../components/Logements/bienvenue/Chauffage.bienvenue.jsx';
import JardinBienvenue from '../../../components/Logements/bienvenue/Jardin.bienvenue.jsx';
import ContactUrgence from '../../../components/Logements/ContactUrgence.jsx';
import DechetsBienvenue from '../../../components/Logements/bienvenue/Dechets.bienvenue.jsx';
import DurantSejourBienvenue from '../../../components/Logements/bienvenue/DurantSejour.bienvenue.jsx';
import DepartBienvenue from '../../../components/Logements/bienvenue/Depart.bienvenue.jsx';
import MerciBienvenue from '../../../components/Logements/bienvenue/Merci.bienvenue.jsx';

export default function BienvenueVillaRose() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    document.title = 'Bienvenue à VillaRose | Ma Maison Sereine';
    document.description =
        'Découvrez les recommandations pour profiter au mieux de votre logement lors de votre séjour.';
    const [className] = useState(
        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
    );
    const translate = useSelector((store) => store.Template.language);
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

    const weekNumber = Math.ceil(days / 7);
    return (
        <>
            <Header />
            <main className="bg-primary ">
                <div className="d-flex flex-row justify-content-around align-items-center pt-4">
                    <img
                        src={logoSer1}
                        alt={'Logo - logoSer1'}
                        title={'Logo - logoSer1'}
                        loading="lazy"
                        width={'180px'}
                        className="card-location-logo mt-3 rounded-2 border border-1 border-primary"
                    />
                    <div className=" screen-none_qrcode">
                        <img
                            src={qrcode_VillaRose}
                            alt="QRCode VillaRose"
                            width={'190px'}
                            className="card-location-logo mt-4 pt-3"
                        />
                        <small className="text-white mt-2">
                            Version Mobile & <span>English Version</span>{' '}
                            <div className="fi fi-gb"></div>
                        </small>
                    </div>
                </div>
                <TitleBienvenue className={className} title={'Villa Rose'} />
                <GpsBienvenue
                    className={className + ' no-print'}
                    address={'4 Rue des Ajoncs 44770 Préfailles'}
                    gpsGoogle={'https://maps.app.goo.gl/58GviGSRBeDqAiac8'}
                    gpsApple={
                        'https://maps.apple.com/?address=4%20Rue%20des%20Ajoncs,%2044770%20Pr%C3%A9failles,%20France&ll=47.124952,-2.198553&q=4%20Rue%20des%20Ajoncs&_mvs=CjYJRY3BPwSQR0AR41+bJweXAcAZAAALUsQZU0Ah2+ih4X6dZEApr+s8hDCbKkAxAAAAAAAAAAASHwoQNCBSdWUgZGVzIEFqb25jcxoLUHLDqWZhaWxsZXM%3D'
                    }
                    gpsWaze={
                        'https://ul.waze.com/ul?ll=47.12542504%2C-2.19838142&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location'
                    }
                />
                <BoiteCleBienvenue
                    className={className}
                    boiteFr={
                        <>
                            <p>
                                A votre arrivée, vous pouvez être accueilli par
                                votre concierge Fabien ou récupérer les clefs
                                via la boite à clef pour davantage de liberté.{' '}
                            </p>
                            <p>
                                Un système de boîte à clés est présent au niveau
                                de la boîte aux lettres du portail. Le code vous
                                a été envoyé au préalable par sms quelques jours
                                avant votre venue.
                            </p>

                            <p>
                                Il vous est remis un jeu de 5 clefs dont une
                                clef sécurisée. En cas de perte, il vous sera
                                facturé 100€ le nouveau jeu. Vous avez 3 accès à
                                la maison (entrée principale, garage et jardin).
                                Vous pouvez vous partager le jeu de clefs afin
                                que chacun ait accès à la maison. Dans ce cas,
                                attention de ne pas fermer la porte de
                                communication de la cuisine vers le garage.
                            </p>
                            <p className="bg-antique-light p-3">
                                Un état des lieux vous sera remis à votre
                                arrivée. Vous disposez de 24h pour nous faire
                                remonter toutes constatations non spécifiées au
                                préalable.
                            </p>
                        </>
                    }
                    boiteEn={
                        <>
                            <p>
                                On your arrival, you may be greeted by your
                                concierge Fabien or collect the keys via the key
                                box for more freedom.{' '}
                            </p>
                            <p>
                                A key box system is present at the portal
                                mailbox level. The code has been sent to you at
                                beforehand by email or sms a few days before you
                                come.
                            </p>
                            <p>
                                You are given a set of 5 keys, one of which
                                secure key. In case of loss, you will be charged
                                €100 for the new game. You have 3 accesses to
                                the house (main entrance, garage and garden).
                                You can share the set of keys between you in
                                order to that everyone has access to the house.
                                In this case, be careful not to close the door
                                communication from the kitchen to the garage.
                            </p>
                            <p className="bg-antique-light p-3">
                                An inventory will be given to you at your
                                arrival. You have 24 hours to let us know report
                                any unspecified findings to prior.
                            </p>
                        </>
                    }
                />
                <ArriveeBienvenue
                    className={className}
                    presentiel={true}
                    hourFr={'17h00'}
                    hourEn={'5p.m.'}
                    parkingFr={
                        <p>
                            Vous pouvez vous garer dans la cours intérieur (max
                            2 voiture), mais pas dans le garage.
                        </p>
                    }
                    parkingEn={
                        <p>
                            You can park in the interior courtyard (max 2 cars),
                            but not in the garage.
                        </p>
                    }
                    alertFr={
                        <>
                            Faites attention aux trois marches d'accès à la
                            porte d'entrée
                        </>
                    }
                    alertEn={
                        <>
                            Pay attention to the three steps leading to the
                            front door
                        </>
                    }
                    photos={
                        <>
                            {' '}
                            <div className="d-flex flex-row flex-wrap justify-content-around align-items-center mx-auto">
                                <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                    <img
                                        src={marches}
                                        alt={'marches '}
                                        loading="lazy"
                                        width={'250px'}
                                        className="card-location-logo rounded-4"
                                    />
                                    {translate === 'French' && (
                                        <small>Marches </small>
                                    )}
                                    {translate === 'English' && (
                                        <small>steps </small>
                                    )}
                                </div>
                            </div>
                        </>
                    }
                />

                <InternetBienvenue
                    className={className}
                    access={true}
                    wifiName={'Freebox'}
                    wifiPass={'*cotedejade2020*'}
                    infoFr={
                        <>
                            <p>
                                Vous pouvez vous connecter au réseau Wifi de la
                                maison
                            </p>
                        </>
                    }
                    infoEn={
                        <>
                            <p>
                                You can connect to the Wifi network of the House
                            </p>
                        </>
                    }
                />
                <ChauffageBienvenue
                    className={className}
                    consigneFr={
                        <>
                            <p>
                                Le Chauffage est automatique mais vous pouvez
                                augmenter ou baisser la température de consigne
                                en cas de besoins avec les touches + et - sur
                                les radiateurs.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Merci aux petits techniciens curieux de ne pas
                                toucher aux boutons (ni aux disjoncteurs ! )
                            </p>

                            <p className="text-center fw-bold text-primary">
                                L'Energie est notre Avenir. Economisons-là
                            </p>
                            <p>
                                Les prises sont dotées d’une sécurité enfant.
                                Pour brancher ou débrancher, tirer vers soi et
                                non de gauche à droite au risque d’abimer la
                                sécurité enfant.
                            </p>
                        </>
                    }
                    consigneEn={
                        <>
                            <p>
                                Heating is automatic but you can increase or
                                lower the set temperature if necessary with the
                                + and - keys on the radiators.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Thank you to the curious little technicians not
                                to touch the buttons (or the circuit breakers! )
                            </p>

                            <p className="text-center fw-bold text-primary">
                                Energy is our Future. Let's save it
                            </p>
                            <p>
                                The sockets are equipped with child safety. To
                                plug or unplug, pull towards you and not from
                                left to right as you risk damaging the child
                                safety lock.
                            </p>
                        </>
                    }
                    dijoncteurFR={'dans le garage'}
                    dijoncteurEN={'in the garage'}
                    linkyFR={"à l'extérieur de la maison"}
                    linkyEN={'outside the house'}
                />

                <JardinBienvenue
                    className={className}
                    jardinFr={
                        <>
                            <p>
                                Vous avez accès à la terrasse ainsi qu'au jardin
                                extérieur. Vous avez un accès direct à la plage
                                via le portillon au fond du terrain.
                            </p>
                            <p>Je rappel que les fêtes sont interdites !</p>
                            <p>
                                Admirez tout au long de la journée et des
                                saisons les nuances du bleu au gris de la mer,
                                ses reflets et ses mouvements. Une ode à
                                l’apaisement !{' '}
                            </p>
                        </>
                    }
                    jardinEn={
                        <>
                            <p>
                                You have access to the terrace as well as the
                                outdoor garden. You have direct access to the
                                beach via the gate at the back of the plot.
                            </p>
                            <p>I remind you that parties are prohibited !</p>
                            <p>
                                Admire throughout the day and the seasons the
                                shades of blue to gray of the sea, its
                                reflections and its movements. An ode to
                                appeasement!{' '}
                            </p>
                        </>
                    }
                    light={false}
                />

                <ContactUrgence className="bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 print-cut-none " />
                <DurantSejourBienvenue
                    className={
                        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
                    }
                    duringFr={
                        <>
                            <p>
                                Pour bébé : Un lit parapluie, pot pour bébé,
                                table à langer, baignoire bébé avec réducteur
                                sont à votre disposition dans le garage. Une
                                chaise haute bébé dans la salle à manger sous
                                l’escalier - sans supplément
                            </p>
                            <p>
                                Nous apportons une attention toute particulière
                                quant à votre bien être et votre sécurité. Le
                                linge de lit et serviettes ont été lavés et
                                séchés à chaleur élevée (60°). Les sanitaires et
                                la cuisine sont nettoyés avec un détergent
                                spécifique. Pour une sécurité optimale toutes
                                les surfaces de contact sont désinfectées avec
                                une solution virucide et la maison est aérée.
                                Fabien procède au nettoyage du logement avec les
                                équipements nécessaires pour la sécurité de
                                tous.
                            </p>
                            <span className="fs-3 text-primary font-title mt-4 text-start">
                                Les règles de vie dans le logement :
                            </span>
                            <ul className="text-start">
                                <li>
                                    Notre logement est non-fumeur. Merci de bien
                                    vouloir fumer dans le jardin; un cendrier
                                    est prévu à cet effet
                                </li>
                                <li>
                                    Fermez les portes et les fenêtres à clés
                                    quand vous quittez la maison pendant la
                                    journée et éteignez les lumières
                                </li>
                                <li>
                                    Pas de nuisances sonores excessives. Vous
                                    pouvez naturellement dîner dans le jardin
                                    mais ne mettez pas de musique trop forte car
                                    cela gênerait les voisins...
                                </li>
                                <li>
                                    Ne laissez pas les sacs-poubelles dehors :
                                    les chats et les chiens du quartier
                                    viendraient les éventrer et éparpiller les
                                    détritus...
                                </li>
                                <li>
                                    Pas de gaspillage d’eau, nous sommes dans
                                    une région où l’eau est une ressource
                                    précieuse...
                                </li>
                                <li>
                                    Prudence lors de vos barbecues ! Tenez-le
                                    bien éloigné des arbres et des haies, nous
                                    sommes en période de sécheresse et le risque
                                    d’incendie est élevé...
                                </li>
                                <li>
                                    Au retour de la plage, merci d’enlever la
                                    majeure partie du sable au niveau du robinet
                                    extérieur situé à côté du cabanon, à droite
                                    de la terrasse. Le sable bouche les
                                    canalisations des salles de bains.
                                </li>
                            </ul>
                        </>
                    }
                    duringEn={
                        <>
                            <p>
                                For baby: A travel cot, baby potty, changing
                                table, baby bath with reducer are at your
                                disposal in the garage. A baby high chair in the
                                dining room under the stairs - no extra charge
                            </p>
                            <p>
                                We pay particular attention regarding your
                                well-being and safety. The bed linen and towels
                                have been washed and dried at high heat (60°).
                                Sanitary facilities and the kitchen are cleaned
                                with detergent specific. For optimal safety all
                                contact surfaces are disinfected with a
                                virucidal solution and the house is ventilated.
                                Fabien cleans the accommodation with the
                                equipment necessary for the safety of all.
                            </p>

                            <span className="fs-3 text-primary font-title mt-4 text-start">
                                The rules of life in the accommodation:
                            </span>
                            <ul className="text-start">
                                <li>
                                    Our accommodation is non-smoking. thank you
                                    very much wanting to smoke in the garden; an
                                    ashtray is intended for this purpose
                                </li>
                                <li>
                                    Lock doors and windows with keys when you
                                    leave the house during day and turn off the
                                    lights
                                </li>
                                <li>
                                    No excessive noise pollution. YOU can
                                    naturally dine in the garden but don't play
                                    music that's too loud because it would
                                    bother the neighbors...
                                </li>
                                <li>
                                    Do not leave trash bags outside:
                                    neighborhood cats and dogs would come to
                                    disembowel them and scatter the detritus...
                                </li>
                                <li>
                                    No waste of water, we are in a region where
                                    water is a resource precious...
                                </li>
                                <li>
                                    Be careful during your barbecues! hold it
                                    well away from the trees and hedges, we we
                                    are in a period of drought and the risk fire
                                    is high...
                                </li>
                                <li>
                                    When returning from the beach, please remove
                                    the most of the sand at the tap exterior
                                    located next to the shed, on the right from
                                    the terrace. The sand blocks them bathroom
                                    pipes.
                                </li>
                            </ul>
                        </>
                    }
                />
                <DechetsBienvenue
                    className={className}
                    dechetsFr={
                        <>
                            <p>
                                Merci de jeter tous les déchets dans les sacs
                                poubelles prévus à cet effet. Les poubelles se
                                trouvent au niveau du portail. Une poubelle pour
                                les emballages est à votre disposition dans le
                                garage (poubelle grise à clapet) au quotidien. A
                                votre départ, merci de jeter le sac dans la
                                poubelle jaune à côté du portail.
                            </p>
                            <p className="text-start">
                                Est-ce possible, svp, de mettre les poubelles
                                sur le trottoir pour le ramassage des ordures :
                            </p>
                            <ul>
                                <li>Le Lundi Soir : La poubelle Noire</li>
                                <li>
                                    Le Lundi Soir en semaine paire : La poubelle
                                    Jaune (en Eté toutes les semaines)
                                </li>
                            </ul>
                            <p className="text-start no-print">
                                Nous sommes en semaine {weekNumber} soit une
                                semaine{' '}
                                {weekNumber % 2 === 0 ? 'paire' : 'impaire'}
                            </p>
                            <p>
                                Pour le verre, merci de les vider dans le point
                                de tri rue de la vallée à PREFAILLES ou rue de
                                la croix Douteau à LA PLAINE SUR MER (sur votre
                                chemin, si vous allez à la plaine ou à PORNIC)
                            </p>
                        </>
                    }
                    dechetsEn={
                        <>
                            <p>
                                Please throw all waste into bags bins provided
                                for this purpose. The trash cans are found at
                                the portal.A trash bin for packaging is
                                available to you in the garage (gray flap trash
                                bin) on a daily basis. When you leave, please
                                throw the bag in the yellow bin next to the
                                gate.
                            </p>
                            <p className="text-start">
                                Is it possible to put the trash cans on the
                                sidewalk for garbage collection:
                            </p>
                            <ul>
                                <li>Monday Evening: The Black Trash Can</li>
                                <li>
                                    Monday Evening on week pair: The Yellow
                                    Trash Can (On summer, every week)
                                </li>
                            </ul>
                            <p className="text-start no-print">
                                We are on number week {weekNumber} :
                                {weekNumber % 2 === 0 ? 'pair' : 'impair'} week
                            </p>
                            <p>
                                For glass, please empty them at the sorting
                                point on rue de la Vallée in PREFAILLES or rue
                                de la Croix Douteau in LA PLAINE SUR MER (on
                                your way, if you go to La Plaine or PORNIC)
                            </p>
                        </>
                    }
                />
                <DepartBienvenue
                    className={className}
                    hourFr={'10h00'}
                    hourEn={'10a.m.'}
                    remiseCleFr={
                        ' L’horaire de départ du samedi matin se fera avec Fabien ou en boite à clef avant 10h00. Je vous remercie de respecter cet horaire. '
                    }
                    remiseCleEn={
                        'The departure time for Saturday morning will be with Fabien or in a lockbox before 10:00 a.m. Thank you for respecting this time.'
                    }
                    alertFr={
                        <>
                            Le nécessaire de ménage est à votre disposition dans
                            le garage. Vous avez pris la maison propre, nous
                            vous remercions de la laisser dans le même état.
                            Nous vous demandons de : nettoyer la table à manger,
                            la table basse, le bar de la cuisine, le plan de
                            travail, le four, la plaque de cuisson et le
                            micro-ondes. Nous vous demandons de passer le balai
                            ou aspirateur dans l’ensemble de la maison et de
                            ramasser tous vos déchets.
                        </>
                    }
                    alertEn={
                        <>
                            The cleaning supplies are at your disposal in the
                            garage. You took the house clean, we Thank you for
                            leaving it in the same condition. We ask you to:
                            clean the dining table, the coffee table, the
                            kitchen bar, the countertop work, the oven, the hob
                            and the microwave. We ask you to pass the broom or
                            vacuum cleaner throughout the house and pick up all
                            your trash.
                        </>
                    }
                    faireMenageFr={
                        <>
                            <p>
                                Face au grand nombre d’incivilité et pour le
                                respect de nos techniciens, merci de bien
                                l’effectuer sous peine de litige.
                            </p>
                            <p>
                                « Sur une journée d’affluence un technicien peut
                                avoir jusqu’à 8 lieux à vérifier, imaginer qu’il
                                ne peut à chaque location refaire le ménage… le
                                tout entre 10h00 et 16h00 ! Soyons donc tous
                                vigilent et respectueux ! » Fabien.
                            </p>
                        </>
                    }
                    faireMenageEn={
                        <>
                            <p>
                                Faced with the large number of incivilities and
                                for the respect for our technicians, thank you
                                very much do so under penalty of litigation.
                            </p>
                            <p>
                                “On a busy day a technician can have up to 8
                                places to check, imagine that he cannot at each
                                rental redo the housework… all between 10:00
                                a.m. and 4:00 p.m.! So let's all be vigilant and
                                respectful! » Fabian.
                            </p>
                        </>
                    }
                    basesMenageFr={
                        <>
                            Aérer chaque pièce.
                            <ul>
                                <li>
                                    Dans les chambres : merci de retirer les
                                    draps (les mettre par terre) et nettoyer sol
                                    * et surfaces.
                                </li>
                                <li>
                                    Dans la salle de bain : vider la poubelle,
                                    mettre les serviettes par terre ainsi que le
                                    tapis. Nettoyer sol * et surfaces, bien
                                    essuyer la douche, lavabo, toilettes.
                                </li>
                                <li>
                                    Dans la cuisine : vider la poubelle,
                                    nettoyer le réfrigérateur, micro-ondes,
                                    four, surfaces, sol *…… <br />
                                    Mettre toute la vaisselle utilisée propre en
                                    évidence sur le plan de travail. Rendre la
                                    cuisine propre.
                                </li>
                                <li>
                                    Dans les pièces de vie : nettoyer les
                                    surfaces et sol *.
                                </li>
                                <li>
                                    l’aspirateur est vide à votre arrivée, il
                                    devra être vidé à votre départ.
                                </li>
                            </ul>
                            <p>
                                * : Le nettoyage des sols consiste à aspirer et
                                laver avec la serpillère.
                            </p>
                        </>
                    }
                    basesHouseholdEn={
                        <>
                            Ventilate each room.
                            <ul>
                                <li>
                                    In the rooms: please remove the sheets (put
                                    them on the floor) and clean floor * and
                                    surfaces.
                                </li>
                                <li>
                                    In the bathroom: empty the trash can, put
                                    the towels on the floor as well as the
                                    carpet. Clean floor * and surfaces, wipe the
                                    shower well, sink, toilet.
                                </li>
                                <li>
                                    In the kitchen: empty the trash, clean the
                                    refrigerator, microwave, oven, surfaces,
                                    floor *…… <br />
                                    Put all the dishes used clearly visible on
                                    the plan of work. Make the kitchen clean.
                                </li>
                                <li>
                                    In the living rooms: clean the surfaces and
                                    floor *.
                                </li>
                                <li>
                                    the vacuum cleaner is empty when you arrive,
                                    it must be emptied when you leave.
                                </li>
                            </ul>
                            <p>
                                *: Floor cleaning involves vacuuming and wash
                                with the mop.
                            </p>
                        </>
                    }
                />
                <div className={className}>
                    <h2 className="text-center m-3 ">
                        <GiNewspaper />
                        {translate === 'French' && "Manuels / Modes d'emploi"}
                        {translate === 'English' &&
                            'Manuals / Instructions for use'}
                    </h2>

                    <div>
                        {translate === 'French' ? (
                            <p className="text-start">
                                Voici les manuels d'utilisation des appareils
                                présents dans le logement (Français uniquement):
                            </p>
                        ) : (
                            <p className="text-start">
                                Here are the device user manuals present in the
                                accommodation (French only):
                            </p>
                        )}

                        <ul className="text-start">
                            <li>
                                {translate === 'French'
                                    ? 'Placer votre casserole / poele sur le feu désiré. Appuyer longtemps sur le bonton marche/arret complètement à droite .Appuyer sur le symbole U de la plaque choisie, puis selectionner la puissance souhaitée grâce à la reglette. Le «P» étant la température la plus élevée. Rappuyer sur le chiffre affiché une fois la température choisie.'
                                    : 'Place your saucepan/pan on the desired heat. Press and hold the on/off button all the way to the right. Press the U symbol on the chosen plate, then select the desired power using the dial. The “P” being the temperature the highest. Press the number displayed once the temperature has been chosen.'}
                            </li>
                            <li>
                                {translate === 'French'
                                    ? 'Four : les notices se trouvent dans le meuble sous la télévision.'
                                    : 'Oven: the instructions are in the cabinet under the television.'}
                            </li>
                            <li>
                                {translate === 'French'
                                    ? 'Le lave-vaisselle: placer verres et tasses dans le panier du dessus. Placer assiettes et couverts dans le panier du dessous. Mettre une dosette de produit dans son emplacement. Refermer la porte. Appuyer sur le programme choisi, puis sur MARCHE. Une fois le cycle fini, rappuyer sur MARCHE'
                                    : 'The dishwasher: place glasses and cups in the top basket. Place plates and cutlery in the bottom basket. Place a product pod in its slot. Close the door. Press the chosen program, then START. Once the cycle is finished, press START again.'}
                            </li>
                            <li>
                                {translate === 'French'
                                    ? 'Le lave-linge: mettre vos affaires dans le tambour. Remplir de lessive liquide le bac du tiroir côté de gauche (lavage). Le bac du milieu (produit additionnel – adoucissant –javel…). Bac de droite (prélavage). Appuyer sur DEMARRER. Merci de ne pas utiliser de lessive en poudre afin d’éviter d’obstruer le conduit de lessive'
                                    : 'The washing machine: put your things in the drum. Fill the drawer in the left-hand side drawer (wash) with liquid detergent. The middle tray (additional product – fabric softener – bleach, etc.). Right tray (prewash). Press START. Please do not use powder detergent to avoid blocking the detergent duct.'}
                            </li>
                            <li>
                                {translate === 'French'
                                    ? 'Le miroir de la salle de bains: allumer l’interrupteur à côté de la porte. Les 2 voyants sont rouges. Avant la douche, appuyez sur le voyant de gauche pour éviter la buée. Pour allumer la lumière du cadre, appuyer sur le voyant de droite.'
                                    : 'The bathroom mirror: turn on the switch next to the door. The 2 lights are red. Before the shower, press the left light to avoid fogging. To turn on the light of the frame, press the right light.'}
                            </li>
                        </ul>
                    </div>
                </div>
                <MerciBienvenue
                    className={className}
                    merciFr={
                        <>
                            Mon équipe et moi-même (et oui je fais du ménage
                            aussi) vous remercions par avance de votre
                            investissement dans cette tâche qui est la moins
                            drôle des vacances, mais qui fera notre sourire
                            <br />
                            <br />
                            Merci et un bon retour à vous !
                        </>
                    }
                    merciEn={
                        <>
                            My team and myself (and yes I do cleaning too) thank
                            you in advance for your investment in this task
                            which is the less funny of the holidays, but which
                            will make our smile <br />
                            <br />
                            Thank you and welcome back !
                        </>
                    }
                />
                <section className="sect__widgets">
                    <CardHomeWidgetsBottom />
                </section>
            </main>
        </>
    );
}
