import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FaPersonWalkingLuggage } from 'react-icons/fa6';
import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import Carrousel from '../../../components/Logements/Caroussel.jsx';
import ListingDetailsPecherie from '../../../components/Logements/Pecherie/ListingDetailsPecherie.jsx';
import ListingDetailsPiedsDsLeau from '../../../components/Logements/PiedsDsLeau/ListingDetailsPiedsDsLeau.jsx';

export default function PagePiedsDsLeau(props) {
    document.title = `Location '${props.title}' à Pornic 44210 | Ma Maison Sereine`;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const translate = useSelector((store) => store.Template.language);

    return (
        <main className="page_logement">
            <article className="page_logement__content">
                <Carrousel images={props.images} />
                <img
                    src={props.logo}
                    alt={props.title}
                    title={props.title}
                    loading="lazy"
                    width={'160px'}
                    className="card-location-logo"
                />
                <div className="fs-2">
                    <h1>
                        {translate === 'French' && (
                            <>Location - {props.title}</>
                        )}
                        {translate === 'English' && <>Rental - {props.title}</>}
                    </h1>
                    <h2>
                        {translate === 'French' && (
                            <>
                                Maison Complète pour 6 voyageurs - 3 chambres -
                                1 salle de bain
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                Complete House for 6 travelers - 3 bedrooms - 1
                                bathroom
                            </>
                        )}
                    </h2>
                </div>
                <div className="bg-primary hr-h my-4"></div>
                {translate === 'French' && (
                    <>
                        <p>
                            Admirer la vue mer panoramique sur la Baie de
                            Tharon-Plage avec cette jolie maison de 90m²
                            disposée sur un terrain clos de 1000m² idéal pour
                            les familles avec enfants, se situant dans le
                            quartier du Cormier.{' '}
                        </p>
                        <p>
                            Maison dotée d'un terrain privé permettant de s'y
                            garer. Le logement se situe entre Tharon Plage et La
                            Plaine Sur Mer.
                        </p>
                        <p>
                            Elle comprend un séjour / salle à manger spacieux
                            vue Mer. Vous trouverez également 3 chambres avec
                            une salle de bain.
                        </p>

                        <p>Linge de lit et Ménage non compris.</p>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <p>
                            Admire the panoramic sea view over the Bay of
                            Tharon-Plage with this pretty 90m² house set on
                            enclosed land of 1000m² ideal for families with
                            children, located in the Cormier district.{' '}
                        </p>
                        <p>
                            House with private land allowing parking. The
                            accommodation is located between Tharon Plage and La
                            Plaine Sur Mer.
                        </p>
                        <p>
                            It includes a spacious living/dining room with sea
                            view. You will also find 3 bedrooms with a bathroom.
                        </p>

                        <p>Bed linen and cleaning not included.</p>
                    </>
                )}
                <div className="bg-primary hr-h my-4"></div>
                <ListingDetailsPiedsDsLeau />
                <div className="bg-primary hr-h my-4"></div>
                <Carrousel images={props.images} active={true} />
                <div className="bg-primary hr-h my-4"></div>
                <div className="d-flex flex-row flex-wrap">
                    <div className="d-block d-md-none bg-primary hr-h my-4"></div>
                    <div className="calendarWidget locations__localisation mx-auto">
                        <h2 className="fs-1 mb-3 text-center">
                            {translate === 'French' && (
                                <>Calendrier de Réservation</>
                            )}
                            {translate === 'English' && <>Booking Calendar</>}
                        </h2>
                        <div>
                            <a
                                href={
                                    'https://www.airbnb.fr/rooms/941931039015402788'
                                }
                                rel="noreferrer"
                            >
                                <Button variant="primary shadow m-2">
                                    <FaPersonWalkingLuggage className="fs-4 me-2" />
                                    {translate === 'French' && (
                                        <>Créer ma Réservation sur AirBnB</>
                                    )}
                                    {translate === 'English' && (
                                        <>Create my Booking on AirBnB</>
                                    )}
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
            </article>

            <section className="sect__widgets">
                <CardHomeWidgetsBottom />
            </section>
        </main>
    );
}
