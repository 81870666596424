import { Alert } from 'reactstrap';
import { useSelector } from 'react-redux';
import { MdPhoneInTalk } from 'react-icons/md';
import { FaCommentSms } from 'react-icons/fa6';

export default function ContactUrgence(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className={props.className}>
            <h3 className="fs-2 text-center">
                <span className="fs-2 text-primary">
                    <MdPhoneInTalk />
                </span>{' '}
                Contact
            </h3>

            {translate === 'French' ? (
                <p>
                    Une question, appelez Fabien de 12h00 à 22h00 au 07 66 79 99
                    50 ou laissez-lui un sms. Il vous rappellera dès que
                    possible.
                </p>
            ) : (
                <p>
                    A question, call Fabien from 12:00 p.m. to 10:00 p.m. on 07
                    66 79 99 50 or leave him a text. He will call you back as
                    possible.
                </p>
            )}
            <p className="d-flex flex-row justify-content-around no-print ">
                <a href="tel:+33766799950">
                    <span className="fs-2 text-primary">
                        <MdPhoneInTalk />
                    </span>{' '}
                    {translate === 'French' ? 'Appeler' : 'Call'} Fabien
                </a>
                <a href="sms:+33766799950">
                    <span className="fs-2 text-primary">
                        <FaCommentSms />
                    </span>{' '}
                    {translate === 'French' ? 'Envoyer SMS à ' : 'Send SMS at'}{' '}
                    Fabien
                </a>
            </p>
            <hr />
            <Alert className="text-center fw-bold" color="warning">
                {translate === 'French' ? (
                    <p>
                        En cas de problème avec le logement ou de difficultés
                        d’accès à la location voici le numéro d’astreinte
                        24h/24h :
                    </p>
                ) : (
                    <p>
                        In the event of a problem with the accommodation or
                        difficulties accessing rental here is the number on call
                        24 hours a day:
                    </p>
                )}
                <p className="text-center">
                    <a href="tel:+33773089950">
                        <span className="fs-2">
                            <MdPhoneInTalk />
                        </span>{' '}
                        {translate === 'French'
                            ? " Appeler l'Astreinte au 07 73 08 99 50"
                            : 'Call on-call'}
                    </a>
                </p>
                <small className="text-center">
                    {translate === 'French'
                        ? ' (Numéro d’urgence facturé 50 € si abus.) Répéter vos appels jusqu’à la mise en relation.'
                        : '(Emergency number billed at €50 if abused.) Repeat your calls until connection.'}
                </small>
            </Alert>
            <Alert className="text-center fw-bold" color="danger">
                <p>
                    {translate === 'French'
                        ? " En cas de d'urgence engageant un pronostic vital merci de composer : "
                        : 'In the event of a life-threatening emergency please compose:'}
                </p>
                <div className="d-flex flex-row justify-content-around">
                    <a href="tel:112">
                        <span className="fs-2">
                            <MdPhoneInTalk />
                        </span>{' '}
                        {translate === 'French' ? 'Appeler le 112' : 'Call 112'}
                    </a>{' '}
                </div>{' '}
            </Alert>
            <p className="text-start">
                {' '}
                {translate === 'French'
                    ? "Voici diverses Numéros d'urgence :"
                    : 'Here are various emergency numbers:'}
            </p>
            <ul>
                <li>
                    {translate === 'French'
                        ? ' Urgence Médical : '
                        : 'Emergency Medical : '}
                    <a href="tel:15">
                        <span className="">
                            <MdPhoneInTalk />
                        </span>{' '}
                        15
                    </a>{' '}
                    <a href="tel:112">
                        <span className="">
                            <MdPhoneInTalk />
                        </span>{' '}
                        112
                    </a>{' '}
                </li>

                <li>
                    {translate === 'French'
                        ? 'Centre Hospitalier de Saint Nazaire : '
                        : 'Saint Nazaire Hospital Center : '}
                    <a href="tel: 02 72 27 80 70">
                        <span className="">
                            <MdPhoneInTalk />
                        </span>{' '}
                        02 72 27 80 70
                    </a>
                </li>
                <li>
                    {translate === 'French'
                        ? ' Pharmacie de Garde :'
                        : 'All-night drugstore : '}
                    <a href="tel: 32 37">
                        <span className="">
                            <MdPhoneInTalk />
                        </span>{' '}
                        32 37
                    </a>{' '}
                </li>
                <li>
                    {translate === 'French' ? 'Pompier :' : 'Firefighter : '}
                    <a href="tel: 18">
                        <span className="">
                            <MdPhoneInTalk />
                        </span>{' '}
                        18
                    </a>
                </li>
                <li>
                    {translate === 'French' ? 'Police Secours : ' : 'Police : '}
                    <a href="tel: 17">
                        <span className="">
                            <MdPhoneInTalk />
                        </span>{' '}
                        17
                    </a>
                </li>
            </ul>
        </div>
    );
}
