import accueil from '../../../assets/img/PiedsDsLeau/photos/accueil.webp';

import chambreEnfant from '../../../assets/img/PiedsDsLeau/photos/ch3.webp';
import chambreAdulte from '../../../assets/img/PiedsDsLeau/photos/ch1.webp';
import chambreSup from '../../../assets/img/PiedsDsLeau/photos/ch2.webp';
import cuisine from '../../../assets/img/PiedsDsLeau/photos/cuisine.webp';
import salon from '../../../assets/img/PiedsDsLeau/photos/salon.jpg';
import salleamanger from '../../../assets/img/PiedsDsLeau/photos/salle-a-manger.webp';
import sdb1 from '../../../assets/img/PiedsDsLeau/photos/sdb1.webp';
import sdb2 from '../../../assets/img/PiedsDsLeau/photos/sdb2.webp';
import terrasse from '../../../assets/img/PiedsDsLeau/photos/terrasse.webp';
import terrasse2 from '../../../assets/img/PiedsDsLeau/photos/terrasse2.webp';
import terrainDevant from '../../../assets/img/PiedsDsLeau/photos/terrain_devant.webp';

export default function imagesPiedsDsLeau() {
    const listing = [
        {
            original: accueil,
            description: 'Espace Salon Extérieur',
            descriptionEn: 'Outdoor Lounge Area',
        },
        {
            original: salon,
            description: 'Salon vue sur la mer',
            descriptionEn: 'Sea View - Living room',
        },
        {
            original: salleamanger,
            description: 'Salle à manger',
            descriptionEn: 'Dining room',
        },
        {
            original: cuisine,
            description:
                'Cuisine équipée avec plaque gaz, four, micro-ondes, lave-vaisselle, etc.',
            descriptionEn:
                'Kitchen equipped with gas hob, oven, microwave, dishwasher, etc.',
        },
        {
            original: chambreAdulte,
            description: 'Chambre avec lit double en 140 avec placard',
            descriptionEn: 'Bedroom with double bed 140 with closet',
        },
        {
            original: chambreSup,
            description: 'Chambre avec canapé lit',
            descriptionEn: 'Bedroom with sofa bed',
        },
        {
            original: chambreEnfant,
            description: 'Chambre avec lit bébé avec placard',
            descriptionEn: 'Bedroom with cot with closet',
        },
        {
            original: sdb1,
            description: 'Salle de bain',
            descriptionEn: 'Bathroom',
        },
        {
            original: sdb2,
            description: 'Salle de bain',
            descriptionEn: 'Bathroom',
        },
        {
            original: terrasse,
            description: 'Espace Salon Extérieur',
            descriptionEn: 'Outdoor Lounge Area',
        },
        {
            original: terrasse2,
            description: 'Espace Salon Extérieur',
            descriptionEn: 'Outdoor Lounge Area',
        },
        {
            original: terrainDevant,
            description: 'Entrée du terrain',
            descriptionEn: 'Entrance to the land',
        },
    ];
    return listing;
}
