import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaInfoCircle } from 'react-icons/fa';

export default function HelpSGestionnaire() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <FaInfoCircle /> Informations complémentaires
            </Button>

            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {' '}
                        Services de réservation par le gestionnaire
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        Le principal travail du gestionnaire est de créer et
                        gérer votre annonce, il s'occupe des ventes et de la
                        mise en avant de votre bien pour la localisation. A vous
                        de négocier vos conditions de travail directement avec
                        celui-ci. Cependant si vous travaillez avec un gérant
                        partenaire de Ma Maison Sereine, vous bénéficiez d'une
                        commission réduite sur nos services.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        D'accord
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
