import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import Table from 'react-bootstrap/Table';
import { Alert, Badge, Button } from 'reactstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useFetchGetRates } from '../../../utils/Api/FetchSmoobu.jsx';
import { useFetchGetLocationSettings } from '../../../utils/Api/FetchLocationSettings.jsx';
import LoadingK from '../../Widget/LoadingK.jsx';
import StatusStancerVoyageur from './StatusStancerVoyageur.jsx';
import WidgetRGPD from '../../Widget/RGPD.jsx';

import CoefPrixBaseToSite from '../coefPrix.jsx';

export default function CheckReservation() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    document.title = 'Module de Réservation | Ma Maison Sereine';
    document.description =
        'Page de réservation concernant les logements en location AirBnb';
    const navigate = useNavigate();

    const [isOpenRGPD, setIsOpenRGPD] = useState(false);
    const translate = useSelector((store) => store.Template.language);
    const selectedAppart = useSelector((store) => store.Config.selectedAppart);
    const dateSelected = useSelector((store) => store.Config.dateSelected);
    const authUser = useSelector((store) => store.User.authUser);

    let dateSelectedCalendar = dateSelected;
    if (dateSelectedCalendar[0] === undefined) {
        dateSelectedCalendar = [new Date(), new Date()];
    }
    let selectDateStart = new Date(dateSelectedCalendar[0]);
    const getDate = dateSelectedCalendar[0].getDate();
    selectDateStart.setDate(getDate + 1);
    selectDateStart = selectDateStart.toJSON().split('T')[0];
    const selectDateEnd = dateSelectedCalendar[1].toISOString().split('T')[0];
    let idAppart = 0;
    if (selectedAppart !== undefined) {
        idAppart = selectedAppart.id;
    }
    const { dataLocationSettings, isLoadingLocationSettings } =
        useFetchGetLocationSettings();
    const { dataPlanning } = useFetchGetRates(
        idAppart,
        selectDateStart,
        selectDateEnd
    );
    const [idReservation, setIdReservation] = useState(undefined);
    const [noSended, setNoSended] = useState(true);

    const [lastName, setLastName] = useState(undefined);
    const [firstName, setFirstName] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [numberPhone, setNumberPhone] = useState(undefined);
    const [birthday, setBirthday] = useState(undefined);
    const refBirthday = useRef(null);
    const [adress, setAdress] = useState(undefined);
    const [zipCode, setZipCode] = useState(undefined);
    const [city, setCity] = useState(undefined);
    const [country, setCountry] = useState(undefined);

    const [agree, setAgree] = useState(false);
    const [firstSend, setFirstSend] = useState(true);
    const [nberNight, setNberNight] = useState(0);
    const [totalDate, setTotalDate] = useState(0);
    const [detailsDate, setDetailsDate] = useState(undefined);
    const [detailsDateCalculed, setDetailsDateCalculed] = useState(undefined);

    const [totalOptions, setTotalOptions] = useState(0);
    const [priceOptionsMenage, setPriceOptionsMenage] = useState(0);
    const [priceOptionsAnimaux, setPriceOptionsAnimaux] = useState(0);
    const [nberOptionsLinge2pcouleur, setNberOptionsLinge2pcouleur] =
        useState(0);
    const [nberOptionsLinge1pcouleur, setNberOptionsLinge1pcouleur] =
        useState(0);
    const [nberOptionsLinge2pblanc, setNberOptionsLinge2pblanc] = useState(0);
    const [nberOptionsLinge1pblanc, setNberOptionsLinge1pblanc] = useState(0);
    useEffect(() => {
        if (dataLocationSettings !== undefined) {
            if (nberNight <= dataLocationSettings.number_night_linge) {
                setTotalOptions(
                    nberOptionsLinge1pcouleur *
                        dataLocationSettings.price_linge_1p_couleur +
                        nberOptionsLinge2pcouleur *
                            dataLocationSettings.price_linge_2p_couleur +
                        nberOptionsLinge1pblanc *
                            dataLocationSettings.price_linge_1p_blanc +
                        nberOptionsLinge2pblanc *
                            dataLocationSettings.price_linge_2p_blanc +
                        priceOptionsMenage +
                        priceOptionsAnimaux
                );
            } else {
                setTotalOptions(
                    nberOptionsLinge1pblanc *
                        (dataLocationSettings.price_linge_1p_blanc -
                            dataLocationSettings.price_linge_1p_couleur) +
                        nberOptionsLinge2pblanc *
                            (dataLocationSettings.price_linge_2p_blanc -
                                dataLocationSettings.price_linge_2p_couleur) +
                        priceOptionsMenage +
                        priceOptionsAnimaux
                );
            }
        }
    }, [
        nberOptionsLinge2pcouleur,
        nberOptionsLinge1pcouleur,
        nberOptionsLinge2pblanc,
        nberOptionsLinge1pblanc,
        priceOptionsMenage,
        dataLocationSettings,
        priceOptionsAnimaux,
    ]);
    useEffect(() => {
        setTotalAmount(totalOptions + totalDate);
    }, [totalDate, totalOptions]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [formBookingOK, setFormBookingOK] = useState(false);
    const [bookingOK, setBookingOK] = useState(false);
    useEffect(() => {
        const nberNight = Math.trunc(
            (dateSelectedCalendar[1] - dateSelectedCalendar[0]) /
                (1000 * 60 * 60 * 24)
        );
        setNberNight(nberNight);
        let detailsDate = '';
        let totalDateCheck = 0;
        const dateReservations = new Map();
        let searchedDate;
        if (dataPlanning !== undefined) {
            searchedDate = Object.values(dataPlanning.data, idAppart)[0];
        }
        if (searchedDate !== undefined) {
            for (let i = 0; i < nberNight; i += 1) {
                const newDate = new Date();
                newDate.setFullYear(dateSelectedCalendar[0].getFullYear());
                newDate.setMonth(dateSelectedCalendar[0].getMonth());
                newDate.setDate(dateSelectedCalendar[0].getDate() + i);
                const controlDate = String(newDate.toISOString().split('T')[0]);
                if (
                    Object.hasOwn(searchedDate, controlDate) &&
                    Object.getOwnPropertyDescriptor(searchedDate, controlDate)
                        .value.available === 1
                ) {
                    detailsDate +=
                        controlDate +
                        ' : ' +
                        CoefPrixBaseToSite(
                            Object.getOwnPropertyDescriptor(
                                searchedDate,
                                controlDate
                            ).value.price
                        ) +
                        '€' +
                        ' / ';
                    totalDateCheck += CoefPrixBaseToSite(
                        Object.getOwnPropertyDescriptor(
                            searchedDate,
                            controlDate
                        ).value.price
                    );

                    dateReservations.set(
                        controlDate,
                        Object.getOwnPropertyDescriptor(
                            searchedDate,
                            controlDate
                        ).value
                    );
                }
                setTotalDate(totalDateCheck);
            }
        }
        setTotalDate(totalDateCheck);
        setDetailsDateCalculed(totalDateCheck);
        setTotalAmount(totalDateCheck);
        setDetailsDate(detailsDate);
    }, [dataPlanning, dateSelected, idAppart]);
    let formData = new FormData();
    // FUNCTION SIGNAL TO USER IF DATA SUCCESS OR DATA ERROR ///////////
    // set green background and clean attribute data-error
    function dataChecked(element) {
        element.classList.remove('bg-error');
        element.classList.add('bg-checked');
        element.parentElement.removeAttribute('data-error-visible');
        element.parentElement.removeAttribute('data-error');
    }

    // set red background  and set attribute data-error
    function dataError(element) {
        element.parentElement.setAttribute('data-error-visible', 'true');
        element.classList.add('bg-error');
        element.classList.remove('bg-checked');
    }
    // fct check value into input type=text with min 2 characters
    function checkInputText(e) {
        if (e.target.value.length < 2) {
            dataError(e.target);
            e.target.parentElement.setAttribute(
                'data-error',
                'Veuillez entrer 2 caractères ou plus pour le champ ' +
                    e.target.previousElementSibling.innerText
            );
            if (e.target.id === 'firstname') {
                setFirstName(undefined);
            }
            if (e.target.id === 'lastname') {
                setLastName(undefined);
            }
            if (e.target.id === 'adress') {
                setAdress(undefined);
            }
            if (e.target.id === 'zipCode') {
                setZipCode(undefined);
            }
            if (e.target.id === 'city') {
                setCity(undefined);
            }
            if (e.target.id === 'country') {
                setCountry(undefined);
            }
        } else if (
            // regex text with no integer but with accent into first name for exemple
            !/^[a-zéèçàö]{2,50}(-| )?([a-zéèçàö]{2,50})?$/i.test(e.target.value)
        ) {
            dataError(e.target);
            e.target.parentElement.setAttribute(
                'data-error',
                'Veuillez entrer uniquement du texte pour ce champ.'
            );
            if (e.target.id === 'firstname') {
                setFirstName(undefined);
            }
            if (e.target.id === 'lastname') {
                setLastName(undefined);
            }
            if (e.target.id === 'adress') {
                setAdress(undefined);
            }
            if (e.target.id === 'zipCode') {
                setZipCode(undefined);
            }
            if (e.target.id === 'city') {
                setCity(undefined);
            }
            if (e.target.id === 'country') {
                setCountry(undefined);
            }
        } else {
            dataChecked(e.target);
            if (e.target.id === 'firstname') {
                setFirstName(e.target.value);
            }
            if (e.target.id === 'lastname') {
                setLastName(e.target.value);
            }
            if (e.target.id === 'adress') {
                setAdress(e.target.value);
            }
            if (e.target.id === 'zipCode') {
                setZipCode(e.target.value);
            }
            if (e.target.id === 'city') {
                setCity(e.target.value);
            }
            if (e.target.id === 'country') {
                setCountry(e.target.value);
            }
        }
    }
    function checkInputPhone(e) {
        if (e.target.value.length !== 10) {
            dataError(e.target);
            e.target.parentElement.setAttribute(
                'data-error',
                'Veuillez entrer 10 caractères pour le champ ' +
                    e.target.previousElementSibling.innerText
            );
            setNumberPhone(undefined);
        } else {
            dataChecked(e.target);
            setNumberPhone(e.target.value);
        }
    }

    // search today date, set min date and max date into form birthday (18-90years)
    const today = new Date();
    const maxDateAdult = new Date();
    maxDateAdult.setYear(today.getFullYear() - 18);
    const minDateAdult = new Date();
    minDateAdult.setYear(today.getFullYear() - 90);
    const dateMinInput = String(minDateAdult.toISOString().split('T')[0]);
    const dateMaxInput = String(maxDateAdult.toISOString().split('T')[0]);
    function addDateBirthAdult(element) {
        element.target.setAttribute('min', dateMinInput);
        element.target.setAttribute('max', dateMaxInput);
    }

    // check input data birthday
    function checkDateBirthAdult(element) {
        // if input is not undefined
        if (element.value !== '') {
            const dateMaxAdult = new Date(maxDateAdult).getTime();
            const dateMinAdult = new Date(minDateAdult).getTime();
            const controlDateInput = new Date(element.value).getTime();
            // control if age of user is 18< age <90 years
            if (controlDateInput >= dateMaxAdult) {
                element.parentElement.setAttribute(
                    'data-error',
                    "Vous devez entrer votre date de naissance & être Majeur selon les conditions d'utilisation."
                );
                dataError(element.target);
                setBirthday(undefined);
            } else if (controlDateInput < dateMinAdult) {
                element.parentElement.setAttribute(
                    'data-error',
                    "Vous devez avoir moins de 90ans pour vous inscrire selon les conditions d'utilisation."
                );
                dataError(element.target);
                setBirthday(undefined);
            } else {
                dataChecked(element.target);
                setBirthday(element.target.value);
            }
        } else {
            element.parentElement.setAttribute(
                'data-error',
                'Vous devez entrer votre date de naissance'
            );
            dataError(element.target);
            setBirthday(undefined);
        }
    }
    // fct check value into input type="email"
    function checkValueEmail(element) {
        // control data with regex mail
        if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                element.target.value
            )
        ) {
            dataError(element.target);
            element.target.parentElement.setAttribute(
                'data-error',
                'Vous devez saisir une adresse mail valide.'
            );
            setEmail(undefined);
        } else {
            dataChecked(element.target);
            setEmail(element.target.value);
        }
    }
    useEffect(() => {
        if (authUser !== null && authUser.is_superuser === true) {
            setFirstName(authUser.first_name);
            setLastName(authUser.last_name);
            setNumberPhone(authUser.phone_number);
            setEmail(authUser.username);
            setAdress('Adresse Sereine');
            setZipCode('44770');
            setCity('La Plaine Sur Mer');
            setCountry('France');
            setFormBookingOK(true);
        }
        if (
            firstName !== undefined &&
            lastName !== undefined &&
            email !== undefined &&
            numberPhone !== undefined
        ) {
            setFormBookingOK(true);
        } else {
            setFormBookingOK(false);
        }
    }, [firstName, lastName, numberPhone, email]);
    const [i, setI] = useState(0);
    const [numberRandom, setNomberRandom] = useState(0);
    useEffect(() => {
        let random = Math.random();
        random = random.toString();
        random = random.split('');
        random = random.slice(3, 11);
        let number = '';
        random.map((element) => (number += element.toString()));
        setNomberRandom(number);
        setI(1);
    }, [i]);
    // control if blocked checkbox is checked
    function checkBoxBlocked(element) {
        if (element.checked) {
            setTotalAmount(0);
            setTotalDate(0);

            document.getElementById('phone').value = '0766799950';
            document.getElementById('email').value =
                'contact@mamaisonsereine.fr';
            document.getElementById('lastname').value = 'Blocked';
            document.getElementById('firstname').value = 'Ser1';
        } else {
            setTotalAmount(detailsDateCalculed);
            setTotalDate(detailsDateCalculed);
            document.getElementById('phone').value = numberPhone;
            document.getElementById('email').value = email;
            document.getElementById('lastname').value = lastName;
            document.getElementById('firstname').value = firstName;
        }
    }
    // control if blocked checkbox is checked
    function checkBoxContract(element) {
        if (element.target.checked) {
            setAgree(true);
        } else {
            setAgree(false);
            {
                translate === 'French' &&
                    toast.warning(
                        "Merci d'accepter les conditions de réservations ...",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
            {
                translate === 'English' &&
                    toast.warning(
                        'Thank you for accepting the booking conditions...',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        if (formBookingOK === true && agree === true) {
            if (firstSend === false) {
                formData = new FormData();
            }
            // add into controlForm Map to future send data
            formData.append('nberNight', nberNight);
            formData.append('adults', document.getElementById('adults').value);
            formData.append(
                'children',
                document.getElementById('children').value
            );
            formData.append('lastname', lastName);
            formData.append('firstname', firstName);
            formData.append('email', email);
            formData.append('birthdate', birthday);
            formData.append('phone', numberPhone);
            formData.append('adress', adress);
            formData.append('zipCode', zipCode);
            formData.append('city', city);
            formData.append('country', country);

            try {
                if (document.getElementById('notes').value !== undefined) {
                    formData.append(
                        'notes',
                        document.getElementById('notes').value
                    );
                }
            } catch {
                formData.append('notes', '');
            }
            try {
                if (document.getElementById('msg').value !== undefined) {
                    formData.append(
                        'msg',
                        document.getElementById('msg').value
                    );
                }
            } catch {
                formData.append('msg', '');
            }

            formData.append('totalDate', totalDate);
            formData.append('totalAmount', totalAmount);

            let startDate = new Date();
            startDate.setFullYear(dateSelected[0].getFullYear());
            startDate.setMonth(dateSelected[0].getMonth());
            startDate.setDate(dateSelected[0].getDate());
            startDate = String(startDate.toISOString().split('T')[0]);
            formData.append('arrival', startDate);
            let endDate = new Date();
            endDate.setFullYear(dateSelected[1].getFullYear());
            endDate.setMonth(dateSelected[1].getMonth());
            endDate.setDate(dateSelected[1].getDate());
            endDate = String(endDate.toISOString().split('T')[0]);
            formData.append('departure', endDate);
            formData.append('priceOptionsMenage', priceOptionsMenage);
            formData.append('priceOptionsAnimaux', priceOptionsAnimaux);
            formData.append(
                'nberOptionsLinge2pcouleur',
                nberOptionsLinge2pcouleur
            );
            formData.append(
                'nberOptionsLinge1pcouleur',
                nberOptionsLinge1pcouleur
            );
            formData.append('nberOptionsLinge2pblanc', nberOptionsLinge2pblanc);
            formData.append('nberOptionsLinge1pblanc', nberOptionsLinge1pblanc);
            formData.append('codeSMS', numberRandom);
            formData.append('totalOptions', totalOptions);
            setFirstSend(false);
            setNoSended(false);
            async function PostCreateReservationAdmin(
                selectedAppart,
                formData
            ) {
                try {
                    toast('Demande de réservation en cours...', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 20000,
                    });
                    const response = await fetch(
                        `https://api.mamaisonsereine.fr/api/smoobu/v1/apartment/${selectedAppart.id}/booking/create/`,
                        {
                            method: 'post',
                            headers: new Headers({
                                Accept: 'application/json',
                            }),
                            body: formData,
                        }
                    );
                    const data = await response.json();
                    if (response.status === 400) {
                        let msg = '';

                        Object.keys(data.validation_messages).forEach((key) =>
                            Object.keys(data.validation_messages[key]).forEach(
                                (key2) =>
                                    (msg += data.validation_messages[key][key2])
                            )
                        );
                        toast.error(
                            'Le serveur de réservation distant est en erreur : ' +
                                msg,
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    } else if (response.status === 401) {
                        toast.error(
                            'Le serveur de réservation distant (Smoobu) est en erreur ' +
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                }
                        );
                    } else if (response.status === 404) {
                        toast.error(
                            'La connexion au serveur de réservation distant est introuvable : ' +
                                data.detail,
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    } else {
                        toast.success(
                            'Création Réussie ! ✅ N°Réservation' + data.id,
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                        setBookingOK(true);
                        setIdReservation(data.get('id'));
                        localStorage.setItem(
                            'authVoyageurPort',
                            document.getElementById('phone').value
                        );
                        localStorage.setItem('authVoyageurCode', numberRandom);
                        navigate('/voyageur');
                    }
                } catch (err) {
                    toast.error(
                        'Oups! Impossible de créer une nouvelle réservation 😔 Contactez le support',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            }
            PostCreateReservationAdmin(selectedAppart, formData);
        } else {
            {
                translate === 'French' &&
                    toast.error(
                        "Merci de remplir les champs requis et d'accepter les conditions de réservation",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
            {
                translate === 'English' &&
                    toast.error(
                        'Please fill in the required fields and accept the booking conditions',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
        }
    };

    return (
        <div className="my-5 col-11 col-lg-9 mx-auto">
            <Table bordered hover className=" mx-auto bg-white border border-4">
                <thead>
                    <tr className="text-center fs-5 bg-primary text-white">
                        {translate === 'French' && (
                            <>
                                <th>Désignation</th>
                                <th>Montant</th>
                                <th>Total</th>
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                <th>Designation</th>
                                <th>Amount</th>
                                <th>Total</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {selectedAppart !== null && (
                        <>
                            <tr>
                                <td className="text-center">
                                    <i className="fa-solid fa-house"></i>{' '}
                                    {selectedAppart !== null &&
                                        selectedAppart.name}
                                    <br />
                                    {' du '}
                                    <span className="text-success">
                                        <i className="fa-solid fa-plane-arrival"></i>
                                        {translate === 'French' && (
                                            <>
                                                {dateSelectedCalendar[0].toLocaleDateString(
                                                    'fr-FR',
                                                    {
                                                        weekday: 'short',
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    }
                                                )}{' '}
                                                17h00
                                            </>
                                        )}
                                        {translate === 'English' && (
                                            <>
                                                {dateSelectedCalendar[0].toLocaleDateString(
                                                    'en-Us',
                                                    {
                                                        weekday: 'short',
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                    }
                                                )}{' '}
                                                5p.m.
                                            </>
                                        )}
                                    </span>{' '}
                                    <br />
                                    au{' '}
                                    <span className="text-danger">
                                        <i className="fa-solid fa-plane-departure"></i>
                                        {translate === 'French' && (
                                            <>
                                                {dateSelectedCalendar[1].toLocaleDateString(
                                                    'fr-FR',
                                                    {
                                                        weekday: 'short',
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    }
                                                )}{' '}
                                                10h00
                                            </>
                                        )}
                                        {translate === 'English' && (
                                            <>
                                                {dateSelectedCalendar[1].toLocaleDateString(
                                                    'en-Us',
                                                    {
                                                        weekday: 'short',
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                    }
                                                )}{' '}
                                                10a.m.
                                            </>
                                        )}
                                    </span>{' '}
                                    <br />({nberNight}{' '}
                                    {translate === 'French' && (
                                        <span>nuits</span>
                                    )}{' '}
                                    {translate === 'English' && (
                                        <span>nights</span>
                                    )}
                                    )
                                </td>
                                <td>{detailsDate}</td>
                                <td className="text-end">{totalDate}€</td>
                            </tr>
                            {isLoadingLocationSettings !== true && (
                                <>
                                    {priceOptionsMenage !== 0 && (
                                        <tr>
                                            <td>
                                                <i className="fa-solid fa-house"></i>{' '}
                                                {selectedAppart !== undefined &&
                                                    selectedAppart.name}
                                                <br />
                                                Options
                                            </td>
                                            <td>
                                                {translate === 'French' && (
                                                    <span>
                                                        {' '}
                                                        Forfait Ménage{' '}
                                                    </span>
                                                )}{' '}
                                                {translate === 'English' && (
                                                    <span>Cleaning fee</span>
                                                )}{' '}
                                                ({nberNight} nuits) -{' '}
                                                {priceOptionsMenage} €
                                            </td>
                                            <td className="text-end">
                                                {priceOptionsMenage}€
                                            </td>
                                        </tr>
                                    )}

                                    {priceOptionsAnimaux !== 0 && (
                                        <tr>
                                            <td>
                                                <i className="fa-solid fa-house"></i>{' '}
                                                {idAppart !== undefined &&
                                                    selectedAppart.name}
                                                <br />
                                                Options
                                            </td>
                                            <td>
                                                {translate === 'French' && (
                                                    <span>
                                                        {' '}
                                                        Forfait Animaux{' '}
                                                    </span>
                                                )}{' '}
                                                {translate === 'English' && (
                                                    <span>Pets Pack</span>
                                                )}{' '}
                                                - {priceOptionsAnimaux} €
                                            </td>
                                            <td className="text-end">
                                                {priceOptionsAnimaux}€
                                            </td>{' '}
                                        </tr>
                                    )}
                                    {nberOptionsLinge2pcouleur > 0 && (
                                        <tr>
                                            <td>
                                                <i className="fa-solid fa-house"></i>{' '}
                                                {selectedAppart !== undefined &&
                                                    selectedAppart.name}
                                                <br />
                                                Options
                                            </td>
                                            <td>
                                                {translate === 'French' && (
                                                    <span>
                                                        Forfait Pack Linge Lit
                                                        Couleur 2p (140) -{' '}
                                                    </span>
                                                )}
                                                {translate === 'English' && (
                                                    <span>
                                                        Color Bed Linen Pack
                                                        Package 2p (140) -{' '}
                                                    </span>
                                                )}
                                                {nberNight >
                                                dataLocationSettings.number_night_linge ? (
                                                    <span>
                                                        {' '}
                                                        {translate ===
                                                            'French' && (
                                                            <>Inclus</>
                                                        )}
                                                        {translate ===
                                                            'English' && (
                                                            <>Included</>
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {
                                                            nberOptionsLinge2pcouleur
                                                        }{' '}
                                                        x{' '}
                                                        {
                                                            dataLocationSettings.price_linge_2p_couleur
                                                        }{' '}
                                                        €
                                                    </span>
                                                )}
                                            </td>
                                            <td className="text-end">
                                                {nberNight >
                                                dataLocationSettings.number_night_linge ? (
                                                    <span>
                                                        {translate ===
                                                            'French' && (
                                                            <>Inclus</>
                                                        )}
                                                        {translate ===
                                                            'English' && (
                                                            <>Included</>
                                                        )}{' '}
                                                        (x
                                                        {
                                                            nberOptionsLinge2pcouleur
                                                        }
                                                        )
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {nberOptionsLinge2pcouleur *
                                                            dataLocationSettings.price_linge_2p_couleur}
                                                        €
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                    {nberOptionsLinge1pcouleur > 0 && (
                                        <tr>
                                            <td>
                                                <i className="fa-solid fa-house"></i>{' '}
                                                {selectedAppart !== undefined &&
                                                    selectedAppart.name}
                                                <br />
                                                Options
                                            </td>
                                            <td>
                                                {translate === 'French' && (
                                                    <>
                                                        Forfait Pack Linge Lit
                                                        Couleur 1p (90) -{' '}
                                                    </>
                                                )}
                                                {translate === 'English' && (
                                                    <>
                                                        Color Bed Linen Pack
                                                        Package 1p (90) -
                                                    </>
                                                )}

                                                {nberNight >
                                                dataLocationSettings.number_night_linge ? (
                                                    <span>
                                                        {translate ===
                                                            'French' && (
                                                            <>Inclus</>
                                                        )}
                                                        {translate ===
                                                            'English' && (
                                                            <>Included</>
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {
                                                            nberOptionsLinge1pcouleur
                                                        }{' '}
                                                        x{' '}
                                                        {
                                                            dataLocationSettings.price_linge_1p_couleur
                                                        }{' '}
                                                        €
                                                    </span>
                                                )}
                                            </td>
                                            <td className="text-end">
                                                {nberNight >
                                                dataLocationSettings.number_night_linge ? (
                                                    <span>
                                                        {translate ===
                                                            'French' && (
                                                            <>Inclus</>
                                                        )}
                                                        {translate ===
                                                            'English' && (
                                                            <>Included</>
                                                        )}{' '}
                                                        (x
                                                        {
                                                            nberOptionsLinge1pcouleur
                                                        }
                                                        )
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {nberOptionsLinge1pcouleur *
                                                            dataLocationSettings.price_linge_1p_couleur}
                                                        €
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                    {nberOptionsLinge2pblanc > 0 && (
                                        <tr>
                                            <td>
                                                <i className="fa-solid fa-house"></i>{' '}
                                                {selectedAppart !== undefined &&
                                                    selectedAppart.name}
                                                <br />
                                                Options
                                            </td>
                                            <td>
                                                {translate === 'French' && (
                                                    <>
                                                        Forfait Pack Linge Lit
                                                        Blanc 2p (140) -{' '}
                                                    </>
                                                )}
                                                {translate === 'English' && (
                                                    <>
                                                        White Bed Linen Pack
                                                        Package 2p (140) -
                                                    </>
                                                )}

                                                <span>
                                                    {' '}
                                                    {
                                                        nberOptionsLinge2pblanc
                                                    } x{' '}
                                                    {nberNight >
                                                    dataLocationSettings.number_night_linge ? (
                                                        <>
                                                            {' '}
                                                            {dataLocationSettings.price_linge_2p_blanc -
                                                                dataLocationSettings.price_linge_2p_couleur}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {
                                                                dataLocationSettings.price_linge_2p_blanc
                                                            }
                                                        </>
                                                    )}{' '}
                                                    €
                                                </span>
                                            </td>
                                            <td className="text-end">
                                                <span>
                                                    {nberNight >
                                                    dataLocationSettings.number_night_linge ? (
                                                        <>
                                                            {nberOptionsLinge2pblanc *
                                                                (dataLocationSettings.price_linge_2p_blanc -
                                                                    dataLocationSettings.price_linge_2p_couleur)}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {nberOptionsLinge2pblanc *
                                                                dataLocationSettings.price_linge_2p_blanc}
                                                        </>
                                                    )}
                                                    €
                                                </span>
                                            </td>
                                        </tr>
                                    )}
                                    {nberOptionsLinge1pblanc > 0 && (
                                        <tr>
                                            <td>
                                                <i className="fa-solid fa-house"></i>{' '}
                                                {selectedAppart !== undefined &&
                                                    selectedAppart.name}
                                                <br />
                                                Options
                                            </td>
                                            <td>
                                                {translate === 'French' && (
                                                    <>
                                                        Forfait Pack Linge Lit
                                                        Blanc 1p (90) -
                                                    </>
                                                )}
                                                {translate === 'English' && (
                                                    <>
                                                        White Bed Linen Pack
                                                        Package 1p (90) -
                                                    </>
                                                )}

                                                <span>
                                                    {' '}
                                                    {
                                                        nberOptionsLinge1pblanc
                                                    } x{' '}
                                                    {nberNight >
                                                    dataLocationSettings.number_night_linge ? (
                                                        <>
                                                            {' '}
                                                            {dataLocationSettings.price_linge_1p_blanc -
                                                                dataLocationSettings.price_linge_1p_couleur}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {
                                                                dataLocationSettings.price_linge_1p_blanc
                                                            }
                                                        </>
                                                    )}{' '}
                                                    €
                                                </span>
                                            </td>
                                            <td className="text-end">
                                                {nberNight >
                                                dataLocationSettings.number_night_linge ? (
                                                    <>
                                                        {nberOptionsLinge1pblanc *
                                                            (dataLocationSettings.price_linge_1p_blanc -
                                                                dataLocationSettings.price_linge_1p_couleur)}
                                                    </>
                                                ) : (
                                                    <>
                                                        {nberOptionsLinge1pblanc *
                                                            dataLocationSettings.price_linge_1p_blanc}
                                                    </>
                                                )}
                                                €
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}

                            <tr>
                                <td className="bg-primary"></td>
                                <td>
                                    {translate === 'French' && (
                                        <>
                                            TVA non applicable - article 293 B
                                            du Code général des impôts{' '}
                                        </>
                                    )}
                                    {translate === 'English' && (
                                        <>
                                            VAT not applicable - article 293 B
                                            of the French Tax Code
                                        </>
                                    )}
                                </td>
                                <td className="text-end">0€</td>
                            </tr>
                            <tr>
                                <td className="bg-primary"></td>
                                <td className="text-end fs-5 text-primary">
                                    Total
                                </td>
                                <td
                                    className="text-end fs-5 text-primary"
                                    id="totalAmount"
                                >
                                    {totalAmount}€
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </Table>
            {bookingOK === false ? (
                <>
                    <div className="bg-white border border-3 border-purple p-3">
                        <h2 className="text-center text-purple">
                            {translate === 'French' && (
                                <> Liste des Options Proposées</>
                            )}
                            {translate === 'English' && (
                                <> List of Proposed Options</>
                            )}
                        </h2>
                        {isLoadingLocationSettings !== true &&
                        dataLocationSettings !== undefined ? (
                            <>
                                <p className="text-purple mt-3">
                                    {translate === 'French' && (
                                        <> Forfait Ménage : </>
                                    )}
                                    {translate === 'English' && (
                                        <>Cleaning fee :</>
                                    )}
                                </p>
                                <div className="option__menage mt-3 d-flex flex-row align-items-center px-2 mx-auto">
                                    <div className="text-center w-75">
                                        <i className="mx-1 fa-solid fa-broom fs-5"></i>{' '}
                                        {translate === 'French' && (
                                            <>
                                                {' '}
                                                Ménage ({nberNight}
                                                {' nuits '}){' '}
                                            </>
                                        )}
                                        {translate === 'English' && (
                                            <>
                                                {' '}
                                                Cleaning ({nberNight}
                                                {' nights '})
                                            </>
                                        )}
                                        : +
                                        {(idAppart === 1579532 ||
                                            idAppart === 1579538 ||
                                            idAppart === 1584836) &&
                                            nberNight <=
                                                dataLocationSettings.number_night_short_booking &&
                                            dataLocationSettings.price_menage_cat1_inf}
                                        {(idAppart === 1579532 ||
                                            idAppart === 1579538 ||
                                            idAppart === 1584836) &&
                                            nberNight >
                                                dataLocationSettings.number_night_short_booking &&
                                            dataLocationSettings.price_menage_cat1_sup}
                                        {(idAppart === 1688317 ||
                                            idAppart === 1711442 ||
                                            idAppart === 1583795) &&
                                            nberNight <=
                                                dataLocationSettings.number_night_short_booking &&
                                            dataLocationSettings.price_menage_cat2_inf}
                                        {(idAppart === 1688317 ||
                                            idAppart === 1711442 ||
                                            idAppart === 1583795) &&
                                            nberNight >
                                                dataLocationSettings.number_night_short_booking &&
                                            dataLocationSettings.price_menage_cat2_sup}
                                        €
                                    </div>

                                    <div className="d-flex flex-row w-25">
                                        <Badge
                                            className="m-1 d-flex flex-row justify-content-center align-item-center "
                                            color="danger"
                                            pill
                                            onClick={(e) => {
                                                setPriceOptionsMenage(0);
                                            }}
                                        >
                                            <i className="fa-solid fa-minus"></i>
                                        </Badge>{' '}
                                        x
                                        {priceOptionsMenage === 0 ? (
                                            <span>0</span>
                                        ) : (
                                            <span>1</span>
                                        )}
                                        <Badge
                                            className="m-1 d-flex flex-row justify-content-center align-item-center "
                                            color="success"
                                            pill
                                            onClick={(e) => {
                                                {
                                                    (idAppart === 1579532 ||
                                                        idAppart === 1579538 ||
                                                        idAppart === 1584836) &&
                                                        nberNight <=
                                                            dataLocationSettings.number_night_short_booking &&
                                                        setPriceOptionsMenage(
                                                            dataLocationSettings.price_menage_cat1_inf
                                                        );
                                                }
                                                {
                                                    (idAppart === 1579532 ||
                                                        idAppart === 1579538 ||
                                                        idAppart === 1584836) &&
                                                        nberNight >
                                                            dataLocationSettings.number_night_short_booking &&
                                                        setPriceOptionsMenage(
                                                            dataLocationSettings.price_menage_cat1_sup
                                                        );
                                                }
                                                {
                                                    (idAppart === 1583795 ||
                                                        idAppart === 1688317 ||
                                                        idAppart === 1711442) &&
                                                        nberNight <=
                                                            dataLocationSettings.number_night_short_booking &&
                                                        setPriceOptionsMenage(
                                                            dataLocationSettings.price_menage_cat2_inf
                                                        );
                                                }
                                                {
                                                    (idAppart === 1583795 ||
                                                        idAppart === 1688317 ||
                                                        idAppart === 1711442) &&
                                                        nberNight >
                                                            dataLocationSettings.number_night_short_booking &&
                                                        setPriceOptionsMenage(
                                                            dataLocationSettings.price_menage_cat2_sup
                                                        );
                                                }
                                            }}
                                        >
                                            <i className="fa-solid fa-plus"></i>
                                        </Badge>
                                    </div>
                                </div>
                                <div className="option__animaux mt-3 d-flex flex-row align-items-center px-2 mx-auto">
                                    <div className="text-center w-75">
                                        <i className="mx-1 fa-solid fa-paw fs-5"></i>{' '}
                                        {translate === 'French' && <>Animaux</>}
                                        {translate === 'English' && <>Pets</>}:
                                        +{dataLocationSettings.price_animaux}€
                                    </div>

                                    <div className="d-flex flex-row w-25">
                                        <Badge
                                            className="m-1 d-flex flex-row justify-content-center align-item-center "
                                            color="danger"
                                            pill
                                            onClick={(e) => {
                                                setPriceOptionsAnimaux(0);
                                            }}
                                        >
                                            <i className="fa-solid fa-minus"></i>
                                        </Badge>{' '}
                                        x
                                        {priceOptionsAnimaux === 0 ? (
                                            <span>0</span>
                                        ) : (
                                            <span>1</span>
                                        )}
                                        <Badge
                                            className="m-1 d-flex flex-row justify-content-center align-item-center "
                                            color="success"
                                            pill
                                            onClick={(e) => {
                                                setPriceOptionsAnimaux(
                                                    dataLocationSettings.price_animaux
                                                );
                                            }}
                                        >
                                            <i className="fa-solid fa-plus"></i>
                                        </Badge>
                                    </div>
                                </div>
                                <hr className="col-6 mx-auto" />
                                <p className="text-purple mt-3">
                                    {translate === 'French' && (
                                        <>Pack Linge Couleur (Standard) :</>
                                    )}
                                    {translate === 'English' && (
                                        <>Color Linen Pack (Standard):</>
                                    )}
                                </p>
                                <div className="option__lit2p_couleur mt-3 d-flex flex-row align-items-center px-2 mx-auto">
                                    <div className="text-center w-75">
                                        <i className="ms-1  me-2 fa-solid fa-bed "></i>
                                        {translate === 'French' && (
                                            <>Lit Couleur 2p (140) : </>
                                        )}
                                        {translate === 'English' && (
                                            <>Bed Color 2p (140) : </>
                                        )}

                                        {nberNight >
                                        dataLocationSettings.number_night_linge ? (
                                            <span>
                                                {translate === 'French' && (
                                                    <>Inclus </>
                                                )}
                                                {translate === 'English' && (
                                                    <>Included</>
                                                )}
                                            </span>
                                        ) : (
                                            <span>
                                                +{' '}
                                                {
                                                    dataLocationSettings.price_linge_2p_couleur
                                                }
                                                €/
                                                {translate === 'French' && (
                                                    <> Lit </>
                                                )}
                                                {translate === 'English' && (
                                                    <>Bed</>
                                                )}
                                            </span>
                                        )}
                                    </div>
                                    <div className="d-flex flex-row w-25">
                                        <Badge
                                            className="m-1 d-flex flex-row justify-content-center align-item-center "
                                            color="danger"
                                            pill
                                            onClick={(e) => {
                                                nberOptionsLinge2pcouleur > 0 &&
                                                    setNberOptionsLinge2pcouleur(
                                                        nberOptionsLinge2pcouleur -
                                                            1
                                                    );
                                            }}
                                        >
                                            <i className="fa-solid fa-minus"></i>
                                        </Badge>{' '}
                                        x{nberOptionsLinge2pcouleur}
                                        <Badge
                                            className="m-1 d-flex flex-row justify-content-center align-item-center "
                                            color="success"
                                            pill
                                            onClick={(e) => {
                                                nberOptionsLinge2pcouleur < 6 &&
                                                    setNberOptionsLinge2pcouleur(
                                                        nberOptionsLinge2pcouleur +
                                                            1
                                                    );
                                            }}
                                        >
                                            <i className="fa-solid fa-plus"></i>
                                        </Badge>
                                    </div>
                                </div>
                                <div className="option__lit1p_couleur mt-3 d-flex flex-row align-items-center px-2 mx-auto">
                                    <div className="text-center w-75">
                                        <i className="ms-1  me-2 fa-solid fa-bed "></i>

                                        {translate === 'French' && (
                                            <> Lit Couleur 1p (90) : </>
                                        )}
                                        {translate === 'English' && (
                                            <>Color bed 1p (90):</>
                                        )}
                                        {nberNight >
                                        dataLocationSettings.number_night_linge ? (
                                            <span>
                                                {translate === 'French' && (
                                                    <>Inclus </>
                                                )}
                                                {translate === 'English' && (
                                                    <>Included</>
                                                )}
                                            </span>
                                        ) : (
                                            <span>
                                                +{' '}
                                                {
                                                    dataLocationSettings.price_linge_1p_couleur
                                                }
                                                €/
                                                {translate === 'French' && (
                                                    <> Lit </>
                                                )}
                                                {translate === 'English' && (
                                                    <>Bed</>
                                                )}
                                            </span>
                                        )}
                                    </div>
                                    <div className="d-flex flex-row w-25">
                                        <Badge
                                            className="m-1 d-flex flex-row justify-content-center align-item-center "
                                            color="danger"
                                            pill
                                            onClick={(e) => {
                                                nberOptionsLinge1pcouleur > 0 &&
                                                    setNberOptionsLinge1pcouleur(
                                                        nberOptionsLinge1pcouleur -
                                                            1
                                                    );
                                            }}
                                        >
                                            <i className="fa-solid fa-minus"></i>
                                        </Badge>{' '}
                                        x{nberOptionsLinge1pcouleur}
                                        <Badge
                                            className="m-1 d-flex flex-row justify-content-center align-item-center "
                                            color="success"
                                            pill
                                            onClick={(e) => {
                                                nberOptionsLinge1pcouleur < 6 &&
                                                    setNberOptionsLinge1pcouleur(
                                                        nberOptionsLinge1pcouleur +
                                                            1
                                                    );
                                            }}
                                        >
                                            <i className="fa-solid fa-plus"></i>
                                        </Badge>
                                    </div>
                                </div>
                                <p className="text-purple mt-3">
                                    {translate === 'French' && (
                                        <>
                                            {' '}
                                            Pack Linge Blanc Hôtelier (Premium)
                                            :{' '}
                                        </>
                                    )}
                                    {translate === 'English' && (
                                        <>Hotel White Linen Pack (Premium): </>
                                    )}
                                </p>
                                <div className="option__lit2p_blanc mt-3 d-flex flex-row align-items-center px-2 mx-auto">
                                    <div className="text-center w-75">
                                        <i className="ms-1  me-2 fa-solid fa-bed "></i>

                                        {translate === 'French' && (
                                            <>Lit Blanc 2p (140) :</>
                                        )}
                                        {translate === 'English' && (
                                            <>White bed 2p (140):</>
                                        )}
                                        {nberNight >
                                        dataLocationSettings.number_night_linge ? (
                                            <span>
                                                +{' '}
                                                {dataLocationSettings.price_linge_2p_blanc -
                                                    dataLocationSettings.price_linge_2p_couleur}
                                                €/
                                                {translate === 'French' && (
                                                    <> Lit </>
                                                )}
                                                {translate === 'English' && (
                                                    <>Bed</>
                                                )}
                                            </span>
                                        ) : (
                                            <span>
                                                +{' '}
                                                {
                                                    dataLocationSettings.price_linge_2p_blanc
                                                }
                                                €/
                                                {translate === 'French' && (
                                                    <> Lit </>
                                                )}
                                                {translate === 'English' && (
                                                    <>Bed</>
                                                )}
                                            </span>
                                        )}
                                    </div>
                                    <div className="d-flex flex-row w-25">
                                        <Badge
                                            className="m-1 d-flex flex-row justify-content-center align-item-center "
                                            color="danger"
                                            pill
                                            onClick={(e) => {
                                                nberOptionsLinge2pblanc > 0 &&
                                                    setNberOptionsLinge2pblanc(
                                                        nberOptionsLinge2pblanc -
                                                            1
                                                    );
                                            }}
                                        >
                                            <i className="fa-solid fa-minus"></i>
                                        </Badge>{' '}
                                        x{nberOptionsLinge2pblanc}
                                        <Badge
                                            className="m-1 d-flex flex-row justify-content-center align-item-center "
                                            color="success"
                                            pill
                                            onClick={(e) => {
                                                nberOptionsLinge2pblanc < 6 &&
                                                    setNberOptionsLinge2pblanc(
                                                        nberOptionsLinge2pblanc +
                                                            1
                                                    );
                                            }}
                                        >
                                            <i className="fa-solid fa-plus"></i>
                                        </Badge>
                                    </div>
                                </div>
                                <div className="option__lit1p_blanc mt-3 d-flex flex-row align-items-center px-2 mx-auto">
                                    <div className="text-center w-75">
                                        <i className="ms-1  me-2 fa-solid fa-bed "></i>

                                        {translate === 'French' && (
                                            <> Lit Blanc 1p (90) {' : '}</>
                                        )}
                                        {translate === 'English' && (
                                            <> White Bed 1p (90) {' : '}</>
                                        )}
                                        {nberNight >
                                        dataLocationSettings.number_night_linge ? (
                                            <span>
                                                +{' '}
                                                {dataLocationSettings.price_linge_1p_blanc -
                                                    dataLocationSettings.price_linge_1p_couleur}
                                                €/
                                                {translate === 'French' && (
                                                    <> Lit </>
                                                )}
                                                {translate === 'English' && (
                                                    <>Bed</>
                                                )}
                                            </span>
                                        ) : (
                                            <span>
                                                +{' '}
                                                {
                                                    dataLocationSettings.price_linge_1p_blanc
                                                }
                                                €/
                                                {translate === 'French' && (
                                                    <> Lit </>
                                                )}
                                                {translate === 'English' && (
                                                    <>Bed</>
                                                )}
                                            </span>
                                        )}
                                    </div>
                                    <div className="d-flex flex-row w-25">
                                        <Badge
                                            className="m-1 d-flex flex-row justify-content-center align-item-center "
                                            color="danger"
                                            pill
                                            onClick={(e) => {
                                                nberOptionsLinge1pblanc > 0 &&
                                                    setNberOptionsLinge1pblanc(
                                                        nberOptionsLinge1pblanc -
                                                            1
                                                    );
                                            }}
                                        >
                                            <i className="fa-solid fa-minus"></i>
                                        </Badge>{' '}
                                        x{nberOptionsLinge1pblanc}
                                        <Badge
                                            className="m-1 d-flex flex-row justify-content-center align-item-center "
                                            color="success"
                                            pill
                                            onClick={(e) => {
                                                nberOptionsLinge1pblanc < 6 &&
                                                    setNberOptionsLinge1pblanc(
                                                        nberOptionsLinge1pblanc +
                                                            1
                                                    );
                                            }}
                                        >
                                            <i className="fa-solid fa-plus"></i>
                                        </Badge>
                                    </div>
                                </div>
                                <div>
                                    {nberNight >
                                        dataLocationSettings.number_night_linge && (
                                        <Alert
                                            className="my-3 text-center mx-auto col-10"
                                            color="warning"
                                        >
                                            {translate === 'French' && (
                                                <>
                                                    Le linge de lit est offert
                                                    dans votre séjour de{' '}
                                                    {nberNight} nuités. Merci de
                                                    nous confirmer la
                                                    préparation des lits svp.
                                                </>
                                            )}
                                            {translate === 'English' && (
                                                <>
                                                    Bed linen is offered in your{' '}
                                                    {nberNight} stay nights.
                                                    Thank you for confirming us
                                                    the preparation of the beds
                                                    please.
                                                </>
                                            )}
                                        </Alert>
                                    )}
                                    <hr className="col-6 mx-auto" />
                                    <small>
                                        {translate === 'French' && (
                                            <>
                                                Chaque pack comprend : drap +
                                                housse de couette + taie(s) +
                                                serviette(s) de toilette
                                            </>
                                        )}
                                        {translate === 'English' && (
                                            <>
                                                Each pack includes: sheet +
                                                cover duvet cover +
                                                pillowcase(s) + towel(s) toilet
                                            </>
                                        )}
                                    </small>
                                </div>
                            </>
                        ) : (
                            <LoadingK />
                        )}
                    </div>
                    <form
                        className="d-flex flex-column bg-white  border border-3 border-warning  my-3"
                        onSubmit={(e) => {
                            handleSubmit(e);
                        }}
                    >
                        <div className=" d-flex flex-column">
                            <div id="formDataDetails">
                                <h3 className="mx-auto my-3 fs-2 text-center">
                                    {translate === 'French' && (
                                        <>Détails du Séjour:</>
                                    )}
                                    {translate === 'English' && (
                                        <>Stay details:</>
                                    )}
                                </h3>
                                <div className="d-flex flex-row flex-wrap justify-content-around">
                                    <div className="mx-auto">
                                        <div className="formData mx-auto ">
                                            <label htmlFor="adults">
                                                {translate === 'French' && (
                                                    <>Nombre d'adultes :</>
                                                )}
                                                {translate === 'English' && (
                                                    <>Number of adults:</>
                                                )}
                                            </label>
                                            <input
                                                className="text-control"
                                                type="number"
                                                id="adults"
                                                name="adults"
                                                min={'1'}
                                                max={'6'}
                                                required
                                                defaultValue={'1'}
                                            />
                                        </div>
                                        <div className="formData mx-auto">
                                            <label htmlFor="children">
                                                {translate === 'French' && (
                                                    <>
                                                        Nombre d'enfants (inf.
                                                        18ans):
                                                    </>
                                                )}
                                                {translate === 'English' && (
                                                    <>
                                                        Number of children (inf.
                                                        18 years old):
                                                    </>
                                                )}
                                            </label>
                                            <input
                                                className="text-control"
                                                type="number"
                                                id="children"
                                                name="children"
                                                min={'0'}
                                                max={'6'}
                                                required
                                                defaultValue={'0'}
                                            />
                                        </div>
                                    </div>

                                    <div className="formData  mx-auto">
                                        <label htmlFor="msg">
                                            {translate === 'French' && (
                                                <>Un Message pour Fabien ?</>
                                            )}
                                            {translate === 'English' && (
                                                <>A message for Fabien ?</>
                                            )}
                                        </label>
                                        <textarea
                                            className="text-area mx-auto"
                                            id="msg"
                                            name="msg"
                                            rows="5"
                                            placeholder="ex: Je souhaite préciser que ..."
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div id="formDataCoord">
                                <div className="d-flex flex-row flex-wrap justify-content-around">
                                    <div className="mx-auto">
                                        <h3 className="mx-auto my-3 fs-2 text-center">
                                            {translate === 'French' && (
                                                <>Coordonnées du Voyageur:</>
                                            )}
                                            {translate === 'English' && (
                                                <>
                                                    Traveller's contact details:
                                                </>
                                            )}
                                        </h3>
                                        <div className="d-flex flex-column mx-auto">
                                            <div className="formData">
                                                <label htmlFor="lastname">
                                                    {translate === 'French' && (
                                                        <>Nom:</>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>Last name:</>
                                                    )}
                                                </label>
                                                {authUser !== null ? (
                                                    <input
                                                        className="text-control"
                                                        type="text"
                                                        id="lastname"
                                                        name="lastname"
                                                        autoComplete="family-name"
                                                        required
                                                        placeholder="ex: Doe"
                                                        defaultValue={
                                                            authUser !== null &&
                                                            authUser.last_name
                                                        }
                                                        onBlur={(e) =>
                                                            checkInputText(e)
                                                        }
                                                    />
                                                ) : (
                                                    <input
                                                        className="text-control"
                                                        type="text"
                                                        id="lastname"
                                                        name="lastname"
                                                        autoComplete="family-name"
                                                        required
                                                        placeholder="ex: Doe"
                                                        onBlur={(e) =>
                                                            checkInputText(e)
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div className="formData">
                                                <label htmlFor="firstname">
                                                    {translate === 'French' && (
                                                        <> Prénom:</>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <> First name:</>
                                                    )}
                                                </label>
                                                {authUser !== null ? (
                                                    <input
                                                        className="text-control"
                                                        type="text"
                                                        id="firstname"
                                                        name="firstname"
                                                        autoComplete="given-name"
                                                        autoFocus
                                                        required
                                                        placeholder="ex: John"
                                                        defaultValue={
                                                            authUser.first_name
                                                        }
                                                        onBlur={(e) =>
                                                            checkInputText(e)
                                                        }
                                                    />
                                                ) : (
                                                    <input
                                                        className="text-control"
                                                        type="text"
                                                        id="firstname"
                                                        name="firstname"
                                                        autoComplete="given-name"
                                                        autoFocus
                                                        required
                                                        placeholder="ex: John"
                                                        onBlur={(e) =>
                                                            checkInputText(e)
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div className="formData">
                                                <label for="birthdate">
                                                    {translate === 'French' && (
                                                        <> Date de naissance:</>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <> Date of birth:</>
                                                    )}
                                                </label>
                                                {authUser !== null &&
                                                authUser.is_superuser ===
                                                    true ? (
                                                    <input
                                                        ref={refBirthday}
                                                        className="text-control"
                                                        type="date"
                                                        id="birthdate"
                                                        name="birthdate"
                                                        autocomplete="bday"
                                                    />
                                                ) : (
                                                    <input
                                                        ref={refBirthday}
                                                        className="text-control"
                                                        type="date"
                                                        id="birthdate"
                                                        name="birthdate"
                                                        autocomplete="bday"
                                                        onClick={(e) =>
                                                            addDateBirthAdult(e)
                                                        }
                                                        onChange={(e) =>
                                                            checkDateBirthAdult(
                                                                e
                                                            )
                                                        }
                                                        required
                                                    />
                                                )}
                                            </div>
                                            <div className="formData">
                                                <label htmlFor="email">
                                                    E-mail
                                                </label>

                                                {authUser !== null ? (
                                                    <input
                                                        className="text-control"
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        autoComplete="email"
                                                        onBlur={(e) =>
                                                            checkValueEmail(e)
                                                        }
                                                        required
                                                        placeholder="ex: adresse@domaine.fr"
                                                        defaultValue={
                                                            authUser !== null &&
                                                            authUser.username
                                                        }
                                                    />
                                                ) : (
                                                    <input
                                                        className="text-control"
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        autoComplete="email"
                                                        onBlur={(e) =>
                                                            checkValueEmail(e)
                                                        }
                                                        required
                                                        placeholder="ex: adresse@domaine.fr"
                                                    />
                                                )}
                                            </div>
                                            <div className="formData">
                                                <label htmlFor="phone">
                                                    {translate === 'French' && (
                                                        <>Portable (SMS) :</>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <> Mobile (SMS)</>
                                                    )}
                                                </label>

                                                {authUser !== null ? (
                                                    <input
                                                        className="text-control"
                                                        type="phone"
                                                        id="phone"
                                                        name="phone"
                                                        autoComplete="phone"
                                                        required
                                                        placeholder="ex: 0601020304"
                                                        defaultValue={
                                                            authUser !== null &&
                                                            authUser.phone_number
                                                        }
                                                        onBlur={(e) =>
                                                            checkInputPhone(e)
                                                        }
                                                    />
                                                ) : (
                                                    <input
                                                        className="text-control"
                                                        type="phone"
                                                        id="phone"
                                                        name="phone"
                                                        autoComplete="phone"
                                                        required
                                                        placeholder="ex: 0601020304"
                                                        onBlur={(e) =>
                                                            checkInputPhone(e)
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mx-auto">
                                        <h3 className="mx-auto my-3 fs-2 text-center">
                                            {translate === 'French' && (
                                                <>Adresse de facturation :</>
                                            )}
                                            {translate === 'English' && (
                                                <> Billing address :</>
                                            )}
                                        </h3>
                                        <div className="d-flex flex-column mx-auto">
                                            <div className="formData">
                                                <label htmlFor="adress">
                                                    {translate === 'French' && (
                                                        <>Adresse</>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <> Address :</>
                                                    )}
                                                </label>

                                                {authUser !== null &&
                                                authUser.is_superuser ===
                                                    true ? (
                                                    <input
                                                        className="text-control"
                                                        type="text"
                                                        id="adress"
                                                        name="adress"
                                                        required
                                                        placeholder="Chemin/Rue/Avenue/..."
                                                        maxLength={300}
                                                        defaultValue={
                                                            'Adresse Sereine'
                                                        }
                                                        onBlur={(e) =>
                                                            checkInputText(e)
                                                        }
                                                    />
                                                ) : (
                                                    <input
                                                        className="text-control"
                                                        type="text"
                                                        id="adress"
                                                        name="adress"
                                                        required
                                                        placeholder="Chemin/Rue/Avenue/..."
                                                        maxLength={300}
                                                        onBlur={(e) =>
                                                            checkInputText(e)
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div className="formData">
                                                <label htmlFor="adress">
                                                    {translate === 'French' && (
                                                        <> Code Postal</>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <> Zip Code :</>
                                                    )}
                                                </label>

                                                {authUser !== null &&
                                                authUser.is_superuser ===
                                                    true ? (
                                                    <input
                                                        className="text-control"
                                                        type="text"
                                                        id="zipCode"
                                                        name="zipCode"
                                                        required
                                                        placeholder="44770"
                                                        defaultValue={'44770'}
                                                        maxLength={8}
                                                        onBlur={(e) =>
                                                            checkInputText(e)
                                                        }
                                                    />
                                                ) : (
                                                    <input
                                                        className="text-control"
                                                        type="text"
                                                        id="zipCode"
                                                        name="zipCode"
                                                        required
                                                        placeholder="44770"
                                                        maxLength={8}
                                                        onBlur={(e) =>
                                                            checkInputText(e)
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div className="formData">
                                                <label htmlFor="adress">
                                                    {translate === 'French' && (
                                                        <> Ville</>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>City :</>
                                                    )}
                                                </label>

                                                {authUser !== null &&
                                                authUser.is_superuser ===
                                                    true ? (
                                                    <input
                                                        className="text-control"
                                                        type="text"
                                                        id="city"
                                                        name="city"
                                                        required
                                                        placeholder="Commune"
                                                        defaultValue={
                                                            'La Plaine Sur Mer'
                                                        }
                                                        maxLength={150}
                                                        onBlur={(e) =>
                                                            checkInputText(e)
                                                        }
                                                    />
                                                ) : (
                                                    <input
                                                        className="text-control"
                                                        type="text"
                                                        id="city"
                                                        name="city"
                                                        required
                                                        placeholder="Commune"
                                                        maxLength={150}
                                                        onBlur={(e) =>
                                                            checkInputText(e)
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div className="formData">
                                                <label htmlFor="adress">
                                                    {translate === 'French' && (
                                                        <> Pays</>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>Country :</>
                                                    )}
                                                </label>

                                                {authUser !== null &&
                                                authUser.is_superuser ===
                                                    true ? (
                                                    <input
                                                        className="text-control"
                                                        type="text"
                                                        id="country"
                                                        name="country"
                                                        required
                                                        placeholder="Pays"
                                                        defaultValue={'France'}
                                                        maxLength={150}
                                                        onBlur={(e) =>
                                                            checkInputText(e)
                                                        }
                                                    />
                                                ) : (
                                                    <input
                                                        className="text-control"
                                                        type="text"
                                                        id="country"
                                                        name="country"
                                                        required
                                                        placeholder="Pays"
                                                        defaultValue={'France'}
                                                        maxLength={150}
                                                        onBlur={(e) =>
                                                            checkInputText(e)
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {authUser !== null &&
                            authUser.is_superuser === true && (
                                <div className="mx-auto p-3">
                                    <h3 className="mx-auto my-3 fs-2 text-center text-danger">
                                        Espace Staff:
                                    </h3>

                                    <div className="formData mx-auto mt-2">
                                        <label
                                            htmlFor="notes"
                                            className="text-danger"
                                        >
                                            Note de réservation (en
                                            Interne/Staff)
                                        </label>
                                        <textarea
                                            className="text-area mx-auto"
                                            id="notes"
                                            name="notes"
                                            rows="5"
                                            placeholder="ex: Pack Linge, Jacuzzi etc"
                                        ></textarea>
                                    </div>
                                    <div className="formData d-flex flex-row">
                                        <label
                                            className="checkbox2-label"
                                            htmlFor="blocked"
                                        >
                                            <span className="checkbox-icon"></span>
                                            Logement à bloquer pour maintenance
                                            ?
                                        </label>{' '}
                                        <input
                                            className="checkbox-input ms-2"
                                            type="checkbox"
                                            id="blocked"
                                            name="blocked"
                                            onChange={(e) =>
                                                checkBoxBlocked(e.target)
                                            }
                                        />
                                    </div>
                                </div>
                            )}

                        <div className="d-flex flex-column flex-lg-row flex-wrap">
                            <Button
                                color="warning"
                                onClick={(e) => setIsOpenRGPD(!isOpenRGPD)}
                                className="mx-auto my-1"
                            >
                                {translate === 'French' && (
                                    <> Infos données Personnelles - RGPD</>
                                )}
                                {translate === 'English' && (
                                    <>Personal data information - GDPR</>
                                )}
                            </Button>
                        </div>
                        <Offcanvas
                            show={isOpenRGPD}
                            onHide={(e) => setIsOpenRGPD(!isOpenRGPD)}
                            placement={'bottom'}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>
                                    {translate === 'French' && (
                                        <>
                                            {' '}
                                            L’utilisation de vos données
                                            personnelles
                                        </>
                                    )}
                                    {translate === 'English' && (
                                        <>The use of your data personal</>
                                    )}
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <hr />
                            <Offcanvas.Body>
                                <WidgetRGPD />
                            </Offcanvas.Body>
                        </Offcanvas>

                        <hr className="col-6 mx-auto" />
                        <div className="text-center d-flex flex-row justify-content-center mt-2">
                            <input
                                className="checkbox-input me-2"
                                type="checkbox"
                                id="checkbox1"
                                name="contract"
                                onChange={(e) => checkBoxContract(e)}
                                required
                                aria-label="J'ai lu et accepté les conditions d'utilisation."
                            />
                            <label
                                className="checkbox2-label w-75"
                                for="checkbox1"
                            >
                                <span className="checkbox-icon"></span>
                                {translate === 'French' && (
                                    <>J'ai lu et accepté les </>
                                )}
                                {translate === 'English' && (
                                    <>I have read and accept the </>
                                )}
                                <a
                                    href="https://mamaisonsereine.fr/cgl-reglement-interieur"
                                    target="_blank"
                                    className="text-primary"
                                >
                                    {translate === 'French' && (
                                        <>Conditions Générales de Locations </>
                                    )}
                                    {translate === 'English' && (
                                        <>General Rental Conditions </>
                                    )}
                                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                </a>{' '}
                                {translate === 'French' && <>ainsi que les </>}
                                {translate === 'English' && <>as well as </>}
                                <a
                                    href="https://mamaisonsereine.fr/cgv-mentions-legales"
                                    target="_blank"
                                    className="text-primary"
                                >
                                    {translate === 'French' && (
                                        <>Conditions Générales de Ventes </>
                                    )}
                                    {translate === 'English' && (
                                        <>Terms and conditions </>
                                    )}
                                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                </a>{' '}
                                {translate === 'French' && <>et autorise </>}
                                {translate === 'English' && <>and authorize </>}
                                MaMaisonSereine (ser1){' '}
                                {translate === 'French' && (
                                    <>
                                        à utiliser mes données uniquement à des
                                        fins de réservations de logement.
                                    </>
                                )}
                                {translate === 'English' && (
                                    <>
                                        to use my given solely for the purpose
                                        of accommodation reservations.
                                    </>
                                )}
                            </label>
                        </div>
                        {agree === false ? (
                            <p
                                color="secondary"
                                className="mx-auto mt-2 mb-5 w-75 text-center bg-secondary rounded p-2 cursor-forbidden"
                            >
                                {translate === 'French' && (
                                    <>
                                        Je valide ma demande de réservation et
                                        je vais procéder au règlement
                                    </>
                                )}
                                {translate === 'English' && (
                                    <>
                                        I validate my booking request and I will
                                        make the payment
                                    </>
                                )}
                            </p>
                        ) : (
                            <>
                                {noSended === true && (
                                    <Button
                                        color="primary"
                                        className="mx-auto mt-2 w-75 mb-5 text-center"
                                        type="submit"
                                        id="btn-submit"
                                    >
                                        {translate === 'French' && (
                                            <>
                                                Je valide ma demande de
                                                réservation et je vais procéder
                                                au règlement
                                            </>
                                        )}
                                        {translate === 'English' && (
                                            <>
                                                I validate my booking request
                                                and I will make the payment
                                            </>
                                        )}
                                    </Button>
                                )}
                            </>
                        )}
                        {noSended === false && (
                            <div className="mx-auto text-center">
                                <span>
                                    Merci de patienter - Please Wait -{' '}
                                    <LoadingK />
                                </span>
                            </div>
                        )}
                    </form>
                </>
            ) : (
                <>
                    <Alert className="text-center fs-5" color="warning">
                        {translate === 'French' && (
                            <>
                                Le logement vient de vous être bloqué à la
                                réservation. <hr className="col-5 mx-auto" />{' '}
                                <br /> Cependant vous avez 30 minutes pour
                                procéder au paiement sous peine d'annulation
                                automatique de votre réservation.
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                The accommodation has just been blocked from
                                your reservation.{' '}
                                <hr className="col-5 mx-auto" /> <br /> However
                                you have 30 minutes to make the payment under
                                penalty automatic cancellation of your
                                reservation.
                            </>
                        )}
                    </Alert>
                    {idReservation !== undefined && (
                        <StatusStancerVoyageur
                            idReservation={idReservation}
                            idChannel={2219288}
                        />
                    )}
                </>
            )}
        </div>
    );
}
