import accueil from '../../../assets/img/villarose/photos/accueil.jpg';
import couloirEntree from '../../../assets/img/villarose/photos/entree.jpg';

import salon1 from '../../../assets/img/villarose/photos/salon1.jpg';
import salon2 from '../../../assets/img/villarose/photos/salon2.jpg';
import salon3 from '../../../assets/img/villarose/photos/salon3.jpg';

import cuisine1 from '../../../assets/img/villarose/photos/cuisine.jpg';
import cuisine2 from '../../../assets/img/villarose/photos/cuisine2.jpg';

import chambreMer1 from '../../../assets/img/villarose/photos/chambremer1.jpg';
import chambreMer2 from '../../../assets/img/villarose/photos/chambremer2.jpg';
import chambreHostensia from '../../../assets/img/villarose/photos/chambre2.jpg';
import chambreEtage1 from '../../../assets/img/villarose/photos/chambreetage1.jpg';

import sdb1 from '../../../assets/img/villarose/photos/sdb.jpg';
import sdb2 from '../../../assets/img/villarose/photos/sdb2.jpg';
import sdbetage1 from '../../../assets/img/villarose/photos/sdbetage1.jpg';
import sdbetage2 from '../../../assets/img/villarose/photos/sdbetage2.jpg';
import wc from '../../../assets/img/villarose/photos/wc.jpg';

import etage from '../../../assets/img/villarose/photos/etage.jpg';
import terraseNuit from '../../../assets/img/villarose/photos/terrassenuit.jpg';

export default function imagesVillaRose() {
    const listing = [
        {
            original: accueil,
            description: 'Maison avec terrain clos et vue sur mer',
            descriptionEn: 'House with enclosed grounds and sea view',
        },
        {
            original: couloirEntree,
            description: "Couloir d'accès aux différentes pièces ",
            descriptionEn: 'Access corridor to the different rooms ',
        },
        {
            original: salon1,
            description: "Vue d'ensemble du salon avec vue mer",
            descriptionEn: 'Overview of the living room with sea view',
        },
        {
            original: salon2,
            description: "Vue d'ensemble du salon avec vue mer",
            descriptionEn: 'Overview of the living room with sea view',
        },
        {
            original: salon3,
            description: 'Canape convertible pour 2 personnes en 140cm',
            descriptionEn: 'Convertible sofa for 2 people in 140cm',
        },

        {
            original: cuisine1,
            description: 'Cuisine équipée avec tout le nécessaire.',
            descriptionEn: 'Fitted kitchen with all the necessities.',
        },
        {
            original: cuisine2,
            description: 'Cuisine équipée avec tout le nécessaire.',
            descriptionEn: 'Fitted kitchen with all the necessities.',
        },
        {
            original: chambreMer1,
            description: 'Chambre Vue Mer en rez-de-chaussée ',
            descriptionEn: 'Sea View Room on the ground floor',
        },
        {
            original: chambreMer2,
            description: 'Chambre Vue Mer en rez-de-chaussée ',
            descriptionEn: 'Sea View Room on the ground floor',
        },
        {
            original: chambreHostensia,
            description: 'Chambre Vue Hostensia en rez-de-chaussée ',
            descriptionEN: 'Hostensia View Room on the ground floor',
        },
        {
            original: sdb1,
            description: 'Salle de douche avec doubles vasques',
            descriptionEn: 'Shower room with double sinks',
        },
        {
            original: sdb2,
            description: 'Salle de douche avec doubles vasques',
            descriptionEn: 'Shower room with double sinks',
        },
        {
            original: wc,
            description: 'WC indépendant',
            descriptionEn: 'Independant WC',
        },
        {
            original: etage,
            description: "A l'étage, un palier avec coin bureau et placard",
            descriptionEn: 'Upstairs, a landing with office area and cupboard',
        },
        {
            original: chambreEtage1,
            description: 'chambre mansardée lumineuse',
            descriptionEn: 'Shower room with double sinks',
        },
        {
            original: sdbetage1,
            description: 'Salle de douche avec vasque simple et wc',
            descriptionEn: 'Shower room with sinks and wc',
        },
        {
            original: sdbetage2,
            description: 'Salle de douche avec vasque simple et wc',
            descriptionEn: 'Shower room with sinks and wc',
        },
        {
            original: terraseNuit,
            description: 'Terrasse vue mer de nuit',
            descriptionEn: 'Night sea view terrace',
        },
    ];
    return listing;
}
