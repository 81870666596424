import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { FiPaperclip } from 'react-icons/fi';
import { GiNewspaper } from 'react-icons/gi';

import logoSouslesPinsHome from '../../../assets/img/SSlesPins/logo__SSlesPins-home.jpg';

import parking from '../../../assets/img/SSlesPins/home/parking.jpg';
import qrcode_SSlesPinsHome from '../../../assets/qrcode/qrcode_SSlesPins-home.png';
import qrcode_wifi_ser1 from '../../../assets/qrcode/qrcode_wifi_mms.png';

import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import Header from '../../../components/Header/Header.jsx';
import TitleBienvenue from '../../../components/Logements/bienvenue/Title.bienvenue.jsx';
import GpsBienvenue from '../../../components/Logements/bienvenue/Gps.bienvenue.jsx';
import ArriveeBienvenue from '../../../components/Logements/bienvenue/Arrivee.bienvenue.jsx';
import BoiteCleBienvenue from '../../../components/Logements/bienvenue/BoiteCle.bienvenue.jsx';
import InternetBienvenue from '../../../components/Logements/bienvenue/Internet.bienvenue.jsx';
import ChauffageBienvenue from '../../../components/Logements/bienvenue/Chauffage.bienvenue.jsx';
import JardinBienvenue from '../../../components/Logements/bienvenue/Jardin.bienvenue.jsx';
import ContactUrgence from '../../../components/Logements/ContactUrgence.jsx';
import DechetsBienvenue from '../../../components/Logements/bienvenue/Dechets.bienvenue.jsx';
import DurantSejourBienvenue from '../../../components/Logements/bienvenue/DurantSejour.bienvenue.jsx';
import DepartBienvenue from '../../../components/Logements/bienvenue/Depart.bienvenue.jsx';
import MerciBienvenue from '../../../components/Logements/bienvenue/Merci.bienvenue.jsx';

export default function BienvenueSouslesPinsHome() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    document.title =
        'Bienvenue à "Sous les Pins - La Maison" | Ma Maison Sereine';
    document.description =
        'Découvrez les recommandations pour profiter au mieux de votre logement lors de votre séjour.';

    const [className] = useState(
        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
    );
    const translate = useSelector((store) => store.Template.language);
    return (
        <>
            <Header />
            <main className="bg-primary ">
                <div className="d-flex flex-row justify-content-around align-items-center pt-4">
                    <img
                        src={logoSouslesPinsHome}
                        alt={'Logo - Sous les Pins - La Maison'}
                        title={'Logo - Sous les Pins - La Maison'}
                        loading="lazy"
                        width={'180px'}
                        className="card-location-logo mt-3 rounded-2 border border-1 border-primary"
                    />
                    <div className=" screen-none_qrcode">
                        <img
                            src={qrcode_SSlesPinsHome}
                            alt="QRCode Sous les Pins - La Maison"
                            width={'190px'}
                            className="card-location-logo mt-4 pt-3"
                        />
                        <small className="text-white mt-2">
                            Version Mobile & <span>English Version</span>{' '}
                            <div className="fi fi-gb"></div>
                        </small>
                    </div>
                </div>
                <TitleBienvenue
                    className={className}
                    title={'Sous les Pins - La Maison'}
                />
                <GpsBienvenue
                    className={className + ' no-print'}
                    address={'34 Rue de la Noé Gast, 44770 Préfailles, France'}
                    gpsGoogle={'https://goo.gl/maps/kivRVsokxAPWgDXD7'}
                    gpsApple={
                        'https://maps.apple.com/?address=34%20Rue%20de%20la%20No%C3%AB%20Gast,%2044770%20Pr%C3%A9failles,%20France&ll=47.137552,-2.233935&q=34%20Rue%20de%20la%20No%C3%AB%20Gast'
                    }
                    gpsWaze={
                        'https://ul.waze.com/ul?place=ChIJU8vJx2ZrBUgR6Gfs6GEivPA&ll=47.13765300%2C-2.23519500&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location'
                    }
                />
                <BoiteCleBienvenue
                    className={className}
                    boiteFr={
                        <>
                            <p>
                                Un système de boîte à clés est présent au niveau
                                de la boîte aux lettres du portail (blanc). Le
                                code vous est envoyé au préalable par sms
                                quelques jours avant votre venue.
                            </p>
                        </>
                    }
                    boiteEn={
                        <>
                            <p>
                                A key box system is present at the portal
                                mailbox level (white). The code is sent to you
                                at beforehand by sms a few days before you come.
                            </p>
                        </>
                    }
                />
                <ArriveeBienvenue
                    className={className}
                    hourFr={'17h00'}
                    hourEn={'5p.m.'}
                    parkingFr={
                        <>
                            <p>
                                La grande clé c’est pour ouvrir le portail.
                                (Bien l’enfoncer) ATTENTION : lors de
                                l’ouverture du portail il faut bien mettre la
                                barre de fer à plat sur le sol et ne pas rouler
                                dessus.
                            </p>
                            <p>
                                Un parking est présent face au garage, Même si
                                la tentation est grande à laisser la voiture à
                                l’arrière de la maison,{' '}
                                <span className="text-danger">
                                    c’est interdit
                                </span>
                                , il faut impérativement espacer les sources
                                potentielles de départ de feu merci.
                            </p>
                        </>
                    }
                    parkingEn={
                        <>
                            <p>
                                The big key is to open the gate. (Push it in
                                well) CAUTION: when the opening of the gate it
                                is necessary to put the iron bar flat on the
                                ground and do not rolling over.
                            </p>
                            <p>
                                There is parking opposite the garage, Even if
                                the temptation is great to leave the car behind
                                the house,{' '}
                                <span className="text-danger">
                                    it's forbidden
                                </span>
                                , it is imperative to space the sources
                                potential fire start thank you.
                            </p>
                        </>
                    }
                    photos={
                        <>
                            {' '}
                            <div className="d-flex flex-row flex-wrap justify-content-around align-items-center mx-auto">
                                <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                    <img
                                        src={parking}
                                        alt={'Parking'}
                                        title={'Parking'}
                                        loading="lazy"
                                        width={'290px'}
                                        className="card-location-logo rounded-4"
                                    />

                                    <small>Parking </small>
                                </div>
                            </div>
                        </>
                    }
                />

                <InternetBienvenue
                    className={className}
                    access={true}
                    wifiName={'Ma Maison Sereine'}
                    wifiPass={'mms44770'}
                    infoFr={
                        <>
                            <p>
                                Un réseau wifi 4G est présent avec débit faible
                            </p>
                        </>
                    }
                    infoEn={
                        <>
                            <p>A 4G wifi network is present with low speed</p>
                        </>
                    }
                    qrcode={qrcode_wifi_ser1}
                />
                <ChauffageBienvenue
                    className={className}
                    consigneFr={
                        <>
                            <p>
                                Le Chauffage est automatique mais vous pouvez
                                augmenter ou baisser la température directement
                                sur les thermostats des radiateurs.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Merci de ne pas toucher aux boutons et vannes de
                                la chaudière et attention certaines surfaces
                                sont chaudes.
                            </p>

                            <p className="text-center fw-bold text-primary">
                                L'Energie est notre Avenir. Economisons-là
                            </p>
                        </>
                    }
                    consigneEn={
                        <>
                            <p>
                                Heating is automatic but you can raise or lower
                                the temperature directly on the radiator
                                thermostats.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Please do not touch the buttons and valves of
                                the boiler and be careful, some surfaces are
                                hot.
                            </p>

                            <p className="text-center fw-bold text-primary">
                                Energy is our Future. Let's save it
                            </p>
                        </>
                    }
                />
                <JardinBienvenue
                    className={className}
                    jardinFr={
                        <>
                            <p>
                                Comme dans tous quatiers résidenciels, attention
                                aux bruits démesurés, principalement dans le
                                jardin en soirée.
                            </p>
                            <p>Je rappel que les fêtes sont interdites.</p>
                        </>
                    }
                    jardinEn={
                        <>
                            <p>
                                As in all residential areas, pay attention to
                                excessive noise, mainly in the garden in the
                                evening.
                            </p>
                            <p>I remind you that parties are prohibited.</p>
                        </>
                    }
                    light={false}
                />
                <DechetsBienvenue
                    className={className}
                    dechetsFr={
                        <p>
                            Les poubelles se situe à l’extérieur de la location
                            sur le parking à droit en sortant.
                        </p>
                    }
                    dechetsEn={
                        <p>
                            The trash cans are located outside the rental in the
                            parking lot on the right as you exit.
                        </p>
                    }
                />
                <ContactUrgence className="bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 print-cut-none " />
                <DurantSejourBienvenue
                    className={
                        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
                    }
                    duringFr={
                        <>
                            <p>
                                La maison se situe à proximité de la plage, en
                                descendant la rue vous pourrez observer l'Océean
                                ainsi que la vue sur Le Croisic, Saint-Nazaire
                                etc.
                            </p>
                            <p>
                                Essayez d'aller à la Pointe Saint Gildas de
                                Préfailles, Dépaysement assuré, les vagues sont
                                magnifique, il ne faut pas hésiter à se balader
                                jusqu’au sémaphore.
                            </p>
                        </>
                    }
                    duringEn={
                        <>
                            <p>
                                The house is located near the beach, walking
                                down the street you can see the ocean as well as
                                the view of Le Croisic, Saint Nazaire etc.
                            </p>
                            <p>
                                Try to go to Pointe Saint Gildas from
                                Préfailles, Change of scenery guaranteed, the
                                waves are magnificent, do not hesitate to walk
                                to the semaphore.
                            </p>
                        </>
                    }
                />
                <DepartBienvenue
                    className={className}
                    hourFr={'10h00'}
                    hourEn={'10a.m.'}
                    remiseCleFr={
                        'Merci de bien tout fermer et de remettre les clés dans la boite à clés (du portail) avec brouillage du code avant de partir svp'
                    }
                    remiseCleEn={
                        'Merci de bien tout fermer et de remettre les clés dans la boite à clés (du portail) avec brouillage du code avant de partir svp'
                    }
                    alertFr={
                        <>
                            Vous devez rendre le logement propre comme à votre
                            arrivée sauf dans le cas d'un forfait ménage
                            souscrit au préalable.
                        </>
                    }
                    alertEn={
                        <>
                            You must leave the home clean as your arrival except
                            in the case of a cleaning fee subscribed to the
                            prior.
                        </>
                    }
                    forfaitMenageFr={
                        'Vous devez tout de même rendre le logement dans un état acceptable, vider vos poubelles ainsi que votre vaisselle propre et rangée. '
                    }
                    forfaitMenageEn={
                        'You must still leave the accommodation in an acceptable condition, empty your trash and your dishes clean and tidy'
                    }
                    faireMenageFr={
                        <>
                            <p>
                                Face au grand nombre d’incivilité et pour le
                                respect de nos techniciens, merci de bien
                                l’effectuer sous peine de litige.
                            </p>
                            <p>
                                « Sur une journée d’affluence un technicien peut
                                avoir jusqu’à 8 lieux à vérifier, imaginer qu’il
                                ne peut à chaque location refaire le ménage… le
                                tout entre 10h00 et 16h00 ! Soyons donc tous
                                vigilent et respectueux ! » Fabien.
                            </p>
                        </>
                    }
                    faireMenageEn={
                        <>
                            <p>
                                Faced with the large number of incivilities and
                                for the respect for our technicians, thank you
                                very much do so under penalty of litigation.
                            </p>
                            <p>
                                “On a busy day a technician can have up to 8
                                places to check, imagine that he cannot at each
                                rental redo the housework… all between 10:00
                                a.m. and 4:00 p.m.! So let's all be vigilant and
                                respectful! » Fabian.
                            </p>
                        </>
                    }
                    basesMenageFr={
                        <>
                            Aérer chaque pièce.
                            <ul>
                                <li>
                                    Dans les chambres : merci de retirer les
                                    draps (les mettre dans un sac en tissu) et
                                    nettoyer sol * et surfaces.
                                </li>
                                <li>
                                    Dans la salle de bain : vider la poubelle,
                                    mettre les serviettes dans le sac ainsi que
                                    le tapis. Nettoyer sol * et surfaces, bien
                                    essuyer la douche, lavabo, toilettes.
                                </li>
                                <li>
                                    Dans la cuisine : vider la poubelle,
                                    nettoyer le réfrigérateur, micro-ondes,
                                    four, surfaces, sol *…… <br />
                                    Mettre toute la vaisselle utilisée propre en
                                    évidence sur le plan de travail. Rendre la
                                    cuisine propre.
                                </li>
                                <li>
                                    Dans les pièces de vie : nettoyer les
                                    surfaces et sol *.
                                </li>
                            </ul>
                            <p>
                                * : Le nettoyage des sols consiste à aspirer et
                                laver avec la serpillère.
                            </p>
                        </>
                    }
                    basesHouseholdEn={
                        <>
                            Ventilate each room.
                            <ul>
                                <li>
                                    In the rooms: please remove the sheets (put
                                    them in a cloth bag) and clean floor * and
                                    surfaces.
                                </li>
                                <li>
                                    In the bathroom: empty the trash can, put
                                    the towels in the bag as well as the carpet.
                                    Clean floor * and surfaces, wipe the shower
                                    well, sink, toilet.
                                </li>
                                <li>
                                    In the kitchen: empty the trash, clean the
                                    refrigerator, microwave, oven, surfaces,
                                    floor *…… <br />
                                    Put all the dishes used clearly visible on
                                    the plan of work. Make the kitchen clean.
                                </li>
                                <li>
                                    In the living rooms: clean the surfaces and
                                    floor *.
                                </li>
                            </ul>
                            <p>
                                *: Floor cleaning involves vacuuming and wash
                                with the mop.
                            </p>
                        </>
                    }
                />
                <MerciBienvenue
                    className={className}
                    merciFr={
                        <>
                            Mon équipe et moi-même (et oui je fais du ménage
                            aussi) vous remercions par avance de votre
                            investissement dans cette tâche qui est la moins
                            drôle des vacances, mais qui fera notre sourire
                            <br />
                            <br />
                            Merci et un bon retour à vous !
                        </>
                    }
                    merciEn={
                        <>
                            My team and myself (and yes I do cleaning too) thank
                            you in advance for your investment in this task
                            which is the less funny of the holidays, but which
                            will make our smile <br />
                            <br />
                            Thank you and welcome back !
                        </>
                    }
                />

                <div className={className}>
                    <h2 className="text-center m-3 ">
                        <GiNewspaper />
                        {translate === 'French' && "Manuels / Modes d'emploi"}
                        {translate === 'English' &&
                            'Manuals / Instructions for use'}
                    </h2>

                    <div>
                        {translate === 'French' ? (
                            <p className="text-start">
                                Voici les manuels d'utilisation des appareils
                                présents dans le logement (Français uniquement):
                            </p>
                        ) : (
                            <p className="text-start">
                                Here are the device user manuals present in the
                                accommodation (French only):
                            </p>
                        )}

                        <ul className="text-start">
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Sous-les-pins-four.pdf'
                                        }
                                    >
                                        Cuisine : Four
                                    </a>
                                ) : (
                                    <p className="text-start">Kitchen: Oven</p>
                                )}
                            </li>
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Sous-les-pins-decodeurTV.pdf'
                                        }
                                    >
                                        Salon : Décodeur TV
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Living room: TV decoder
                                    </p>
                                )}
                            </li>

                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Sous-les-pins-lavelinge.pdf'
                                        }
                                    >
                                        Sellier : Lave-linge
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Saddler: Washing machine
                                    </p>
                                )}
                            </li>
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Sous-les-pins-lavevaisselle.pdf'
                                        }
                                    >
                                        Sellier : Lave-Vaisselle
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Saddler: Dishwasher
                                    </p>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>

                <section className="sect__widgets">
                    <CardHomeWidgetsBottom />
                </section>
            </main>
        </>
    );
}
