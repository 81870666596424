import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaInfoCircle } from 'react-icons/fa';

export default function HelpSBase() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <FaInfoCircle /> Informations complémentaires
            </Button>

            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Services de base</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h2>
                            Pré séjour : appel téléphonique d’informations et
                            d’organisation
                        </h2>
                        <p>
                            Appel téléphonique avant la localisation dans le
                            mais de récapituler les informations de réservation
                            et conditions d’accès au logement. Nous ciblerons
                            par la même occasion les gouts du voyageur pour lui
                            proposer de la documentation et des services adaptés
                            à son séjour.
                        </p>
                        <hr className="col-6 mx-auto" />
                        <h2>
                            Retouches ménage et vérification. (Dans la limite de
                            45 minutes)
                        </h2>
                        <p>
                            Retouches ménage consistantes à vérifier l’état de
                            la propreté du logement et à rapper d’éventuelles
                            oublies. Cela ne remplace en aucun cas le forfait
                            ménage qui sera commandé automatiquement et facturé
                            au propriétaire si la maison n'est pas parfaitement
                            propre pour les prochains voyageurs. Le propriétaire
                            gérera par lui-même le liége qui en découle s'il le
                            souhaite.
                        </p>
                        <hr className="col-6 mx-auto" />
                        <h2>
                            Arrivée et départ des voyageurs via un système de
                            boîte à clés obligatoire.
                        </h2>
                        <p>
                            Les boîtes à clés sont des petits coffres-forts à
                            code pour rendre accessible facilement les clés
                            d'accès au logement pour les voyageurs.
                        </p>
                        <hr className="col-6 mx-auto" />
                        <h2>
                            Prise de contact avec vos voyageurs la veille de
                            leur arrivée pour les instructions
                        </h2>
                        <p>
                            Par appel téléphonique ou message, nous prenons
                            contact avec votre voyageur pour leur communiquer
                            les instructions d’arrivée.
                        </p>
                        <hr className="col-6 mx-auto" />
                        <h2>Pack de Bienvenue "Les Essentiels"</h2>
                        <p>Composition dans 3 boîtes distinctes :</p>
                        <ul>
                            <li>
                                2 spéculos 2 sucres, 2 thés vert, 4 doses de
                                café type Nespresso.
                            </li>
                            <li>
                                1 sac poubelle 50L, 1 sac poubelle 10L, 1
                                pastille de lave-vaisselle, 1 passoire de
                                lessive, liquide vaisselle 30ml, éponge.
                            </li>
                            <li>
                                Gel douche 30 ml, shampoing 30 ml, savon,
                                coton-tige et coton.
                            </li>
                        </ul>
                        <hr className="col-6 mx-auto" />
                        <h2>Cadeau de Bienvenue</h2>
                        <p>Petit bouquet de fleurs et/ou gâteaux.</p>
                        <hr className="col-6 mx-auto" />
                        <h2>
                            Passage de bienvenue du concierge ou communication
                            renforcée
                        </h2>
                        <p>
                            Passage d’un concierge pour souhaiter la bienvenue
                            et répondre à toutes les questions des voyageurs.
                            Cela peut être substitué par une communication
                            renforcée lors de la journée d’arrivée.
                        </p>
                        <hr className="col-6 mx-auto" />
                        <h2>
                            Intermédiaire principal et astreinte pour vos
                            voyageurs pendant leurs séjours.
                        </h2>
                        <p>
                            Nous répondons dans la mesure du possible à toutes
                            les questions de vos voyageurs pendant leurs séjours
                            avec une ligne d'astreinte 7j/7j 24h/24h mise à leur
                            disposition en cas d'urgence. Tout appel de nuit
                            (22h-7h) sera facturé 10€/appel au propriétaire du
                            bien concerné et tout déplacement nocturne à hauteur
                            de 50€ / 30 minutes.
                        </p>
                        <hr className="col-6 mx-auto" />{' '}
                        <h2>Linge offert à partir de la 5ème nuitées</h2>
                        <p>
                            Les packs de linges colorés sont offerts par 5èmes
                            nuitées pour chaque lit du logement concerné.
                        </p>
                        <hr className="col-6 mx-auto" />
                        <h2>Pack Alèse</h2>
                        <p>
                            Fourniture et blanchiment d’alèse de lit et oreiller
                            pour 1 lit
                        </p>
                        <hr className="col-6 mx-auto" />
                        <h2>Diffusion sur le site de Ma Maison Sereine</h2>
                        <p>
                            Votre hébergement figure sur notre site internet et
                            nous renvoyons les visiteurs intéressés sur votre
                            page. En aucun cas nous ne faisons de vente de
                            séjour de vacances, cela est le travail du
                            gestionnaire si vous faites appel à ses services.
                            Par contre, les voyageurs pourront commander leurs
                            prestations complémentaires par le site de
                            mamaisonsereine.fr
                        </p>
                        <hr className="col-6 mx-auto" />
                        <h2>Accès et synchronisation à la plateforme Smoobu</h2>
                        <p>
                            Smoobu est une plateforme de gestion des
                            réservations, ce logiciel nous permet d’extraire le
                            calendrier de réservation ainsi que les détails
                            clients associés pour en informer notre équipe
                            (astreinte, communication, logement concerné, etc.).
                        </p>
                        <hr className="col-6 mx-auto" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        D'accord
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
