import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import CalendarBooking from '../../../components/Reservations/CalendarBooking.jsx';
import Carrousel from '../../../components/Logements/Caroussel.jsx';
import ListingDetailsPure from '../../../components/Logements/Pure/ListingDetailsPure.jsx';
import { getApartments } from '../../../Store/Actions/smoobu.actions';

export default function PagePure(props) {
    document.title = `Location '${props.title}' à Pornic 44210 | Ma Maison Sereine`;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const translate = useSelector((store) => store.Template.language);
    const dispatch = useDispatch();
    const dataApartments = useSelector((store) => store.Smoobu.dataApartments);
    useEffect(() => {
        setTimeout(() => {
            dispatch(getApartments());
        }, 1000);
    }, []);

    return (
        <main className="page_logement">
            <article className="page_logement__content">
                <Carrousel images={props.images} />
                <img
                    src={props.logo}
                    alt={props.title}
                    title={props.title}
                    loading="lazy"
                    width={'160px'}
                    className="card-location-logo"
                />
                <div className="fs-2">
                    <h1>
                        {translate === 'French' && (
                            <>Location - {props.title}</>
                        )}
                        {translate === 'English' && <>Rental - {props.title}</>}
                    </h1>
                    <h2>
                        {translate === 'French' && (
                            <>
                                Appartement Complet pour 6 voyageurs - 3
                                chambres - 4 lits - 1 salle de Bain
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                Complete Apartment for 6 travelers - 3 bedrooms
                                - 4 beds - 1 bathroom
                            </>
                        )}
                    </h2>
                </div>
                <div className="bg-primary hr-h my-4"></div>
                {translate === 'French' && (
                    <>
                        <p>
                            Joli appartement spacieux dans les hauteurs de la
                            ville de Pornic. Vous serez conquis par sa
                            décoration soignée et son emplacement idéal à 50m
                            des rues commerçantes, tout est accessible à pied.
                        </p>
                        <p>
                            Le logement se trouve dans une résidence calme avec
                            ascenseur (2ème), comporte 3 grandes chambres, une
                            grande pièce de vie et une salle de bain. Vous
                            pourrez stationner un véhicule dans le parking fermé
                            de la résidence.
                        </p>
                        <p>
                            Linge de lit compris et Ménage non compris
                            (disponible en option).
                        </p>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <p>
                            Nice spacious apartment in the heights of the town
                            of Pornic. You will be conquered by its neat
                            decoration and its ideal location 50m shopping
                            streets, everything is within walking distance.
                        </p>
                        <p>
                            The accommodation is in a quiet residence with
                            elevator (2nd), has 3 large bedrooms, a large living
                            room and a bathroom. YOU can park a vehicle in the
                            closed car park of the residence.
                        </p>
                        <p>Sheets included and Cleaning not included.</p>
                    </>
                )}
                <div className="bg-primary hr-h my-4"></div>
                <ListingDetailsPure />
                <div className="bg-primary hr-h my-4"></div>
                <Carrousel images={props.images} active={true} />
                <div className="bg-primary hr-h my-4"></div>
                <div className="d-flex flex-row flex-wrap">
                    <div className="d-block d-md-none bg-primary hr-h my-4"></div>
                    <div className="calendarWidget locations__localisation mx-auto">
                        <h2 className="fs-1 mb-3 text-center">
                            {translate === 'French' && (
                                <>Calendrier de Réservation</>
                            )}
                            {translate === 'English' && <>Booking Calendar</>}
                        </h2>
                        {dataApartments !== null &&
                            dataApartments.map(
                                (appart) =>
                                    appart.id === props.id && (
                                        <div key={appart.id}>
                                            <h2>
                                                <i className="fa-solid fa-map-location-dot"></i>{' '}
                                                {appart.name}
                                            </h2>
                                            <CalendarBooking
                                                apartment={appart}
                                                channel={'airbnb'}
                                                linkAirbnb={
                                                    'https://www.airbnb.fr/rooms/886321925063899797?source_impression_id=p3_1692274502_0%2BeiR1GhhFSPCQGa'
                                                }
                                            />
                                        </div>
                                    )
                            )}
                    </div>
                </div>
            </article>

            <section className="sect__widgets">
                <CardHomeWidgetsBottom />
            </section>
        </main>
    );
}
