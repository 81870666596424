import Calendar from 'react-calendar';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setDateSelected } from '../../Store/Actions/config.actions';
import { useFetchGetApartmentCities } from '../../utils/Api/FetchSmoobu.jsx';
import { useFetchGetLocationSettings } from '../../utils/Api/FetchLocationSettings.jsx';
import LoadingK from './LoadingK.jsx';
import MiniListingLogement from '../Logements/MiniListing.logement.jsx';

export default function SearchDispoLog() {
    const translate = useSelector((store) => store.Template.language);
    const dateSelected = useSelector((store) => store.Config.dateSelected);
    const dispatch = useDispatch();
    const [cities, setCities] = useState([]);
    const { dataApartmentCities, isLoadingAppartCities } =
        useFetchGetApartmentCities();
    const today = new Date();

    const [citySelected, setCitySelected] = useState('all');
    const [dataCheck, setDataCheck] = useState(undefined);
    const [apartAvailable, setApartAvailable] = useState(undefined);
    const [apartAvailableWithReserve, setApartAvailableWithReserve] =
        useState(undefined);
    const { dataLocationSettings } = useFetchGetLocationSettings();
    const [nberNight, setNberNight] = useState(0);

    // end date M+6
    const newDate = new Date();
    newDate.setMonth(today.getMonth() + 6);
    newDate.setDate(today.getDate() + 1);

    useEffect(() => {
        if (
            isLoadingAppartCities === false &&
            dataApartmentCities !== undefined
        ) {
            const city = [];
            dataApartmentCities.map((apart) => {
                if (!city.includes(apart.city)) {
                    city.push(apart.city);
                }
                return city;
            });
            setCities(city.sort());
        }
    }, [isLoadingAppartCities, dataApartmentCities]);
    useEffect(() => {
        async function fetchData() {
            try {
                const newCity = citySelected.replace(' ', '+');
                let checkInDate = new Date();
                checkInDate.setFullYear(dateSelected[0].getFullYear());
                checkInDate.setMonth(dateSelected[0].getMonth());
                checkInDate.setDate(dateSelected[0].getDate());
                checkInDate = String(checkInDate.toISOString().split('T')[0]);
                let checkOutDate = new Date();
                checkOutDate.setFullYear(dateSelected[1].getFullYear());
                checkOutDate.setMonth(dateSelected[1].getMonth());
                checkOutDate.setDate(dateSelected[1].getDate());
                checkOutDate = String(checkOutDate.toISOString().split('T')[0]);
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/smoobu/v1/apartments/cities/search/${checkInDate}/${checkOutDate}/${newCity}/`,
                    {
                        method: 'get',
                        headers: new Headers({
                            'content-type': 'application/json',
                        }),
                    }
                );
                const data = await response.json();

                if (response.status === 200) {
                    setDataCheck(data);
                } else if (response.status === 500) {
                    toast.error('Le serveur distant est en erreur', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    'Oups! Impossible de connaître les disponibilités ',
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        if (
            dateSelected !== null &&
            dateSelected[0] !== undefined &&
            dateSelected[1] !== undefined
        ) {
            fetchData();
        }
    }, [dateSelected, citySelected]);

    useEffect(() => {
        setApartAvailable(0);
        setApartAvailableWithReserve(0);
        if (dataCheck !== undefined) {
            dataCheck.map((apart) => {
                apart.available === true &&
                    apart.min_length_of_stay <= nberNight &&
                    setApartAvailable(apartAvailable + 1);
                return apart;
            });
            dataCheck.map((apart) => {
                apart.available === true &&
                    apart.min_length_of_stay > nberNight &&
                    setApartAvailableWithReserve(apartAvailableWithReserve + 1);
                return apart;
            });
        }
    }, [dataCheck]);

    function detectMinNight(e) {
        dispatch(setDateSelected(e));
        const delta = Math.trunc((e[1] - e[0]) / (1000 * 60 * 60 * 24));
        let startDate = new Date();
        startDate.setFullYear(e[0].getFullYear());
        startDate.setMonth(e[0].getMonth());
        startDate.setDate(e[0].getDate());
        startDate = String(startDate.toISOString().split('T')[0]);
        setNberNight(delta);
    }
    return (
        <div>
            {translate === 'French' && (
                <h2 className="text-center">Recherche de disponibilité</h2>
            )}
            {translate === 'English' && (
                <h2 className="text-center">Availability search</h2>
            )}

            <div className="calendrier_booking">
                <div className="mx-auto">
                    {translate === 'French' && (
                        <Calendar
                            onChange={(e) => detectMinNight(e)}
                            selectRange={true}
                            culture="fr"
                            minDate={new Date()}
                            maxDate={newDate}
                            className="mx-auto"
                            showFixedNumberOfWeeks={true}
                            tileClassName="text-center"
                        />
                    )}
                    {translate === 'English' && (
                        <Calendar
                            onChange={(e) => detectMinNight(e)}
                            selectRange={true}
                            locale="en"
                            minDate={new Date()}
                            maxDate={newDate}
                            className="mx-auto"
                        />
                    )}
                    <hr className="col-6 mx-auto" />
                </div>
                <div className="mx-auto ">
                    {dateSelected !== null && (
                        <div className="text-center ">
                            {dateSelected.length > 0 && (
                                <p>
                                    {translate === 'French' && (
                                        <>
                                            Période choisie : <br />
                                            du{' '}
                                            <span className="text-success">
                                                <i className="fa-solid fa-plane-arrival"></i>
                                                {dateSelected[0].toLocaleDateString(
                                                    'fr-FR',
                                                    {
                                                        weekday: 'short',
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    }
                                                )}{' '}
                                                17h00
                                            </span>{' '}
                                            <br />
                                            au{' '}
                                            <span className="text-danger">
                                                <i className="fa-solid fa-plane-departure"></i>
                                                {dateSelected[1].toLocaleDateString(
                                                    'fr-FR',
                                                    {
                                                        weekday: 'short',
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    }
                                                )}{' '}
                                                10h00
                                            </span>
                                        </>
                                    )}
                                    {translate === 'English' && (
                                        <>
                                            Chosen period: <br />
                                            of{' '}
                                            <span className="text-success">
                                                <i className="fa-solid fa-plane-arrival"></i>
                                                {dateSelected[0].toLocaleDateString(
                                                    'fr-FR',
                                                    {
                                                        weekday: 'short',
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    }
                                                )}{' '}
                                                17h00
                                            </span>{' '}
                                            <br />
                                            at{' '}
                                            <span className="text-danger">
                                                <i className="fa-solid fa-plane-departure"></i>
                                                {dateSelected[1].toLocaleDateString(
                                                    'fr-FR',
                                                    {
                                                        weekday: 'short',
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    }
                                                )}{' '}
                                                10h00
                                            </span>
                                        </>
                                    )}
                                </p>
                            )}
                        </div>
                    )}
                    <div className="text-center ">
                        {nberNight !== 0 && (
                            <div>
                                <p className="text-primary">
                                    {translate === 'French' && (
                                        <>
                                            Vous souhaitez réserver pour{' '}
                                            {nberNight} Nuits.
                                        </>
                                    )}
                                    {translate === 'English' && (
                                        <>
                                            You want to book for {nberNight}{' '}
                                            Nights.
                                        </>
                                    )}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="input-wrapper text-center">
                    <label htmlFor="location">
                        {translate === 'French' && <span>Localisation</span>}
                        {translate === 'English' && <span>Location</span>}
                    </label>
                    <div>
                        <i className="fa-solid fa-location-dot ms-2 text-primary me-3"></i>
                        {isLoadingAppartCities === false &&
                            dataApartmentCities !== undefined && (
                                <select
                                    id="location"
                                    onChange={(e) =>
                                        setCitySelected(e.target.value)
                                    }
                                >
                                    <option value="all" key="0">
                                        Tout
                                    </option>
                                    {cities !== undefined &&
                                        cities.map((city) => (
                                            <option value={city} key={city}>
                                                {city}
                                            </option>
                                        ))}
                                </select>
                            )}
                    </div>
                </div>
                <hr className="col-6 mx-auto" />
            </div>
            {dataApartmentCities !== undefined && (
                <>
                    {dateSelected !== null &&
                        dateSelected[0] !== undefined &&
                        dateSelected[1] !== undefined &&
                        dataCheck === undefined && <LoadingK />}
                    {dateSelected !== null &&
                        dateSelected[0] !== undefined &&
                        dateSelected[1] !== undefined &&
                        dataCheck !== undefined && (
                            <>
                                {apartAvailable === 0 && (
                                    <p className="bg-antique-light  p-3">
                                        {translate === 'French' && (
                                            <>
                                                Aucune disponibilité ou nombre
                                                de nuité miniales non atteintes
                                            </>
                                        )}
                                        {translate === 'English' && (
                                            <>
                                                No availability or minimum
                                                number of nights no respected
                                            </>
                                        )}
                                    </p>
                                )}
                                <div className="homepage-search-result">
                                    {dataCheck !== undefined &&
                                        apartAvailable >= 1 && (
                                            <div className="alert alert-success mx-auto">
                                                <h3 className="mx-auto text-center mb-4 text-success">
                                                    {translate === 'French' && (
                                                        <>
                                                            Logement(s)
                                                            Disponible(s)
                                                        </>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>
                                                            Booking(s) Available
                                                        </>
                                                    )}
                                                    <i className="fa-solid fa-house-circle-check text-success fs-5 ms-2"></i>
                                                </h3>
                                                {dataCheck !== undefined &&
                                                    dataApartmentCities.map(
                                                        (apart) => {
                                                            const list = (
                                                                <div
                                                                    key={
                                                                        apart.id_smoobu
                                                                    }
                                                                >
                                                                    {dataCheck.map(
                                                                        (
                                                                            element
                                                                        ) =>
                                                                            parseInt(
                                                                                element.id,
                                                                                10
                                                                            ) ===
                                                                                apart.id_smoobu && (
                                                                                <div
                                                                                    key={
                                                                                        apart.id
                                                                                    }
                                                                                >
                                                                                    {element.available ===
                                                                                        true &&
                                                                                        element.min_length_of_stay <=
                                                                                            nberNight && (
                                                                                            <>
                                                                                                <h4 className="text-primary font-title">
                                                                                                    {
                                                                                                        apart.name
                                                                                                    }
                                                                                                </h4>
                                                                                                <MiniListingLogement
                                                                                                    id={
                                                                                                        apart.id_smoobu
                                                                                                    }
                                                                                                />
                                                                                                <div className="text-center fs-6 mt-3">
                                                                                                    {
                                                                                                        element.total_price
                                                                                                    }

                                                                                                    €
                                                                                                    {translate ===
                                                                                                        'French' && (
                                                                                                        <>
                                                                                                            {' '}
                                                                                                            pour{' '}
                                                                                                            {
                                                                                                                nberNight
                                                                                                            }{' '}
                                                                                                            nuités
                                                                                                        </>
                                                                                                    )}
                                                                                                    {translate ===
                                                                                                        'English' && (
                                                                                                        <>
                                                                                                            to{' '}
                                                                                                            {
                                                                                                                nberNight
                                                                                                            }{' '}
                                                                                                            nights
                                                                                                        </>
                                                                                                    )}{' '}
                                                                                                    <i className="fa-solid fa-moon"></i>
                                                                                                </div>

                                                                                                <div className="text-center fs-6 mt-3">
                                                                                                    {translate ===
                                                                                                        'French' && (
                                                                                                        <>
                                                                                                            {nberNight >
                                                                                                            dataLocationSettings.number_night_linge ? (
                                                                                                                <p>
                                                                                                                    Linge
                                                                                                                    de
                                                                                                                    Lit
                                                                                                                    inclus{' '}
                                                                                                                    <i className="fa-solid fa-bed"></i>{' '}
                                                                                                                    <i className="fa-solid fa-check text-sucess"></i>
                                                                                                                </p>
                                                                                                            ) : (
                                                                                                                <p className="text-danger">
                                                                                                                    Linge
                                                                                                                    de
                                                                                                                    Lit
                                                                                                                    NON
                                                                                                                    compris{' '}
                                                                                                                    <i className="fa-solid fa-bed"></i>{' '}
                                                                                                                    <i className="fa-solid fa-xmark text-danger"></i>
                                                                                                                    <br />
                                                                                                                    <small>
                                                                                                                        Inclus
                                                                                                                        à
                                                                                                                        partir
                                                                                                                        de{' '}
                                                                                                                        {dataLocationSettings.number_night_linge +
                                                                                                                            1}{' '}
                                                                                                                        nuités
                                                                                                                    </small>
                                                                                                                </p>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                    {translate ===
                                                                                                        'English' && (
                                                                                                        <>
                                                                                                            {nberNight >
                                                                                                            dataLocationSettings.number_night_linge ? (
                                                                                                                <p>
                                                                                                                    bedding
                                                                                                                    included{' '}
                                                                                                                    <i className="fa-solid fa-bed"></i>{' '}
                                                                                                                    <i className="fa-solid fa-check text-sucess"></i>
                                                                                                                </p>
                                                                                                            ) : (
                                                                                                                <p className="text-danger">
                                                                                                                    bedding
                                                                                                                    not
                                                                                                                    included{' '}
                                                                                                                    <i className="fa-solid fa-bed"></i>{' '}
                                                                                                                    <i className="fa-solid fa-xmark text-danger"></i>
                                                                                                                    <br />
                                                                                                                    <small>
                                                                                                                        included
                                                                                                                        from
                                                                                                                        {dataLocationSettings.number_night_linge +
                                                                                                                            1}{' '}
                                                                                                                        nights
                                                                                                                    </small>
                                                                                                                </p>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                                <hr />
                                                                                            </>
                                                                                        )}
                                                                                </div>
                                                                            )
                                                                    )}
                                                                </div>
                                                            );
                                                            return list;
                                                        }
                                                    )}
                                            </div>
                                        )}
                                    {dataCheck !== undefined &&
                                        apartAvailableWithReserve >= 1 && (
                                            <div className="alert alert-warning mx-auto">
                                                <h3 className="mx-auto text-center mb-4 text-warning ">
                                                    {translate === 'French' && (
                                                        <>
                                                            Logement(s) sous
                                                            réserve
                                                        </>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>
                                                            Booking(s) subject
                                                            to reservation
                                                        </>
                                                    )}
                                                    <i className="fa-solid fa-house-circle-check text-warning fs-5 ms-2"></i>
                                                </h3>
                                                {dataCheck !== undefined &&
                                                    dataApartmentCities.map(
                                                        (apart) => {
                                                            const list = (
                                                                <>
                                                                    {dataCheck.map(
                                                                        (
                                                                            element
                                                                        ) =>
                                                                            parseInt(
                                                                                element.id,
                                                                                10
                                                                            ) ===
                                                                                apart.id_smoobu && (
                                                                                <div
                                                                                    key={
                                                                                        apart.id_smoobu
                                                                                    }
                                                                                >
                                                                                    {element.available ===
                                                                                        true &&
                                                                                        element.min_length_of_stay >
                                                                                            nberNight && (
                                                                                            <div
                                                                                                key={
                                                                                                    apart.id
                                                                                                }
                                                                                            >
                                                                                                <h4 className="text-primary font-title">
                                                                                                    {
                                                                                                        apart.name
                                                                                                    }
                                                                                                </h4>

                                                                                                <MiniListingLogement
                                                                                                    id={
                                                                                                        apart.id_smoobu
                                                                                                    }
                                                                                                />
                                                                                                {element.min_length_of_stay >=
                                                                                                    nberNight && (
                                                                                                    <div className="font-body text-danger mx-auto mt-2 fs-6 text-center">
                                                                                                        {
                                                                                                            element.min_length_of_stay
                                                                                                        }{' '}
                                                                                                        {translate ===
                                                                                                            'French' && (
                                                                                                            <>
                                                                                                                nuités{' '}
                                                                                                                <i className="fa-solid fa-moon"></i>{' '}
                                                                                                                requises
                                                                                                            </>
                                                                                                        )}
                                                                                                        {translate ===
                                                                                                            'English' && (
                                                                                                            <>
                                                                                                                nights{' '}
                                                                                                                <i className="fa-solid fa-moon"></i>{' '}
                                                                                                                required
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                )}
                                                                                                <hr />
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            )
                                                                    )}
                                                                </>
                                                            );
                                                            return list;
                                                        }
                                                    )}
                                            </div>
                                        )}
                                </div>
                            </>
                        )}
                </>
            )}
        </div>
    );
}
