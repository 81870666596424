import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GiNewspaper } from 'react-icons/gi';
import { FiPaperclip } from 'react-icons/fi';

import qrcodelogement from '../../../assets/qrcode/qrcode_maison_charme_gourmalon.png';
import qrcode_Wifi from '../../../assets/img/Maison-charme-gourmalon/qrcode_wifi_maison-charme-gourmalon.png';
import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import Header from '../../../components/Header/Header.jsx';
import TitleBienvenue from '../../../components/Logements/bienvenue/Title.bienvenue.jsx';
import GpsBienvenue from '../../../components/Logements/bienvenue/Gps.bienvenue.jsx';
import ArriveeBienvenue from '../../../components/Logements/bienvenue/Arrivee.bienvenue.jsx';
import BoiteCleBienvenue from '../../../components/Logements/bienvenue/BoiteCle.bienvenue.jsx';
import InternetBienvenue from '../../../components/Logements/bienvenue/Internet.bienvenue.jsx';
import ChauffageBienvenue from '../../../components/Logements/bienvenue/Chauffage.bienvenue.jsx';
import JardinBienvenue from '../../../components/Logements/bienvenue/Jardin.bienvenue.jsx';
import ContactUrgence from '../../../components/Logements/ContactUrgence.jsx';
import DechetsBienvenue from '../../../components/Logements/bienvenue/Dechets.bienvenue.jsx';
import DurantSejourBienvenue from '../../../components/Logements/bienvenue/DurantSejour.bienvenue.jsx';
import DepartBienvenue from '../../../components/Logements/bienvenue/Depart.bienvenue.jsx';
import MerciBienvenue from '../../../components/Logements/bienvenue/Merci.bienvenue.jsx';
import portail from '../../../assets/img/Maison-charme-gourmalon/photos/portail.png';
import MaisonCharmeGourmalon from '../../../assets/img/Maison-charme-gourmalon/logo-maison-charme-gourmalon.jpg';

import boitecle1 from '../../../assets/img/Maison-charme-gourmalon/photos/IMG_5156.jpeg';
import boitecle2 from '../../../assets/img/Maison-charme-gourmalon/photos/IMG_5157.jpeg';

export default function BienvenueMaisonCharmeGourmalon() {
    const translate = useSelector((store) => store.Template.language);

    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

    const weekNumber = Math.ceil(days / 7);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    document.title =
        'Bienvenue à la Maison de charme au Coeur de Gourmalon | Ma Maison Sereine';
    document.description =
        'Découvrez les recommandations pour profiter au mieux de votre logement lors de votre séjour.';
    const [className] = useState(
        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
    );

    return (
        <>
            <Header />
            <main className="bg-primary ">
                <div className="d-flex flex-row justify-content-around align-items-center pt-4 ">
                    <img
                        src={MaisonCharmeGourmalon}
                        alt={'Logo - Maison de charme au Coeur de Gourmalon'}
                        title={'Logo - Maison de charme au Coeur de Gourmalon'}
                        loading="lazy"
                        width={'180px'}
                        className="card-location-logo mt-3 rounded-2 border border-1 border-primary"
                    />
                    <div className=" screen-none_qrcode ">
                        <img
                            src={qrcodelogement}
                            alt="QRCode Maison de charme au Coeur de Gourmalon"
                            width={'190px'}
                            className="card-location-logo mt-4 pt-3 "
                        />
                        <small className="text-white mt-2">
                            Version Mobile & <span>English Version</span>{' '}
                            <div className="fi fi-gb"></div>
                        </small>
                    </div>
                </div>
                <TitleBienvenue
                    className={className}
                    title={'Maison de charme au Coeur de Gourmalon'}
                />
                <GpsBienvenue
                    className={className + ' no-print'}
                    address={'43 Bis Boulevard Thiers 44210 Pornic'}
                    gpsGoogle={'https://maps.app.goo.gl/vHg7AL8gidMNtR83A'}
                    gpsApple={
                        'https://maps.apple.com/?address=45%20Boulevard%20Thiers,%2044210%20Pornic,%20France&ll=47.110629,-2.101983&q=Rep%C3%A8re%20sur%20le%20plan'
                    }
                    gpsWaze={
                        'https://ul.waze.com/ul?ll=47.10969153%2C-2.10430026&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location'
                    }
                />
                <ArriveeBienvenue
                    className={className}
                    hourFr={'17h00'}
                    hourEn={'5p.m.'}
                    parkingFr={
                        <p>
                            Diverses places de parking (gratuites) sont
                            disponibles dans la rue.
                        </p>
                    }
                    parkingEn={
                        <p>
                            Various (free) parking spaces are available on the
                            street.
                        </p>
                    }
                />
                <BoiteCleBienvenue
                    className={className}
                    boiteFr={
                        <>
                            <p>
                                Vous trouvez la boîte à clé pour votre arrivée
                                autonome au niveau du cabanon en bois présent
                                dans le jardin. <br />
                                Le code vous a été envoyé par Sms ou par Mail
                            </p>
                        </>
                    }
                    boiteEn={
                        <>
                            <p>
                                You find the key box for your arrival autonomous
                                at the level of the wooden shed present in the
                                garden.
                                <br /> The code was sent to you by SMS or by
                                Email
                            </p>
                        </>
                    }
                    photos={
                        <div className="d-flex flex-row flex-wrap justify-content-around align-items-center mx-auto">
                            <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                <img
                                    src={portail}
                                    alt={'portail'}
                                    title={'portail'}
                                    loading="lazy"
                                    width={'320px'}
                                    height={'240px'}
                                    className="card-location-logo rounded-4 object-fit-cover"
                                />
                                {translate === 'French' && (
                                    <small>Portail d'accès </small>
                                )}
                                {translate === 'English' && (
                                    <small>Access portal </small>
                                )}
                            </div>
                            <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                <img
                                    src={boitecle1}
                                    alt={'boitecle1'}
                                    title={'boitecle'}
                                    loading="lazy"
                                    width={'320px'}
                                    height={'240px'}
                                    className="card-location-logo rounded-4 object-fit-cover"
                                />
                                {translate === 'French' && (
                                    <small>
                                        Cabane de Jardin avec Boite à clés{' '}
                                    </small>
                                )}
                                {translate === 'English' && (
                                    <small>Garden Shed with Key Box </small>
                                )}
                            </div>
                            <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                <img
                                    src={boitecle2}
                                    alt={'boitecle2'}
                                    title={'boitecle'}
                                    loading="lazy"
                                    width={'320px'}
                                    height={'240px'}
                                    className="card-location-logo rounded-4 object-fit-cover"
                                />
                                {translate === 'French' && (
                                    <small>Boite à clés </small>
                                )}
                                {translate === 'English' && (
                                    <small>Key Box </small>
                                )}
                            </div>
                        </div>
                    }
                />
                <InternetBienvenue
                    className={className}
                    access={true}
                    wifiName={'Livebox-AD10'}
                    wifiPass={'Pornic43B'}
                    infoFr={
                        <>
                            <p>
                                Vous pouvez vous connecter au réseau Wifi du
                                logement.
                            </p>
                        </>
                    }
                    infoEn={
                        <>
                            <p>
                                You can connect to the Wifi network of the house
                            </p>
                        </>
                    }
                    qrcode={qrcode_Wifi}
                />
                <ChauffageBienvenue
                    className={className}
                    consigneFr={
                        <>
                            <p>
                                Le Chauffage est automatique mais vous pouvez
                                augmenter ou baisser la température de consigne
                                en cas de besoins avec les touches + et - sur
                                les radiateurs.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Merci aux petits techniciens curieux de ne pas
                                toucher aux autres boutons (ni aux disjoncteurs
                                ! )
                            </p>

                            <p className="text-center fw-bold text-primary">
                                L'Energie est notre Avenir. Economisons-là
                            </p>
                        </>
                    }
                    consigneEn={
                        <>
                            <p>
                                Heating is automatic but you can increase or
                                lower the set temperature if necessary with the
                                + and - keys on the radiators.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Thank you to the curious little technicians not
                                to touch the others buttons (or the circuit
                                breakers! )
                            </p>

                            <p className="text-center fw-bold text-primary">
                                Energy is our Future. Let's save it
                            </p>
                        </>
                    }
                    dijoncteurFR={
                        'dans le placard présent dans le placard de la première chambre'
                    }
                    dijoncteurEN={
                        'in the closet present in the closet of the first bedroom'
                    }
                    linkyFR={'au même endroit'}
                    linkyEN={'at the same place'}
                />
                <JardinBienvenue
                    className={className}
                    jardinFr={
                        <>
                            <p>Vous avez libre accès au jardin.</p>
                            <p>
                                Comme dans tous les quartiers d’habitations,
                                attention aux bruits démesurés, principalement
                                dans le jardin en soirée. Je rappelle que les
                                fêtes sont interdites.
                            </p>
                        </>
                    }
                    jardinEn={
                        <>
                            <p>You have free access to the garden.</p>
                            <p>
                                As in all living quarters, pay attention to
                                excessive noise, mainly in the garden in the
                                evening. I remember that the holidays are
                                prohibited.
                            </p>
                        </>
                    }
                    light={false}
                />
                <DechetsBienvenue
                    className={className}
                    dechetsFr={
                        <>
                            <p>
                                Les poubelles se trouvent au niveau du portail
                                blanc.
                            </p>
                            <p className="text-start">
                                Est-ce possible, svp, de mettre les poubelles
                                sur le trottoir pour le ramassage des ordures :
                            </p>
                            <ul>
                                <li>Le Dimanche Soir : La poubelle Noire</li>
                                <li>
                                    Le Dimanche Soir en semaine paire : La
                                    poubelle Jaune (en Eté toutes les semaines)
                                </li>
                            </ul>
                            <p className="text-start no-print">
                                Nous sommes en semaine {weekNumber} soit une
                                semaine{' '}
                                {weekNumber % 2 === 0 ? 'paire' : 'impaire'}
                            </p>
                        </>
                    }
                    dechetsEn={
                        <>
                            <p>
                                The trash cans are located at the gates. whites.
                            </p>
                            <p className="text-start">
                                Is it possible to put the trash cans on the
                                sidewalk for garbage collection:
                            </p>
                            <ul>
                                <li>Sunday Evening: The Black Trash Can</li>
                                <li>
                                    Sunday Evening on week pair: The Yellow
                                    Trash Can (On summer, every week)
                                </li>
                            </ul>
                            <p className="text-start no-print">
                                We are on number week {weekNumber} :
                                {weekNumber % 2 === 0 ? 'pair' : 'impair'} week
                            </p>
                        </>
                    }
                />
                <ContactUrgence className="bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 print-cut-none " />
                <DurantSejourBienvenue
                    className={
                        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
                    }
                    duringFr={
                        <>
                            <p>
                                La Maison est située en quartier Calme de
                                Pornic, vous trouverez tout à proximité pour
                                passer d’excellentes vacances. Le Marché de
                                Pornic se tient tous les jeudis matin et
                                dimanches matin sur la place du marché.
                            </p>
                        </>
                    }
                    duringEn={
                        <>
                            <p>
                                The House is located in the Calm district of
                                Pornic, you will find everything nearby to spend
                                excellent vacation. The Pornic Market held every
                                Thursday morning and Sunday morning in the
                                market square.
                            </p>
                        </>
                    }
                />
                <DepartBienvenue
                    className={className}
                    hourFr={'10h00'}
                    hourEn={'10a.m.'}
                    remiseCleFr={
                        'Merci de bien tout fermer et de mettre les clés dans la boite à clés et de veiller à brouiller le code.'
                    }
                    remiseCleEn={
                        'Please close everything well and put the keys in the key box and make sure to scramble the code.'
                    }
                    alertFr={
                        <>
                            Vous devez rendre la maison propre comme à votre
                            arrivée sauf dans le cas d'un forfait ménage
                            souscrit au préalable.
                        </>
                    }
                    alertEn={
                        <>
                            You must leave the house clean as your arrival
                            except in the case of a cleaning fee subscribed to
                            the prior.
                        </>
                    }
                    forfaitMenageFr={
                        'Vous devez tout de même rendre le logement dans un état acceptable, vider vos poubelles ainsi que votre vaisselle propre et rangée. '
                    }
                    forfaitMenageEn={
                        'You must still leave the accommodation in an acceptable condition, empty your trash and your dishes clean and tidy'
                    }
                    faireMenageFr={
                        <>
                            <p>
                                Face au grand nombre d’incivilité et pour le
                                respect de nos techniciens, merci de bien
                                l’effectuer sous peine de litige.
                            </p>
                            <p>
                                « Sur une journée d’affluence un technicien peut
                                avoir jusqu’à 8 lieux à vérifier/nettoyer,
                                imaginer qu’il ne peut à chaque location refaire
                                le ménage… le tout entre 10h00 et 16h00 ! Soyons
                                donc tous vigilent et respectueux ! » Fabien.
                            </p>
                        </>
                    }
                    faireMenageEn={
                        <>
                            <p>
                                Faced with the large number of incivilities and
                                for the respect for our technicians, thank you
                                very much do so under penalty of litigation.
                            </p>
                            <p>
                                “On a busy day a technician can have up to 8
                                places to check/clean, imagine that he cannot at
                                each rental redo the housework… all between
                                10:00 a.m. and 4:00 p.m.! So let's all be
                                vigilant and respectful! » Fabian.
                            </p>
                        </>
                    }
                    basesMenageFr={
                        <>
                            Aérer chaque pièce.
                            <ul>
                                <li>
                                    Dans les chambres : merci de retirer les
                                    draps (les mettre dans un sac en tissu) et
                                    nettoyer sol * et surfaces.
                                </li>
                                <li>
                                    Dans la salle de bain : vider la poubelle,
                                    mettre les serviettes dans le sac ainsi que
                                    le tapis. Nettoyer sol * et surfaces, bien
                                    essuyer la douche, lavabo, toilettes.
                                </li>
                                <li>
                                    Dans la cuisine : vider la poubelle,
                                    nettoyer le réfrigérateur, micro-ondes,
                                    four, surfaces, sol *…… <br />
                                    Mettre toute la vaisselle utilisée propre en
                                    évidence sur le plan de travail. Rendre la
                                    cuisine propre.
                                </li>
                                <li>
                                    Dans les pièces de vie : nettoyer les
                                    surfaces et sol *.
                                </li>
                            </ul>
                            <p>
                                * : Le nettoyage des sols consiste à aspirer et
                                laver avec la serpillère.
                            </p>
                        </>
                    }
                    basesHouseholdEn={
                        <>
                            Ventilate each room.
                            <ul>
                                <li>
                                    In the rooms: please remove the sheets (put
                                    them in a cloth bag) and clean floor * and
                                    surfaces.
                                </li>
                                <li>
                                    In the bathroom: empty the trash can, put
                                    the towels in the bag as well as the carpet.
                                    Clean floor * and surfaces, wipe the shower
                                    well, sink, toilet.
                                </li>
                                <li>
                                    In the kitchen: empty the trash, clean the
                                    refrigerator, microwave, oven, surfaces,
                                    floor *…… <br />
                                    Put all the dishes used clearly visible on
                                    the plan of work. Make the kitchen clean.
                                </li>
                                <li>
                                    In the living rooms: clean the surfaces and
                                    floor *.
                                </li>
                            </ul>
                            <p>
                                *: Floor cleaning involves vacuuming and wash
                                with the mop.
                            </p>
                        </>
                    }
                />
                <MerciBienvenue
                    className={className}
                    merciFr={
                        <>
                            Mon équipe et moi-même (et oui je fais du ménage
                            aussi) vous remercions par avance de votre
                            investissement dans cette tâche qui est la moins
                            drôle des vacances, mais qui fera notre sourire
                            <br />
                            <br />
                            Merci et un bon retour à vous !
                        </>
                    }
                    merciEn={
                        <>
                            My team and myself (and yes I do cleaning too) thank
                            you in advance for your investment in this task
                            which is the less funny of the holidays, but which
                            will make our smile <br />
                            <br />
                            Thank you and welcome back !
                        </>
                    }
                />
                <div className={className}>
                    <h2 className="text-center m-3 ">
                        <GiNewspaper />
                        {translate === 'French' && "Manuels / Modes d'emploi"}
                        {translate === 'English' &&
                            'Manuals / Instructions for use'}
                    </h2>

                    <div>
                        {translate === 'French' ? (
                            <p className="text-start">
                                Voici les manuels d'utilisation des appareils
                                présents dans le logement (Français uniquement):
                            </p>
                        ) : (
                            <p className="text-start">
                                Here are the device user manuals present in the
                                accommodation (French only):
                            </p>
                        )}

                        <ul className="text-start">
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Maison_charme_gourmalon_radiateurs.pdf'
                                        }
                                    >
                                        Maison : Radiateurs
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Home: Radiators
                                    </p>
                                )}
                            </li>
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Maison_charme_gourmalon_Electrolux_LMS2203EMX_Microwave.pdf'
                                        }
                                    >
                                        Cuisine : Micro-ondes
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Kitchen : Microwave
                                    </p>
                                )}
                            </li>
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Maison_charme_gourmalon_Electrolux_EOD6P60X.pdf'
                                        }
                                    >
                                        Cuisine : Four
                                    </a>
                                ) : (
                                    <p className="text-start">Kitchen : oven</p>
                                )}
                            </li>
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Maison_charme_gourmalon_electrolux_induction.pdf'
                                        }
                                    >
                                        Cuisine : Table Induction
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Kitchen : Induction table
                                    </p>
                                )}
                            </li>
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Maison_charme_gourmalon_electrolux_lavevaisselle_EEQ47210L.pdf'
                                        }
                                    >
                                        Cuisine : Lave Vaisselle
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Kitchen : Dish Machine
                                    </p>
                                )}
                            </li>

                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Maison_charme_gourmalon_radiateur_salon_vertical.pdf'
                                        }
                                    >
                                        Entrée : Radiateur Vertical
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Home: Vertical Radiator
                                    </p>
                                )}
                            </li>
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Maison_charme_gourmalon_TV.pdf'
                                        }
                                    >
                                        Salon : TV
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Living Room : TV
                                    </p>
                                )}
                            </li>
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Maison_charme_gourmalon_decodeurTV.pdf'
                                        }
                                    >
                                        Salon : Décodeur TV
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Living Room : decoder TV
                                    </p>
                                )}
                            </li>
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Maison_charme_gourmalon_boxInternet.pdf'
                                        }
                                    >
                                        Salon : Box internet
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Living Room : Box internet
                                    </p>
                                )}
                            </li>
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Maison_charme_gourmalon_technical_TML1044w.pdf'
                                        }
                                    >
                                        Salle de Douche : Lave-linge (programmes
                                        en page 24)
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Shower room: Washing machine (programmes
                                        on page 24)
                                    </p>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
                <section className="sect__widgets">
                    <CardHomeWidgetsBottom />
                </section>
            </main>
        </>
    );
}
