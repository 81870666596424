import {
    createBrowserRouter,
    createRoutesFromElements,
    useNavigate,
    Route,
    Outlet,
    Navigate,
    useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import SSlesPinHomelogo from '../assets/img/SSlesPins/logo__SSlesPins-home.jpg';
import accueilSSlesPinHome from '../assets/img/SSlesPins/home/ss_les_pins_home-accueil.jpg';
import Purelogo from '../assets/img/Pure/logo_pure.jpg';
import PecherieLogo from '../assets/img/Pecherie/pecherie_logo.jpeg';
import logoSer1 from '../assets/img/logo/logo__ma-maison-sereine.png';

import Footer from '../components/Footer/Footer.jsx';
import Header from '../components/Header/Header.jsx';
import ModuleReservation from '../components/Reservations/buy/ModuleReservation.jsx';

import imagesSousLesPinHome from '../components/Logements/SousLesPins/imagesSousLesPins.home.jsx';

import Accueil from '../Pages/Accueil.jsx';

import Login from '../Pages/authentication/Login.jsx';
import Logout from '../Pages/authentication/Logout.jsx';

import Conciergerie from '../Pages/Others/Conciergerie.jsx';
import Error404 from '../Pages/Others/Error404.jsx';
import BonsPlans from '../Pages/Others/BonsPlans.jsx';
import Contact from '../Pages/Others/Contact.jsx';
import CGV from '../Pages/Others/lois/CGV.jsx';
import CGL from '../Pages/Others/lois/CGL.jsx';
import Sitemap from '../Pages/Others/lois/Sitemap.jsx';

import Locations from '../Pages/locations/Locations.jsx';
import PageSousLesPinHome from '../Pages/locations/pagesDetails/pageSousLesPinHome.jsx';

import HomeGestion from '../Pages/gestion/Home.jsx';
import CalendarGestion from '../Pages/gestion/Calendars.jsx';
import OccupationDirect from '../Pages/gestion/OccupationDirect.jsx';
import ReservationsGestion from '../Pages/gestion/Reservations.jsx';
import SettingsGestion from '../Pages/gestion/Settings.jsx';
import ReservationIdGestion from '../Pages/gestion/ReservationID.jsx';
import StancerDetailsGestion from '../Pages/gestion/StancerDetails.jsx';
import QrCodeBienvenueGestion from '../Pages/gestion/QrCodeBienvenue.jsx';
import MessengerGestion from '../Pages/gestion/Messenger.jsx';
import LogsGestion from '../Pages/gestion/Logs.jsx';
import VoyageurHome from '../Pages/voyageur/VoyageurHome.jsx';
import BienvenuePure from '../Pages/locations/bienvenue/BienvenuePure.jsx';
import BienvenuePecherie from '../Pages/locations/bienvenue/BienvenuePecherie.jsx';
import PagePecherie from '../Pages/locations/pagesDetails/pagePecherie.jsx';
import imagesPure from '../components/Logements/Pure/imagesPure.jsx';
import imagesPecherie from '../components/Logements/Pecherie/imagesPecherie.jsx';
import PagePure from '../Pages/locations/pagesDetails/pagePure.jsx';
import BienvenueSouslesPinsHome from '../Pages/locations/bienvenue/BienvenueSouslesPinsHome.jsx';
import AddPackVoyageur from '../components/Reservations/buy/AddPackVoyageur.jsx';
import MessengerLastReceived from '../Pages/gestion/Messenger/LastReceived.jsx';
import MessengerCreateMsg from '../Pages/gestion/Messenger/Create.jsx';
import MyMissions from '../Pages/gestion/MyMissions.jsx';
import MessengerLastSended from '../Pages/gestion/Messenger/LastSend.jsx';
import MessengerSettings from '../Pages/gestion/Messenger/Settings.jsx';
import imagesMaisonCharmeGourmalon from '../components/Logements/MaisonCharmeGourmalon/imagesMaisonCharmeGourmalon.jsx';
import BienvenueMaisonCharmeGourmalon from '../Pages/locations/bienvenue/BienvenueMaisonCharmeGourmalon.jsx';
import PageMaisonCharmeGourmalon from '../Pages/locations/pagesDetails/pageMaisonCharmeGourmalon.jsx';
import MaisonCharmeGourmalon from '../assets/img/Maison-charme-gourmalon/logo-maison-charme-gourmalon.jpg';
import TimelineSmsAuto from '../Pages/gestion/Messenger/Timeline.jsx';
import BienvenueVillaRose from '../Pages/locations/bienvenue/BienvenueVillaRose.jsx';
import PageVillaRose from '../Pages/locations/pagesDetails/pageVillaRose.jsx';
import imagesVillaRose from '../components/Logements/VillaRose/imagesVillaRose.jsx';
import imagesMaisonCIHome from '../components/Logements/CIHome/imagesMaisonCIHome.jsx';
import PageCIHome from '../Pages/locations/pagesDetails/pageCIHome.jsx';
import BienvenueCIHome from '../Pages/locations/bienvenue/BienvenueCIHome.jsx';
import BienvenuePiedsDsLeau from '../Pages/locations/bienvenue/BienvenuePiedsDsLeau.jsx';
import PagePiedsDsLeau from '../Pages/locations/pagesDetails/pagePiedsDsLeau.jsx';
import imagesPiedsDsLeau from '../components/Logements/PiedsDsLeau/imagesPiedsDsLeau.jsx';
import MessengerPortableSMS from '../Pages/gestion/Messenger/PortableSMS.jsx';

const imagesLogementVillaRose = imagesVillaRose();
const imagesLogementSousLesPinHome = imagesSousLesPinHome();
const imagesLogementPure = imagesPure();
const imagesLogementPecherie = imagesPecherie();
const imagesLogementMaisonCharmeGourmalon = imagesMaisonCharmeGourmalon();
const imagesLogementMaisonCIHome = imagesMaisonCIHome();
const imagesLogementPiedsDsLeau = imagesPiedsDsLeau();

function PrivateRoute({ children }) {
    const location = useLocation();
    const accessToken = useSelector((store) => store.User.accessToken);
    const authUser = useSelector((store) => store.User.authUser);
    if (authUser === undefined || authUser === null || accessToken === null) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/connexion" state={location.pathname} />;
    }

    return children;
}

const ProtectedVoyageur = ({ redirectPath = '/connexion', children }) => {
    const navigate = useNavigate();
    const [nberPhone] = useState(
        localStorage.getItem('authVoyageurPort')
            ? localStorage.getItem('authVoyageurPort')
            : undefined
    );
    const [codeSMSActive] = useState(
        localStorage.getItem('authVoyageurCode')
            ? localStorage.getItem('authVoyageurCode')
            : undefined
    );
    function redirectLogin() {
        navigate('/connexion');
    }

    useEffect(() => {
        if (codeSMSActive === undefined || nberPhone === undefined) {
            redirectLogin();
        }
    }, [codeSMSActive, nberPhone]);

    return children ? children : <Outlet />;
};

function RedirectConciergerie() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/conciergerie', { replace: true });
    }, []);
}
function RedirectLocations() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/locations', { replace: true });
    }, []);
}

export default function Router() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route>
                    <Route
                        index
                        path="/"
                        element={
                            <>
                                <Header />
                                <Accueil />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/connexion"
                        element={
                            <>
                                <Header />
                                <Login />
                                <Footer />
                            </>
                        }
                    />
                    <Route
                        path="/contact"
                        element={
                            <>
                                <Header />
                                <Contact />
                                <Footer />
                            </>
                        }
                    />
                    <Route
                        path="/deconnexion"
                        element={
                            <>
                                <Header />
                                <Logout />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/gestion/"
                        element={
                            <PrivateRoute>
                                <Header />
                                <HomeGestion />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/agenda"
                        element={
                            <PrivateRoute>
                                <Header />
                                <CalendarGestion />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/bienvenue"
                        element={
                            <PrivateRoute>
                                <Header />
                                <QrCodeBienvenueGestion />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/missions/"
                        element={
                            <PrivateRoute>
                                <Header />
                                <MyMissions />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/messagerie"
                        element={
                            <PrivateRoute>
                                <Header />
                                <MessengerGestion />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/messagerie/createmsg"
                        element={
                            <PrivateRoute>
                                <Header />
                                <MessengerCreateMsg />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/messagerie/smsrecus"
                        element={
                            <PrivateRoute>
                                <Header />
                                <MessengerLastReceived />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/messagerie/smsenvoyes"
                        element={
                            <PrivateRoute>
                                <Header />
                                <MessengerLastSended />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/messagerie/timelinesmsauto"
                        element={
                            <PrivateRoute>
                                <Header />
                                <TimelineSmsAuto />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/messagerie/settings"
                        element={
                            <PrivateRoute>
                                <Header />
                                <MessengerSettings />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/messagerie/portableSMS"
                        element={
                            <PrivateRoute>
                                <Header />
                                <MessengerPortableSMS />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/logs"
                        element={
                            <PrivateRoute>
                                <Header />
                                <LogsGestion />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/occupation"
                        element={
                            <PrivateRoute>
                                <Header />
                                <OccupationDirect />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/reservations"
                        element={
                            <PrivateRoute>
                                <Header />
                                <ReservationsGestion />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/reservation/:idReservation/"
                        element={
                            <PrivateRoute>
                                <Header />
                                <ReservationIdGestion />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/settings"
                        element={
                            <PrivateRoute>
                                <Header />
                                <SettingsGestion />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/gestion/stancer"
                        element={
                            <PrivateRoute>
                                <Header />
                                <StancerDetailsGestion />
                                <Footer />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/voyageur"
                        element={
                            <ProtectedVoyageur>
                                <Header />
                                <VoyageurHome />
                                <Footer />
                            </ProtectedVoyageur>
                        }
                    ></Route>
                    <Route
                        path="/voyageur/add_pack"
                        element={
                            <ProtectedVoyageur>
                                <Header />
                                <AddPackVoyageur />
                                <Footer />
                            </ProtectedVoyageur>
                        }
                    ></Route>

                    <Route
                        path="/bienvenue/laplainesurmer/pecherie"
                        element={
                            <>
                                <BienvenuePecherie />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/bienvenue/laplainesurmer/les-pieds-dans-l-eau"
                        element={
                            <>
                                <BienvenuePiedsDsLeau />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/bienvenue/prefailles/villarose"
                        element={
                            <>
                                <BienvenueVillaRose />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/bienvenue/pornic/pure"
                        element={
                            <>
                                <BienvenuePure />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/bienvenue/pornic/maison-charme-gourmalon"
                        element={
                            <>
                                <BienvenueMaisonCharmeGourmalon />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/bienvenue/pornic/c&i-home"
                        element={
                            <>
                                <BienvenueCIHome />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/bienvenue/prefailles/sous-les-pins/maison/"
                        element={
                            <>
                                <BienvenueSouslesPinsHome />
                                <Footer />
                            </>
                        }
                    ></Route>

                    <Route
                        path="/bons-plans"
                        element={
                            <>
                                <BonsPlans />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/conciergerie"
                        element={
                            <>
                                <Header />
                                <Conciergerie />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/menage"
                        element={<RedirectConciergerie />}
                    ></Route>
                    <Route
                        path="/conciergerie-airbnb-pornic"
                        element={<RedirectConciergerie />}
                    ></Route>
                    <Route
                        path="/tarifs-pornic"
                        element={<RedirectConciergerie />}
                    ></Route>
                    <Route
                        path="/gardiennage-pornic"
                        element={<RedirectConciergerie />}
                    ></Route>
                    <Route
                        path="/services"
                        element={<RedirectConciergerie />}
                    ></Route>
                    <Route
                        path="/pret-et-vente"
                        element={<RedirectConciergerie />}
                    ></Route>
                    <Route
                        path="/check-in-check-out"
                        element={<RedirectConciergerie />}
                    ></Route>
                    <Route
                        path="/locations"
                        element={
                            <>
                                <Header />
                                <Locations />
                                <Footer />
                            </>
                        }
                    ></Route>

                    <Route
                        path="/locations/pecherie/"
                        element={
                            <>
                                <Header />
                                <PagePecherie
                                    title={'Au Bord des Pêcheries'}
                                    id={1688317}
                                    logo={PecherieLogo}
                                    images={imagesLogementPecherie}
                                />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/locations/les-pieds-dans-l-eau/"
                        element={
                            <>
                                <Header />
                                <PagePiedsDsLeau
                                    title={"Les Pieds dans l'Eau"}
                                    id={2051344}
                                    logo={logoSer1}
                                    images={imagesLogementPiedsDsLeau}
                                />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/locations/pure/"
                        element={
                            <>
                                <Header />
                                <PagePure
                                    title={'Pure'}
                                    id={1711442}
                                    logo={Purelogo}
                                    images={imagesLogementPure}
                                />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/locations/sous-les-pins/maison/"
                        element={
                            <>
                                <Header />
                                <PageSousLesPinHome
                                    title={'Sous Les Pins - La Maison'}
                                    id={1583795}
                                    accueil={[accueilSSlesPinHome]}
                                    logo={SSlesPinHomelogo}
                                    images={imagesLogementSousLesPinHome}
                                />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/locations/maison-charme-gourmalon/"
                        element={
                            <>
                                <Header />
                                <PageMaisonCharmeGourmalon
                                    title={
                                        'Maison de charme au coeur de Gourmalon'
                                    }
                                    id={1983689}
                                    logo={MaisonCharmeGourmalon}
                                    images={imagesLogementMaisonCharmeGourmalon}
                                />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/locations/villarose/"
                        element={
                            <>
                                <Header />
                                <PageVillaRose
                                    title={'Villa Rose'}
                                    id={2043386}
                                    logo={logoSer1}
                                    images={imagesLogementVillaRose}
                                />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/locations/c&i-home/"
                        element={
                            <>
                                <Header />
                                <PageCIHome
                                    title={'Maison C&I Home'}
                                    id={1983065}
                                    logo={logoSer1}
                                    images={imagesLogementMaisonCIHome}
                                />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/locations/Au-Bord-du-Recif"
                        element={<RedirectLocations />}
                    ></Route>
                    <Route
                        path="/locations/au-bord-du-recif"
                        element={<RedirectLocations />}
                    ></Route>
                    <Route
                        path="/locations/Au-Bord-de-l-eau"
                        element={<RedirectLocations />}
                    ></Route>
                    <Route
                        path="/locations/au-bord-de-l-eau/"
                        element={<RedirectLocations />}
                    ></Route>
                    <Route
                        path="/locations/sous-les-pins/chambre/"
                        element={<RedirectLocations />}
                    ></Route>
                    <Route
                        path="/locations/reservation/create"
                        element={
                            <>
                                <Header />
                                <ModuleReservation />
                                <Footer />
                            </>
                        }
                    ></Route>

                    <Route
                        path="/cgv-mentions-legales"
                        element={
                            <>
                                <Header />
                                <CGV />
                                <Footer />
                            </>
                        }
                    />
                    <Route
                        path="/conditions-generales-de-vente"
                        element={
                            <>
                                <Header />
                                <CGV />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/cgl-reglement-interieur"
                        element={
                            <>
                                <Header />
                                <CGL />
                                <Footer />
                            </>
                        }
                    />
                    <Route
                        path="/plan-de-site"
                        element={
                            <>
                                <Header />
                                <Sitemap />
                                <Footer />
                            </>
                        }
                    />
                    <Route
                        path="/sitemap"
                        element={
                            <>
                                <Header />
                                <Sitemap />
                                <Footer />
                            </>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <>
                                <Header />
                                <Error404 />
                                <Footer />
                            </>
                        }
                    />
                </Route>
            </>
        )
    );
    return router;
}
