import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaUmbrellaBeach } from 'react-icons/fa';
import { HiMiniArrowTopRightOnSquare } from 'react-icons/hi2';
import MassageEvelyne from '../../../Pages/Others/BonsPlansEvelyne.jsx';

export default function DurantSejourBienvenue(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className={props.className}>
            <h2 className="text-center m-3">
                <span className="m-1">
                    <FaUmbrellaBeach />
                </span>
                {translate === 'French' && <>Durant votre Séjour</>}
                {translate === 'English' && <>During your stay</>}
            </h2>

            <p>
                {translate === 'French' && <>{props.duringFr}</>}
                {translate === 'English' && <>{props.duringEn}</>}
            </p>
            <hr className="w-50 mx-auto my-4" />
            <p className="px-4">
                {translate === 'French' && (
                    <>
                        "Je vous invite à regarder la liste des activités que je
                        vous conseille durant votre séjour ainsi que mes
                        restaurants préférés où je suis déjà aller
                        personnellement" <br />
                        Fabien
                    </>
                )}
                {translate === 'English' && (
                    <>
                        "I invite you to look at the list of activities that I
                        advise you during your stay as well as my favorite
                        restaurants I've been to personally" <br />
                        Fabien
                    </>
                )}
            </p>
            <Link
                to="/bons-plans"
                className="d-flex flex-row justify-content-center"
            >
                <div
                    color="primary"
                    className="rounded bg-primary text-white  p-2 mb-2 text-center"
                >
                    {translate === 'French' && (
                        <>
                            Lien vers les Bons-Plans de Fabien{' '}
                            <span className="m-1">
                                <HiMiniArrowTopRightOnSquare />
                            </span>
                        </>
                    )}
                    {translate === 'English' && (
                        <>
                            Link to Fabien's Tips{' '}
                            <span className="m-1">
                                <HiMiniArrowTopRightOnSquare />
                            </span>
                        </>
                    )}
                </div>
            </Link>
            <MassageEvelyne />
        </div>
    );
}
