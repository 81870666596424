import accueil from '../../../assets/img/Pure/photo/accueil.jpg';
import cuisineSalon from '../../../assets/img/Pure/photo/cuisine_salon.jpg';
import chambreSalon from '../../../assets/img/Pure/photo/chambreSalon.jpg';
import chambreSalon2 from '../../../assets/img/Pure/photo/chambreSalon2.jpg';
import couloirEntree from '../../../assets/img/Pure/photo/couloirEntree.jpg';
import chambreEntree from '../../../assets/img/Pure/photo/chambreEntree.jpg';
import chambreEntree2 from '../../../assets/img/Pure/photo/chambreEntree2.jpg';
import chambreEnfant from '../../../assets/img/Pure/photo/chambreEnfant.jpg';
import chambreEnfant2 from '../../../assets/img/Pure/photo/chambreEnfants2.jpg';
import salon from '../../../assets/img/Pure/photo/salon.jpg';
import sdb from '../../../assets/img/Pure/photo/sdb.jpg';
import portail from '../../../assets/img/Pure/photo/portail.jpg';
import cours from '../../../assets/img/Pure/photo/cours.jpg';
import cours2 from '../../../assets/img/Pure/photo/cours2.jpg';
import vueExt from '../../../assets/img/Pure/photo/vueExt.jpg';

export default function imagesPure() {
    const listing = [
        {
            original: accueil,
            description: 'Grand salon vue sur la ville',
            descriptionEn: 'Large living room with city view',
        },
        {
            original: vueExt,
            description:
                "Vue du salon sur l'arrière de la petite chapelle en plein cœur de Pornic. ",
            descriptionEn:
                'View from the living room on the back of the small chapel in the heart of Pornic.',
        },
        {
            original: couloirEntree,
            description:
                "Couloir d'accès aux différentes pièces de l'appartement.",
            descriptionEn:
                'Access corridor to the different rooms of the apartment.',
        },
        {
            original: salon,
            description: "Vue d'ensemble salon, cuisine. ",
            descriptionEn: 'Overall view living room, kitchen.',
        },
        {
            original: cuisineSalon,
            description:
                'Cuisine équipée ouverte sur le salon avec tout le nécessaire. ',
            descriptionEn:
                'Fitted kitchen open to the living room with all the necessities.',
        },
        {
            original: chambreSalon,
            description: 'Chambre principale avec tv et lit de 140cm',
            descriptionEn: 'Master bedroom with tv and 140cm bed',
        },
        {
            original: chambreSalon2,
            description: 'Chambre principale avec tv et lit de 140cm',
            descriptionEn: 'Master bedroom with tv and 140cm bed',
        },
        {
            original: chambreEntree,
            description:
                'Chambre avec lit en 140cm et dressing, coté jardin et parking de la résidence donnant sur un balcon fleuris',
            descriptionEn:
                'Bedroom with 140cm bed and dressing room, garden side and parking lot of the residence overlooking a flowered balcony',
        },
        {
            original: chambreEntree2,
            description:
                'Chambre avec lit en 140cm et dressing, coté jardin et parking de la résidence donnant sur un balcon fleuris',
            descriptionEn:
                'Bedroom with 140cm bed and dressing room, garden side and parking lot of the residence overlooking a flowered balcony',
        },
        {
            original: chambreEnfant,
            description:
                'Chambre enfants avec deux lits de 90cm et grande penderie. (coté jardin / parking)',
            descriptionEn:
                "Children's bedroom with two 90cm beds and large wardrobe. (garden side / car park)",
        },
        {
            original: chambreEnfant2,
            description: 'Chambres enfants, 2 lit en 90cm.',
            descriptionEn: "Children's bedroom with two 90cm beds",
        },
        {
            original: sdb,
            description: 'Salle de bain avec baignoire et machine à laver.',
            descriptionEn: 'Bathroom with bathtub and washing machine.',
        },
        {
            original: portail,
            description: 'Accès à au parking privée au cœur de Pornic. ',
            descriptionEn: 'Access to private parking in the heart of Pornic.',
        },
        {
            original: cours,
            description: "Arrière de la résidence avec l'accès au Parking ",
            descriptionEn: 'Back of the residence with access to parking',
        },
        {
            original: cours2,
            description: 'A la place du Kangoo, votre place de Parking privée ',
            descriptionEn: 'In place of Kangoo your private parking space',
        },
    ];
    return listing;
}
