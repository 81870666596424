import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaInfoCircle } from 'react-icons/fa';

export default function HelpSProprietaire() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <FaInfoCircle /> Informations complémentaires
            </Button>

            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Services & Options supplémentaires pour les
                        propriétaires
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>Heure de ménage supplémentaire</h2>
                    <p>
                        Les heures de ménage supplémentaires sont en supplément
                        d’une prestation, chaque heure démarrée est facturée en
                        totalité.
                    </p>
                    <hr className="col-6 mx-auto" />
                    <h2>
                        Pack de Bienvenue "Les Essentiels" en plus grandes
                        quantités pour s'adapter au nombre de voyageurs
                    </h2>
                    <p>
                        Adaptation des packs au nombre de voyageurs et de la
                        durée du séjour.
                    </p>
                    <hr className="col-6 mx-auto" />
                    <h2>Passage de Bienvenue pendant le séjour</h2>
                    <p>
                        Consiste à passer au début du séjour pour souhaiter la
                        bienvenue au voyageur et répondre à toutes leurs
                        questions.
                    </p>
                    <hr className="col-6 mx-auto" />
                    <h2>Accueil en présentiel</h2>
                    <p>
                        Organisation de l’arrivée des voyageurs sur RDV et selon
                        les disponibilités du planning. Remise des clés,
                        Présentation de la localisation et de son environnement.
                        (Dans la limite de 30min et sur place)
                    </p>
                    <hr className="col-6 mx-auto" />
                    <h2>Départ en présentiel</h2>
                    <p>
                        Organisation du départ des voyageurs sur RDV et selon
                        les disponibilités du planning Recueille des remarques
                        des voyageurs et reprises des clés ainsi qu'une mise en
                        sécurité du logement. (Dans la limite de 30min et sur
                        place)
                    </p>
                    <hr className="col-6 mx-auto" />
                    <h2>Corbeille de fruits de Bienvenue</h2>
                    <p>Corbeille de fruit comprenant 5 à 10 pièces</p>
                    <hr className="col-6 mx-auto" />
                    <h2>Bouquet de fleurs de Bienvenue</h2>
                    <p>Bouquet de fleurs en poche de 5 à 10 tiges</p>
                    <hr className="col-6 mx-auto" />
                    <h2>Blanchiment de votre linge</h2>
                    <p>Nous blanchissons votre propre linge</p>
                    <ul>
                        <li>
                            Par personne : 1 serviette, 1 draps housse, 1 housse
                            de couette, 1 taie.
                        </li>
                        <li>
                            Par Cuisine : un essuie main et un torchon à
                            vaisselle.
                        </li>
                        <li> Par Salle de Douche/Bain: 1 tapis de bain.</li>
                        <li>
                            Tout linge épais amène à une majoration de 50%, 7
                            jours de délais.
                        </li>
                    </ul>

                    <hr className="col-6 mx-auto" />
                    <h2>Blanchiment de votre linge à la pièce</h2>
                    <p>
                        Consiste à blanchir le linge dit « spécial » par exemple
                        un plaid de canapé. Selon la taille le prix augmente de
                        5€ à 15€
                    </p>
                    <hr className="col-6 mx-auto" />
                    <h2>Pack de Bienvenue "Les Essentiels"</h2>
                    <p>Composition dans 3 boites distinctes :</p>
                    <ul>
                        <li>
                            2 spéculos 2 sucres, 2 thés verts, 4 dosettes de
                            café type Nespresso.
                        </li>
                        <li>
                            1 sac poubelle 50L, 1 sac poubelle 10L, 1 pastille
                            de lave-vaisselle, 1 passoire de lessive, liquide
                            vaisselle 30ml, éponge
                        </li>
                        <li>
                            Gel douche 30 ml, shampoing 30 ml, savon, coton tige
                            et coton
                        </li>
                    </ul>

                    <hr className="col-6 mx-auto" />
                    <h2>Pack alèse.</h2>
                    <p>
                        Fourniture et blanchiment d’alèse de lit et oreiller
                        pour 1 lit
                    </p>
                    <hr className="col-6 mx-auto" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        D'accord
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
