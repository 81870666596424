import { useSelector } from 'react-redux';
import { BsKey } from 'react-icons/bs';

export default function BoiteCleBienvenue(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className={props.className}>
            <h2 className="text-center m-3">
                <span className="me-2">
                    {' '}
                    <BsKey />
                </span>
                {translate === 'French' && 'Les clés du logements'}
                {translate === 'English' && 'Accommodation keys'}
            </h2>

            <div>
                {translate === 'French' && <>{props.boiteFr}</>}
                {translate === 'English' && <>{props.boiteEn}</>}
            </div>
            {props.photos}
        </div>
    );
}
