import { useSelector } from 'react-redux';

export default function CitationLogementHome() {
    const translate = useSelector((store) => store.Template.language);
    return (
        <article className="banner__citation">
            {translate === 'French' && (
                <p className="font-title text-center  mx-auto my-4 display-6 w-75">
                    «Je suis très exigeant sur la qualité des services que je
                    peux offrir, car produire un service de qualité à toujours
                    fait partie de mes valeurs »
                </p>
            )}
            {translate === 'English' && (
                <p className="font-title text-center mx-auto my-4 display-6 w-75">
                    “I am very demanding on the quality of the services that I
                    can offer, because producing quality service forever is part
                    of my values”
                </p>
            )}
        </article>
    );
}
