import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { VscSettings } from 'react-icons/vsc';
import { PiAirplaneTiltFill } from 'react-icons/pi';
import { IoBed } from 'react-icons/io5';

import imgHomeKey from '../../assets/img/illustrations/home-589068_640.jpg';

export default function PartConciergerieHome() {
    const translate = useSelector((store) => store.Template.language);
    return (
        <div className="homepage-conciergerie">
            <div className="homepage-conciergerie-text">
                <div className="text-center">
                    {translate === 'French' && (
                        <>
                            <h2 className="font-body">
                                Profitez de nos Services de Conciergerie et
                                détentez-vous
                            </h2>
                            <h3>Ma Maison Sereine, avec Vous, à Vos côtés.</h3>
                        </>
                    )}
                    {translate === 'English' && (
                        <>
                            <h2>Enjoy our Concierge Services! and relax</h2>
                            <h3>My Serene Home, with You, by Your side.</h3>
                        </>
                    )}
                </div>
                <div className="homepage-conciergerie-text-grid">
                    <div className="spart">
                        <div className="spart-logo serein">
                            <VscSettings />
                        </div>

                        {translate === 'French' && <h4>Gestion Annonce</h4>}
                        {translate === 'English' && (
                            <h4>Announcement management</h4>
                        )}

                        <ul className="text-start">
                            {translate === 'French' && (
                                <>
                                    <li>Supervision Annonces</li>
                                    <li>Supervision Calendrier de Prix</li>
                                    <li>Communication Voyageurs</li>
                                    <li>Intermédiaire Unique</li>
                                </>
                            )}
                            {translate === 'English' && (
                                <>
                                    <li>Announcements Monitoring</li>
                                    <li>Price Schedule Supervision</li>
                                    <li>Traveller Communication</li>
                                    <li>Single Intermediary</li>
                                </>
                            )}
                        </ul>
                    </div>
                    <div className="spart">
                        <div className="spart-logo serein">
                            <PiAirplaneTiltFill />
                        </div>

                        {translate === 'French' && (
                            <h4>Communication Voyageurs</h4>
                        )}
                        {translate === 'English' && (
                            <h4>Traveler Communication</h4>
                        )}

                        <ul className="text-start">
                            {translate === 'French' && (
                                <>
                                    <li>Astreine et communication par SMS</li>
                                    <li>
                                        SMS livret d'accueil numérique & SMS
                                        code Boîte à Clés
                                    </li>
                                    <li>Vérification Logement au départ</li>
                                    <li>Recueil Avis Voyageurs</li>
                                </>
                            )}
                            {translate === 'English' && (
                                <>
                                    <li>Astreine and communication by SMS</li>
                                    <li>
                                        SMS digital welcome booklet & SMS code
                                        Key Box
                                    </li>
                                    <li>Accommodation check on departure</li>
                                    <li>Traveler Reviews Collection</li>
                                </>
                            )}
                        </ul>
                    </div>
                    <div className="spart">
                        <div className="spart-logo serein">
                            <i className="fa-solid fa-person-circle-exclamation"></i>
                        </div>

                        {translate === 'French' && <h4>Ménage</h4>}
                        {translate === 'English' && <h4>Household</h4>}

                        <ul className="text-start">
                            {translate === 'French' && (
                                <>
                                    <li>
                                        Entretien Systématique et Professionnel
                                    </li>
                                    <li>Nettoyage/Dépoussiérage meubles</li>
                                    <li>Aspiration/Lessivages des sols</li>
                                    <li>Désinfection SDB/WC</li>
                                    <li>
                                        Nettoyage Cuisine, contrôle vaisselle,
                                        etc
                                    </li>
                                    <li>Vérification des poubelles</li>
                                </>
                            )}
                            {translate === 'English' && (
                                <>
                                    <li>
                                        Systematic and Professional Maintenance
                                    </li>
                                    <li>Furniture cleaning/dusting</li>
                                    <li>Soil vacuuming/washing</li>
                                    <li>Bathroom/WC disinfection</li>
                                    <li>Kitchen cleaning, dishwashing, etc.</li>
                                    <li>Checking trash cans</li>
                                </>
                            )}
                        </ul>
                    </div>

                    <div className="spart">
                        <div className="spart-logo serein">
                            <IoBed />
                        </div>
                        {translate === 'French' && (
                            <h4>Prêt de linge / Pressing</h4>
                        )}
                        {translate === 'English' && <h4>Pressing</h4>}

                        <ul className="text-start">
                            {translate === 'French' && (
                                <>
                                    <li>Blanchiment Linge</li>
                                    <li>Location linge de lit standard</li>
                                    <li>Location linge de lit hôtelier</li>
                                </>
                            )}
                            {translate === 'English' && (
                                <>
                                    <li>Laundry Bleaching</li>
                                    <li>Standard bed linen rental</li>
                                    <li>Hotel bed linen rental</li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-around mt-5">
                    <Link to="/conciergerie" className="mt-5">
                        <button className="btn btn-primary p-3">
                            Découvrir nos Services de Conciergerie
                        </button>
                    </Link>
                </div>
            </div>
            <div className="homepage-conciergerie-img">
                <img
                    src={imgHomeKey}
                    alt="Maison avec clés"
                    loading="lazy"
                ></img>
            </div>
        </div>
    );
}
