import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function MiniCardLogement(props) {
    const link = props.to;
    const translate = useSelector((store) => store.Template.language);

    return (
        <Link
            to={link}
            className={props.classNameLink}
            title="Cliquer pour plus d'information sur la réservation"
        >
            <article className={props.classNameArticle}>
                <div className="card__loge__city">
                    <i className="fa-solid fa-signs-post fs-6 me-2"></i>
                    <span>{props.city}</span>
                </div>
                <div className="d-flex flex-row justify-content-around">
                    <div className="card__loge__logo">
                        <img
                            src={props.logo}
                            alt={`Logo - ${props.title}`}
                            width={props.logoWidth}
                        />
                    </div>

                    <div className="card__loge__details">
                        <h2 className={props.classTitle}>"{props.title}"</h2>
                        {props.subtitle && (
                            <h3 className={props.classSubtitle}>
                                {props.subtitle}
                            </h3>
                        )}
                        <div>
                            <i className="fa-solid fa-house"></i>
                            <span>{props.typeLogement}</span>
                        </div>
                        <div>
                            <i className="fa-solid fa-people-roof"></i>
                            {translate === 'French' && (
                                <span>Pour {props.nbrVoyageurs} voyageurs</span>
                            )}
                            {translate === 'English' && (
                                <span>For {props.nbrVoyageurs} travelers</span>
                            )}
                        </div>
                        {props.parking && (
                            <div>
                                <i className="fa-solid fa-car"></i>
                                <span>{props.parking}</span>
                            </div>
                        )}
                        {props.arrivalAuto && (
                            <div>
                                <i className="fa-solid fa-key"></i>

                                {translate === 'French' && (
                                    <span>Arrivée autonome</span>
                                )}
                                {translate === 'English' && (
                                    <span>Autonomous arrival</span>
                                )}
                            </div>
                        )}
                        {props.lits && (
                            <div>
                                <i className="fa-solid fa-bed"></i>
                                <span>{props.lits}</span>
                            </div>
                        )}
                        {props.sdb && (
                            <div>
                                <i className="fa-solid fa-shower"></i>
                                <span>{props.sdb}</span>
                            </div>
                        )}{' '}
                        {props.garden && (
                            <div>
                                <i className="fa-solid fa-cloud-moon"></i>
                                <span>{props.garden}</span>
                            </div>
                        )}
                        {props.optionSpa && (
                            <div>
                                <i className="fa-solid fa-spa"></i>
                                <span>{props.optionSpa}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card__loge__reservation">
                    <i className="fa-solid fa-receipt fs-2 me-2"></i>

                    {translate === 'French' && (
                        <span>En Savoir plus et Réserver</span>
                    )}
                    {translate === 'English' && (
                        <span>More informations and Book inline</span>
                    )}
                </div>
                {props.logementHost && (
                    <div className="card__loge__fabien">
                        <i className="fa-solid fa-house fs-6 me-2"></i>
                        {translate === 'French' && (
                            <span>Logement Chez {props.logementHost}</span>
                        )}
                        {translate === 'English' && (
                            <span>Housing with {props.logementHost}</span>
                        )}
                    </div>
                )}
            </article>
        </Link>
    );
}
