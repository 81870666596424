import { useSelector } from 'react-redux';
import { FaMapSigns } from 'react-icons/fa';
import { SiGooglestreetview } from 'react-icons/si';
import { FaApple, FaWaze } from 'react-icons/fa6';

export default function GpsBienvenue(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className={props.className}>
            <h2 className="text-center m-3">
                <FaMapSigns />
                {translate === 'French' ? 'Adresse' : 'Address'}
            </h2>

            {translate === 'French' && (
                <>
                    <p>
                        L'accès au logement se fait via cette adresse :{' '}
                        {props.address}
                    </p>
                    <p>Voici les Liens GPS d'accès :</p>
                </>
            )}
            {translate === 'English' && (
                <>
                    <p>Access is via this address: {props.address}</p>
                    <p>Here are the GPS Access Links:</p>
                </>
            )}
            <div className="d-flex flex-row flex-wrap justify-content-around align-items-center">
                <div className=" mt-2 ms-4 d-flex flex-row justify-content-start align-items-center">
                    <a
                        href={props.gpsGoogle}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-outline-primary"
                    >
                        {translate === 'French' && <>Lien Google Maps</>}
                        {translate === 'English' && <>Google Maps Link</>}
                        <span className="ms-2 fs-3">
                            <SiGooglestreetview />
                        </span>
                    </a>
                </div>
                {props.gpsApple !== undefined && (
                    <div className=" mt-2 ms-4 d-flex flex-row justify-content-start align-items-center">
                        <a
                            href={props.gpsApple}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-outline-primary"
                        >
                            {translate === 'French' && <> Lien Apple Maps</>}
                            {translate === 'English' && <>Apple Maps Link</>}
                            <span className="ms-2 fs-3">
                                <FaApple />
                            </span>
                        </a>
                    </div>
                )}
                {props.gpsWaze !== undefined && (
                    <div className=" mt-2 ms-4 d-flex flex-row justify-content-start align-items-center">
                        <a
                            href={props.gpsWaze}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-outline-primary"
                        >
                            {translate === 'French' && <> Lien Waze</>}
                            {translate === 'English' && <>Waze Link</>}
                            <span className="ms-2 fs-3">
                                <FaWaze />
                            </span>
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
}
