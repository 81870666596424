import { useSelector } from 'react-redux';

export default function DescriptionLogSousLesPinsHome() {
    const translate = useSelector((store) => store.Template.language);
    return (
        <>
            {translate === 'French' && (
                <>
                    <p>
                        La maison de plain-pied se situe à seulement 200 mètres
                        de l'océan. Elle comporte un trés grand jardin privé
                        sous les pins avec du mobiliers d'extérieurs. La maison
                        comprend une cuisine équipée, un séjour, cheminée, télé,
                        une salle de douche, un toilette, une chambre avec un
                        grand lit ,chambre avec 2 lits 1 p.
                    </p>
                    <p>
                        Une chambre supplémentaire attenant à la maison avec
                        salle de douche + wc et ouverte à partir du cinquième
                        voyageur, lit 2 places.
                    </p>
                    <p>
                        Draps et serviettes fournis (offert), ménage non
                        compris.
                    </p>
                    <p>
                        Fabien, notre concierge, s'occupe de notre maison de
                        vacance car nous habitons loin. Il sera disponible et
                        présent en cas de besoin. Il vous contactera quelques
                        jours avant pour organiser votre arrivée via une boite à
                        clés.
                    </p>
                </>
            )}
            {translate === 'English' && (
                <>
                    <p>
                        The single-storey house is located just 200 meters of
                        the ocean. It has a very large private garden under the
                        pines with outdoor furniture. The House includes an
                        equipped kitchen, a living room, fireplace, TV, a shower
                        room, a toilet, a bedroom with a double bed, bedroom
                        with 2 single beds.
                    </p>
                    <p>
                        An additional bedroom adjoining the house with shower
                        room + wc and open from the fifth traveler, double bed.
                    </p>
                    <p>
                        Sheets and towels provided (offered), cleaning not
                        Understood.
                    </p>
                    <p>
                        Fabien, our caretaker, takes care of our house from
                        vacation because we live far away. It will be available
                        and present when needed. He will contact you a few days
                        before to organize your arrival via a mailbox keys.
                    </p>
                </>
            )}
        </>
    );
}
