import { Alert } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
    FaTemperatureHalf,
    FaPlugCircleExclamation,
    FaTriangleExclamation,
} from 'react-icons/fa6';

export default function ChauffageBienvenue(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className={props.className}>
            <h2 className="text-center m-3">
                <span className="m-1">
                    <FaTemperatureHalf />
                </span>
                {translate === 'French' && <>Electricité & Chauffage</>}
                {translate === 'English' && <>Electricity & Heating</>}
            </h2>

            <div>
                {translate === 'French' && <>{props.consigneFr}</>}
                {translate === 'English' && <>{props.consigneEn}</>}
                <Alert
                    className="text-center fw-bold"
                    color="warning"
                    isOpen={true}
                >
                    {' '}
                    <p>
                        <span className="m-1">
                            <FaPlugCircleExclamation />
                        </span>
                        {translate === 'French' && (
                            <>
                                Tout excès de chauffage sera facturé. Merci de
                                faire attention.
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                Any excess heating will be charged. Thanks for
                                doing attention.
                            </>
                        )}
                    </p>
                    <hr />
                    <p>
                        <span className="m-1">
                            <FaTriangleExclamation />
                        </span>
                        {translate === 'French' && (
                            <>
                                En cas de coupure électrique, vérifier les
                                disjoncteurs du logement présent{' '}
                                {props.dijoncteurFR}. <br />
                                Sinon appuyer 5 secondes sur le bouton + du
                                compteurs électrique Linky présent{' '}
                                {props.linkyFR}
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                In the event of a power outage, check the
                                circuit breakers of the dwelling present{' '}
                                {props.dijoncteurEN}. <br />
                                Otherwise press for 5 seconds on the + button of
                                the Linky electric meters present{' '}
                                {props.linkyEN}
                            </>
                        )}
                    </p>
                </Alert>
            </div>
        </div>
    );
}
