import { useSelector } from 'react-redux';
import { Badge, Table } from 'reactstrap';
import LoadingK from '../Widget/LoadingK.jsx';

export default function WidgetPackMenage(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className="listing__menage">
            {translate === 'French' && (
                <h3 className="fs-2">
                    <u>Le Ménage : </u>{' '}
                    <Badge className="mt-1 ms-3" color="purple" pill>
                        En Option
                    </Badge>
                </h3>
            )}
            {translate === 'English' && (
                <h3 className="fs-2">
                    <u>Cleaning: </u>{' '}
                    <Badge className="mt-1 ms-3" color="purple" pill>
                        Optional
                    </Badge>
                </h3>
            )}

            <span>
                {translate === 'French' && 'Profitez du Service Ménage :'}
                {translate === 'English' &&
                    'Take advantage of the Cleaning Service:'}
                <i className="fa-solid fa-broom"></i>
            </span>
            <br />
            <Table bordered hover className="text-center bg-light rounded mt-3">
                <tbody>
                    <tr>
                        <th className="bg-primary text-white">Maison</th>
                        <th className="bg-primary text-white">
                            <span>
                                Séjour 1 à{' '}
                                {props.dataLocationSettings !== undefined &&
                                    props.dataLocationSettings
                                        .number_night_short_booking}
                                j inclus{' '}
                            </span>
                        </th>
                        <th className="bg-primary text-white">
                            <span>
                                Séjour sup. à{' '}
                                {props.dataLocationSettings !== undefined &&
                                    props.dataLocationSettings
                                        .number_night_short_booking + 1}
                                j
                            </span>
                        </th>
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">
                            Forfait
                        </th>

                        <td className="text-primary">
                            {props.dataLocationSettings !== undefined ? (
                                <span>
                                    <>
                                        {props.cat === 1 &&
                                            props.dataLocationSettings
                                                .price_menage_cat1_inf}
                                        {props.cat === 2 &&
                                            props.dataLocationSettings
                                                .price_menage_cat2_inf}
                                        {props.cat === 3 &&
                                            props.dataLocationSettings
                                                .price_menage_cat3_inf}
                                    </>
                                    {' €'}
                                </span>
                            ) : (
                                <LoadingK />
                            )}
                        </td>
                        <td className="text-primary">
                            {props.dataLocationSettings !== undefined ? (
                                <span>
                                    <>
                                        {props.cat === 1 &&
                                            props.dataLocationSettings
                                                .price_menage_cat1_sup}
                                        {props.cat === 2 &&
                                            props.dataLocationSettings
                                                .price_menage_cat2_sup}
                                        {props.cat === 3 &&
                                            props.dataLocationSettings
                                                .price_menage_cat3_sup}
                                    </>
                                    {' €'}
                                </span>
                            ) : (
                                <LoadingK />
                            )}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}
