import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import CalendarBooking from '../../../components/Reservations/CalendarBooking.jsx';
import Carrousel from '../../../components/Logements/Caroussel.jsx';
import ListingDetailsPecherie from '../../../components/Logements/Pecherie/ListingDetailsPecherie.jsx';
import { getApartments } from '../../../Store/Actions/smoobu.actions';

export default function PagePecherie(props) {
    document.title = `Location '${props.title}' à Pornic 44210 | Ma Maison Sereine`;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const translate = useSelector((store) => store.Template.language);
    const dispatch = useDispatch();
    const dataApartments = useSelector((store) => store.Smoobu.dataApartments);
    useEffect(() => {
        setTimeout(() => {
            dispatch(getApartments());
        }, 1000);
    }, []);
    return (
        <main className="page_logement">
            <article className="page_logement__content">
                <Carrousel images={props.images} />
                <img
                    src={props.logo}
                    alt={props.title}
                    title={props.title}
                    loading="lazy"
                    width={'160px'}
                    className="card-location-logo"
                />
                <div className="fs-2">
                    <h1>
                        {translate === 'French' && (
                            <>Location - {props.title}</>
                        )}
                        {translate === 'English' && <>Rental - {props.title}</>}
                    </h1>
                    <h2>
                        {translate === 'French' && (
                            <>
                                Maison Complète pour 8 voyageurs - 3 chambres -
                                5 lits - 1 salle d'eau avec Douche
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                Complete house for 8 travelers - 3 bedrooms - 5
                                beds - 1 bathroom with shower
                            </>
                        )}
                    </h2>
                </div>
                <div className="bg-primary hr-h my-4"></div>
                {translate === 'French' && (
                    <>
                        <p>
                            Jolie maison, sur un terrain clos et sans vis-à-vis,
                            idéal pour les familles avec enfants, se situant à
                            200 mètres de la mer dans un quartier calme.{' '}
                        </p>
                        <p>
                            Maison dotée d'un parking privé. Le logement se
                            situe entre Tharon Plage et La Plaine Sur Mer.
                        </p>
                        <p>
                            Elle comprend une cuisine, un séjour spacieux et une
                            salle de douche. Vous trouverez également 3 chambres
                            à l'étage. Et une véranda donnant sur un jardin.
                        </p>

                        <p>Linge de lit et Ménage non compris.</p>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <p>
                            Pretty house, on a closed ground and without
                            vis-à-vis, ideal for families with children, located
                            at 200 meters from the sea in a quiet area.{' '}
                        </p>
                        <p>
                            House with private parking. The accommodation is
                            located between Tharon Plage and La Plaine Sur Mer.
                        </p>
                        <p>
                            It includes a kitchen, a spacious living room and a
                            bathroom. You will also find 3 bedrooms upstairs.
                            And a veranda overlooking a garden.
                        </p>

                        <p>Linen and cleaning not included.</p>
                    </>
                )}
                <div className="bg-primary hr-h my-4"></div>
                <ListingDetailsPecherie />
                <div className="bg-primary hr-h my-4"></div>
                <Carrousel images={props.images} active={true} />
                <div className="bg-primary hr-h my-4"></div>
                <div className="d-flex flex-row flex-wrap">
                    <div className="d-block d-md-none bg-primary hr-h my-4"></div>
                    <div className="calendarWidget locations__localisation mx-auto">
                        <h2 className="fs-1 mb-3 text-center">
                            {translate === 'French' && (
                                <>Calendrier de Réservation</>
                            )}
                            {translate === 'English' && <>Booking Calendar</>}
                        </h2>
                        {dataApartments !== null &&
                            dataApartments.map(
                                (appart) =>
                                    appart.id === props.id && (
                                        <div key={appart.id}>
                                            <h2>
                                                <i className="fa-solid fa-map-location-dot"></i>{' '}
                                                {appart.name}
                                            </h2>
                                            <CalendarBooking
                                                apartment={appart}
                                                channel={'airbnb'}
                                                linkAirbnb={
                                                    'https://www.airbnb.fr/rooms/550496413853318325?source_impression_id=p3_1692274502_vMwz9IstqImxOFHE'
                                                }
                                            />
                                        </div>
                                    )
                            )}
                    </div>
                </div>
            </article>

            <section className="sect__widgets">
                <CardHomeWidgetsBottom />
            </section>
        </main>
    );
}
