import { useSelector } from 'react-redux';

export default function MerciBienvenue(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className={props.className}>
            {translate === 'French' && (
                <p className="text-center pt-3">{props.merciFr}</p>
            )}
            {translate === 'English' && (
                <p className="text-center pt-3">{props.merciEn}</p>
            )}

            <div className="d-flex flex-row justify-content-center align-items-center">
                <span className="ms-2 text-primary">Fabien & son équipe</span>
            </div>
        </div>
    );
}
