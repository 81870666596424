import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import CardHomeWidgetsBottom from '../../components/Widget/CardsWidgetsBottom.jsx';
import AccordeonConceptLocation from '../../components/Logements/AccordeonConceptLocation.jsx';
import ListingLogement from '../../components/Logements/Listing.logement.jsx';
import SearchDispoLog from '../../components/Widget/SearchDispoLog.jsx';

export default function Locations() {
    document.title =
        'Découvrer nos locations sur Pornic, La Plaine Sur Mer, Préfailles, etc. | Ma Maison Sereine';
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const translate = useSelector((store) => store.Template.language);
    return (
        <main className="bg-primary pt-4">
            <h1 className="mt-3 text-center text-white mx-auto">
                <i className="fa-solid fa-house me-2"></i>
                {translate === 'French' && (
                    <span>Découvrez nos Logements en AirBnb !</span>
                )}
                {translate === 'English' && <span>Services for owners</span>}
            </h1>
            <h2 className="text-center mt-3 text-white w-75 mx-auto mb-5">
                {translate === 'French' && (
                    <span>
                        Dormez Sereinement : Logement vérifié, nettoyage de
                        qualité et toujours quelqu'un à vos côtés.
                    </span>
                )}
                {translate === 'English' && (
                    <span>
                        Sleep Peacefully: Housing verified, cleaning of quality
                        and always someone by your side.
                    </span>
                )}
            </h2>

            <section className="pageLocation-listing">
                <ListingLogement />
            </section>
            <p className="my-5 font-title text-primary fs-4 text-center text-white">
                {translate === 'French' && (
                    <>
                        Les propriétaires qui travaillent et proposent leur
                        logement avec <br />
                        Ma Maison Sereine <br />
                        respectent nos Valeurs et Nos Engagements.
                    </>
                )}
                {translate === 'English' && (
                    <>
                        Landlords who work and offer their accommodation with{' '}
                        <br />
                        My Serene Home <br />
                        Respects our Values and Our Commitments.
                    </>
                )}
            </p>

            <div className="bg-white p-5">
                <SearchDispoLog />
            </div>
            <section className="sect__banner__bottom ">
                <AccordeonConceptLocation />
            </section>
            <section className="sect__widgets">
                <CardHomeWidgetsBottom />
            </section>
        </main>
    );
}
