import accueil from '../../../assets/img/SSlesPins/home/ss_les_pins_home-accueil.jpg';
import chambrePrincipale from '../../../assets/img/SSlesPins/home/chambre_principale.jpg';
import salleDouche from '../../../assets/img/SSlesPins/home/salledouche.jpg';
import terrain from '../../../assets/img/SSlesPins/home/terrain.jpg';
import terrasseCuisine from '../../../assets/img/SSlesPins/home/terasse_cuisine.jpg';
import entree from '../../../assets/img/SSlesPins/home/entree.jpg';
import parking from '../../../assets/img/SSlesPins/home/parking.jpg';
import maison from '../../../assets/img/SSlesPins/home/maison.jpg';
import salon from '../../../assets/img/SSlesPins/home/salon.jpg';
import salon2 from '../../../assets/img/SSlesPins/home/salon2.jpeg';
import salon3 from '../../../assets/img/SSlesPins/home/salon3.jpg';
import cuisine from '../../../assets/img/SSlesPins/home/cuisine.jpg';
import cuisine2 from '../../../assets/img/SSlesPins/home/cuisine2.jpg';
import jardin from '../../../assets/img/SSlesPins/home/jardin.jpg';
import chambreSecondaire from '../../../assets/img/SSlesPins/home/chambre_secondaire.jpg';

export default function imagesSousLesPinHome() {
    const listing = [
        {
            original: accueil,
            description: 'Espace Salon Extérieur avec grande Terrasse',
            descriptionEn: 'Outdoor Lounge Area with large terrace',
        },
        {
            original: parking,
            description: 'Parking privée dans la propriété cloturée',
            descriptionEn: 'Private parking in the fenced property',
        },
        {
            original: maison,
            description: 'Découvrez la Maison "Sous les Pins"',
            descriptionEn: 'Discover the House "Under the Pines"',
        },
        {
            original: entree,
            description: 'Entrée de la Maison',
            descriptionEn: 'Entrance of the House',
        },
        {
            original: cuisine,
            description:
                'Cuisine équipée de la Maison avec four (lave-linge et lave-vaisselle dans sellier',
            descriptionEn:
                'Kitchen equipped with the House with oven (washing machine and dishwasher in saddler',
        },
        {
            original: cuisine2,
            description:
                'Cuisine équipée de la Maison avec four (lave-linge et lave-vaisselle dans sellier',
            descriptionEn:
                'Kitchen equipped with the House with oven (washing machine and dishwasher in saddler',
        },
        {
            original: salon,
            description: 'Salon avec accès Cuisine et Grande terrasse ',
            descriptionEn:
                'Living room with access to kitchen and large terrace',
        },
        {
            original: salon2,
            description: 'Salon ',
            descriptionEn: 'Living room',
        },
        {
            original: salon3,
            description: 'Salon avec accès Cuisine et Grande terrasse ',
            descriptionEn:
                'Living room with access to kitchen and large terrace',
        },
        {
            original: chambrePrincipale,
            description: 'Chambre avec grand lit 140 avec grand placard mural',
            descriptionEn:
                'Bedroom with double bed 140 with large wall cupboard',
        },
        {
            original: chambreSecondaire,
            description: 'Chambre avec 2 lits 90 avec placard mural ',
            descriptionEn: 'Bedroom with 2 single beds with wall cupboard',
        },
        {
            original: salleDouche,
            description: "Salle d'eau avec Douche",
            descriptionEn: 'Bathroom with Shower',
        },
        {
            original: terrain,
            description: "Jardin à l'entrée de la propriété",
            descriptionEn: 'Garden at the entrance of the property',
        },
        {
            original: terrasseCuisine,
            description: 'Petite Terrasse attenante à la Cuisine',
            descriptionEn: 'Small Terrace adjoining the Kitchen',
        },
        {
            original: jardin,
            description: 'Jardin sous les pins',
            descriptionEn: 'Garden under the pines',
        },
    ];
    return listing;
}
