import { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
import { useSelector } from 'react-redux';

import useFetchGetListingVoyageur from '../../utils/Api/FetchVoyageur.jsx';
import LoadingK from '../../components/Widget/LoadingK.jsx';
import CardHomeWidgetsBottom from '../../components/Widget/CardsWidgetsBottom.jsx';
import CardSejourVoyageur from '../../components/Voyageur/CardSejourVoyageur.jsx';
import StatusStancerPackVoyageur from '../../components/Reservations/buy/StatusStancerPackVoyageur.jsx';
import { useFetchGetLocationSettings } from '../../utils/Api/FetchLocationSettings.jsx';

export default function VoyageurHome() {
    document.title = 'Mes Séjours chez Ma Maison Sereine';
    document.description =
        'Page de gestion des réservations réalisées chez Ma Maison Sereine, avec possiblité de rajouter des prestations/services durant son séjour.';

    const translate = useSelector((store) => store.Template.language);
    const { dataVoyageur, isLoadingVoyageurListing } =
        useFetchGetListingVoyageur();

    const [nberPhone] = useState(
        localStorage.getItem('authVoyageurPort')
            ? localStorage.getItem('authVoyageurPort')
            : undefined
    );
    const [codeSMSActive] = useState(
        localStorage.getItem('authVoyageurCode')
            ? localStorage.getItem('authVoyageurCode')
            : undefined
    );
    const { dataLocationSettings } = useFetchGetLocationSettings();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <main className="bg-primary ">
                <h1 className="text-white text-center fs-1 pt-5">
                    Ma Maison Sereine
                </h1>
                <h2 className="text-white text-center fs-1">
                    {translate === 'French' && (
                        <> Gestion de vos Réservations</>
                    )}
                    {translate === 'English' && (
                        <>Management of your Reservations</>
                    )}
                </h2>
                <hr className="text-white col-3 mx-auto" />
                <Alert
                    className="my-3 text-center mx-auto col-10"
                    color="primary"
                >
                    <i className="fa-solid fa-circle-arrow-right"></i>

                    {translate === 'French' && (
                        <>
                            Retrouver sur cette page le détails de vos
                            réservations, ajout de prestations/services durant
                            votre séjour, ainsi que d'autres informations.
                        </>
                    )}
                    {translate === 'English' && (
                        <>
                            Find on this page the details of your reservations,
                            addition of benefits/services during your stay, as
                            well as other information.
                        </>
                    )}
                </Alert>
                <hr className="text-white col-3 mx-auto" />

                {isLoadingVoyageurListing === true &&
                dataVoyageur === undefined ? (
                    <div className="bg-light p-5">
                        <LoadingK />
                    </div>
                ) : (
                    <>
                        {nberPhone === undefined &&
                        codeSMSActive === undefined ? (
                            <>
                                <div className="p-4 ">
                                    <h2 className="fs-1 col-11 -col-md-8 mx-auto text-center text-white">
                                        {translate === 'French' && (
                                            <>Bienvenue </>
                                        )}
                                        {translate === 'English' && (
                                            <>Welcome </>
                                        )}
                                    </h2>
                                </div>
                            </>
                        ) : (
                            <div className="p-4 ">
                                <h2 className="fs-1 col-11 -col-md-8 mx-auto text-center text-white">
                                    {translate === 'French' && <>Bienvenue </>}
                                    {translate === 'English' && <>Welcome </>}
                                    {dataVoyageur.reservations_guest[0] !==
                                        undefined && (
                                        <>
                                            {
                                                dataVoyageur
                                                    .reservations_guest[0]
                                                    .firstname
                                            }{' '}
                                            {
                                                dataVoyageur
                                                    .reservations_guest[0]
                                                    .lastname
                                            }
                                        </>
                                    )}
                                </h2>
                                <p className="text-warning pt-2">
                                    {translate === 'French' && (
                                        <>
                                            Intervalle de recherche du{' '}
                                            {dataVoyageur.date_before} au{' '}
                                            {dataVoyageur.date_after}
                                        </>
                                    )}
                                    {translate === 'English' && (
                                        <>
                                            search range{' '}
                                            {dataVoyageur.date_before} to{' '}
                                            {dataVoyageur.date_after}
                                        </>
                                    )}
                                </p>
                                {dataVoyageur.reservations_guest !==
                                undefined ? (
                                    dataVoyageur.reservations_guest.length >
                                    0 ? (
                                        <>
                                            <p className="text-white fs-4 text-decoration-underline">
                                                {translate === 'French' && (
                                                    <>Vous avez réservé :</>
                                                )}
                                                {translate === 'English' && (
                                                    <>You have booked</>
                                                )}
                                            </p>

                                            {dataVoyageur.reservations_guest.map(
                                                (voyage) => {
                                                    if (
                                                        voyage.type !==
                                                        'cancellation'
                                                    ) {
                                                        return (
                                                            <>
                                                                <CardSejourVoyageur
                                                                    voyage={
                                                                        voyage
                                                                    }
                                                                    className={
                                                                        'bg-white border border-1 border-primary rounded shadow m-3 p-4 mx-auto col-11 col-lg-8'
                                                                    }
                                                                    dataLocationSettings={
                                                                        dataLocationSettings
                                                                    }
                                                                />
                                                                <StatusStancerPackVoyageur
                                                                    idReservation={
                                                                        voyage.id
                                                                    }
                                                                    dataLocationSettings={
                                                                        dataLocationSettings
                                                                    }
                                                                />
                                                            </>
                                                        );
                                                    }
                                                    return <></>;
                                                }
                                            )}

                                            <p className="text-white fs-4 text-decoration-underline">
                                                {translate === 'French' && (
                                                    <>
                                                        Vos réservation Annulées
                                                        :
                                                    </>
                                                )}
                                                {translate === 'English' && (
                                                    <>
                                                        Your canceled
                                                        reservations:
                                                    </>
                                                )}
                                            </p>

                                            {dataVoyageur.reservations_guest !==
                                                undefined &&
                                                dataVoyageur.reservations_guest
                                                    .length > 0 &&
                                                dataVoyageur.reservations_guest.map(
                                                    (voyage) => {
                                                        if (
                                                            voyage.type ===
                                                            'cancellation'
                                                        ) {
                                                            return (
                                                                <CardSejourVoyageur
                                                                    voyage={
                                                                        voyage
                                                                    }
                                                                    className={
                                                                        'bg__red-light border border-1 border-primary rounded shadow m-3 p-4 mx-auto col-11 col-lg-8'
                                                                    }
                                                                />
                                                            );
                                                        }
                                                        return <></>;
                                                    }
                                                )}
                                        </>
                                    ) : (
                                        <p className="bg-white border border-1 border-primary rounded shadow m-3 p-4 mx-auto col-11 col-lg-8">
                                            Aucune réservation n'a été détectée
                                            avec ce N° de portable.
                                        </p>
                                    )
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                        )}
                    </>
                )}
            </main>
            <section className="sect__widgets">
                <CardHomeWidgetsBottom />
            </section>
        </>
    );
}
