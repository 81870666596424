import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheck } from 'react-icons/fa';
import Badge from 'react-bootstrap/Badge';
import {
    getSettingsLocation,
    getTarifsConciergerie,
} from '../../Store/Actions/config.actions';

import CardHomeWidgetsBottom from '../../components/Widget/CardsWidgetsBottom.jsx';
import HelpFormules from '../../components/Conciergerie/help/formulePourcentage.jsx';
import HelpSBase from '../../components/Conciergerie/help/sBase.jsx';
import HelpSGestionnaire from '../../components/Conciergerie/help/sGestionnaire.jsx';
import HelpSProprietaire from '../../components/Conciergerie/help/sProprietaire.jsx';
import HelpSVoyageur from '../../components/Conciergerie/help/sVoyageur.jsx';

export default function Conciergerie() {
    document.title = 'Services de Conciergerie | Ma Maison Sereine';
    document.description =
        "Dans le secteur de Pornic et de La Plaine sur Mer, vous souhaitez déléguer vos tâches d'accueil, de communication, de gestion pour vos locations AirBnb ? Déchargez-vous de ces activités avec un savoir-faire professionnel, efficace et de confiance.";
    const translate = useSelector((store) => store.Template.language);
    const tarifsConciergerie = useSelector(
        (store) => store.Config.tarifsConciergerie
    );
    const settingsLocations = useSelector(
        (store) => store.Config.settingsLocations
    );
    const dispatch = useDispatch();
    useEffect(() => {
        if (tarifsConciergerie === null) {
            dispatch(getTarifsConciergerie());
        }
        if (settingsLocations === null) {
            dispatch(getSettingsLocation());
        }
    }, []);
    return (
        <main className="bg-primary">
            <div className="d-flex flex-column justify-content-center m-auto mb-2  ">
                <h1 className="mt-3 text-center mx-auto display-5 text-white">
                    <i className="fa-solid fa-bell-concierge"></i>
                    {translate === 'French' && (
                        <span>Découvrez nos Services de Conciergerie !</span>
                    )}
                    {translate === 'English' && (
                        <span>Discover our Concierge Services!</span>
                    )}
                </h1>
            </div>

            <div className="row row-cols-1 row-cols-md-3 mb-3 text-center p-4">
                <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm">
                        <div className="card-header py-3">
                            <h4 className="my-0 fw-normal">Forfait Eco</h4>
                        </div>
                        <div className="card-body">
                            <h1 className="card-title pricing-card-title">
                                65€
                                <small className="text-body-secondary fw-light">
                                    /réservation
                                </small>
                            </h1>
                            <ul className="list-unstyled mt-3 mb-4">
                                <li>Accès aux services de base</li>
                            </ul>
                            <HelpFormules />
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm">
                        <div className="card-header py-3">
                            <h4 className="my-0 fw-normal">
                                Forfait Pourcentage
                            </h4>
                        </div>
                        <div className="card-body">
                            <h1 className="card-title pricing-card-title">
                                20%
                                <small className="ms-2 text-body-secondary fw-light">
                                    des Recettes
                                </small>
                            </h1>
                            <ul className="list-unstyled mt-3 mb-4">
                                <li>Formule avec plus de sérénité</li>
                                <li>Communication voyageurs renforcée</li>
                                <li>Linge de lit inclus en long séjour</li>
                            </ul>
                            <HelpFormules />
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm border-primary">
                        <div className="card-header py-3 ">
                            <h4 className="my-0 fw-normal">
                                Pack Duo <br />{' '}
                                <small>Conciergerie & Gestionnaire</small>
                            </h4>
                        </div>
                        <div className="card-body">
                            <h1 className="card-title pricing-card-title">
                                15% + 15%
                                <small className="ms-2 text-body-secondary fw-light">
                                    des Recettes
                                </small>
                            </h1>
                            <ul className="list-unstyled mt-3 mb-4">
                                <li>Formule avec plus de sérénité</li>
                                <li>Communication voyageurs renforcée</li>
                                <li>Linge de lit inclus en long séjour</li>
                                <li>
                                    Suivi Administratifs avec un gestionnaire
                                    extérieur.
                                </li>
                            </ul>
                            <HelpFormules />
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-2">
                <div className="bg-primary p-3">
                    <h2 className="display-6 text-center mb-4 text-white">
                        Comparer les formules
                    </h2>
                </div>
                <div className="sticky-top bg-light d-flex flex-row text-center">
                    <div style={{ width: '65%' }} className="py-3">
                        Description
                    </div>
                    <div style={{ width: '14%' }} className="py-3">
                        <small>
                            Forfait <br />
                            Eco
                        </small>
                    </div>
                    <div style={{ width: '14%' }} className="py-3">
                        <small>
                            Forfait <br />
                            Pourcentage
                        </small>
                    </div>
                    <div style={{ width: '14%' }} className="py-3">
                        <small>
                            {' '}
                            Pack <br />
                            Duo
                        </small>
                    </div>
                </div>
            </div>
            <div className="table-responsive px-2">
                <table className="table text-center hover striped table-hover ">
                    <tbody>
                        <tr>
                            <th
                                colspan={4}
                                className="text-center fs-6 bg-secondary"
                            >
                                Condition d'Accès
                            </th>
                        </tr>
                        <tr>
                            <th
                                scope="row"
                                style={{ width: '56%' }}
                                className="text-start"
                            >
                                1 Journée libre entre chaque location
                            </th>
                            <td style={{ width: '12%' }}>
                                <Badge bg="danger">Obligatoire</Badge>
                            </td>
                            <td style={{ width: '12%' }}>
                                <Badge bg="secondary">Non requis</Badge>
                            </td>
                            <td style={{ width: '12%' }}>
                                <Badge bg="secondary">Non requis</Badge>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Départ interdit les mardi et mercredi
                            </th>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Départ & Arrivée interdit les 24/12 - 25/12 -
                                31/12 - 01/01
                            </th>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Prise de réservation jusqu'à 3j avant l'arrivée
                                des voyageurs du 1 Sept. au 30 Juin
                            </th>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Fin de prise de réservation au 30 Juin pour les
                                réservations des mois de Juillet et Août
                            </th>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Déposer un dossier de candidature
                            </th>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Avoir plus de 120 nuitées de location par an
                            </th>
                            <td>
                                <span className="text-success">
                                    <Badge bg="warning">Négociable</Badge>
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th
                                colspan={4}
                                className="text-center fs-5 bg-secondary "
                            >
                                Services de base <br />
                                <HelpSBase />
                            </th>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Pré-Séjour : Appel téléphonique d'information et
                                d'organisation
                            </th>
                            <td>
                                {' '}
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <Badge bg="warning">Non</Badge>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Retouche Ménage et vérification (45min max)
                            </th>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Arrivée et Départ des voyageurs via boite à clés
                            </th>
                            <td>
                                <Badge bg="danger">obligatoire</Badge>
                            </td>
                            <td>
                                <Badge bg="danger">obligatoire</Badge>
                            </td>
                            <td>
                                <Badge bg="danger">obligatoire</Badge>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Prise de contact avec vos voyageurs la veille de
                                leur arrivée pour les instructions{' '}
                            </th>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Pack de Bienvenue "Les Essentiels"
                            </th>
                            <td>
                                <Badge bg="warning">En option €</Badge>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Cadeau de Bienvenue
                            </th>
                            <td>
                                <Badge bg="warning">En option €</Badge>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Passage de bienvenue du concierge ou
                                communication renforcée
                            </th>
                            <td>
                                <Badge bg="warning">En option €</Badge>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Intermédiaire principal et astreinte pour vos
                                voyageurs pendant leur séjour
                            </th>
                            <td>
                                <Badge bg="warning">
                                    Astreinte <br /> uniquement
                                </Badge>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Pack Linge de Lit offer à partir de la 5ème
                                nuitée
                            </th>
                            <td>
                                <Badge bg="warning">En option €</Badge>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Pack Alèse
                            </th>
                            <td>
                                <Badge bg="warning">En option €</Badge>
                            </td>
                            <td>
                                <Badge bg="warning">En option €</Badge>
                            </td>
                            <td>
                                <Badge bg="warning">En option €</Badge>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Diffusion sur le site de Ma Maison Sereine
                            </th>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Synchronisation Calendrier de location du
                                logement avec la plateforme Smoobu
                            </th>
                            <td>
                                <Badge bg="danger">Obligatoire</Badge>
                            </td>
                            <td>
                                <Badge bg="danger">Obligatoire</Badge>
                            </td>
                            <td>
                                <Badge bg="danger">Obligatoire</Badge>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th
                                colspan={4}
                                className="text-center fs-5 bg-secondary "
                            >
                                Services de réservation par le gestionnaire{' '}
                                <br /> <HelpSGestionnaire />
                            </th>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Diffusion multisites de votre annonce
                            </th>
                            <td>
                                <Badge bg="secondary">
                                    Non <br />
                                    disponible
                                </Badge>
                            </td>
                            <td>
                                <Badge bg="secondary">
                                    Non <br />
                                    disponible
                                </Badge>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Gestion et création de votre annonce
                            </th>
                            <td>
                                <Badge bg="secondary">
                                    Non <br />
                                    disponible
                                </Badge>
                            </td>
                            <td>
                                <Badge bg="secondary">
                                    Non <br />
                                    disponible
                                </Badge>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Suivi administratif
                            </th>
                            <td>
                                <Badge bg="secondary">
                                    Non <br />
                                    disponible
                                </Badge>
                            </td>
                            <td>
                                <Badge bg="secondary">
                                    Non <br />
                                    disponible
                                </Badge>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Suivi des ventes et ajustement de vos tarifs
                            </th>
                            <td>
                                <Badge bg="secondary">
                                    Non <br />
                                    disponible
                                </Badge>
                            </td>
                            <td>
                                <Badge bg="secondary">
                                    Non <br />
                                    disponible
                                </Badge>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th
                                colspan={4}
                                className="text-center fs-5 bg-secondary "
                            >
                                Services & Options supplémentaires pour les
                                propriétaires <br />
                                <HelpSProprietaire />
                            </th>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Heures de ménage supplémentaires
                            </th>
                            <td> 25€/h</td>
                            <td> 25€/h</td>
                            <td> 25€/h</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Pack de Bienvenue "Les Essentiels" adapté au
                                nombre de voyageurs
                            </th>
                            <td> 5€/pers.</td>
                            <td> 5€/pers.</td>
                            <td> 5€/pers.</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Passage de Bienvenue pendant le séjour
                            </th>
                            <td> 15€</td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Accueil en présentiel des voyageurs
                            </th>
                            <td>
                                <Badge bg="secondary">
                                    Non <br />
                                    disponible
                                </Badge>
                            </td>
                            <td> 25€</td>
                            <td> 25€</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Départ en présentiel des voyageurs
                            </th>
                            <td>
                                <Badge bg="secondary">
                                    Non <br />
                                    disponible
                                </Badge>
                            </td>
                            <td> 25€</td>
                            <td> 25€</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Corbeille de fruits de Bienvenue
                            </th>
                            <td> 10€</td>
                            <td> 10€</td>
                            <td> 10€</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Bouquet de fleurs de Bienvenue
                            </th>
                            <td> 30€</td>
                            <td> 30€</td>
                            <td> 30€</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Blanchiment de votre linge
                            </th>
                            <td>7€/pers.</td>
                            <td>
                                <Badge bg="secondary">
                                    Non <br />
                                    disponible
                                </Badge>
                            </td>
                            <td>
                                <Badge bg="secondary">
                                    Non <br />
                                    disponible
                                </Badge>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Pack de Bienvenue "Les Essentiels"
                            </th>
                            <td>5€</td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                            <td>
                                <span className="text-success">
                                    <FaCheck />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Pack alèse
                            </th>
                            <td>5€/lit</td>
                            <td>5€/lit</td>
                            <td>5€/lit</td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th
                                colspan={4}
                                className="text-center fs-5 bg-secondary "
                            >
                                Services & Options supplémentaires pour les
                                Voyageurs
                                <br />
                                <HelpSVoyageur />
                            </th>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Forfait Ménage avant votre arrivée, réalisé par
                                un professionnel
                            </th>
                            <td> 50€</td>
                            <td> 50€</td>
                            <td> 50€</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Location de linge de lit Hotelier
                            </th>
                            <td>
                                {tarifsConciergerie !== null &&
                                    tarifsConciergerie.blanc_mms_1p}
                                €/pers.
                            </td>
                            <td>
                                {tarifsConciergerie !== null &&
                                    tarifsConciergerie.blanc_mms_1p}
                                €/pers.
                            </td>
                            <td>
                                {tarifsConciergerie !== null &&
                                    tarifsConciergerie.blanc_mms_1p}
                                €/pers.
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Blanchiment et fourniture du linge de lit de
                                couleur
                            </th>
                            <td>
                                {tarifsConciergerie !== null &&
                                    tarifsConciergerie.couleur_mms_1p}
                                €/pers.
                            </td>
                            <td>
                                {tarifsConciergerie !== null &&
                                    tarifsConciergerie.couleur_mms_1p}
                                €/pers.
                            </td>
                            <td>
                                {tarifsConciergerie !== null &&
                                    tarifsConciergerie.couleur_mms_1p}
                                €/pers.
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Forfait Ménage en sortie de Séjour <br />
                                Petit Logement
                            </th>
                            <td>
                                <span>
                                    <Badge bg="warning">
                                        Séjour {'<='} 2 nuits
                                    </Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat1_inf}
                                    €
                                </span>{' '}
                                <br />{' '}
                                <span>
                                    <Badge bg="warning">Séjour 3 nuits</Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat1_sup}
                                    €
                                </span>
                            </td>
                            <td>
                                <span>
                                    <Badge bg="warning">
                                        Séjour {'<='} 2 nuits
                                    </Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat1_inf}
                                    €
                                </span>{' '}
                                <br />{' '}
                                <span>
                                    <Badge bg="warning">Séjour 3 nuits</Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat1_sup}
                                    €
                                </span>
                            </td>
                            <td>
                                <span>
                                    <Badge bg="warning">
                                        Séjour {'<='} 2 nuits
                                    </Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat1_inf}
                                    €
                                </span>{' '}
                                <br />{' '}
                                <span>
                                    <Badge bg="warning">Séjour 3 nuits</Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat1_sup}
                                    €
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Forfait Ménage en sortie de Séjour <br />
                                Moyen Logement
                            </th>
                            <td>
                                <span>
                                    <Badge bg="warning">
                                        Séjour {'<='} 2 nuits
                                    </Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat2_inf}
                                    €
                                </span>{' '}
                                <br />{' '}
                                <span>
                                    <Badge bg="warning">Séjour 3 nuits</Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat2_sup}
                                    €
                                </span>
                            </td>
                            <td>
                                <span>
                                    <Badge bg="warning">
                                        Séjour {'<='} 2 nuits
                                    </Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat2_inf}
                                    €
                                </span>{' '}
                                <br />{' '}
                                <span>
                                    <Badge bg="warning">Séjour 3 nuits</Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat2_sup}
                                    €
                                </span>
                            </td>
                            <td>
                                <span>
                                    <Badge bg="warning">
                                        Séjour {'<='} 2 nuits
                                    </Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat2_inf}
                                    €
                                </span>{' '}
                                <br />{' '}
                                <span>
                                    <Badge bg="warning">Séjour 3 nuits</Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat2_sup}
                                    €
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Forfait Ménage en sortie de Séjour <br />
                                Grand Logement
                            </th>
                            <td>
                                <span>
                                    <Badge bg="warning">
                                        Séjour {'<='} 2 nuits
                                    </Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat3_inf}
                                    €
                                </span>{' '}
                                <br />{' '}
                                <span>
                                    <Badge bg="warning">Séjour 3 nuits</Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat3_sup}
                                    €
                                </span>
                            </td>
                            <td>
                                <span>
                                    <Badge bg="warning">
                                        Séjour {'<='} 2 nuits
                                    </Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat3_inf}
                                    €
                                </span>{' '}
                                <br />{' '}
                                <span>
                                    <Badge bg="warning">Séjour 3 nuits</Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat3_sup}
                                    €
                                </span>
                            </td>
                            <td>
                                <span>
                                    <Badge bg="warning">
                                        Séjour {'<='} 2 nuits
                                    </Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat3_inf}
                                    €
                                </span>{' '}
                                <br />{' '}
                                <span>
                                    <Badge bg="warning">Séjour 3 nuits</Badge>
                                    {settingsLocations !== null &&
                                        settingsLocations.price_menage_cat3_sup}
                                    €
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Pack alèse
                            </th>
                            <td>5€/lit</td>
                            <td>5€/lit</td>
                            <td>5€/lit</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="table-responsive px-2">
                <table className="table text-center hover striped table-hover ">
                    <thead>
                        <tr>
                            <th
                                colspan={4}
                                className="text-center fs-6 bg-secondary "
                            >
                                Services & Options disponibles hors contrat
                                signé
                                <br />
                                <small>
                                    Des Prestations peuvent être proposées au
                                    propriétaire qui sont hors contrat. Un devis
                                    préalable sera obligatoire et réalisé selon
                                    les disponibilités du planning (non
                                    prioritaire).
                                </small>
                            </th>
                        </tr>
                        <tr>
                            <th
                                colspan={3}
                                className="text-center fs-6 bg-secondary "
                            >
                                Prestations
                            </th>
                            <td className="text-center fs-6 bg-secondary ">
                                Tarifs
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">
                                Frais de Déplacement
                            </th>
                            <td colspan={3}>15€</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Une heure de Ménage
                            </th>
                            <td colspan={3}>30€</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Passage de Bienvenue pendant le séjour
                            </th>
                            <td colspan={3}>25€</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Accueil en présentiel
                            </th>
                            <td colspan={3}>30€</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Départ en présentiel
                            </th>
                            <td colspan={3}>30€</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Corbeille de fruits de Bienvenue
                            </th>
                            <td colspan={3}>15€</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Bouquet de Fleurs de Bienvenue
                            </th>
                            <td colspan={3}>50€</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Pack de Bievenue "Les Essentiels"
                            </th>
                            <td colspan={3}>10€</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Pack Alèse
                            </th>
                            <td colspan={3}>10€/lit</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">
                                Blanchiment et fourniture du linge de lit de
                                couleur (caution)
                            </th>
                            <td colspan={3}>15€/pers.</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="text-center">
                <Link to="/contact">
                    <button className="btn btn-light text-primary text-center p-3 m-3">
                        {translate === 'French' && (
                            <span>
                                Une demande de renseignement ? <br /> Vous
                                souhaitez adhérer ? <br /> Alors Contactez-nous
                            </span>
                        )}
                        {translate === 'English' && (
                            <span>
                                A request for information? <br /> YOU want to
                                join? <br /> So contact us
                            </span>
                        )}
                    </button>
                </Link>
            </div>
            <section className="sect__widgets">
                <CardHomeWidgetsBottom />
            </section>
        </main>
    );
}
