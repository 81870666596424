import { useSelector } from 'react-redux';

import CardLogement from './Card.logement.jsx';
import SSlesPinHomelogo from '../../assets/img/SSlesPins/logo__SSlesPins-home.jpg';
import Pure from '../../assets/img/Pure/logo_pure.jpg';
import PecherieLogo from '../../assets/img/Pecherie/pecherie_logo.jpeg';
import MaisonCharmeGourmalon from '../../assets/img/Maison-charme-gourmalon/logo-maison-charme-gourmalon.jpg';
import VillaRose from '../../assets/img/logo/logo__ma-maison-sereine.png';

export default function ListingLogement() {
    const translate = useSelector((store) => store.Template.language);

    return (
        <>
            <CardLogement
                to={'/locations/pecherie/'}
                title={'Au bord des Pêcheries'}
                logo={PecherieLogo}
                logoWidth={'80px'}
                classTitle={'mb-4'}
                classNameLink={'card__link'}
                classNameArticle={'card__loge card__loge-Pecherie'}
                typeLogement={
                    translate === 'French' ? 'Maison Complète' : 'Full House'
                }
                nbrVoyageurs={8}
                parking={
                    translate === 'French'
                        ? 'Parking Privatif'
                        : 'Private Parking'
                }
                lits={
                    translate === 'French'
                        ? '3 Lit 180 et 2 Lits 90'
                        : '3 Beds 180 and 2 Beds 90'
                }
                sdb={
                    translate === 'French'
                        ? "1 salle d'eau avec Douche"
                        : '1 Shower room'
                }
                garden={
                    translate === 'French'
                        ? 'Un Jardin avec Plancha Gaz, Veranda'
                        : 'A Garden with Gas Plancha, Veranda'
                }
                city={'La Plaine Sur Mer 44770'}
                logementHost={'Claudine'}
            />
            <CardLogement
                to={'/locations/pure/'}
                title={'Pure'}
                logo={Pure}
                logoWidth={'80px'}
                classTitle={'mb-4'}
                classNameLink={'card__link'}
                classNameArticle={'card__loge card__loge-Pure'}
                typeLogement={
                    translate === 'French'
                        ? 'Appartement Complet'
                        : 'Complete Apartment'
                }
                nbrVoyageurs={6}
                parking={
                    translate === 'French'
                        ? '1 Place de Parking en résidence'
                        : '1 Parking Space in residence'
                }
                lits={
                    translate === 'French'
                        ? '2 Lit 180 et 2 Lits 90'
                        : '2 Beds 180 et 2 Beds 90'
                }
                sdb={translate === 'French' ? '1 salle de Bain' : '1 bathroom'}
                garden={
                    translate === 'French'
                        ? 'En plein Centre-Ville de Pornic'
                        : 'Right in the city center of Pornic'
                }
                city={'Pornic 44210'}
                logementHost={'Jacqueline'}
            />
            <CardLogement
                to={'/locations/sous-les-pins/maison/'}
                title={'Sous Les Pins'}
                subtitle={'La Maison'}
                logo={SSlesPinHomelogo}
                logoWidth={'80px'}
                classTitle={''}
                classNameLink={'card__link'}
                classNameArticle={'card__loge card__loge-SSpinsHome'}
                typeLogement={
                    translate === 'French' ? 'Maison Complète' : 'Full House'
                }
                nbrVoyageurs={6}
                parking={
                    translate === 'French'
                        ? 'Parking Privatif'
                        : 'Private Parking'
                }
                lits={
                    translate === 'French'
                        ? '2 Lit 180 et 2 Lits 90'
                        : '2 Beds 180 et 2 Beds 90'
                }
                sdb={
                    translate === 'French'
                        ? "2 salle d'eau avec Douche"
                        : '2 Shower room'
                }
                garden={
                    translate === 'French'
                        ? 'Un Salon extérieur à disposition'
                        : 'An outdoor lounge available'
                }
                city={'Préfailles 44770'}
                logementHost={'José'}
            />
            <CardLogement
                to={'/locations/maison-charme-gourmalon/'}
                title={'Maison de charme au Coeur de Gourmalon'}
                logo={MaisonCharmeGourmalon}
                logoWidth={'80px'}
                classTitle={''}
                classNameLink={'card__link'}
                classNameArticle={'card__loge card__loge-MaisonCharmeGourmalon'}
                typeLogement={
                    translate === 'French' ? 'Maison Complète' : 'Full House'
                }
                nbrVoyageurs={6}
                lits={
                    translate === 'French'
                        ? '2 Lit 180 et 2 Lits 90'
                        : '2 Beds 180 et 2 Beds 90'
                }
                sdb={
                    translate === 'French'
                        ? "2 salle d'eau avec Douche"
                        : '2 Shower room'
                }
                garden={
                    translate === 'French'
                        ? 'Un Salon extérieur à disposition'
                        : 'An outdoor lounge available'
                }
                city={'Pornic 44210'}
                logementHost={'Thomas'}
            />
            <CardLogement
                to={'/locations/villarose/'}
                title={'Villa Rose'}
                logo={VillaRose}
                logoWidth={'120px'}
                classTitle={''}
                classNameLink={'card__link'}
                classNameArticle={'card__loge card__loge-VillaRose'}
                typeLogement={
                    translate === 'French' ? 'Maison Complète' : 'Full House'
                }
                nbrVoyageurs={9}
                parking={
                    translate === 'French'
                        ? 'Parking Privatif'
                        : 'Private Parking'
                }
                lits={
                    translate === 'French'
                        ? '1 Lit 160, 2 Lits 140 et  1 canapé lit 140'
                        : '1 Beds 160, 2 Beds 140 et 1 sofa bed 140'
                }
                sdb={
                    translate === 'French'
                        ? "2 salle d'eau avec Douche"
                        : '2 Shower room'
                }
                garden={
                    translate === 'French'
                        ? 'Terrasse vue Mer'
                        : 'Sea view terrace'
                }
                city={'Préfailles 44770'}
                logementHost={'Julie'}
            />
            <CardLogement
                to={'/locations/c&i-home/'}
                title={'Maison C&I Home'}
                logo={VillaRose}
                logoWidth={'120px'}
                classTitle={''}
                classNameLink={'card__link'}
                classNameArticle={'card__loge card__loge-CIHome'}
                typeLogement={
                    translate === 'French' ? 'Maison Complète' : 'Full House'
                }
                nbrVoyageurs={8}
                lits={
                    translate === 'French'
                        ? '2 Lits 160, 1 lits 140 et 2 matelas 90 '
                        : '2 160 beds, 1 140 beds and 2 extra 90 '
                }
                sdb={
                    translate === 'French'
                        ? "3 salle d'eau avec Douche"
                        : '3 Shower room'
                }
                garden={
                    translate === 'French'
                        ? 'Un Salon extérieur à disposition'
                        : 'An outdoor lounge available'
                }
                city={'Pornic 44210'}
                logementHost={'Claire & Isabelle'}
            />
            <CardLogement
                to={'/locations/les-pieds-dans-l-eau/'}
                title={"Les Pieds Dans L'Eau"}
                logo={VillaRose}
                logoWidth={'120px'}
                classTitle={''}
                classNameLink={'card__link'}
                classNameArticle={'card__loge card__loge-PiedsDansLeau'}
                typeLogement={
                    translate === 'French' ? 'Maison Complète' : 'Full House'
                }
                nbrVoyageurs={6}
                lits={
                    translate === 'French'
                        ? '1 Lits 140, 2 lits 90 et Canapé lit 140'
                        : '1 140 cm beds, 2 90 cm beds and 140 cm sofa bed'
                }
                sdb={translate === 'French' ? '1 salle de bain' : '1 bathroom'}
                garden={
                    translate === 'French'
                        ? 'Terrasse Vue Mer & Salon extérieur à disposition'
                        : 'Sea View Terrace & outdoor lounge available'
                }
                city={'La Plaine Sur Mer 44770'}
                logementHost={'Sébastien'}
            />
        </>
    );
}
