import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import imgPecheriePlage from '../../assets/img/accueil/pecherie_plage.jpg';

export default function TitleHome() {
    const translate = useSelector((store) => store.Template.language);

    return (
        <>
            <div className="homepage-banner">
                <div className="homepage-banner-text">
                    <h1 className="font-title mb-4">Ma Maison Sereine</h1>
                    <h2 className="text-center mb-2 font-body">
                        {translate === 'French' && (
                            <span>
                                Locations en AirBnb & Services de Conciergerie
                            </span>
                        )}
                        {translate === 'English' && (
                            <span>AirBnb Rentals & Concierge Services</span>
                        )}
                    </h2>
                    {translate === 'French' && (
                        <p className="text-center mb-5">
                            <i className="fa-solid fa-magnifying-glass-location"></i>
                            Sur <strong>Pornic</strong> et sa périphérie (
                            <strong>La Plaine Sur Mer</strong>,{' '}
                            <strong>Préfailles</strong>, <strong>Tharon</strong>
                            , etc.)
                        </p>
                    )}
                    {translate === 'English' && (
                        <p className="text-center mb-5">
                            <i className="fa-solid fa-magnifying-glass-location"></i>
                            On <strong>Pornic</strong> and its periphery (
                            <strong>La Plaine Sur Mer</strong>,{' '}
                            <strong>Préfailles</strong>, <strong>Tharon</strong>
                            , etc.)
                        </p>
                    )}

                    <h2 className="text-center mt-5 mb-5 w-75 mx-auto">
                        {translate === 'French' && (
                            <span>
                                Dormez Sereinement : Logement vérifié, nettoyage
                                de qualité et toujours quelqu'un à vos côtés.
                            </span>
                        )}
                        {translate === 'English' && (
                            <span>
                                Sleep Peacefully: Housing verified, cleaning of
                                quality and always someone by your side.
                            </span>
                        )}
                    </h2>
                </div>
            </div>

            <div className="homepage-beach">
                <div className="homepage-beach-img">
                    <img
                        src={imgPecheriePlage}
                        alt="Pecherie de Tharon Plage"
                        loading="lazy"
                    ></img>
                </div>
                <div className="homepage-beach-text">
                    <div className="homepage-beach-text-title">
                        {translate === 'French' && (
                            <>
                                <h2 className="font-body">
                                    Profitez de nos Locations et détentez-vous
                                </h2>
                                <h3>
                                    Ma Maison Sereine, avec Vous, à Vos côtés.
                                </h3>
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                <h2>Enjoy your travel and relax</h2>
                                <h3>My Serene Home, with You, by Your side.</h3>
                            </>
                        )}
                    </div>
                    <div className="homepage-beach-text-grid">
                        <div className="spart">
                            <div className="spart-logo serein">
                                <i className="fa-solid fa-car-side"></i>
                            </div>

                            {translate === 'French' && (
                                <>
                                    <h4>Avant votre arrivée</h4>
                                    <p>
                                        Envoi d'un sms de bienvenue contenant un
                                        lien vers le livret d'accueil numérique
                                        pour vous familiariser avec le logement
                                        pour une arrivée sereine
                                    </p>
                                </>
                            )}
                            {translate === 'English' && (
                                <>
                                    <h4>Before your arrival</h4>
                                    <p>
                                        Sending a welcome SMS containing a link
                                        to the digital welcome booklet for us
                                        with the accommodation for an arrival
                                        serene
                                    </p>
                                </>
                            )}
                        </div>
                        <div className="spart">
                            <div className="spart-logo serein">
                                <i className="fa-solid fa-umbrella-beach"></i>
                            </div>

                            {translate === 'French' && (
                                <>
                                    <h4>Durant votre Séjour</h4>
                                    <p>
                                        Proposition Linge de lit, Ménage, etc.
                                    </p>
                                </>
                            )}
                            {translate === 'English' && (
                                <>
                                    <h4>During your Stay</h4>
                                    <p>Proposal Bed linen, Cleaning, etc.</p>
                                </>
                            )}
                        </div>
                        <div className="spart">
                            <div className="spart-logo serein">
                                <i className="fa-solid fa-person-circle-exclamation"></i>
                            </div>

                            {translate === 'French' && (
                                <>
                                    <h4>Astreinte</h4>
                                    <p>Toujours à vos côtés en cas d'Urgence</p>
                                </>
                            )}
                            {translate === 'English' && (
                                <>
                                    <h4>Emergency On-call</h4>
                                    <p>
                                        Always by your side in case of Emergency
                                    </p>
                                </>
                            )}
                        </div>

                        <div className="spart">
                            <div className="spart-logo serein">
                                <i className="fa-solid fa-plane-departure"></i>
                            </div>

                            {translate === 'French' && (
                                <>
                                    <h4>Lors de votre départ</h4>
                                    <p>Proposition de forfait Ménage Complet</p>
                                </>
                            )}
                            {translate === 'English' && (
                                <>
                                    <h4>When you leave</h4>
                                    <p>Proposed Complete Cleaning package</p>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-around align-items-center">
                        <Link to="/locations" className="m-2">
                            <button className="btn btn-primary p-3">
                                Découvrir nos locations
                            </button>
                        </Link>
                        <Link to="/connexion" className="m-2">
                            <button className="btn btn-primary p-3">
                                Accéder à mon espace Voyageur
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
