import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    FaPlaneLock,
    FaPersonShelter,
    FaPersonWalkingLuggage,
} from 'react-icons/fa6';
import { LiaPlaneDepartureSolid, LiaPiggyBankSolid } from 'react-icons/lia';
import { FaHouseUser } from 'react-icons/fa';
import { IoCalendarOutline } from 'react-icons/io5';
import { BsQrCodeScan } from 'react-icons/bs';
import { LuRadioTower, LuMessagesSquare } from 'react-icons/lu';
import { GiVacuumCleaner, GiExitDoor } from 'react-icons/gi';
import { MdOutlineListAlt } from 'react-icons/md';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';

import { useEffect } from 'react';
import {
    getPingPort,
    getReceivedSmsNotRead,
} from '../../Store/Actions/messenger.actions';
import logo from '../../assets/img/logo/logo__ma-maison-sereine.png';
import {
    switchToEnglish,
    switchToFrench,
} from '../../Store/Actions/template.actions';

export default function Header() {
    const authUser = useSelector((store) => store.User.authUser);
    const accessToken = useSelector((store) => store.User.accessToken);
    const translate = useSelector((store) => store.Template.language);
    const numberSMSNotRead = useSelector(
        (store) => store.Messenger.numberSMSNotRead
    );
    const pingPort = useSelector((store) => store.Messenger.pingPort);
    const dispatch = useDispatch();

    function ExitNavbar() {
        const divNavBarMobile = document.querySelector('.nav-mobile');
        if (divNavBarMobile !== null) {
            divNavBarMobile.classList.remove('nav-mobile');
            divNavBarMobile.classList.add('nav');
        }
        const btnOpenNav = document.querySelector('.icon_nav_mobile');
        btnOpenNav.innerText = 'Menu';
    }
    function Clicknavbar() {
        const divNavBar = document.querySelector('.nav');
        if (divNavBar !== null) {
            divNavBar.classList.remove('nav');
            divNavBar.classList.add('nav-mobile');
            const btnOpenNav = document.querySelector('.icon_nav_mobile');
            {
                translate === 'French'
                    ? (btnOpenNav.innerText = 'Fermer')
                    : (btnOpenNav.innerText = 'Close');
            }
        } else {
            ExitNavbar();
        }
    }
    useEffect(() => {
        if (
            accessToken !== null &&
            accessToken !== undefined &&
            authUser !== null &&
            authUser.is_superuser === true
        ) {
            dispatch(getReceivedSmsNotRead(accessToken));
            dispatch(getPingPort(accessToken));
        }
    }, [accessToken, authUser]);
    useEffect(() => {
        const interval = setInterval(() => {
            if (
                accessToken !== null &&
                accessToken !== undefined &&
                authUser !== null &&
                authUser.is_superuser === true
            ) {
                dispatch(getReceivedSmsNotRead(accessToken));
                dispatch(getPingPort(accessToken));
            }
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [accessToken, authUser]);

    return (
        <>
            <header>
                <div className="logo-brand ">
                    <Link to="/">
                        <img
                            src={logo}
                            alt="Logo - Ma Maison Sereine"
                            className="logo__img"
                        />
                    </Link>
                    <div className="trad">
                        {translate === 'French' ? (
                            <button
                                className="btn m-1 p-1 "
                                onClick={() => dispatch(switchToEnglish())}
                                data-testid="flagFrench"
                                aria-label="Traduire en Français"
                            >
                                <span
                                    className="fi fi-fr"
                                    aria-label="Drapeau Français"
                                    role="button"
                                ></span>
                            </button>
                        ) : (
                            <button
                                className="btn m-1 p-1"
                                onClick={() => dispatch(switchToFrench())}
                                data-testid="flagEnglish"
                                aria-label="Translate to English"
                            >
                                <span
                                    className="fi fi-gb"
                                    aria-label="Flag English"
                                    role="button"
                                ></span>
                            </button>
                        )}
                    </div>
                    <div className="icon_nav_mobile" onClick={Clicknavbar}>
                        Menu
                    </div>
                </div>

                <nav className="nav" onMouseLeave={ExitNavbar}>
                    <Link to="/" className="nav__link" onClick={ExitNavbar}>
                        {translate === 'French' ? 'Accueil' : 'Home'}
                    </Link>

                    <Link
                        to="/locations"
                        className="nav__link"
                        onClick={ExitNavbar}
                    >
                        {translate === 'French' ? 'Locations' : 'Lettings'}
                    </Link>

                    <Link
                        to="/conciergerie"
                        className="nav__link"
                        onClick={ExitNavbar}
                    >
                        {translate === 'French' ? 'Conciergerie' : 'Concierge'}
                    </Link>

                    <Link
                        to="/bons-plans"
                        className="nav__link"
                        onClick={ExitNavbar}
                    >
                        {translate === 'French' ? 'Bons-Plans' : 'Tips'}
                    </Link>

                    <Link
                        to="/contact"
                        className="nav__link"
                        onClick={ExitNavbar}
                    >
                        Contact
                    </Link>

                    {localStorage.getItem('authVoyageurPort') === null &&
                        authUser === null && (
                            <Link
                                className="nav__link"
                                to="/connexion"
                                title="Se connecter à son compte"
                                onClick={ExitNavbar}
                            >
                                <FaPlaneLock />
                                {translate === 'French'
                                    ? 'Connexion'
                                    : 'Sign in '}
                            </Link>
                        )}
                    {localStorage.getItem('authVoyageurPort') !== null && (
                        <Link
                            className="nav__link"
                            to="/voyageur"
                            title="Accéder à son interface de gestion de compte"
                            onClick={ExitNavbar}
                        >
                            <LiaPlaneDepartureSolid />
                            {translate === 'French'
                                ? 'Mon Compte '
                                : 'My Account '}
                        </Link>
                    )}
                    {authUser !== null && (
                        <Link
                            className="nav__link"
                            to="/gestion"
                            title="Accéder à son interface de gestion de compte"
                            onClick={ExitNavbar}
                        >
                            <FaHouseUser />
                            {translate === 'French'
                                ? 'AdminStaff'
                                : 'AdminStaff'}
                        </Link>
                    )}

                    {(authUser !== null ||
                        localStorage.getItem('authVoyageurPort') !== null) && (
                        <Link
                            className="nav__link"
                            to="/deconnexion"
                            onClick={ExitNavbar}
                        >
                            <GiExitDoor />
                            {translate === 'French' ? 'Déconnexion' : 'Log out'}
                        </Link>
                    )}
                </nav>
            </header>
            {authUser !== null && (
                <div className="nav-admin ">
                    {' '}
                    {authUser !== null && authUser.is_superuser === true && (
                        <Link className="nav-link" to="/gestion/occupation">
                            <FaPersonShelter />
                        </Link>
                    )}
                    {authUser !== null && authUser.is_superuser === true && (
                        <Link className="nav-link" to="/gestion/reservations">
                            <FaPersonWalkingLuggage />
                        </Link>
                    )}
                    {authUser !== null && authUser.is_superuser === true && (
                        <Link className="nav-link" to="/gestion/agenda">
                            <IoCalendarOutline />
                        </Link>
                    )}
                    <Link
                        className="nav-link text-primary"
                        to="/gestion/bienvenue"
                    >
                        <BsQrCodeScan />
                    </Link>
                    {authUser !== null && (
                        <Link
                            className="nav-link text-rose  icon-menage"
                            to="/gestion/missions"
                        >
                            <GiVacuumCleaner />
                        </Link>
                    )}
                    {authUser !== null && authUser.is_superuser === true && (
                        <Link className="nav-link" to="/gestion/messagerie">
                            <LuMessagesSquare />
                            {numberSMSNotRead !== null &&
                                numberSMSNotRead > 0 && (
                                    <span className="m-1 bg-purple text-white rounded  px-2">
                                        <small>{numberSMSNotRead}</small>
                                    </span>
                                )}
                            <span className={' ms-3 text-' + pingPort}>
                                <LuRadioTower />
                            </span>
                        </Link>
                    )}
                    {authUser !== null && authUser.is_superuser === true && (
                        <Link
                            className="nav-link text-warning"
                            to="/gestion/stancer"
                        >
                            <LiaPiggyBankSolid />
                        </Link>
                    )}
                    {authUser !== null && authUser.is_superuser === true && (
                        <Link
                            className="nav-link text-warning"
                            to="/gestion/logs"
                        >
                            <MdOutlineListAlt />
                        </Link>
                    )}
                    {authUser !== null && authUser.is_superuser === true && (
                        <Link
                            className="nav-link text-warning"
                            to="/gestion/settings"
                        >
                            <HiOutlineWrenchScrewdriver />
                        </Link>
                    )}
                </div>
            )}
        </>
    );
}
