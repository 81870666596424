import { useSelector } from 'react-redux';

import { Badge, Alert } from 'reactstrap';

import { useFetchGetLocationSettings } from '../../../utils/Api/FetchLocationSettings.jsx';
import WidgetPackMenage from '../WigdetPackMenage.jsx';
import WidgetPackLinge from '../WigdetPackLinge.jsx';

export default function ListingDetailsPecherie(props) {
    const translate = useSelector((store) => store.Template.language);
    const { dataLocationSettings, isLoadingLocationSettings } =
        useFetchGetLocationSettings();
    return (
        <div className="locations__description">
            {translate === 'French' && (
                <h2 className="fs-1">Composition du Logement</h2>
            )}
            {translate === 'English' && (
                <h2 className="fs-1">Housing Composition</h2>
            )}

            <div className="d-flex flex-row flex-wrap">
                <div className="d-flex flex-column col-12 col-lg-5 mx-auto">
                    <div className="listing__arrive">
                        {translate === 'French' && (
                            <h3 className="fs-2">
                                <u>Pour votre Arrivé :</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Inclus
                                </Badge>
                            </h3>
                        )}
                        {translate === 'English' && (
                            <h3 className="fs-2">
                                <u>For your Arrival:</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Included
                                </Badge>
                            </h3>
                        )}

                        <div className="composition__logement">
                            <div className="composition__logement_text">
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-key"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Arrivée autonome en boîte à clé
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Autonomous arrival in key box
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-car"></i>{' '}
                                    {translate === 'French' && (
                                        <>
                                            <span>
                                                <strong>
                                                    Parking Privatif devant la
                                                    maison
                                                </strong>
                                            </span>
                                        </>
                                    )}
                                    {translate === 'English' && (
                                        <>
                                            <span>
                                                Private parking in front of the
                                                house
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listing__sejour">
                        {translate === 'French' && (
                            <h3 className="fs-2">
                                <u>Lors de votre séjour :</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Inclus
                                </Badge>
                            </h3>
                        )}
                        {translate === 'English' && (
                            <h3 className="fs-2">
                                <u>During your stay:</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Included
                                </Badge>
                            </h3>
                        )}

                        <div className="composition__logement">
                            <div className="composition__logement_text">
                                <div>
                                    <i className="fa-solid fa-house"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Maison Complète dans quartier calme
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Complete house in quiet area
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-person-swimming"></i>{' '}
                                    {translate === 'French' && (
                                        <span>Très proche de la Mer 200m</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>Near from the sea 200m</span>
                                    )}
                                </div>

                                <div>
                                    <i className="fa-solid fa-house-signal"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Séjour avec Tv et 1 Cuisine
                                            donnant sur la véranda du Jardin
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 Living room with TV and 1 Kitchen
                                            overlooking the veranda of the
                                            Garden
                                        </span>
                                    )}
                                </div>
                                <hr className="col-3 mx-auto" />
                                <div>
                                    <i className="fa-solid fa-kitchen-set"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Cuisine équipée avec four,
                                            réfrigérateur, cafetière,
                                            bouilloire, micro-ondes avec
                                            lave-vaisselle et lave-linge dans le
                                            sellier
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 Kitchen equipped with oven,
                                            fridge, coffee maker, kettle,
                                            microwave with dishwasher and
                                            washing machine in the saddler
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-cloud-moon"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Profitez de l'espace Jardin avec
                                            salons d'extérieur et plancha Gaz,
                                            pour profiter d'une soirée agréable
                                            et de détente.
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Take advantage of the garden space
                                            with outdoor lounges and gas
                                            plancha, to enjoy a enjoyable and
                                            relaxing evening.
                                        </span>
                                    )}
                                </div>
                                <hr className="col-3 mx-auto" />
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Chambre - Lit Double 140 avec 1
                                            lit bébé
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 Bedroom - Double Bed 140 with 1
                                            baby bed
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Chambres - 1 Lit Double 140
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 Bedrooms - 1 Double Bed 140
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Chambre - 1 Lit Double 140 & 2 Lit
                                            90
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 Bedroom - 1 Double Bed 140 & 2 Bed
                                            90
                                        </span>
                                    )}
                                </div>

                                <div>
                                    {' '}
                                    <i className="fa-solid fa-shower"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Salle de bain avec 2 Toilettes
                                            indépendants
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 bathroom with 2 separates toilets
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column col-12 col-lg-5 mx-auto">
                    <WidgetPackLinge
                        dataLocationSettings={dataLocationSettings}
                    />
                    <WidgetPackMenage
                        dataLocationSettings={dataLocationSettings}
                        cat={2}
                    />

                    <Alert
                        className="my-3 text-center mx-auto"
                        color="primary"
                        isOpen={true}
                    >
                        <i className="fa-solid fa-circle-arrow-right"></i>
                        {translate === 'French' && (
                            <span>
                                Vous pourrez reserver nos différents services
                                lors de la réservation du logement.
                            </span>
                        )}
                        {translate === 'English' && (
                            <span>
                                You can book our different services when booking
                                accommodation.
                            </span>
                        )}
                    </Alert>
                </div>
            </div>
        </div>
    );
}
