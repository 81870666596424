import { useEffect, useState } from 'react';
import { Alert, Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import CardHomeWidgetsBottom from '../../components/Widget/CardsWidgetsBottom.jsx';
import Header from '../../components/Header/Header.jsx';
import fabien from '../../assets/img/logo/fabien_hennion.png';
import logo_cave_nature from '../../assets/img/bons-plans/cave-nature.jpg';
import logo_patio_fouquet from '../../assets/img/bons-plans/patio_fouquet.jpeg';
import logo_orangerie from '../../assets/img/bons-plans/orangerie.png';
import logo_petitNice from '../../assets/img/bons-plans/petit_nice.png';
import img_ria from '../../assets/img/bons-plans/ria.png';
import img_plage_etang from '../../assets/img/bons-plans/la_plaine.jpeg';
import img_plage_source from '../../assets/img/bons-plans/plage_source.png';
import img_plage_noeveillard from '../../assets/img/bons-plans/plage_noeveillard.png';
import img_plage_Tharon from '../../assets/img/bons-plans/plage-Tharon.jpg';
import sushi_plaine from '../../assets/img/bons-plans/sushi_plaine.jpg';
import img_pointes_prefailles from '../../assets/img/bons-plans/pointes_prefailles.jpg';
import img_plage_gohaud from '../../assets/img/bons-plans/plages_gohaud.jpg';
import saint_paul from '../../assets/img/bons-plans/saint-paul.jpg';
import au_bois_flotte from '../../assets/img/bons-plans/au-bois-flotte.jpg';
import MassageEvelyne from './BonsPlansEvelyne.jsx';

export default function BonsPlans() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    document.title = 'Les Bons Plans de Fabien | Ma Maison Sereine';
    document.description =
        "Dans le secteur de Pornic et de La Plaine sur Mer, découvez les bons plans à réaliser durant votre séjour pour des moments d'évasion";
    const authUser = useSelector((store) => store.User.authUser);
    const translate = useSelector((store) => store.Template.language);
    const [citySelected, setCitySelected] = useState(undefined);
    const [activitySelected, setActivitySelected] = useState(undefined);

    const [nberPhone] = useState(
        localStorage.getItem('authVoyageurPort')
            ? localStorage.getItem('authVoyageurPort')
            : undefined
    );
    const [codeSMSActive] = useState(
        localStorage.getItem('authVoyageurCode')
            ? localStorage.getItem('authVoyageurCode')
            : undefined
    );
    return (
        <>
            <Header />
            <main className="mb-5" style={{ minHeight: '100vh' }}>
                <h1 className="fs-1 text-center mt-5 pt-5">
                    {translate === 'French' && (
                        <>Découvrez les Bons-Plans de Fabien </>
                    )}
                    {translate === 'English' && (
                        <>
                            Discover Fabien's Good Tips{' '}
                            <img
                                src={fabien}
                                alt="Fabien Hennion"
                                width={'80px'}
                            />
                        </>
                    )}
                </h1>
                {translate === 'French' && (
                    <p className="text-center text-white">
                        Pour des pures Moments d'évasions et de détente
                    </p>
                )}
                {translate === 'English' && (
                    <p className="text-center text-white">
                        For pure moments of escape and relaxation
                    </p>
                )}

                <hr className="col-5 mx-auto" />

                <div>
                    <div id="city" className="text-center my-2">
                        {translate === 'French' && <h2>Sur quelle Ville ? </h2>}
                        {translate === 'English' && <h2>In which city? </h2>}
                        <div className="d-flex flex-row justify-content-around">
                            {citySelected === 'plaine' ? (
                                <Button
                                    color="warning"
                                    onClick={() => setCitySelected('plaine')}
                                >
                                    <>
                                        <i className="fa-solid fa-location-arrow me-1 fs-4"></i>
                                        La Plaine Sur Mer
                                    </>
                                </Button>
                            ) : (
                                <Button
                                    color="primary"
                                    outline
                                    onClick={() => setCitySelected('plaine')}
                                >
                                    <>
                                        <i className="fa-solid fa-location-arrow me-1 fs-4"></i>
                                        La Plaine Sur Mer
                                    </>
                                </Button>
                            )}

                            {citySelected === 'pornic' ? (
                                <Button
                                    color="warning"
                                    onClick={() => setCitySelected('pornic')}
                                >
                                    <>
                                        <i className="fa-solid fa-location-arrow me-1 fs-4"></i>
                                        Pornic
                                    </>
                                </Button>
                            ) : (
                                <Button
                                    color="primary"
                                    outline
                                    onClick={() => setCitySelected('pornic')}
                                >
                                    <>
                                        <i className="fa-solid fa-location-arrow me-1 fs-4"></i>
                                        Pornic
                                    </>
                                </Button>
                            )}
                            {citySelected === 'prefailles' ? (
                                <Button
                                    color="warning"
                                    onClick={() =>
                                        setCitySelected('prefailles')
                                    }
                                >
                                    <>
                                        <i className="fa-solid fa-location-arrow me-1 fs-4"></i>
                                        Préfailles
                                    </>
                                </Button>
                            ) : (
                                <Button
                                    color="primary"
                                    outline
                                    onClick={() =>
                                        setCitySelected('prefailles')
                                    }
                                >
                                    <>
                                        <i className="fa-solid fa-location-arrow me-1 fs-4"></i>
                                        Préfailles
                                    </>
                                </Button>
                            )}
                            {citySelected === 'tharon' ? (
                                <Button
                                    color="warning"
                                    onClick={() => setCitySelected('tharon')}
                                >
                                    <>
                                        <i className="fa-solid fa-location-arrow me-1 fs-4"></i>
                                        Tharon
                                    </>
                                </Button>
                            ) : (
                                <Button
                                    color="primary"
                                    outline
                                    onClick={() => setCitySelected('tharon')}
                                >
                                    <>
                                        <i className="fa-solid fa-location-arrow me-1 fs-4"></i>
                                        Tharon
                                    </>
                                </Button>
                            )}
                        </div>
                    </div>
                    <hr className="col-5 mx-auto" />

                    {citySelected !== undefined && (
                        <div id="activity" className="text-center my-2">
                            {translate === 'French' && (
                                <h2>Pour quelle Activité ? </h2>
                            )}
                            {translate === 'English' && (
                                <h2>For which Activity? </h2>
                            )}
                            <div className="d-flex flex-row justify-content-around">
                                {activitySelected !== 'store' &&
                                    (citySelected === 'pornic' ||
                                        citySelected === 'plaine') && (
                                        <Button
                                            color="primary"
                                            outline
                                            className="mx-2"
                                            onClick={(e) =>
                                                setActivitySelected('store')
                                            }
                                        >
                                            {translate === 'French' && (
                                                <>
                                                    <i className="fa-solid fa-shop me-2 fs-4"></i>{' '}
                                                    Commerces
                                                </>
                                            )}
                                            {translate === 'English' && (
                                                <>
                                                    {' '}
                                                    <i className="fa-solid fa-shop me-2 fs-4"></i>{' '}
                                                    Stores
                                                </>
                                            )}
                                        </Button>
                                    )}
                                {activitySelected === 'store' && (
                                    <Button
                                        color="warning"
                                        className="mx-2"
                                        onClick={(e) =>
                                            setActivitySelected('store')
                                        }
                                    >
                                        {translate === 'French' && (
                                            <>
                                                <i className="fa-solid fa-shop me-2 fs-4"></i>{' '}
                                                Commerces
                                            </>
                                        )}
                                        {translate === 'English' && (
                                            <>
                                                {' '}
                                                <i className="fa-solid fa-shop me-2 fs-4"></i>{' '}
                                                Stores
                                            </>
                                        )}
                                    </Button>
                                )}
                                {activitySelected !== 'resto' &&
                                    (citySelected === 'pornic' ||
                                        citySelected === 'tharon' ||
                                        citySelected === 'prefailles') && (
                                        <Button
                                            color="primary"
                                            outline
                                            className="mx-2"
                                            onClick={(e) =>
                                                setActivitySelected('resto')
                                            }
                                        >
                                            {translate === 'French' && (
                                                <>
                                                    <i className="fa-solid fa-utensils me-2 fs-4"></i>{' '}
                                                    Restaurants
                                                </>
                                            )}
                                            {translate === 'English' && (
                                                <>
                                                    {' '}
                                                    <i className="fa-solid fa-utensils me-2 fs-4"></i>{' '}
                                                    Restaurants
                                                </>
                                            )}
                                        </Button>
                                    )}
                                {activitySelected === 'resto' &&
                                    (citySelected === 'pornic' ||
                                        citySelected === 'tharon' ||
                                        citySelected === 'prefailles') && (
                                        <Button
                                            color="warning"
                                            className="mx-2"
                                            onClick={(e) =>
                                                setActivitySelected('resto')
                                            }
                                        >
                                            {translate === 'French' && (
                                                <>
                                                    <i className="fa-solid fa-utensils me-2 fs-4"></i>{' '}
                                                    Restaurants
                                                </>
                                            )}
                                            {translate === 'English' && (
                                                <>
                                                    <i className="fa-solid fa-utensils me-2 fs-4"></i>{' '}
                                                    Restaurants
                                                </>
                                            )}
                                        </Button>
                                    )}
                                {activitySelected !== 'visite' && (
                                    <Button
                                        color="primary"
                                        className="mx-2"
                                        outline
                                        onClick={(e) =>
                                            setActivitySelected('visite')
                                        }
                                    >
                                        {translate === 'French' && (
                                            <>
                                                <i className="fa-solid fa-person-hiking me-2 fs-4"></i>{' '}
                                                Visites
                                            </>
                                        )}
                                        {translate === 'English' && (
                                            <>
                                                {' '}
                                                <i className="fa-solid fa-person-hiking me-2 fs-4"></i>{' '}
                                                Visits
                                            </>
                                        )}
                                    </Button>
                                )}
                                {activitySelected === 'visite' && (
                                    <Button
                                        color="warning"
                                        className="mx-2"
                                        onClick={(e) =>
                                            setActivitySelected('visite')
                                        }
                                    >
                                        {translate === 'French' && (
                                            <>
                                                <i className="fa-solid fa-person-hiking me-2 fs-4"></i>{' '}
                                                Visites
                                            </>
                                        )}
                                        {translate === 'English' && (
                                            <>
                                                {' '}
                                                <i className="fa-solid fa-person-hiking me-2 fs-4"></i>{' '}
                                                Visits
                                            </>
                                        )}
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                    {citySelected === 'plaine' && (
                        <section className="evasion-plaine p-5">
                            {activitySelected === 'store' && (
                                <>
                                    <MassageEvelyne />
                                    <div className="evasion-card">
                                        <h2>Sabay Sushi Thai</h2>
                                        <hr className="col-6 mx-auto" />
                                        <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                            <img
                                                src={sushi_plaine}
                                                alt={'Sabay Sushi Thai'}
                                                title={'Sabay Sushi Thai'}
                                                loading="lazy"
                                                width={'240px'}
                                                className="mx-auto rounded-4"
                                            />
                                            <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                                {translate === 'French' && (
                                                    <p>
                                                        Thai qui se trouve près
                                                        du centre de La Plaine
                                                        Sur Mer, Repas dépannage
                                                        à emporter mais de
                                                        qualité.
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        Thai located near from
                                                        the center of La Plaine
                                                        On the Sea, Emergency
                                                        Meals to take away but
                                                        quality.
                                                    </p>
                                                )}
                                                <a
                                                    href="https://sabay-sushi-thai.business.site"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-map-location-dot me-1"></i>
                                                    GPS
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activitySelected === 'visite' && (
                                <div className="evasion-card">
                                    <h2>La Plage de l'Etang</h2>
                                    <hr className="col-6 mx-auto" />
                                    <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                        <img
                                            src={img_plage_etang}
                                            alt={"La Plage de l'Etang"}
                                            title={"La Plage de l'Etang"}
                                            loading="lazy"
                                            width={'240px'}
                                            className="mx-auto rounded-4"
                                        />
                                        <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                            {translate === 'French' && (
                                                <p>
                                                    Venez découvrir la plage de
                                                    l'étang de La Plaine Sur
                                                    Mer, la vue sur les petites
                                                    falaises locales, la
                                                    baignade y est surveillée en
                                                    période estivale. Profitez
                                                    des sentiers piétonniers
                                                    tout le long de l'Océan
                                                    (vous pouvez remonter à pied
                                                    jusqu'à Pornic ou
                                                    Préfailles.)
                                                </p>
                                            )}
                                            {translate === 'English' && (
                                                <p>
                                                    Come and discover the beach
                                                    the pond of La Plaine Sur
                                                    Sea, view of the small local
                                                    cliff, swimming is monitored
                                                    there during periods summer.
                                                    Take advantage of walking
                                                    trails all along the Ocean
                                                    (you can walk back up to
                                                    Pornic or Préfailles.)
                                                </p>
                                            )}
                                            <a
                                                href="https://goo.gl/maps/DeBV24cPwn9PMTuR6"
                                                target="_blank"
                                            >
                                                <i className="fa-solid fa-map-location-dot me-1"></i>
                                                GPS
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </section>
                    )}
                    {citySelected === 'pornic' && (
                        <section className="evasion-pornic p-5">
                            {activitySelected === 'store' && (
                                <>
                                    <div className="evasion-card">
                                        <h2>La Cave Nature </h2>
                                        <hr className="col-6 mx-auto" />
                                        <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                            <img
                                                src={logo_cave_nature}
                                                alt={'Cave Nature'}
                                                title={'Cave Nature'}
                                                loading="lazy"
                                                width={'240px'}
                                                className="mx-auto rounded-4"
                                            />
                                            <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                                {translate === 'French' && (
                                                    <p>
                                                        Pour trouver de bonnes
                                                        bouteilles et de
                                                        précieux conseils il
                                                        faut aller voir Hélène à
                                                        sa cave:
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        To find good bottles and
                                                        valuable advice you have
                                                        to go see Hélène in her
                                                        cellar:
                                                    </p>
                                                )}
                                                <a
                                                    href="https://goo.gl/maps/pwTSJ3ZcozQrMJLz7"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-map-location-dot me-1"></i>
                                                    GPS
                                                </a>
                                                {translate === 'French' && (
                                                    <p>
                                                        Elle vous accueille du
                                                        mardi au dimanche matin,
                                                        pour vous faire partager
                                                        sa passion et ses
                                                        connaissances des bons
                                                        vins.{' '}
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        It welcomes you from
                                                        Tuesday to Sunday
                                                        morning, to make you
                                                        share his passion and
                                                        his knowledge of good
                                                        wines.{' '}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <MassageEvelyne />
                                </>
                            )}

                            {activitySelected === 'resto' && (
                                <>
                                    <div className="evasion-card">
                                        <h2>L'Orangerie</h2>
                                        <hr className="col-6 mx-auto" />
                                        <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                            <img
                                                src={logo_orangerie}
                                                alt={"L'Orangerie"}
                                                title={"L'Orangerie"}
                                                loading="lazy"
                                                width={'240px'}
                                                className="mx-auto rounded-4"
                                            />
                                            <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                                {translate === 'French' && (
                                                    <p>
                                                        Cuisine gastronomique à
                                                        un prix abordable,
                                                        cuisine qui saura
                                                        éveiller vos sens.
                                                        Personnels aux petits
                                                        soins.
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        Gourmet cuisine at a
                                                        price affordable,
                                                        kitchen that will know
                                                        awaken your senses.
                                                        Personal to little care.
                                                    </p>
                                                )}
                                                <a
                                                    href="https://goo.gl/maps/TZUJv21k5XCqH41t7"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-map-location-dot me-1"></i>
                                                    GPS
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="evasion-card">
                                        <h2>Le Patio du Fouquet</h2>
                                        <hr className="col-6 mx-auto" />
                                        <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                            <img
                                                src={logo_patio_fouquet}
                                                alt={'Le Patio du Fouquet'}
                                                title={'Le Patio du Fouquet'}
                                                loading="lazy"
                                                width={'240px'}
                                                className="mx-auto rounded-4"
                                            />
                                            <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                                {translate === 'French' && (
                                                    <p>
                                                        Bonne cuisine maison
                                                        d’inspiration
                                                        franco-italienne de
                                                        l’entrée au dessert.
                                                        S’adapte à tous les
                                                        budgets tout en servant
                                                        de la qualité.
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        Good home cooking
                                                        inspiration
                                                        Franco-Italian from
                                                        entry to dessert. Fits
                                                        all budgets while
                                                        serving quality.
                                                    </p>
                                                )}
                                                <a
                                                    href="https://goo.gl/maps/pTdwJFpQ1hdkhMN39"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-map-location-dot me-1"></i>
                                                    GPS
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="evasion-card">
                                        <h2>Le Petit Nice</h2>
                                        <hr className="col-6 mx-auto" />
                                        <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                            <img
                                                src={logo_petitNice}
                                                alt={'Le Petit Nice'}
                                                title={'Le Petit Nice'}
                                                loading="lazy"
                                                width={'240px'}
                                                className="mx-auto rounded-4"
                                            />
                                            <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                                {translate === 'French' && (
                                                    <p>
                                                        Petite crêperie servant
                                                        des produits de qualité
                                                        avec une vue imprenable
                                                        sur le port.
                                                        Régulièrement en service
                                                        continu l’après-midi.
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        Small creperie serving
                                                        produce quality with
                                                        stunning views on the
                                                        Haven. Regularly in
                                                        continuous service in
                                                        the afternoon.
                                                    </p>
                                                )}
                                                <a
                                                    href="https://goo.gl/maps/dZ6EGjwuTTN58t3n6"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-map-location-dot me-1"></i>
                                                    GPS
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {activitySelected === 'visite' && (
                                <>
                                    <div className="evasion-card">
                                        <h2>La Ria de Pornic</h2>
                                        <hr className="col-6 mx-auto" />
                                        <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                            <img
                                                src={img_ria}
                                                alt={'La Ria de Pornic'}
                                                title={'La Ria de Pornic'}
                                                loading="lazy"
                                                width={'240px'}
                                                className="mx-auto rounded-4"
                                            />
                                            <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                                {translate === 'French' && (
                                                    <p>
                                                        Depuis le Coeur de la
                                                        Ville de Pornic, venez
                                                        découvrir "La Ria", le
                                                        parc botanique pour une
                                                        promenade nature et
                                                        n'hésitez-pas à remonter
                                                        jusqu'à l'étang du Val
                                                        Saint Martin (2,5 km)
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        From the Heart of the
                                                        City of Pornic, come and
                                                        discover "La Ria", the
                                                        botanical park for a
                                                        walk nature and do not
                                                        hesitate to go up to the
                                                        pond of Val Saint Martin
                                                        (2.5km)
                                                    </p>
                                                )}
                                                <a
                                                    href="https://goo.gl/maps/HoTR7trrXCwQZSiXA"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-map-location-dot me-1"></i>
                                                    GPS
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="evasion-card">
                                        <h2>
                                            La plage de la Source (Thalasso)
                                        </h2>
                                        <hr className="col-6 mx-auto" />
                                        <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                            <img
                                                src={img_plage_source}
                                                alt={'La plage de la Source'}
                                                title={'La plage de la Source'}
                                                loading="lazy"
                                                width={'240px'}
                                                className="mx-auto rounded-4"
                                            />
                                            <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                                {translate === 'French' && (
                                                    <p>
                                                        Depuis le Centre-Ville
                                                        de Pornic, Remontez à
                                                        pied pour les plus
                                                        courageux jusqu'à la
                                                        plage de la Source et
                                                        visitez le quartier de
                                                        Gourmalon (à proximité
                                                        de la Thalasso de
                                                        Pornic)
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        From the city center of
                                                        Pornic, Walk back up for
                                                        more brave to the beach
                                                        Source and visit the
                                                        district of Gourmalon
                                                        (near the Thalasso of
                                                        Pornic)
                                                    </p>
                                                )}
                                                <a
                                                    href="https://goo.gl/maps/2SJUv63RtmXDBi738"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-map-location-dot me-1"></i>
                                                    GPS
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="evasion-card">
                                        <h2>
                                            La plage de la Noeveillard (Nouveau
                                            Port de Pornic)
                                        </h2>
                                        <hr className="col-6 mx-auto" />
                                        <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                            <img
                                                src={img_plage_noeveillard}
                                                alt={
                                                    'La plage de la Noeveillard'
                                                }
                                                title={
                                                    'La plage de la Noeveillard'
                                                }
                                                loading="lazy"
                                                width={'240px'}
                                                className="mx-auto rounded-4"
                                            />
                                            <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                                {translate === 'French' && (
                                                    <p>
                                                        Depuis le Château de
                                                        Pornic, Remontez à pied
                                                        sur les différents
                                                        sentiers piétonniers le
                                                        long de l'Océan en
                                                        direction du Nouveau
                                                        Port de Pornic pour y
                                                        découvrir la grande
                                                        Plage de la Noéveillard
                                                        (plage la plus proche).
                                                        Des navettes gratuites
                                                        (bus) sont disponibles
                                                        durant la période
                                                        estivale.
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        From the Cattle of
                                                        Pornic, Walk up the
                                                        different footpaths
                                                        along the ocean towards
                                                        of the New Port of
                                                        Pornic to discover the
                                                        large beach of the
                                                        Noéveillard (the most
                                                        near). Free shuttles
                                                        (bus) are available
                                                        during the summer
                                                        period.
                                                    </p>
                                                )}
                                                <a
                                                    href="https://goo.gl/maps/5BDhz8z6QaBHwRybA"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-map-location-dot me-1"></i>
                                                    GPS
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </section>
                    )}
                    {citySelected === 'prefailles' && (
                        <section className="evasion-prefailles p-5">
                            {activitySelected === 'store' && (
                                <>
                                    <MassageEvelyne />
                                </>
                            )}
                            {activitySelected === 'visite' && (
                                <>
                                    <div className="evasion-card">
                                        <h2>
                                            La Pointe st Gildas à Préfailles
                                        </h2>
                                        <hr className="col-6 mx-auto" />
                                        <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                            <img
                                                src={img_pointes_prefailles}
                                                alt={' La Pointe st Gildas'}
                                                title={' La Pointe st Gildas'}
                                                loading="lazy"
                                                width={'240px'}
                                                className="mx-auto rounded-4"
                                            />
                                            <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                                {translate === 'French' && (
                                                    <p>
                                                        Dépaysement assuré, les
                                                        vagues sont magnifiques,
                                                        il ne faut pas hésiter à
                                                        se balader jusqu’au
                                                        sémaphore.
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        A change of scenery
                                                        guaranteed, waves are
                                                        magnificent, you should
                                                        not hesitate to stroll
                                                        to semaphore.
                                                    </p>
                                                )}
                                                <a
                                                    href="https://goo.gl/maps/z2oLgRjmiowzyizd6"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-map-location-dot me-1"></i>
                                                    GPS
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activitySelected === 'resto' && (
                                <>
                                    <div className="evasion-card">
                                        <h2>Le Saint Paul à Préfailles</h2>
                                        <hr className="col-6 mx-auto" />
                                        <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                            <img
                                                src={saint_paul}
                                                alt={'Le Saint Paul'}
                                                title={'Le Saint Paul'}
                                                loading="lazy"
                                                width={'240px'}
                                                className="mx-auto rounded-4"
                                            />
                                            <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                                {translate === 'French' && (
                                                    <p>
                                                        Cuisine gastronomique à
                                                        un prix abordable,
                                                        cuisine qui saura
                                                        éveiller vos sens.
                                                        Personnels aux petits
                                                        soins.
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        Gourmet cuisine at an
                                                        affordable price,
                                                        cuisine that will awaken
                                                        your senses. Caring
                                                        staff.
                                                    </p>
                                                )}
                                                <a
                                                    href="https://goo.gl/maps/z2oLgRjmiowzyizd6"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-map-location-dot me-1"></i>
                                                    GPS
                                                </a>
                                                <a
                                                    href="https://www.le-saint-paul.fr"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-earth-europe"></i>
                                                    Site Internet
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </section>
                    )}
                    {citySelected === 'tharon' && (
                        <section className="evasion-tharon p-5">
                            {activitySelected === 'store' && (
                                <>
                                    <MassageEvelyne />
                                </>
                            )}
                            {activitySelected === 'visite' && (
                                <>
                                    <div className="evasion-card">
                                        <h2>La Grande Plage de Tharon</h2>
                                        <hr className="col-6 mx-auto" />
                                        <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                            <img
                                                src={img_plage_Tharon}
                                                alt={
                                                    'La grande Plage de Tharon'
                                                }
                                                title={
                                                    'La grande Plage de Tharon'
                                                }
                                                loading="lazy"
                                                width={'240px'}
                                                className="mx-auto rounded-4"
                                            />
                                            <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                                {translate === 'French' && (
                                                    <p>
                                                        Venez découvrir la plus
                                                        grande Plage de Tharon -
                                                        St-Michel-Chef-Chef à
                                                        proximité de Pornic !
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        Come and discover the
                                                        largest Plage de Tharon
                                                        - St-Michel-Chef-Chef
                                                        near Pornic!
                                                    </p>
                                                )}
                                                <a
                                                    href="https://goo.gl/maps/WUugh1NycLcMMv1HA"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-map-location-dot me-1"></i>
                                                    GPS
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="evasion-card">
                                        <h2>
                                            Plage de Gohaud à St Michel chef
                                            chef
                                        </h2>
                                        <hr className="col-6 mx-auto" />
                                        <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                            <img
                                                src={img_plage_gohaud}
                                                alt={
                                                    'Plage de Gohaud à St Michel chef chef'
                                                }
                                                title={
                                                    'Plage de Gohaud à St Michel chef chef'
                                                }
                                                loading="lazy"
                                                width={'240px'}
                                                className="mx-auto rounded-4"
                                            />
                                            <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                                {translate === 'French' && (
                                                    <p>
                                                        Magnifique plage à
                                                        visiter et même se
                                                        baigner, à 15 minutes en
                                                        voiture, ça mérite le
                                                        détour !
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        Beautiful beach at visit
                                                        and even swim, at 15
                                                        minutes by car, it's
                                                        worth a detour !
                                                    </p>
                                                )}
                                                <a
                                                    href="https://goo.gl/maps/gutaTBPf4shuLHaMA"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-map-location-dot me-1"></i>
                                                    GPS
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activitySelected === 'resto' && (
                                <>
                                    <div className="evasion-card">
                                        <h2>Au bois Flotté à Tharon</h2>
                                        <hr className="col-6 mx-auto" />
                                        <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                                            <img
                                                src={au_bois_flotte}
                                                alt={'Au bois Flotté'}
                                                title={'Au bois Flotté'}
                                                loading="lazy"
                                                width={'240px'}
                                                className="mx-auto rounded-4"
                                            />
                                            <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center">
                                                {translate === 'French' && (
                                                    <p>
                                                        L’une des meilleures
                                                        pizzerias du coin, pâte
                                                        au blé noir et le tout
                                                        fait maison avec des
                                                        produits du coin.
                                                    </p>
                                                )}
                                                {translate === 'English' && (
                                                    <p>
                                                        One of the best local
                                                        pizzerias, dough with
                                                        buckwheat and everything
                                                        homemade with local
                                                        products.
                                                    </p>
                                                )}
                                                <a
                                                    href="https://goo.gl/maps/1wg6r2JtNwTVrvq98"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-map-location-dot me-1"></i>
                                                    GPS
                                                </a>
                                                <a
                                                    href="https://www.facebook.com/auboisflotte/"
                                                    target="_blank"
                                                >
                                                    <i className="fa-solid fa-earth-europe"></i>
                                                    Site Internet
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </section>
                    )}
                </div>

                <section className="sect__widgets">
                    <CardHomeWidgetsBottom />
                </section>
            </main>
        </>
    );
}
