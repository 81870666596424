import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { GiNewspaper } from 'react-icons/gi';
import { FiPaperclip } from 'react-icons/fi';
import qrcode_PiedDansLeau from '../../../assets/qrcode/qrcode_pied-dans-leau.png';
import qrcode_wifi_piedsDansLeau from '../../../assets/qrcode/qrcode_wifi_piedsDansLeau.png';
import logoSer1 from '../../../assets/img/logo/logo__ma-maison-sereine.png';

import cle1 from '../../../assets/img/PiedsDsLeau/photos/cle1.jpg';
import cle2 from '../../../assets/img/PiedsDsLeau/photos/cle2.jpg';
import cle3 from '../../../assets/img/PiedsDsLeau/photos/cle3.jpg';

import gaz1 from '../../../assets/img/PiedsDsLeau/photos/gaz1.jpg';
import gaz2 from '../../../assets/img/PiedsDsLeau/photos/gaz2.jpg';
import gaz3 from '../../../assets/img/PiedsDsLeau/photos/gaz3.jpg';
import chauffeEau from '../../../assets/img/PiedsDsLeau/photos/chauffe-eau.jpg';

import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import Header from '../../../components/Header/Header.jsx';
import TitleBienvenue from '../../../components/Logements/bienvenue/Title.bienvenue.jsx';
import GpsBienvenue from '../../../components/Logements/bienvenue/Gps.bienvenue.jsx';
import ArriveeBienvenue from '../../../components/Logements/bienvenue/Arrivee.bienvenue.jsx';
import BoiteCleBienvenue from '../../../components/Logements/bienvenue/BoiteCle.bienvenue.jsx';
import InternetBienvenue from '../../../components/Logements/bienvenue/Internet.bienvenue.jsx';
import ChauffageBienvenue from '../../../components/Logements/bienvenue/Chauffage.bienvenue.jsx';
import JardinBienvenue from '../../../components/Logements/bienvenue/Jardin.bienvenue.jsx';
import ContactUrgence from '../../../components/Logements/ContactUrgence.jsx';
import DechetsBienvenue from '../../../components/Logements/bienvenue/Dechets.bienvenue.jsx';
import DurantSejourBienvenue from '../../../components/Logements/bienvenue/DurantSejour.bienvenue.jsx';
import DepartBienvenue from '../../../components/Logements/bienvenue/Depart.bienvenue.jsx';
import MerciBienvenue from '../../../components/Logements/bienvenue/Merci.bienvenue.jsx';

export default function BienvenuePiedsDsLeau() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    document.title = 'Bienvenue à "Les Pieds dans l\'eau" | Ma Maison Sereine';
    document.description =
        'Découvrez les recommandations pour profiter au mieux de votre logement lors de votre séjour.';
    const [className] = useState(
        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
    );
    const translate = useSelector((store) => store.Template.language);

    return (
        <>
            <Header />
            <main className="bg-primary ">
                <div className="d-flex  flex-row justify-content-around align-items-center pt-4">
                    <img
                        src={logoSer1}
                        alt={'Logo - ser1'}
                        title={'Logo - ser1'}
                        loading="lazy"
                        width={'180px'}
                        className="card-location-logo mt-3 rounded-2 border border-1 border-primary"
                    />

                    <div className=" screen-none_qrcode">
                        <img
                            src={qrcode_PiedDansLeau}
                            alt="QRCode les pieds dans l'eau"
                            width={'190px'}
                            className="card-location-logo mt-4 pt-3"
                        />
                        <small className="text-white mt-2">
                            Version Mobile & <span>English Version</span>{' '}
                            <div className="fi fi-gb"></div>
                        </small>
                    </div>
                </div>
                <TitleBienvenue
                    className={className}
                    title={"Les Pieds dans l'eau"}
                />
                <GpsBienvenue
                    className={className + ' no-print'}
                    address={
                        "66, Boulevard de l'Océran 44770 La Plaine Sur Mer"
                    }
                    gpsGoogle={'https://maps.app.goo.gl/uMiwB16WTK4cFMX26'}
                    gpsApple={
                        "https://maps.apple.com/?address=66%20Boulevard%20de%20l'Oc%C3%A9an,%2044770%20La%20Plaine-sur-Mer,%20France&ll=47.156575,-2.182085&q=66%20Boulevard%20de%20l'Oc%C3%A9an"
                    }
                />
                <ArriveeBienvenue
                    className={className}
                    hourFr={'17h00'}
                    hourEn={'5p.m.'}
                    parkingFr={
                        <>
                            <p>
                                Vous pouvez garer vos véhicules sur le terrain
                            </p>
                        </>
                    }
                    parkingEn={
                        <>
                            <p>You can park your vehicles on the lot</p>
                        </>
                    }
                />
                <BoiteCleBienvenue
                    className={className}
                    boiteFr={
                        <p>
                            Entrez dans le jardin puis dirigez-vous sur la
                            gauche. Suivez le chemin jusqu'à la maison. Vous
                            trouvez la boîte à clé pour votre arrivée autonome
                            au niveau de la porte d'entrée. <br />
                            Le code vous a été envoyé par Sms
                        </p>
                    }
                    boiteEn={
                        <p>
                            Enter the garden then head left. Follow the path to
                            the house. You find the key box for your arrival
                            autonomous at the entrance door. <br />
                            The code was sent to you by SMS
                        </p>
                    }
                    photos={
                        <>
                            <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                <img
                                    src={cle1}
                                    alt={'Allée de la Maison'}
                                    title={'Allée de la Maison'}
                                    loading="lazy"
                                    width={'250px'}
                                    className="card-location-logo rounded-4"
                                />

                                <small>Allée de la Maison </small>
                            </div>

                            <div className="d-flex flex-row flex-wrap justify-content-around align-items-center mx-auto">
                                <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                    <img
                                        src={cle2}
                                        alt={'Boite à Clé '}
                                        title={'Boite à Clé'}
                                        loading="lazy"
                                        width={'250px'}
                                        className="card-location-logo rounded-4"
                                    />

                                    <small>Boite à Clé</small>
                                </div>
                                <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                    <img
                                        src={cle3}
                                        alt={'Boite à Clé '}
                                        title={'Boite à Clé'}
                                        loading="lazy"
                                        width={'250px'}
                                        className="card-location-logo rounded-4"
                                    />

                                    <small>Boite à Clé</small>
                                </div>
                            </div>
                        </>
                    }
                />
                <InternetBienvenue
                    className={className}
                    access={true}
                    wifiName={'Airbox Guest_922C'}
                    wifiPass={'tomtomnana'}
                    infoFr={
                        <>
                            <p>Vous pouvez vous connecter au réseau Wifi</p>
                        </>
                    }
                    infoEn={
                        <>
                            <p>You can connect to the Wifi network</p>
                        </>
                    }
                    qrcode={qrcode_wifi_piedsDansLeau}
                />
                <ChauffageBienvenue
                    className={className}
                    dijoncteurFR={'dans le placard présent dans le salon'}
                    dijoncteurEN={'in the closet present in the living room'}
                    consigneFr={
                        <>
                            <p>
                                Le Chauffage est automatique mais vous pouvez
                                augmenter ou baisser la température de consigne
                                en cas de besoins avec les touches + et - sur
                                les radiateurs.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Merci aux petits techniciens curieux de ne pas
                                toucher aux boutons (ni aux disjoncteurs ! )
                            </p>

                            <p className="text-center fw-bold text-primary">
                                L'Energie est notre Avenir. Economisons-là
                            </p>
                        </>
                    }
                    consigneEn={
                        <>
                            <p>
                                Heating is automatic but you can increase or
                                lower the set temperature if necessary with the
                                + and - keys on the radiators.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Thank you to the curious little technicians not
                                to touch the buttons (or the circuit breakers! )
                            </p>

                            <p className="text-center fw-bold text-primary">
                                Energy is our Future. Let's save it
                            </p>
                        </>
                    }
                />
                <JardinBienvenue
                    className={className}
                    jardinFr={
                        <>
                            <p>Vous avez libre accès au jardin .</p>
                            <p>
                                Comme dans tous les quartiers d’habitations,
                                attention aux bruits démesurés, principalement
                                dans le jardin en soirée. Je rappelle que les
                                fêtes sont interdites.
                            </p>
                            <p>
                                Nous avons un accès direct sur la plage qui
                                n'est pas sécurisé, si vous l'empruntez, merci
                                de bien faire attention où vous mettez les
                                pieds... A vos risques et périls.
                            </p>
                            <p>
                                Nous vous demandons de bien vouloir : ne pas
                                fumer dans la maison ni jeter les mégots sur le
                                terrain ou la plage éteindre les lumières
                                inutiles et utiliser l'eau juste ce qu'il faut
                                ne pas amèner d'animaux (ou demandez notre
                                accord) utiliser le wifi de manière responsable
                                ne pas utiliser la cheminée
                            </p>
                            <p>
                                Si vous avez un soucis avec la bouteille de gaz,
                                merci de regarder la procédure en bas de page
                                avec les modes d'emploi.
                            </p>
                        </>
                    }
                    jardinEn={
                        <>
                            <p>You have free access to the garden .</p>
                            <p>
                                As in all living quarters, pay attention to
                                excessive noise, mainly in the garden in the
                                evening. I remember that the holidays are
                                prohibited.
                            </p>
                            <p>
                                We have direct access to the beach which is not
                                secure, if you borrow it, thank you to be
                                careful where you put the feet... At your own
                                risk.
                            </p>
                            <p>
                                We kindly ask you: do not smoke in the house or
                                throw cigarette butts on the field or beach turn
                                off the lights unnecessary and use just enough
                                water do not bring animals (or ask our
                                agreement) use wifi responsibly do not use the
                                fireplace
                            </p>
                            <p>
                                If you have a problem with the gas bottle,
                                please look at the procedure at the bottom of
                                the page with the instructions for use.
                            </p>
                        </>
                    }
                    light={false}
                />
                <DechetsBienvenue
                    className={className}
                    dechetsFr={
                        <p>
                            Les poubelles se trouvent au niveau des portails
                            blancs. Est-ce possible de mettre les poubelles pour
                            le ramassage des ordures le vendredi soir (pour un
                            passage le samedi matin) svp ? merci.
                        </p>
                    }
                    dechetsEn={
                        <p>
                            Trash cans are located at the gates whites. Is it
                            possible to put the trash cans for garbage
                            collection on Friday evening (for a passage on
                            Saturday morning) please? Thanks.
                        </p>
                    }
                />
                <ContactUrgence className="bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 print-cut-none " />
                <DurantSejourBienvenue
                    className={
                        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
                    }
                    duringFr={
                        <>
                            <p>
                                La maisone est située à proximité de la plage de
                                Port Giraud
                            </p>
                            <p>
                                N’hésitez pas à vous déplacer jusqu’à
                                Tharon-plage. C’est un peu notre mini
                                centre-ville « estivale » avec un belle esprit
                                de vacances en famille. Vous y trouverez un
                                manège, les glace de la fraiseraie, des
                                restaurants ……… Et une immense plage parfaite
                                pour les enfants.
                            </p>
                            <p>
                                Le Marché de La Plaine Sur Mer se tient tous les
                                dimanches matin. Le magasin le plus proche pour
                                faire de petite course est L’Intermarché de La
                                Plaine Sur Mer. Pharmacie la plus proche : dans
                                le centre de la Plaine Sur Mer. L’une des
                                meilleures boulangeries du coin : L’arbre à Pain{' '}
                            </p>
                        </>
                    }
                    duringEn={
                        <>
                            <p>
                                The house is located near the beach of Port
                                Giraud
                            </p>
                            <p>
                                Do not hesitate to move to Tharon-beach. It's
                                kind of our mini "summery" town center with a
                                beautiful family holiday spirit. You there find
                                a merry-go-round, ice cream strawberry
                                plantation, restaurants ……… And a huge beach
                                perfect for children.
                            </p>
                            <p>
                                The La Plaine Sur Mer Market is held every
                                Sunday mornings. The store most close to do a
                                little shopping is The Intermarket of La Plaine
                                Sur Mer. Nearest pharmacy: in the center of la
                                Plaine Sur Mer. One of the best local bakeries:
                                L’arbre à Pain{' '}
                            </p>
                        </>
                    }
                />
                <DepartBienvenue
                    className={className}
                    hourFr={'10h00'}
                    hourEn={'10a.m.'}
                    remiseCleFr={
                        'Merci de bien tout fermer et de mettre les clés dans la boite à clés et de veiller à brouiller le code.'
                    }
                    remiseCleEn={
                        'Please close everything well and put the keys in the key box and make sure to scramble the code.'
                    }
                    alertFr={
                        <>
                            Vous devez rendre la maison propre comme à votre
                            arrivée sauf dans le cas d'un forfait ménage
                            souscrit au préalable.
                        </>
                    }
                    alertEn={
                        <>
                            You must leave the house clean as your arrival
                            except in the case of a cleaning fee subscribed to
                            the prior.
                        </>
                    }
                    forfaitMenageFr={
                        'Vous devez tout de même rendre le logement dans un état acceptable, vider vos poubelles ainsi que votre vaisselle propre et rangée. '
                    }
                    forfaitMenageEn={
                        'You must still leave the accommodation in an acceptable condition, empty your trash and your dishes clean and tidy'
                    }
                    faireMenageFr={
                        <>
                            <p>
                                Face au grand nombre d’incivilité et pour le
                                respect de nos techniciens, merci de bien
                                l’effectuer sous peine de litige.
                            </p>
                            <p>
                                « Sur une journée d’affluence un technicien peut
                                avoir jusqu’à 8 lieux à vérifier/nettoyer,
                                imaginer qu’il ne peut à chaque location refaire
                                le ménage… le tout entre 10h00 et 16h00 ! Soyons
                                donc tous vigilent et respectueux ! » Fabien.
                            </p>
                        </>
                    }
                    faireMenageEn={
                        <>
                            <p>
                                Faced with the large number of incivilities and
                                for the respect for our technicians, thank you
                                very much do so under penalty of litigation.
                            </p>
                            <p>
                                “On a busy day a technician can have up to 8
                                places to check/clean, imagine that he cannot at
                                each rental redo the housework… all between
                                10:00 a.m. and 4:00 p.m.! So let's all be
                                vigilant and respectful! » Fabian.
                            </p>
                        </>
                    }
                    basesMenageFr={
                        <>
                            Aérer chaque pièce.
                            <ul>
                                <li>
                                    Dans les chambres : merci de retirer les
                                    draps (les mettre dans un sac en tissu) et
                                    nettoyer sol * et surfaces.
                                </li>
                                <li>
                                    Dans la salle de bain : vider la poubelle,
                                    mettre les serviettes dans le sac ainsi que
                                    le tapis. Nettoyer sol * et surfaces, bien
                                    essuyer la douche, lavabo, toilettes.
                                </li>
                                <li>
                                    Dans la cuisine : vider la poubelle,
                                    nettoyer le réfrigérateur, micro-ondes,
                                    four, surfaces, sol *…… <br />
                                    Mettre toute la vaisselle utilisée propre en
                                    évidence sur le plan de travail. Rendre la
                                    cuisine propre.
                                </li>
                                <li>
                                    Dans les pièces de vie : nettoyer les
                                    surfaces et sol *.
                                </li>
                            </ul>
                            <p>
                                * : Le nettoyage des sols consiste à aspirer et
                                laver avec la serpillère.
                            </p>
                        </>
                    }
                    basesHouseholdEn={
                        <>
                            Ventilate each room.
                            <ul>
                                <li>
                                    In the rooms: please remove the sheets (put
                                    them in a cloth bag) and clean floor * and
                                    surfaces.
                                </li>
                                <li>
                                    In the bathroom: empty the trash can, put
                                    the towels in the bag as well as the carpet.
                                    Clean floor * and surfaces, wipe the shower
                                    well, sink, toilet.
                                </li>
                                <li>
                                    In the kitchen: empty the trash, clean the
                                    refrigerator, microwave, oven, surfaces,
                                    floor *…… <br />
                                    Put all the dishes used clearly visible on
                                    the plan of work. Make the kitchen clean.
                                </li>
                                <li>
                                    In the living rooms: clean the surfaces and
                                    floor *.
                                </li>
                            </ul>
                            <p>
                                *: Floor cleaning involves vacuuming and wash
                                with the mop.
                            </p>
                        </>
                    }
                />
                <MerciBienvenue
                    className={className}
                    merciFr={
                        <>
                            Mon équipe et moi-même (et oui je fais du ménage
                            aussi) vous remercions par avance de votre
                            investissement dans cette tâche qui est la moins
                            drôle des vacances, mais qui fera notre sourire
                            <br />
                            <br />
                            Merci et un bon retour à vous !
                        </>
                    }
                    merciEn={
                        <>
                            My team and myself (and yes I do cleaning too) thank
                            you in advance for your investment in this task
                            which is the less funny of the holidays, but which
                            will make our smile <br />
                            <br />
                            Thank you and welcome back !
                        </>
                    }
                />
                <div className={className}>
                    <h2 className="text-center m-3 ">
                        <GiNewspaper />
                        {translate === 'French' && "Manuels / Modes d'emploi"}
                        {translate === 'English' &&
                            'Manuals / Instructions for use'}
                    </h2>

                    <div>
                        {translate === 'French' ? (
                            <p className="text-start">
                                Voici les manuels d'utilisation des appareils
                                présents dans le logement (Français uniquement):
                            </p>
                        ) : (
                            <p className="text-start">
                                Here are the device user manuals present in the
                                accommodation (French only):
                            </p>
                        )}

                        <ul className="text-start">
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/PiedsDansLeau_CANDY_CBI1974.pdf'
                                        }
                                    >
                                        Cuisine : Lave Vaisselle
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Kitchen : Dish Machine
                                    </p>
                                )}
                            </li>
                            <li>
                                {' '}
                                <p className="text-start">Panne GAZ</p>
                                <div className="d-flex flex-column ">
                                    {translate === 'French' ? (
                                        <small>
                                            En cas de panne de gaz,allez au
                                            local Gaz est situé à l'extérieur au
                                            niveau des fenêtre de la cuisine
                                            (porte gauche) Si le gaz ne
                                            fonctionne alors la bouteille est
                                            vide (témoin rouge) il faut alors
                                            fermer la bouteille et permuter le
                                            robinet vers la bouteille pleine que
                                            vous aurez ouverte le témoins
                                            passera au vert <br />
                                            Si le témoin est vert et que la
                                            plaque de gaz ne fonctionne pas il
                                            faut réamorcer le robinet de la
                                            cuisine dans le meuble à gauche
                                            (côté droit) en le tournant vers la
                                            droite
                                        </small>
                                    ) : (
                                        <small>
                                            In the event of a gas outage, go to
                                            the gas room located outside at the
                                            kitchen window (left door) If the
                                            gas does not work then the bottle is
                                            empty (red light) you must then
                                            close the bottle and switch the tap
                                            to the full bottle that you have
                                            opened the indicator light will turn
                                            green <br />
                                            If the light is green and the gas
                                            plate does not work it must re-prime
                                            the valve kitchen in the cabinet on
                                            the left (right side) by turning it
                                            towards the RIGHT
                                        </small>
                                    )}
                                </div>
                                <div className="d-flex flex-row flex-wrap justify-content-around align-items-center mx-auto">
                                    <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                        <img
                                            src={gaz1}
                                            alt={'Allée de la Maison'}
                                            title={'Allée de la Maison'}
                                            loading="lazy"
                                            width={'250px'}
                                            className="card-location-logo rounded-4"
                                        />

                                        <small>Local Gaz</small>
                                    </div>
                                    <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                        <img
                                            src={gaz2}
                                            alt={'Boite à Clé '}
                                            title={'Boite à Clé'}
                                            loading="lazy"
                                            width={'250px'}
                                            className="card-location-logo rounded-4"
                                        />

                                        <small>Temoin Gaz</small>
                                    </div>
                                    <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                        <img
                                            src={gaz3}
                                            alt={'Boite à Clé '}
                                            title={'Boite à Clé'}
                                            loading="lazy"
                                            width={'330px'}
                                            className="card-location-logo rounded-4"
                                        />

                                        <small>Robinet d'amorçage</small>
                                    </div>
                                </div>
                            </li>
                            <li>
                                {' '}
                                <p className="text-start">Panne Eau Chaude</p>
                                <div className="d-flex flex-column ">
                                    {translate === 'French' ? (
                                        <small>
                                            Le ballon d'eau chaude est en mode
                                            Eco+ Si l'eau venait à manquer
                                            (vaisselle et/ou douches longues)
                                            Appuyer sur la touche +/- pour
                                            activer le mode Chauffe rapide
                                        </small>
                                    ) : (
                                        <small>
                                            The hot water tank is in Eco+ mode
                                            If water runs out (dishes and/or
                                            long showers) Press the +/- button
                                            to activate rapid heating mode
                                        </small>
                                    )}
                                </div>
                                <div className="d-flex flex-row flex-wrap justify-content-around align-items-center mx-auto">
                                    <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                        <img
                                            src={chauffeEau}
                                            alt={'Ecran digital Chauffe Eau'}
                                            title={'Ecran digital Chauffe Eau'}
                                            loading="lazy"
                                            width={'250px'}
                                            className="card-location-logo rounded-4"
                                        />

                                        {translate === 'French' ? (
                                            <small>
                                                Ecran digital Chauffe Eau
                                            </small>
                                        ) : (
                                            <small>
                                                Water heater digital screen
                                            </small>
                                        )}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <section className="sect__widgets">
                    <CardHomeWidgetsBottom />
                </section>
            </main>
        </>
    );
}
