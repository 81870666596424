import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Table from 'react-bootstrap/Table';
import { Alert, Badge, Button } from 'reactstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';

import CardSejourVoyageur from '../../Voyageur/CardSejourVoyageur.jsx';
import { useFetchGetLocationSettings } from '../../../utils/Api/FetchLocationSettings.jsx';
import LoadingK from '../../Widget/LoadingK.jsx';
import WidgetRGPD from '../../Widget/RGPD.jsx';
import StatusStancerPackVoyageur from './StatusStancerPackVoyageur.jsx';

export default function AddPackVoyageur() {
    document.title = 'Module Pack Réservation  | Ma Maison Sereine';
    document.description =
        'Page de réservation de Pack concernant les logements en location AirBnb';
    const navigate = useNavigate();
    const [isOpenRGPD, setIsOpenRGPD] = useState(false);
    const translate = useSelector((store) => store.Template.language);
    const voyageSmoobu = useSelector((store) => store.Config.voyageSmoobu);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { dataLocationSettings, isLoadingLocationSettings } =
        useFetchGetLocationSettings();

    const [nberPhone] = useState(
        localStorage.getItem('authVoyageurPort')
            ? localStorage.getItem('authVoyageurPort')
            : undefined
    );
    const [codeSMSActive] = useState(
        localStorage.getItem('authVoyageurCode')
            ? localStorage.getItem('authVoyageurCode')
            : undefined
    );

    const [agree, setAgree] = useState(false);
    const [noSended, setNoSended] = useState(true);
    const [firstSend, setFirstSend] = useState(true);
    const [nberNight, setNberNight] = useState(0);
    const [idAppart] = useState(
        voyageSmoobu !== null && voyageSmoobu.apartment.id !== undefined
            ? voyageSmoobu.apartment.id
            : 0
    );
    const [mail, setMail] = useState(undefined);
    const [totalOptions, setTotalOptions] = useState(0);
    const [priceOptionsMenage, setPriceOptionsMenage] = useState(0);
    const [priceOptionsAnimaux, setPriceOptionsAnimaux] = useState(0);
    const [nberOptionsLinge2pcouleur, setNberOptionsLinge2pcouleur] =
        useState(0);
    const [nberOptionsLinge1pcouleur, setNberOptionsLinge1pcouleur] =
        useState(0);
    const [nberOptionsLinge2pblanc, setNberOptionsLinge2pblanc] = useState(0);
    const [nberOptionsLinge1pblanc, setNberOptionsLinge1pblanc] = useState(0);
    useEffect(() => {
        const checkInDate = new Date();
        const checkOutDate = new Date();
        if (voyageSmoobu !== undefined) {
            checkInDate.setFullYear(
                new Date(voyageSmoobu.arrival).getFullYear()
            );
            checkInDate.setMonth(new Date(voyageSmoobu.arrival).getMonth());
            checkInDate.setDate(new Date(voyageSmoobu.arrival).getDate());

            checkOutDate.setFullYear(
                new Date(voyageSmoobu.departure).getFullYear()
            );
            checkOutDate.setMonth(new Date(voyageSmoobu.departure).getMonth());
            checkOutDate.setDate(new Date(voyageSmoobu.departure).getDate());
        }

        const delta = Math.trunc(
            (checkOutDate - checkInDate) / (1000 * 60 * 60 * 24)
        );

        setNberNight(delta);
    }, [voyageSmoobu]);

    useEffect(() => {
        if (dataLocationSettings !== undefined) {
            if (nberNight <= dataLocationSettings.number_night_linge) {
                setTotalOptions(
                    nberOptionsLinge1pcouleur *
                        dataLocationSettings.price_linge_1p_couleur +
                        nberOptionsLinge2pcouleur *
                            dataLocationSettings.price_linge_2p_couleur +
                        nberOptionsLinge1pblanc *
                            dataLocationSettings.price_linge_1p_blanc +
                        nberOptionsLinge2pblanc *
                            dataLocationSettings.price_linge_2p_blanc +
                        priceOptionsMenage +
                        priceOptionsAnimaux
                );
            } else {
                setTotalOptions(
                    nberOptionsLinge1pblanc *
                        (dataLocationSettings.price_linge_1p_blanc -
                            dataLocationSettings.price_linge_1p_couleur) +
                        nberOptionsLinge2pblanc *
                            (dataLocationSettings.price_linge_2p_blanc -
                                dataLocationSettings.price_linge_2p_couleur) +
                        priceOptionsMenage +
                        priceOptionsAnimaux
                );
            }
        }
    }, [
        nberOptionsLinge2pcouleur,
        nberOptionsLinge1pcouleur,
        nberOptionsLinge2pblanc,
        nberOptionsLinge1pblanc,
        priceOptionsMenage,
        dataLocationSettings,
        priceOptionsAnimaux,
    ]);
    useEffect(() => {
        setTotalAmount(totalOptions);
    }, [totalOptions]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [bookingOK, setBookingOK] = useState(false);

    let formData = new FormData();
    // control if blocked checkbox is checked
    function checkBoxContract(element) {
        if (element.target.checked) {
            setAgree(true);
        } else {
            setAgree(false);
            {
                translate === 'French' &&
                    toast.warning(
                        "Merci d'accepter les conditions de réservations ...",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
            {
                translate === 'English' &&
                    toast.warning(
                        'Thank you for accepting the booking conditions...',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            agree === true &&
            (nberOptionsLinge2pcouleur !== 0 ||
                nberOptionsLinge1pcouleur !== 0 ||
                nberOptionsLinge2pblanc !== 0 ||
                nberOptionsLinge1pblanc !== 0 ||
                priceOptionsAnimaux !== 0 ||
                priceOptionsMenage !== 0)
        ) {
            formData = new FormData();
            formData.append('mail', mail);
            formData.append('totalAmount', totalAmount);
            formData.append('nberNight', nberNight);
            formData.append('priceOptionsMenage', priceOptionsMenage);
            formData.append('priceOptionsAnimaux', priceOptionsAnimaux);
            formData.append(
                'nberOptionsLinge2pcouleur',
                nberOptionsLinge2pcouleur
            );
            formData.append(
                'nberOptionsLinge1pcouleur',
                nberOptionsLinge1pcouleur
            );
            formData.append('nberOptionsLinge2pblanc', nberOptionsLinge2pblanc);
            formData.append('nberOptionsLinge1pblanc', nberOptionsLinge1pblanc);
            formData.append('totalOptions', totalOptions);
            setFirstSend(false);
            setNoSended(false);
            async function PostAddPack(formData) {
                try {
                    toast('Demande de réservation en cours...', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 20000,
                    });
                    const response = await fetch(
                        `https://api.mamaisonsereine.fr/api/locations/v1/pack_booking/create_reservation_pack/${idAppart}/${voyageSmoobu.id}/${nberPhone}/${codeSMSActive}/`,
                        {
                            method: 'post',
                            headers: new Headers({
                                Accept: 'application/json',
                            }),
                            body: formData,
                        }
                    );
                    if (response.status === 202) {
                        navigate('/voyageur');
                    } else if (response.status === 401) {
                        toast.error(
                            'Le serveur de pack réservation est en erreur authentification' +
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                }
                        );
                    } else if (response.status === 404) {
                        toast.error(
                            'La connexion au serveur de réservation distant est introuvable : ' +
                                data.detail,
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    } else {
                        setBookingOK(false);
                    }
                } catch (err) {
                    toast.error(
                        'Oups! Impossible de créer une nouvelle réservation 😔 Contactez le support',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            }
            PostAddPack(formData);
        } else {
            {
                translate === 'French' &&
                    toast.error(
                        "Merci de choisir une option &  d'accepter les conditions de réservation",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
            {
                translate === 'English' &&
                    toast.error(
                        'Please choose an option & accept the booking conditions',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
            }
        }
    };

    return (
        <main className="py-5">
            <h1 className="text-center">
                {translate === 'French' && <>Ajout d'Option à votre Séjour</>}
                {translate === 'English' && (
                    <>Addition of Option to your Stay</>
                )}
            </h1>
            {nberPhone !== undefined &&
            codeSMSActive !== undefined &&
            voyageSmoobu !== undefined ? (
                <>
                    <CardSejourVoyageur
                        voyage={voyageSmoobu}
                        className={
                            'bg-white border border-1 border-primary rounded shadow m-3 p-4 mx-auto col-11 col-lg-8'
                        }
                    />

                    <div className="my-5 col-11 col-lg-9 mx-auto">
                        <Table
                            bordered
                            hover
                            className=" mx-auto bg-white border border-4"
                        >
                            <thead>
                                <tr className="text-center fs-5 bg-primary text-white">
                                    {translate === 'French' && (
                                        <>
                                            <th>Désignation</th>
                                            <th>Montant</th>
                                            <th>Total</th>
                                        </>
                                    )}
                                    {translate === 'English' && (
                                        <>
                                            <th>Designation</th>
                                            <th>Amount</th>
                                            <th>Total</th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {isLoadingLocationSettings !== true && (
                                    <>
                                        {priceOptionsMenage !== 0 && (
                                            <tr>
                                                <td>
                                                    <i className="fa-solid fa-house"></i>{' '}
                                                    {idAppart !== undefined &&
                                                        voyageSmoobu.apartment
                                                            .name}
                                                    <br />
                                                    Options
                                                </td>
                                                <td>
                                                    {translate === 'French' && (
                                                        <span>
                                                            {' '}
                                                            Forfait Ménage{' '}
                                                        </span>
                                                    )}{' '}
                                                    {translate ===
                                                        'English' && (
                                                        <span>
                                                            Cleaning fee
                                                        </span>
                                                    )}{' '}
                                                    ({nberNight} nuits) -{' '}
                                                    {priceOptionsMenage} €
                                                </td>
                                                <td className="text-end">
                                                    {priceOptionsMenage}€
                                                </td>{' '}
                                            </tr>
                                        )}

                                        {priceOptionsAnimaux !== 0 && (
                                            <tr>
                                                <td>
                                                    <i className="fa-solid fa-house"></i>{' '}
                                                    {idAppart !== undefined &&
                                                        voyageSmoobu.apartment
                                                            .name}
                                                    <br />
                                                    Options
                                                </td>
                                                <td>
                                                    {translate === 'French' && (
                                                        <span>
                                                            {' '}
                                                            Forfait Animaux{' '}
                                                        </span>
                                                    )}{' '}
                                                    {translate ===
                                                        'English' && (
                                                        <span>Pets Pack</span>
                                                    )}{' '}
                                                    - {priceOptionsAnimaux} €
                                                </td>
                                                <td className="text-end">
                                                    {priceOptionsAnimaux}€
                                                </td>{' '}
                                            </tr>
                                        )}

                                        {nberOptionsLinge2pcouleur > 0 && (
                                            <>
                                                <tr>
                                                    <td>
                                                        <i className="fa-solid fa-house"></i>{' '}
                                                        {idAppart !==
                                                            undefined &&
                                                            voyageSmoobu
                                                                .apartment.name}
                                                        <br />
                                                        Options
                                                    </td>
                                                    <td>
                                                        {translate ===
                                                            'French' && (
                                                            <span>
                                                                Forfait Pack
                                                                Linge Lit
                                                                Couleur 2p (140)
                                                                -{' '}
                                                            </span>
                                                        )}
                                                        {translate ===
                                                            'English' && (
                                                            <span>
                                                                Color Bed Linen
                                                                Pack Package 2p
                                                                (140) -{' '}
                                                            </span>
                                                        )}
                                                        {nberNight >
                                                        dataLocationSettings.number_night_linge ? (
                                                            <span>
                                                                {' '}
                                                                {translate ===
                                                                    'French' && (
                                                                    <>Inclus</>
                                                                )}
                                                                {translate ===
                                                                    'English' && (
                                                                    <>
                                                                        Included
                                                                    </>
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {
                                                                    nberOptionsLinge2pcouleur
                                                                }{' '}
                                                                x{' '}
                                                                {
                                                                    dataLocationSettings.price_linge_2p_couleur
                                                                }{' '}
                                                                €
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td className="text-end">
                                                        {nberNight >
                                                        dataLocationSettings.number_night_linge ? (
                                                            <span>
                                                                {translate ===
                                                                    'French' && (
                                                                    <>Inclus</>
                                                                )}
                                                                {translate ===
                                                                    'English' && (
                                                                    <>
                                                                        Included
                                                                    </>
                                                                )}{' '}
                                                                (x
                                                                {
                                                                    nberOptionsLinge2pcouleur
                                                                }
                                                                )
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {nberOptionsLinge2pcouleur *
                                                                    dataLocationSettings.price_linge_2p_couleur}
                                                                €
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        {nberOptionsLinge1pcouleur > 0 && (
                                            <>
                                                <tr>
                                                    <td>
                                                        <i className="fa-solid fa-house"></i>{' '}
                                                        {idAppart !==
                                                            undefined &&
                                                            voyageSmoobu
                                                                .apartment.name}
                                                        <br />
                                                        Options
                                                    </td>
                                                    <td>
                                                        {translate ===
                                                            'French' && (
                                                            <>
                                                                Forfait Pack
                                                                Linge Lit
                                                                Couleur 1p (90)
                                                                -{' '}
                                                            </>
                                                        )}
                                                        {translate ===
                                                            'English' && (
                                                            <>
                                                                Color Bed Linen
                                                                Pack Package 1p
                                                                (90) -
                                                            </>
                                                        )}

                                                        {nberNight >
                                                        dataLocationSettings.number_night_linge ? (
                                                            <span>
                                                                {translate ===
                                                                    'French' && (
                                                                    <>Inclus</>
                                                                )}
                                                                {translate ===
                                                                    'English' && (
                                                                    <>
                                                                        Included
                                                                    </>
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {
                                                                    nberOptionsLinge1pcouleur
                                                                }{' '}
                                                                x{' '}
                                                                {
                                                                    dataLocationSettings.price_linge_1p_couleur
                                                                }{' '}
                                                                €
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td className="text-end">
                                                        {nberNight >
                                                        dataLocationSettings.number_night_linge ? (
                                                            <span>
                                                                {translate ===
                                                                    'French' && (
                                                                    <>Inclus</>
                                                                )}
                                                                {translate ===
                                                                    'English' && (
                                                                    <>
                                                                        Included
                                                                    </>
                                                                )}{' '}
                                                                (x
                                                                {
                                                                    nberOptionsLinge1pcouleur
                                                                }
                                                                )
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {nberOptionsLinge1pcouleur *
                                                                    dataLocationSettings.price_linge_1p_couleur}
                                                                €
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>{' '}
                                            </>
                                        )}
                                        {nberOptionsLinge2pblanc > 0 && (
                                            <>
                                                <tr>
                                                    <td>
                                                        <i className="fa-solid fa-house"></i>{' '}
                                                        {idAppart !==
                                                            undefined &&
                                                            voyageSmoobu
                                                                .apartment.name}
                                                        <br />
                                                        Options
                                                    </td>
                                                    <td>
                                                        {translate ===
                                                            'French' && (
                                                            <>
                                                                Forfait Pack
                                                                Linge Lit Blanc
                                                                2p (140) -{' '}
                                                            </>
                                                        )}
                                                        {translate ===
                                                            'English' && (
                                                            <>
                                                                White Bed Linen
                                                                Pack Package 2p
                                                                (140) -
                                                            </>
                                                        )}

                                                        <span>
                                                            {' '}
                                                            {
                                                                nberOptionsLinge2pblanc
                                                            }{' '}
                                                            x{' '}
                                                            {nberNight >
                                                            dataLocationSettings.number_night_linge ? (
                                                                <>
                                                                    {' '}
                                                                    {dataLocationSettings.price_linge_2p_blanc -
                                                                        dataLocationSettings.price_linge_2p_couleur}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        dataLocationSettings.price_linge_2p_blanc
                                                                    }
                                                                </>
                                                            )}{' '}
                                                            €
                                                        </span>
                                                    </td>
                                                    <td className="text-end">
                                                        <span>
                                                            {nberNight >
                                                            dataLocationSettings.number_night_linge ? (
                                                                <>
                                                                    {nberOptionsLinge2pblanc *
                                                                        (dataLocationSettings.price_linge_2p_blanc -
                                                                            dataLocationSettings.price_linge_2p_couleur)}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {nberOptionsLinge2pblanc *
                                                                        dataLocationSettings.price_linge_2p_blanc}
                                                                </>
                                                            )}
                                                            €
                                                        </span>
                                                    </td>
                                                </tr>{' '}
                                            </>
                                        )}
                                        {nberOptionsLinge1pblanc > 0 && (
                                            <>
                                                <tr>
                                                    <td>
                                                        <i className="fa-solid fa-house"></i>{' '}
                                                        {idAppart !==
                                                            undefined &&
                                                            voyageSmoobu
                                                                .apartment.name}
                                                        <br />
                                                        Options
                                                    </td>
                                                    <td>
                                                        {translate ===
                                                            'French' && (
                                                            <>
                                                                Forfait Pack
                                                                Linge Lit Blanc
                                                                1p (90) -
                                                            </>
                                                        )}
                                                        {translate ===
                                                            'English' && (
                                                            <>
                                                                White Bed Linen
                                                                Pack Package 1p
                                                                (90) -
                                                            </>
                                                        )}

                                                        <span>
                                                            {' '}
                                                            {
                                                                nberOptionsLinge1pblanc
                                                            }{' '}
                                                            x{' '}
                                                            {nberNight >
                                                            dataLocationSettings.number_night_linge ? (
                                                                <>
                                                                    {' '}
                                                                    {dataLocationSettings.price_linge_1p_blanc -
                                                                        dataLocationSettings.price_linge_1p_couleur}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        dataLocationSettings.price_linge_1p_blanc
                                                                    }
                                                                </>
                                                            )}{' '}
                                                            €
                                                        </span>
                                                    </td>
                                                    <td className="text-end">
                                                        <span>
                                                            {nberNight >
                                                            dataLocationSettings.number_night_linge ? (
                                                                <>
                                                                    {nberOptionsLinge1pblanc *
                                                                        (dataLocationSettings.price_linge_1p_blanc -
                                                                            dataLocationSettings.price_linge_1p_couleur)}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {nberOptionsLinge1pblanc *
                                                                        dataLocationSettings.price_linge_1p_blanc}
                                                                </>
                                                            )}
                                                            €
                                                        </span>
                                                    </td>
                                                </tr>{' '}
                                            </>
                                        )}
                                    </>
                                )}

                                <tr>
                                    <td className="bg-primary"></td>
                                    <td>
                                        {translate === 'French' && (
                                            <>
                                                TVA non applicable - article 293
                                                B du Code général des impôts{' '}
                                            </>
                                        )}
                                        {translate === 'English' && (
                                            <>
                                                VAT not applicable - article 293
                                                B of the French Tax Code
                                            </>
                                        )}
                                    </td>
                                    <td className="text-end">0€</td>
                                </tr>
                                <tr>
                                    <td className="bg-primary"></td>
                                    <td className="text-end fs-5 text-primary">
                                        Total
                                    </td>
                                    <td
                                        className="text-end fs-5 text-primary"
                                        id="totalAmount"
                                    >
                                        {totalAmount}€
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        {bookingOK === false ? (
                            <>
                                <div className="bg-white border border-3 border-purple p-3">
                                    <h2 className="text-center text-purple">
                                        {translate === 'French' && (
                                            <> Liste des Options Proposées</>
                                        )}
                                        {translate === 'English' && (
                                            <> List of Proposed Options</>
                                        )}
                                    </h2>
                                    {isLoadingLocationSettings !== true &&
                                    dataLocationSettings !== undefined ? (
                                        <>
                                            <p className="text-purple mt-3">
                                                {translate === 'French' && (
                                                    <> Forfait Ménage : </>
                                                )}
                                                {translate === 'English' && (
                                                    <>Cleaning fee :</>
                                                )}
                                            </p>
                                            <div className="option__menage mt-3 d-flex flex-row align-items-center px-2 mx-auto">
                                                <div className="text-center w-75">
                                                    <i className="mx-1 fa-solid fa-broom fs-5"></i>{' '}
                                                    {translate === 'French' && (
                                                        <>
                                                            {' '}
                                                            Ménage ({nberNight}
                                                            {' nuits '}){' '}
                                                        </>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>
                                                            {' '}
                                                            Cleaning (
                                                            {nberNight}
                                                            {' nights '})
                                                        </>
                                                    )}
                                                    : +
                                                    {(idAppart === 1579532 ||
                                                        idAppart === 1579538 ||
                                                        idAppart === 1584836) &&
                                                        nberNight <=
                                                            dataLocationSettings.number_night_short_booking &&
                                                        dataLocationSettings.price_menage_cat1_inf}
                                                    {(idAppart === 1579532 ||
                                                        idAppart === 1579538 ||
                                                        idAppart === 1584836) &&
                                                        nberNight >
                                                            dataLocationSettings.number_night_short_booking &&
                                                        dataLocationSettings.price_menage_cat1_sup}
                                                    {(idAppart === 1688317 ||
                                                        idAppart === 1711442 ||
                                                        idAppart === 1583795) &&
                                                        nberNight <=
                                                            dataLocationSettings.number_night_short_booking &&
                                                        dataLocationSettings.price_menage_cat2_inf}
                                                    {(idAppart === 1688317 ||
                                                        idAppart === 1711442 ||
                                                        idAppart === 1583795) &&
                                                        nberNight >
                                                            dataLocationSettings.number_night_short_booking &&
                                                        dataLocationSettings.price_menage_cat2_sup}
                                                    €
                                                </div>

                                                <div className="d-flex flex-row w-25">
                                                    <Badge
                                                        className="m-1 d-flex flex-row justify-content-center align-item-center "
                                                        color="danger"
                                                        pill
                                                        onClick={(e) => {
                                                            setPriceOptionsMenage(
                                                                0
                                                            );
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-minus"></i>
                                                    </Badge>{' '}
                                                    x
                                                    {priceOptionsMenage ===
                                                    0 ? (
                                                        <span>0</span>
                                                    ) : (
                                                        <span>1</span>
                                                    )}
                                                    <Badge
                                                        className="m-1 d-flex flex-row justify-content-center align-item-center "
                                                        color="success"
                                                        pill
                                                        onClick={(e) => {
                                                            {
                                                                (idAppart ===
                                                                    1579532 ||
                                                                    idAppart ===
                                                                        1579538 ||
                                                                    idAppart ===
                                                                        1584836) &&
                                                                    nberNight <=
                                                                        dataLocationSettings.number_night_short_booking &&
                                                                    setPriceOptionsMenage(
                                                                        dataLocationSettings.price_menage_cat1_inf
                                                                    );
                                                            }
                                                            {
                                                                (idAppart ===
                                                                    1579532 ||
                                                                    idAppart ===
                                                                        1579538 ||
                                                                    idAppart ===
                                                                        1584836) &&
                                                                    nberNight >
                                                                        dataLocationSettings.number_night_short_booking &&
                                                                    setPriceOptionsMenage(
                                                                        dataLocationSettings.price_menage_cat1_sup
                                                                    );
                                                            }
                                                            {
                                                                (idAppart ===
                                                                    1583795 ||
                                                                    idAppart ===
                                                                        1688317 ||
                                                                    idAppart ===
                                                                        1711442) &&
                                                                    nberNight <=
                                                                        dataLocationSettings.number_night_short_booking &&
                                                                    setPriceOptionsMenage(
                                                                        dataLocationSettings.price_menage_cat2_inf
                                                                    );
                                                            }
                                                            {
                                                                (idAppart ===
                                                                    1583795 ||
                                                                    idAppart ===
                                                                        1688317 ||
                                                                    idAppart ===
                                                                        1711442) &&
                                                                    nberNight >
                                                                        dataLocationSettings.number_night_short_booking &&
                                                                    setPriceOptionsMenage(
                                                                        dataLocationSettings.price_menage_cat2_sup
                                                                    );
                                                            }
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-plus"></i>
                                                    </Badge>
                                                </div>
                                            </div>
                                            <div className="option__animaux mt-3 d-flex flex-row align-items-center px-2 mx-auto">
                                                <div className="text-center w-75">
                                                    <i className="mx-1 fa-solid fa-paw fs-5"></i>{' '}
                                                    {translate === 'French' && (
                                                        <>Animaux</>
                                                    )}
                                                    {translate ===
                                                        'English' && <>Pets</>}
                                                    : +
                                                    {
                                                        dataLocationSettings.price_animaux
                                                    }
                                                    €
                                                </div>

                                                <div className="d-flex flex-row w-25">
                                                    <Badge
                                                        className="m-1 d-flex flex-row justify-content-center align-item-center "
                                                        color="danger"
                                                        pill
                                                        onClick={(e) => {
                                                            setPriceOptionsAnimaux(
                                                                0
                                                            );
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-minus"></i>
                                                    </Badge>{' '}
                                                    x
                                                    {priceOptionsAnimaux ===
                                                    0 ? (
                                                        <span>0</span>
                                                    ) : (
                                                        <span>1</span>
                                                    )}
                                                    <Badge
                                                        className="m-1 d-flex flex-row justify-content-center align-item-center "
                                                        color="success"
                                                        pill
                                                        onClick={(e) => {
                                                            setPriceOptionsAnimaux(
                                                                dataLocationSettings.price_animaux
                                                            );
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-plus"></i>
                                                    </Badge>
                                                </div>
                                            </div>
                                            <hr className="col-6 mx-auto" />
                                            <p className="text-purple mt-3">
                                                {translate === 'French' && (
                                                    <>
                                                        Pack Linge Couleur
                                                        (Standard) :
                                                    </>
                                                )}
                                                {translate === 'English' && (
                                                    <>
                                                        Color Linen Pack
                                                        (Standard):
                                                    </>
                                                )}
                                            </p>
                                            <div className="option__lit2p_couleur mt-3 d-flex flex-row align-items-center px-2 mx-auto">
                                                <div className="text-center w-75">
                                                    <i className="ms-1  me-2 fa-solid fa-bed "></i>
                                                    {translate === 'French' && (
                                                        <>
                                                            Lit Couleur 2p (140)
                                                            :{' '}
                                                        </>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>
                                                            Bed Color 2p (140) :{' '}
                                                        </>
                                                    )}

                                                    {nberNight >
                                                    dataLocationSettings.number_night_linge ? (
                                                        <span>
                                                            {translate ===
                                                                'French' && (
                                                                <>Inclus </>
                                                            )}
                                                            {translate ===
                                                                'English' && (
                                                                <>Included</>
                                                            )}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            +{' '}
                                                            {
                                                                dataLocationSettings.price_linge_2p_couleur
                                                            }
                                                            €/
                                                            {translate ===
                                                                'French' && (
                                                                <> Lit </>
                                                            )}
                                                            {translate ===
                                                                'English' && (
                                                                <>Bed</>
                                                            )}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="d-flex flex-row w-25">
                                                    <Badge
                                                        className="m-1 d-flex flex-row justify-content-center align-item-center "
                                                        color="danger"
                                                        pill
                                                        onClick={(e) => {
                                                            nberOptionsLinge2pcouleur >
                                                                0 &&
                                                                setNberOptionsLinge2pcouleur(
                                                                    nberOptionsLinge2pcouleur -
                                                                        1
                                                                );
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-minus"></i>
                                                    </Badge>{' '}
                                                    x{nberOptionsLinge2pcouleur}
                                                    <Badge
                                                        className="m-1 d-flex flex-row justify-content-center align-item-center "
                                                        color="success"
                                                        pill
                                                        onClick={(e) => {
                                                            nberOptionsLinge2pcouleur <
                                                                6 &&
                                                                setNberOptionsLinge2pcouleur(
                                                                    nberOptionsLinge2pcouleur +
                                                                        1
                                                                );
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-plus"></i>
                                                    </Badge>
                                                </div>
                                            </div>
                                            <div className="option__lit1p_couleur mt-3 d-flex flex-row align-items-center px-2 mx-auto">
                                                <div className="text-center w-75">
                                                    <i className="ms-1  me-2 fa-solid fa-bed "></i>

                                                    {translate === 'French' && (
                                                        <>
                                                            {' '}
                                                            Lit Couleur 1p (90)
                                                            :{' '}
                                                        </>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>Color bed 1p (90):</>
                                                    )}
                                                    {nberNight >
                                                    dataLocationSettings.number_night_linge ? (
                                                        <span>
                                                            {translate ===
                                                                'French' && (
                                                                <>Inclus </>
                                                            )}
                                                            {translate ===
                                                                'English' && (
                                                                <>Included</>
                                                            )}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            +{' '}
                                                            {
                                                                dataLocationSettings.price_linge_1p_couleur
                                                            }
                                                            €/
                                                            {translate ===
                                                                'French' && (
                                                                <> Lit </>
                                                            )}
                                                            {translate ===
                                                                'English' && (
                                                                <>Bed</>
                                                            )}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="d-flex flex-row w-25">
                                                    <Badge
                                                        className="m-1 d-flex flex-row justify-content-center align-item-center "
                                                        color="danger"
                                                        pill
                                                        onClick={(e) => {
                                                            nberOptionsLinge1pcouleur >
                                                                0 &&
                                                                setNberOptionsLinge1pcouleur(
                                                                    nberOptionsLinge1pcouleur -
                                                                        1
                                                                );
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-minus"></i>
                                                    </Badge>{' '}
                                                    x{nberOptionsLinge1pcouleur}
                                                    <Badge
                                                        className="m-1 d-flex flex-row justify-content-center align-item-center "
                                                        color="success"
                                                        pill
                                                        onClick={(e) => {
                                                            nberOptionsLinge1pcouleur <
                                                                6 &&
                                                                setNberOptionsLinge1pcouleur(
                                                                    nberOptionsLinge1pcouleur +
                                                                        1
                                                                );
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-plus"></i>
                                                    </Badge>
                                                </div>
                                            </div>
                                            <p className="text-purple mt-3">
                                                {translate === 'French' && (
                                                    <>
                                                        {' '}
                                                        Pack Linge Blanc
                                                        Hôtelier (Premium) :{' '}
                                                    </>
                                                )}
                                                {translate === 'English' && (
                                                    <>
                                                        Hotel White Linen Pack
                                                        (Premium):{' '}
                                                    </>
                                                )}
                                            </p>
                                            <div className="option__lit2p_blanc mt-3 d-flex flex-row align-items-center px-2 mx-auto">
                                                <div className="text-center w-75">
                                                    <i className="ms-1  me-2 fa-solid fa-bed "></i>

                                                    {translate === 'French' && (
                                                        <>
                                                            Lit Blanc 2p (140) :
                                                        </>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>White bed 2p (140):</>
                                                    )}
                                                    {nberNight >
                                                    dataLocationSettings.number_night_linge ? (
                                                        <span>
                                                            +{' '}
                                                            {dataLocationSettings.price_linge_2p_blanc -
                                                                dataLocationSettings.price_linge_2p_couleur}
                                                            €/
                                                            {translate ===
                                                                'French' && (
                                                                <> Lit </>
                                                            )}
                                                            {translate ===
                                                                'English' && (
                                                                <>Bed</>
                                                            )}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            +{' '}
                                                            {
                                                                dataLocationSettings.price_linge_2p_blanc
                                                            }
                                                            €/
                                                            {translate ===
                                                                'French' && (
                                                                <> Lit </>
                                                            )}
                                                            {translate ===
                                                                'English' && (
                                                                <>Bed</>
                                                            )}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="d-flex flex-row w-25">
                                                    <Badge
                                                        className="m-1 d-flex flex-row justify-content-center align-item-center "
                                                        color="danger"
                                                        pill
                                                        onClick={(e) => {
                                                            nberOptionsLinge2pblanc >
                                                                0 &&
                                                                setNberOptionsLinge2pblanc(
                                                                    nberOptionsLinge2pblanc -
                                                                        1
                                                                );
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-minus"></i>
                                                    </Badge>{' '}
                                                    x{nberOptionsLinge2pblanc}
                                                    <Badge
                                                        className="m-1 d-flex flex-row justify-content-center align-item-center "
                                                        color="success"
                                                        pill
                                                        onClick={(e) => {
                                                            nberOptionsLinge2pblanc <
                                                                6 &&
                                                                setNberOptionsLinge2pblanc(
                                                                    nberOptionsLinge2pblanc +
                                                                        1
                                                                );
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-plus"></i>
                                                    </Badge>
                                                </div>
                                            </div>
                                            <div className="option__lit1p_blanc mt-3 d-flex flex-row align-items-center px-2 mx-auto">
                                                <div className="text-center w-75">
                                                    <i className="ms-1  me-2 fa-solid fa-bed "></i>

                                                    {translate === 'French' && (
                                                        <>
                                                            {' '}
                                                            Lit Blanc 1p (90){' '}
                                                            {' : '}
                                                        </>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>
                                                            {' '}
                                                            White Bed 1p (90){' '}
                                                            {' : '}
                                                        </>
                                                    )}
                                                    {nberNight >
                                                    dataLocationSettings.number_night_linge ? (
                                                        <span>
                                                            +{' '}
                                                            {dataLocationSettings.price_linge_1p_blanc -
                                                                dataLocationSettings.price_linge_1p_couleur}
                                                            €/
                                                            {translate ===
                                                                'French' && (
                                                                <> Lit </>
                                                            )}
                                                            {translate ===
                                                                'English' && (
                                                                <>Bed</>
                                                            )}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            +{' '}
                                                            {
                                                                dataLocationSettings.price_linge_1p_blanc
                                                            }
                                                            €/
                                                            {translate ===
                                                                'French' && (
                                                                <> Lit </>
                                                            )}
                                                            {translate ===
                                                                'English' && (
                                                                <>Bed</>
                                                            )}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="d-flex flex-row w-25">
                                                    <Badge
                                                        className="m-1 d-flex flex-row justify-content-center align-item-center "
                                                        color="danger"
                                                        pill
                                                        onClick={(e) => {
                                                            nberOptionsLinge1pblanc >
                                                                0 &&
                                                                setNberOptionsLinge1pblanc(
                                                                    nberOptionsLinge1pblanc -
                                                                        1
                                                                );
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-minus"></i>
                                                    </Badge>{' '}
                                                    x{nberOptionsLinge1pblanc}
                                                    <Badge
                                                        className="m-1 d-flex flex-row justify-content-center align-item-center "
                                                        color="success"
                                                        pill
                                                        onClick={(e) => {
                                                            nberOptionsLinge1pblanc <
                                                                6 &&
                                                                setNberOptionsLinge1pblanc(
                                                                    nberOptionsLinge1pblanc +
                                                                        1
                                                                );
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-plus"></i>
                                                    </Badge>
                                                </div>
                                            </div>
                                            <div>
                                                {nberNight >
                                                    dataLocationSettings.number_night_linge && (
                                                    <Alert
                                                        className="my-3 text-center mx-auto col-10"
                                                        color="warning"
                                                    >
                                                        {translate ===
                                                            'French' && (
                                                            <>
                                                                Le linge de lit
                                                                est offert dans
                                                                votre séjour de{' '}
                                                                {nberNight}{' '}
                                                                nuités. Merci de
                                                                nous confirmer
                                                                la préparation
                                                                des lits svp.
                                                            </>
                                                        )}
                                                        {translate ===
                                                            'English' && (
                                                            <>
                                                                Bed linen is
                                                                offered in your{' '}
                                                                {nberNight} stay
                                                                nights. Thank
                                                                you for
                                                                confirming us
                                                                the preparation
                                                                of the beds
                                                                please.
                                                            </>
                                                        )}
                                                    </Alert>
                                                )}
                                                <hr className="col-6 mx-auto" />
                                                <small>
                                                    {translate === 'French' && (
                                                        <>
                                                            Chaque pack comprend
                                                            : drap + housse de
                                                            couette + taie(s) +
                                                            serviette(s) de
                                                            toilette
                                                        </>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>
                                                            Each pack includes:
                                                            sheet + cover duvet
                                                            cover +
                                                            pillowcase(s) +
                                                            towel(s) toilet
                                                        </>
                                                    )}
                                                </small>
                                            </div>
                                        </>
                                    ) : (
                                        <LoadingK />
                                    )}
                                </div>
                                <form
                                    className="d-flex flex-column bg-white  border border-3 border-warning  my-3"
                                    onSubmit={(e) => {
                                        handleSubmit(e);
                                    }}
                                >
                                    <div className="d-flex flex-column flex-lg-row flex-wrap">
                                        <Button
                                            color="warning"
                                            onClick={(e) =>
                                                setIsOpenRGPD(!isOpenRGPD)
                                            }
                                            className="mx-auto my-1"
                                        >
                                            {translate === 'French' && (
                                                <>
                                                    {' '}
                                                    Infos données Personnelles -
                                                    RGPD
                                                </>
                                            )}
                                            {translate === 'English' && (
                                                <>
                                                    Personal data information -
                                                    GDPR
                                                </>
                                            )}
                                        </Button>
                                    </div>
                                    <Offcanvas
                                        show={isOpenRGPD}
                                        onHide={(e) =>
                                            setIsOpenRGPD(!isOpenRGPD)
                                        }
                                        placement={'bottom'}
                                    >
                                        <Offcanvas.Header closeButton>
                                            <Offcanvas.Title>
                                                {translate === 'French' && (
                                                    <>
                                                        {' '}
                                                        L’utilisation de vos
                                                        données personnelles
                                                    </>
                                                )}
                                                {translate === 'English' && (
                                                    <>
                                                        The use of your data
                                                        personal
                                                    </>
                                                )}
                                            </Offcanvas.Title>
                                        </Offcanvas.Header>
                                        <hr />
                                        <Offcanvas.Body>
                                            <WidgetRGPD />
                                        </Offcanvas.Body>
                                    </Offcanvas>

                                    <hr className="col-6 mx-auto" />
                                    <Form.Group
                                        className="d-flex flex-column mb-3 mx-auto"
                                        id="mail"
                                    >
                                        <Form.Label className="mt-2 mx-auto">
                                            {translate === 'French' && (
                                                <>
                                                    {' '}
                                                    Merci de nous confirmer
                                                    votre Mail pour la banque
                                                </>
                                            )}
                                            {translate === 'English' && (
                                                <>
                                                    {' '}
                                                    Please can you confirm Your
                                                    Mail to the Bank
                                                </>
                                            )}
                                        </Form.Label>

                                        <Form.Control
                                            className="col-11 col-lg-6 mx-auto"
                                            type="email"
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            placeholder="adresse@domaine.fr"
                                            id="mail"
                                            name="mail"
                                            required
                                            onChange={(e) =>
                                                setMail(e.target.value)
                                            }
                                        />
                                    </Form.Group>
                                    <hr className="col-6 mx-auto" />
                                    <div className="text-center d-flex flex-row justify-content-center mt-2">
                                        <input
                                            className="checkbox-input me-2"
                                            type="checkbox"
                                            id="checkbox1"
                                            name="contract"
                                            onChange={(e) =>
                                                checkBoxContract(e)
                                            }
                                            required
                                            aria-label="J'ai lu et accepté les conditions d'utilisation."
                                        />
                                        <label
                                            className="checkbox2-label w-75"
                                            for="checkbox1"
                                        >
                                            <span className="checkbox-icon"></span>
                                            {translate === 'French' && (
                                                <>J'ai lu et accepté les </>
                                            )}
                                            {translate === 'English' && (
                                                <>I have read and accept the </>
                                            )}
                                            <a
                                                href="https://mamaisonsereine.fr/cgl-reglement-interieur"
                                                target="_blank"
                                                className="text-primary"
                                            >
                                                {translate === 'French' && (
                                                    <>
                                                        Conditions Générales de
                                                        Locations{' '}
                                                    </>
                                                )}
                                                {translate === 'English' && (
                                                    <>
                                                        General Rental
                                                        Conditions{' '}
                                                    </>
                                                )}
                                                <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                            </a>{' '}
                                            {translate === 'French' && (
                                                <>ainsi que les </>
                                            )}
                                            {translate === 'English' && (
                                                <>as well as </>
                                            )}
                                            <a
                                                href="https://mamaisonsereine.fr/cgv-mentions-legales"
                                                target="_blank"
                                                className="text-primary"
                                            >
                                                {translate === 'French' && (
                                                    <>
                                                        Conditions Générales de
                                                        Ventes{' '}
                                                    </>
                                                )}
                                                {translate === 'English' && (
                                                    <>Terms and conditions </>
                                                )}
                                                <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                            </a>{' '}
                                        </label>
                                    </div>
                                    {agree === false && noSended === true ? (
                                        <p
                                            color="secondary"
                                            className="mx-auto mt-2 mb-5 w-75 text-center bg-secondary rounded p-2 cursor-forbidden"
                                        >
                                            {translate === 'French' && (
                                                <>
                                                    Je valide ma demande et je
                                                    vais procéder au règlement
                                                </>
                                            )}
                                            {translate === 'English' && (
                                                <>
                                                    I validate my request and I
                                                    will make the payment
                                                </>
                                            )}
                                        </p>
                                    ) : (
                                        <>
                                            {noSended === true && (
                                                <Button
                                                    color="primary"
                                                    className="mx-auto mt-2 w-75 mb-5 text-center"
                                                    type="submit"
                                                    id="btn-submit"
                                                >
                                                    {translate === 'French' && (
                                                        <>
                                                            Je valide ma demande
                                                            de réservation et je
                                                            vais procéder au
                                                            règlement
                                                        </>
                                                    )}
                                                    {translate ===
                                                        'English' && (
                                                        <>
                                                            I validate my
                                                            booking request and
                                                            I will make the
                                                            payment
                                                        </>
                                                    )}
                                                </Button>
                                            )}
                                        </>
                                    )}
                                    {noSended === false && (
                                        <div className="mx-auto text-center">
                                            <span>
                                                Merci de patienter - Please Wait
                                                - <LoadingK />
                                            </span>
                                        </div>
                                    )}
                                </form>
                            </>
                        ) : (
                            <>
                                <StatusStancerPackVoyageur
                                    idReservation={voyageSmoobu.id}
                                    dataLocationSettings={dataLocationSettings}
                                    onlyNotPay={true}
                                />
                            </>
                        )}
                    </div>
                </>
            ) : (
                <div className="text-center">
                    <Alert color={'danger'} className="text-center">
                        <i className=" me-2 fs-3 fa-solid fa-triangle-exclamation"></i>
                        {translate === 'French' && (
                            <>
                                Merci de sélectionner votre séjour avant de
                                rajouter des options
                            </>
                        )}
                        {translate === 'English' && (
                            <>Please select your stay before add options</>
                        )}
                    </Alert>

                    <Link to="/voyageur">
                        <button className="bg-primary text-white p-2 m-3 rounded border-0 shadow">
                            <i className="fa-solid fa-reply me-1"></i>{' '}
                            {translate === 'French' && (
                                <>Retour à ma liste de Réservations</>
                            )}
                            {translate === 'English' && (
                                <>Back to my list of Reservations</>
                            )}
                        </button>
                    </Link>
                </div>
            )}
        </main>
    );
}
