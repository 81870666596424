import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GiNewspaper } from 'react-icons/gi';
import { FiPaperclip } from 'react-icons/fi';

import qrcodelogement from '../../../assets/qrcode/qrcode_c&i-home.png';
import qrcode_Wifi from '../../../assets/qrcode/qrcode_wifi_CIHome.png';
import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import Header from '../../../components/Header/Header.jsx';
import TitleBienvenue from '../../../components/Logements/bienvenue/Title.bienvenue.jsx';
import GpsBienvenue from '../../../components/Logements/bienvenue/Gps.bienvenue.jsx';
import ArriveeBienvenue from '../../../components/Logements/bienvenue/Arrivee.bienvenue.jsx';
import BoiteCleBienvenue from '../../../components/Logements/bienvenue/BoiteCle.bienvenue.jsx';
import InternetBienvenue from '../../../components/Logements/bienvenue/Internet.bienvenue.jsx';
import ChauffageBienvenue from '../../../components/Logements/bienvenue/Chauffage.bienvenue.jsx';
import JardinBienvenue from '../../../components/Logements/bienvenue/Jardin.bienvenue.jsx';
import ContactUrgence from '../../../components/Logements/ContactUrgence.jsx';
import DechetsBienvenue from '../../../components/Logements/bienvenue/Dechets.bienvenue.jsx';
import DurantSejourBienvenue from '../../../components/Logements/bienvenue/DurantSejour.bienvenue.jsx';
import DepartBienvenue from '../../../components/Logements/bienvenue/Depart.bienvenue.jsx';
import MerciBienvenue from '../../../components/Logements/bienvenue/Merci.bienvenue.jsx';
import logoSer1 from '../../../assets/img/logo/logo__ma-maison-sereine.png';

export default function BienvenueCIHome() {
    const translate = useSelector((store) => store.Template.language);

    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

    const weekNumber = Math.ceil(days / 7);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    document.title =
        'Bienvenue à la Maison C&I Home à Pornic | Ma Maison Sereine';
    document.description =
        'Découvrez les recommandations pour profiter au mieux de votre logement lors de votre séjour.';
    const [className] = useState(
        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
    );

    return (
        <>
            <Header />
            <main className="bg-primary ">
                <div className="d-flex flex-row justify-content-around align-items-center pt-4 ">
                    <img
                        src={logoSer1}
                        alt={'Logo - logoSer1'}
                        title={'Logo - logoSer1'}
                        loading="lazy"
                        width={'180px'}
                        className="card-location-logo mt-3 rounded-2 border border-1 border-primary"
                    />
                    <div className=" screen-none_qrcode ">
                        <img
                            src={qrcodelogement}
                            alt="QRCode Maison C&I Home"
                            width={'190px'}
                            className="card-location-logo mt-4 pt-3 "
                        />
                        <small className="text-white mt-2">
                            Version Mobile & <span>English Version</span>{' '}
                            <div className="fi fi-gb"></div>
                        </small>
                    </div>
                </div>
                <TitleBienvenue
                    className={className}
                    title={'Maison C&I Home à Pornic'}
                />
                <GpsBienvenue
                    className={className + ' no-print'}
                    address={'16 Rue des Perrières 44210 Pornic'}
                    gpsGoogle={'https://maps.app.goo.gl/YzbDK13VJqKhWBqi9'}
                    gpsApple={
                        'https://maps.apple.com/?address=16%20Rue%20des%20Perri%C3%A8res,%2044210%20Pornic,%20France&ll=47.113973,-2.108492&q=16%20Rue%20des%20Perri%C3%A8res'
                    }
                    gpsWaze={
                        'https://ul.waze.com/ul?ll=47.11405795%2C-2.10838795&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location'
                    }
                />
                <ArriveeBienvenue
                    className={className}
                    hourFr={'17h00'}
                    hourEn={'5p.m.'}
                    parkingFr={
                        <p>
                            Diverses places de parking (gratuites) sont
                            disponibles dans la rue.
                        </p>
                    }
                    parkingEn={
                        <p>
                            Various (free) parking spaces are available on the
                            street.
                        </p>
                    }
                />
                <BoiteCleBienvenue
                    className={className}
                    boiteFr={
                        <>
                            <p>
                                Vous trouvez la boîte à clé pour votre arrivée
                                autonome au niveau l'arrière de la maison, sur
                                la terasse, derrière la table de ping pong.{' '}
                                <br />
                                Le code vous a été envoyé par Sms ou par Mail
                            </p>
                            <p>
                                2 jeux de clés vous sont remis au début de votre
                                séjour comprenant chacun :
                            </p>
                            <ul>
                                <li>1 clé de la porte d’entrée</li>
                                <li> 1 clé de la porte de la buanderie </li>
                                <li> 1 clé de la porte du cabanon</li>
                            </ul>
                            <p>
                                Pour la sécurité, Nous vous remercions de
                                veiller à bien fermer à clé toutes les portes et
                                verrouiller l’ensemble des baies vitrées et
                                fenêtres lorsque vous quittez la maison. Pour
                                votre sécurité des détecteurs de fumée sont
                                installés au rez-au-chaussée et à l’étage.
                            </p>
                        </>
                    }
                    boiteEn={
                        <>
                            <p>
                                You find the key box for your arrival autonomous
                                at the back of the house, on the terrace, behind
                                the ping pong table. <br />
                                The code was sent to you by SMS or Email
                            </p>
                            <p>
                                2 sets of keys are given to you at the start of
                                your stay including each:
                            </p>
                            <ul>
                                <li>1 front door key</li>
                                <li> 1 key to the laundry room door </li>
                                <li> 1 key to the shed door</li>
                            </ul>
                            <p>
                                For safety, we thank you for be sure to lock all
                                doors and lock all the bay windows and windows
                                when you leave the house. For your safety smoke
                                detectors are installed on the ground floor and
                                upstairs.
                            </p>
                        </>
                    }
                />
                <InternetBienvenue
                    className={className}
                    access={true}
                    wifiName={'Pornic_Home'}
                    wifiPass={'Pornic_Vibes'}
                    infoFr={
                        <>
                            <p>
                                Vous pouvez vous connecter au réseau Wifi du
                                logement.
                            </p>
                        </>
                    }
                    infoEn={
                        <>
                            <p>
                                You can connect to the Wifi network of the house
                            </p>
                        </>
                    }
                    qrcode={qrcode_Wifi}
                />
                <ChauffageBienvenue
                    className={className}
                    consigneFr={
                        <>
                            <p>
                                Le Chauffage est automatique mais vous pouvez
                                augmenter ou baisser la température de consigne
                                en cas de besoins avec les touches + et - sur
                                les radiateurs.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Merci aux petits techniciens curieux de ne pas
                                toucher aux autres boutons (ni aux disjoncteurs
                                ! )
                            </p>

                            <p className="text-center fw-bold text-primary">
                                L'Energie est notre Avenir. Economisons-là
                            </p>
                        </>
                    }
                    consigneEn={
                        <>
                            <p>
                                Heating is automatic but you can increase or
                                lower the set temperature if necessary with the
                                + and - keys on the radiators.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Thank you to the curious little technicians not
                                to touch the others buttons (or the circuit
                                breakers! )
                            </p>

                            <p className="text-center fw-bold text-primary">
                                Energy is our Future. Let's save it
                            </p>
                        </>
                    }
                    dijoncteurFR={
                        'dans la buanderie. Merci de ne pas toucher au boitier Enedis Vert qui se trouve à côté.'
                    }
                    dijoncteurEN={
                        'in the laundry room. Please do not touch the Enedis Vert box which is next to it.'
                    }
                    linkyFR={'à côté'}
                    linkyEN={'at the same place'}
                />
                <JardinBienvenue
                    className={className}
                    jardinFr={
                        <>
                            <p>Vous avez libre accès au jardin.</p>
                            <p>
                                Comme dans tous les quartiers d’habitations,
                                attention aux bruits démesurés, principalement
                                dans le jardin en soirée. Je rappelle que les
                                fêtes sont interdites.
                            </p>
                            <p>
                                Vous pouvez fumer à l'extérieur de la maison,
                                sur la terrasse, dans le jardin. Merci de
                                ramasser vos mégots de cigarette. Il est
                                interdit de fumer à l'intérieur de la maison.
                            </p>
                            <p>
                                Vous trouverez dans le cabanon un barbecue, des
                                transats et des jeux de plage.
                            </p>
                            <p className="font-bold">
                                Pour profiter pleinement de votre séjour, tout
                                le nécessaire est à votre disposition pour vous
                                détendre et déjeuner/dîner à l'extérieur. Nous
                                vous remercions de ranger les fauteuils, chaises
                                et table en fin de journée. La table de
                                ping-pong reste à l'extérieur.
                            </p>
                        </>
                    }
                    jardinEn={
                        <>
                            <p>You have free access to the garden.</p>
                            <p>
                                As in all living quarters, pay attention to
                                excessive noise, mainly in the garden in the
                                evening. I remember that the holidays are
                                prohibited.
                            </p>
                            <p>
                                You can smoke outside the house, on the terrace,
                                in the garden. Please pick up your cigarette
                                butts. Smoking is prohibited inside the house.
                            </p>
                            <p>
                                In the shed you will find a barbecue, deckchairs
                                and beach games.
                            </p>
                            <p className="font-bold">
                                To fully enjoy your stay, everything you need is
                                available for you to relax and have lunch/dinner
                                outside. We thank you for putting away the
                                armchairs, chairs and table at the end of your
                                day. The table tennis table remains outside.
                            </p>
                        </>
                    }
                    light={false}
                />
                <DechetsBienvenue
                    className={className}
                    dechetsFr={
                        <>
                            <p>
                                Les poubelles se trouvent au niveau du portail
                            </p>
                            <p className="text-start">
                                Est-ce possible, svp, de mettre les poubelles
                                sur le trottoir pour le ramassage des ordures :
                            </p>
                            <ul>
                                <li>Le Dimanche Soir : La poubelle Noire</li>
                                <li>
                                    Le mercredi Soir en semaine paire : La
                                    poubelle Jaune (en Eté toutes les semaines)
                                </li>
                            </ul>
                            <p className="text-start no-print">
                                Nous sommes en semaine {weekNumber} soit une
                                semaine{' '}
                                {weekNumber % 2 === 0 ? 'paire' : 'impaire'}
                            </p>
                        </>
                    }
                    dechetsEn={
                        <>
                            <p>The trash cans are located at the gates.</p>
                            <p className="text-start">
                                Is it possible to put the trash cans on the
                                sidewalk for garbage collection:
                            </p>
                            <ul>
                                <li>Sunday Evening: The Black Trash Can</li>
                                <li>
                                    Wednesday Evening on week pair: The Yellow
                                    Trash Can (On summer, every week)
                                </li>
                            </ul>
                            <p className="text-start no-print">
                                We are on number week {weekNumber} :
                                {weekNumber % 2 === 0 ? 'pair' : 'impair'} week
                            </p>
                        </>
                    }
                />
                <ContactUrgence className="bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 print-cut-none " />
                <DurantSejourBienvenue
                    className={
                        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
                    }
                    duringFr={
                        <>
                            <p>
                                La Maison est située en quartier Calme de
                                Pornic, vous trouverez tout à proximité pour
                                passer d’excellentes vacances. Le Marché de
                                Pornic se tient tous les jeudis matin et
                                dimanches matin sur la place du marché.
                            </p>
                        </>
                    }
                    duringEn={
                        <>
                            <p>
                                The House is located in the Calm district of
                                Pornic, you will find everything nearby to spend
                                excellent vacation. The Pornic Market held every
                                Thursday morning and Sunday morning in the
                                market square.
                            </p>
                        </>
                    }
                />
                <DepartBienvenue
                    className={className}
                    hourFr={'10h00'}
                    hourEn={'10a.m.'}
                    remiseCleFr={
                        'Merci de bien tout fermer et de mettre les clés dans la boite à clés et de veiller à brouiller le code.'
                    }
                    remiseCleEn={
                        'Please close everything well and put the keys in the key box and make sure to scramble the code.'
                    }
                    alertFr={
                        <>
                            La maison doit témoigner d’un entretien régulier
                            durant le séjour. Des produits ménagers sont à votre
                            disposition sous l'évier dans la buanderie. Si la
                            maison n'est pas rendue propre, nous faisons faire
                            le ménage par une entreprise spécialisée et
                            déduisons le montant total de la facture sur le
                            remboursement du dépôt de garantie.
                        </>
                    }
                    alertEn={
                        <>
                            The house must demonstrate regular maintenance
                            during the stay. Household products are available
                            under the sink in the laundry room. If the house is
                            not left clean, we have a specialist company clean
                            it and deduct the full amount from the security
                            deposit refund.
                        </>
                    }
                    forfaitMenageFr={
                        'Vous devez tout de même rendre le logement dans un état acceptable, vider vos poubelles ainsi que votre vaisselle propre et rangée. '
                    }
                    forfaitMenageEn={
                        'You must still leave the accommodation in an acceptable condition, empty your trash and your dishes clean and tidy'
                    }
                    faireMenageFr={
                        <>
                            <p>
                                Face au grand nombre d’incivilité et pour le
                                respect de nos techniciens, merci de bien
                                l’effectuer sous peine de litige.
                            </p>
                            <p>
                                « Sur une journée d’affluence un technicien peut
                                avoir jusqu’à 8 lieux à vérifier/nettoyer,
                                imaginer qu’il ne peut à chaque location refaire
                                le ménage… le tout entre 10h00 et 16h00 ! Soyons
                                donc tous vigilent et respectueux ! » Fabien.
                            </p>
                        </>
                    }
                    faireMenageEn={
                        <>
                            <p>
                                Faced with the large number of incivilities and
                                for the respect for our technicians, thank you
                                very much do so under penalty of litigation.
                            </p>
                            <p>
                                “On a busy day a technician can have up to 8
                                places to check/clean, imagine that he cannot at
                                each rental redo the housework… all between
                                10:00 a.m. and 4:00 p.m.! So let's all be
                                vigilant and respectful! » Fabian.
                            </p>
                        </>
                    }
                    basesMenageFr={
                        <>
                            Aérer chaque pièce.
                            <ul>
                                <li>
                                    Dans les chambres : merci de retirer les
                                    draps (les mettre dans un sac en tissu) et
                                    nettoyer sol * et surfaces.
                                </li>
                                <li>
                                    Dans la salle de bain : vider la poubelle,
                                    mettre les serviettes dans le sac ainsi que
                                    le tapis. Nettoyer sol * et surfaces, bien
                                    essuyer la douche, lavabo, toilettes.
                                </li>
                                <li>
                                    Dans la cuisine : vider la poubelle,
                                    nettoyer le réfrigérateur, micro-ondes,
                                    four, surfaces, sol *…… <br />
                                    Mettre toute la vaisselle utilisée propre en
                                    évidence sur le plan de travail. Rendre la
                                    cuisine propre.
                                </li>
                                <li>
                                    Dans les pièces de vie : nettoyer les
                                    surfaces et sol *.
                                </li>
                            </ul>
                            <p>
                                * : Le nettoyage des sols consiste à aspirer et
                                laver avec la serpillère.
                            </p>
                        </>
                    }
                    basesHouseholdEn={
                        <>
                            Ventilate each room.
                            <ul>
                                <li>
                                    In the rooms: please remove the sheets (put
                                    them in a cloth bag) and clean floor * and
                                    surfaces.
                                </li>
                                <li>
                                    In the bathroom: empty the trash can, put
                                    the towels in the bag as well as the carpet.
                                    Clean floor * and surfaces, wipe the shower
                                    well, sink, toilet.
                                </li>
                                <li>
                                    In the kitchen: empty the trash, clean the
                                    refrigerator, microwave, oven, surfaces,
                                    floor *…… <br />
                                    Put all the dishes used clearly visible on
                                    the plan of work. Make the kitchen clean.
                                </li>
                                <li>
                                    In the living rooms: clean the surfaces and
                                    floor *.
                                </li>
                            </ul>
                            <p>
                                *: Floor cleaning involves vacuuming and wash
                                with the mop.
                            </p>
                        </>
                    }
                />
                <MerciBienvenue
                    className={className}
                    merciFr={
                        <>
                            Mon équipe et moi-même (et oui je fais du ménage
                            aussi) vous remercions par avance de votre
                            investissement dans cette tâche qui est la moins
                            drôle des vacances, mais qui fera notre sourire
                            <br />
                            <br />
                            Merci et un bon retour à vous !
                        </>
                    }
                    merciEn={
                        <>
                            My team and myself (and yes I do cleaning too) thank
                            you in advance for your investment in this task
                            which is the less funny of the holidays, but which
                            will make our smile <br />
                            <br />
                            Thank you and welcome back !
                        </>
                    }
                />

                <section className="sect__widgets">
                    <CardHomeWidgetsBottom />
                </section>
            </main>
        </>
    );
}
