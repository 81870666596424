import { useSelector } from 'react-redux';
import { IoIosWifi } from 'react-icons/io';

import { BsRouter } from 'react-icons/bs';

export default function InternetBienvenue(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className={props.className}>
            <h2 className="text-center m-3">
                <span className="m-1 fs-1">
                    {' '}
                    <IoIosWifi />
                </span>
                {translate === 'French' && <>Accès Internet</>}
                {translate === 'English' && <>Internet access</>}
            </h2>
            <div className="d-flex flex-row justify-content-around align-items-center flex-wrap">
                {props.access === true ? (
                    <div>
                        {translate === 'French' && (
                            <>
                                <p>
                                    Vous pouvez vous connecter au réseau Wifi
                                    durant votre voyage.
                                </p>

                                <p>Voici les Codes d'accès :</p>
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                <p>
                                    You can connect to the Wifi network during
                                    your trip.
                                </p>

                                <p>Here are the Access Codes:</p>
                            </>
                        )}
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <span className="m-1 fs-1">
                                {' '}
                                <BsRouter />
                            </span>
                            <span className="m-2">
                                {translate === 'French' && (
                                    <>Réseau : {props.wifiName}</>
                                )}
                                {translate === 'English' && (
                                    <>Network : {props.wifiName}</>
                                )}
                            </span>
                            <span className="m-1">
                                {translate === 'French' && (
                                    <>Code : {props.wifiPass}</>
                                )}
                                {translate === 'English' && (
                                    <>Code : {props.wifiPass}</>
                                )}
                            </span>
                            <span className="mt-2">
                                {translate === 'French' && <>{props.infoFr}</>}
                                {translate === 'English' && <>{props.infoEn}</>}
                            </span>
                        </div>
                    </div>
                ) : (
                    <div>
                        {translate === 'French' && (
                            <p>
                                Ce logement ne dispose pas de box internet à
                                votre disposition.
                            </p>
                        )}
                        {translate === 'English' && (
                            <p>
                                This accommodation does not have an internet box
                                at your disposal.
                            </p>
                        )}
                    </div>
                )}

                {props.qrcode !== undefined && (
                    <div className="d-flex flex-column justify-content-around align-items-center text-center">
                        <img
                            src={props.qrcode}
                            alt={'QRcode de connexion Wifi'}
                            title={'Qrcode Wifi'}
                            loading="lazy"
                            width={'250px'}
                            className="card-location-logo rounded-4"
                        />

                        <small>QrCode Acces Wifi</small>
                    </div>
                )}
            </div>
        </div>
    );
}
