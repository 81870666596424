import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export default function useFetchGetListingVoyageur() {
    const [dataVoyageur, setData] = useState(undefined);
    const [isLoadingVoyageurListing, setLoading] = useState(true);
    const [nberPhone] = useState(
        localStorage.getItem('authVoyageurPort')
            ? localStorage.getItem('authVoyageurPort')
            : undefined
    );
    const [codeSMSActive] = useState(
        localStorage.getItem('authVoyageurCode')
            ? localStorage.getItem('authVoyageurCode')
            : undefined
    );
    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/locations/voyageur/${nberPhone}/${codeSMSActive}/listing/`,
                    {
                        method: 'get',
                        headers: new Headers({
                            'content-type': 'application/json',
                        }),
                    }
                );
                const data = await response.json();

                if (response.status === 200) {
                    setData(data);
                    setLoading(false);
                } else if (response.status === 404) {
                    toast.error(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setLoading(false);
                } else if (response.status === 415) {
                    toast.error(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setLoading(false);
                } else if (response.status === 500) {
                    toast.error(
                        'Le serveur distant API Voyageur est en erreur',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible d'obtenir la liste de vos réservations ",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        fetchData();
    }, [codeSMSActive, nberPhone]);
    return { dataVoyageur, isLoadingVoyageurListing };
}
