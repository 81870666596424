import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import LoadingK from '../Widget/LoadingK.jsx';

export default function WidgetPackLinge(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className="listing__linge">
            {translate === 'French' && (
                <h3 className="fs-2">
                    <u>Le Linge de Lit : </u>{' '}
                </h3>
            )}
            {translate === 'English' && (
                <h3 className="fs-2">
                    <u>bed linen: </u>{' '}
                </h3>
            )}

            <p>
                {translate === 'French' &&
                    "Afin de proposer un tarif de nuité plus faible pour tous, le linge de lit n'est plus inclus et est en option "}
                {translate === 'English' &&
                    'In order to offer a lower nightly rate for all, bed linen is no longer included and is optional '}
            </p>
            <div className="table-responsive">
                <Table
                    bordered
                    hover
                    className="text-center bg-light rounded border border-primary "
                >
                    <tbody>
                        <tr>
                            <th className="bg-primary text-white text-center pt-5">
                                Pack
                            </th>

                            <th className="bg-primary text-white">
                                <span>2pers. (140)</span>
                            </th>
                            <th className="bg-primary text-white">
                                <span>1pers. (90)</span>
                            </th>
                        </tr>
                        <tr>
                            <th scope="row">Couleur (standard)</th>
                            <td className="text-primary">
                                {props.dataLocationSettings !== undefined ? (
                                    <span>
                                        {
                                            props.dataLocationSettings
                                                .price_linge_2p_couleur
                                        }
                                        {' € / '}
                                        {translate === 'French' && <>lit</>}
                                        {translate === 'English' && <>bed</>}
                                    </span>
                                ) : (
                                    <LoadingK />
                                )}
                            </td>
                            <td className="text-primary">
                                {props.dataLocationSettings !== undefined ? (
                                    <span>
                                        {
                                            props.dataLocationSettings
                                                .price_linge_1p_couleur
                                        }
                                        {' € / '}
                                        {translate === 'French' && <>lit</>}
                                        {translate === 'English' && <>bed</>}
                                    </span>
                                ) : (
                                    <LoadingK />
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Blanc Hôtelier (premium)</th>

                            <td className="text-primary">
                                {props.dataLocationSettings !== undefined ? (
                                    <span>
                                        {
                                            props.dataLocationSettings
                                                .price_linge_2p_blanc
                                        }
                                        {' € / '}
                                        {translate === 'French' && <>lit</>}
                                        {translate === 'English' && <>bed</>}
                                    </span>
                                ) : (
                                    <LoadingK />
                                )}
                            </td>
                            <td className="text-primary">
                                {props.dataLocationSettings !== undefined ? (
                                    <span>
                                        {
                                            props.dataLocationSettings
                                                .price_linge_1p_blanc
                                        }
                                        {' € / '}
                                        {translate === 'French' && <>lit</>}
                                        {translate === 'English' && <>bed</>}
                                    </span>
                                ) : (
                                    <LoadingK />
                                )}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            {translate === 'French' && (
                <small>
                    Chaque pack comprend : drap + housse de couette + taie(s) +
                    serviette(s) de toilette
                </small>
            )}
            {translate === 'English' && (
                <small>
                    Each pack includes: sheet + duvet cover + pillowcase(s) +
                    towel(s)
                </small>
            )}
            <hr />
        </div>
    );
}
