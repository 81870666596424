import accueil from '../../../assets/img/Maison-charme-gourmalon/photos/accueil.jpeg';
import maisonRue from '../../../assets/img/Maison-charme-gourmalon/photos/portail.png';
import jardin from '../../../assets/img/Maison-charme-gourmalon/photos/jardin.jpg';
import entree from '../../../assets/img/Maison-charme-gourmalon/photos/entree.jpg';
import salon from '../../../assets/img/Maison-charme-gourmalon/photos/salon.jpg';
import salon2 from '../../../assets/img/Maison-charme-gourmalon/photos/salon2.jpg';
import cuisine from '../../../assets/img/Maison-charme-gourmalon/photos/cuisine.jpg';
import couloir from '../../../assets/img/Maison-charme-gourmalon/photos/couloir.jpg';
import chambre1 from '../../../assets/img/Maison-charme-gourmalon/photos/chambre1.jpg';
import chambre2 from '../../../assets/img/Maison-charme-gourmalon/photos/chambre2.jpg';
import douche from '../../../assets/img/Maison-charme-gourmalon/photos/douche.jpg';
import douche2 from '../../../assets/img/Maison-charme-gourmalon/photos/douche2.jpg';
import wc from '../../../assets/img/Maison-charme-gourmalon/photos/wc.jpg';
import entreeCuisine from '../../../assets/img/Maison-charme-gourmalon/photos/IMG_5158.jpeg';
import chambre3 from '../../../assets/img/Maison-charme-gourmalon/photos/chambre3.jpg';

export default function imagesMaisonCharmeGourmalon() {
    const listing = [
        {
            original: accueil,
            description: 'Espace Salon',
            descriptionEn: ' Lounge Area',
        },
        {
            original: maisonRue,
            description: 'Maison vue de la Rue',
            descriptionEn: 'House seen from the Street',
        },
        {
            original: jardin,
            description: 'Jardin avec terasse en bois',
            descriptionEn: 'Garden with wooden terrace',
        },
        {
            original: entree,
            description: 'Entrée de la Maison',
            descriptionEn: 'Entrance to the House',
        },
        {
            original: salon,
            description: 'Salon avec TV',
            descriptionEn: 'Living room with TV',
        },
        {
            original: salon2,
            description: 'Salon avec TV',
            descriptionEn: 'Living room with TV',
        },
        {
            original: cuisine,
            description:
                'Cuisine équipée avec plaque induction, four, micro-ondes, lave-vaisselle, etc.',
            descriptionEn:
                'Kitchen equipped with induction hob, oven, microwave, dishwasher, etc.',
        },
        {
            original: entreeCuisine,
            description: 'Entrée de la Maison avec la salle à Manger',
            descriptionEn: 'Entrance to the House',
        },
        {
            original: couloir,
            description: "Couloir d'accès aux chambres et à l'étage",
            descriptionEn:
                'Corridor providing access to the bedrooms and upstairs',
        },
        {
            original: chambre1,
            description: 'Chambre 1 avec lit double 140x190',
            descriptionEn: 'Bedroom 1 with double bed 140x190',
        },
        {
            original: chambre2,
            description: 'Chambre 2 avec 2 lit simple 90x190',
            descriptionEn: 'Bedroom 2 with 2 single beds 90x190',
        },
        {
            original: chambre3,
            description: "Chambre 3 avec lit double 140x190 à l'étage",
            descriptionEn: 'Bedroom 3 with double bed 140x190 upstairs',
        },
        {
            original: douche,
            description:
                'Salle de Douche avec Lave-linge intégré dans le meuble',
            descriptionEn:
                'Shower room with washing machine integrated into the unit',
        },
        {
            original: douche2,
            description:
                'Salle de Douche avec Lave-linge intégré dans le meuble',
            descriptionEn:
                'Shower room with washing machine integrated into the unit',
        },
        {
            original: wc,
            description: 'Toilette indépendant',
            descriptionEn: 'Independent toilet',
        },
    ];
    return listing;
}
