import { useSelector } from 'react-redux';

import MiniCardLogement from './MiniCard.logement.jsx';
import SSlesPinHomelogo from '../../assets/img/SSlesPins/logo__SSlesPins-home.jpg';
import SSlesPinChlogo from '../../assets/img/SSlesPins/logo__SSlesPins-ch.jpeg';
import Pure from '../../assets/img/Pure/logo_pure.jpg';
import PecherieLogo from '../../assets/img/Pecherie/pecherie_logo.jpeg';
import MaisonCharmeGourmalon from '../../assets/img/Maison-charme-gourmalon/logo-maison-charme-gourmalon.jpg';
import VillaRose from '../../assets/img/logo/logo__ma-maison-sereine.png';

export default function MiniListingLogement(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <>
            {props.id === 1583795 && (
                <MiniCardLogement
                    to={'/locations/sous-les-pins/maison/'}
                    title={'Sous Les Pins'}
                    subtitle={'La Maison'}
                    logo={SSlesPinHomelogo}
                    logoWidth={'80px'}
                    classTitle={''}
                    classNameLink={'card__link'}
                    classNameArticle={'mini-card__loge card__loge-SSpinsHome'}
                    typeLogement={
                        translate === 'French'
                            ? 'Maison Complète'
                            : 'Full House'
                    }
                    nbrVoyageurs={6}
                    parking={
                        translate === 'French'
                            ? 'Parking Privatif'
                            : 'Private Parking'
                    }
                    garden={
                        translate === 'French'
                            ? 'Un Jardin avec Plancha Gaz, Veranda'
                            : 'A Garden with Gas Plancha, Veranda'
                    }
                    city={'Préfailles 44770'}
                    logementHost={'José'}
                />
            )}
            {props.id === 1583795 && (
                <MiniCardLogement
                    to={'/locations/sous-les-pins/maison/'}
                    title={'Sous Les Pins'}
                    subtitle={'La Maison'}
                    logo={SSlesPinHomelogo}
                    logoWidth={'80px'}
                    classTitle={''}
                    classNameLink={'card__link'}
                    classNameArticle={'mini-card__loge card__loge-SSpinsHome'}
                    typeLogement={
                        translate === 'French'
                            ? 'Maison Complète'
                            : 'Full House'
                    }
                    nbrVoyageurs={6}
                    parking={
                        translate === 'French'
                            ? 'Parking Privatif'
                            : 'Private Parking'
                    }
                    garden={
                        translate === 'French'
                            ? 'Un Salon extérieur à disposition'
                            : 'An outdoor lounge available'
                    }
                    city={'Préfailles 44770'}
                    logementHost={'José'}
                />
            )}

            {props.id === 1711442 && (
                <MiniCardLogement
                    to={'/locations/pure/'}
                    title={'Pure'}
                    logo={Pure}
                    logoWidth={'80px'}
                    classTitle={''}
                    classNameLink={'card__link'}
                    classNameArticle={'mini-card__loge card__loge-Pure'}
                    typeLogement={
                        translate === 'French'
                            ? 'Appartement Complet'
                            : 'Complete Apartment'
                    }
                    nbrVoyageurs={6}
                    parking={
                        translate === 'French'
                            ? '1 Place de Parking en résidence'
                            : '1 Parking Space in residence'
                    }
                    city={'Pornic 44770'}
                    logementHost={'Jacqueline'}
                />
            )}
            {props.id === 1688317 && (
                <MiniCardLogement
                    to={'/locations/pecherie/'}
                    title={'Au bord des Pêcheries'}
                    logo={PecherieLogo}
                    logoWidth={'80px'}
                    classTitle={''}
                    classNameLink={'card__link'}
                    classNameArticle={'mini-card__loge card__loge-Pecherie'}
                    typeLogement={
                        translate === 'French'
                            ? 'Maison Complète'
                            : 'Full House'
                    }
                    nbrVoyageurs={6}
                    parking={
                        translate === 'French'
                            ? 'Parking Privatif'
                            : 'Private Parking'
                    }
                    garden={
                        translate === 'French'
                            ? 'Un Salon extérieur à disposition'
                            : 'An outdoor lounge available'
                    }
                    city={'La Plaine Sur Mer 44770'}
                    logementHost={'Claudine'}
                />
            )}
            {props.id === 1983689 && (
                <MiniCardLogement
                    to={'/locations/Maison-charme-gourmalon/'}
                    title={'Maison de charme au Coeur de Gourmalon'}
                    logo={MaisonCharmeGourmalon}
                    logoWidth={'80px'}
                    classTitle={''}
                    classNameLink={'card__link'}
                    classNameArticle={
                        'mini-card__loge card__loge-MaisonCharmeGourmalon'
                    }
                    typeLogement={
                        translate === 'French'
                            ? 'Maison Complète'
                            : 'Full House'
                    }
                    nbrVoyageurs={6}
                    garden={
                        translate === 'French'
                            ? 'Un Salon extérieur à disposition'
                            : 'An outdoor lounge available'
                    }
                    city={'Pornic 44210'}
                    logementHost={'Thomas'}
                />
            )}
            {props.id === 2043386 && (
                <MiniCardLogement
                    to={'/locations/villarose/'}
                    title={'VillaRose'}
                    logo={VillaRose}
                    logoWidth={'80px'}
                    classTitle={''}
                    classNameLink={'card__link'}
                    classNameArticle={'mini-card__loge card__loge-VillaRose'}
                    typeLogement={
                        translate === 'French'
                            ? 'Maison Complète'
                            : 'Full House'
                    }
                    nbrVoyageurs={9}
                    garden={
                        translate === 'French'
                            ? 'Terrasse vue Mer'
                            : 'Sea view terrace'
                    }
                    city={'Préfailles 44770'}
                    logementHost={'Julie'}
                />
            )}
            {props.id === 1983065 && (
                <MiniCardLogement
                    to={'/locations/c&i-home/'}
                    title={'Maison C&I Home'}
                    logo={VillaRose}
                    logoWidth={'80px'}
                    classTitle={''}
                    classNameLink={'card__link'}
                    classNameArticle={'mini-card__loge card__loge-CIHome'}
                    typeLogement={
                        translate === 'French'
                            ? 'Maison Complète'
                            : 'Full House'
                    }
                    nbrVoyageurs={8}
                    garden={translate === 'French' ? 'Terrasse' : 'Terrace'}
                    city={'Pornic 44210'}
                    logementHost={'Claire & Isabelle'}
                />
            )}
            {props.id === 2051344 && (
                <MiniCardLogement
                    to={'/locations/les-pieds-dans-l-eau/'}
                    title={"Les Pieds Dans L'Eau"}
                    logo={VillaRose}
                    logoWidth={'80px'}
                    classTitle={''}
                    classNameLink={'card__link'}
                    classNameArticle={
                        'mini-card__loge card__loge-PiedsDansLeau'
                    }
                    typeLogement={
                        translate === 'French'
                            ? 'Maison Complète'
                            : 'Full House'
                    }
                    nbrVoyageurs={8}
                    garden={
                        translate === 'French'
                            ? 'Terrasse Vue Mer'
                            : 'Sea View Terrace'
                    }
                    city={'La Plaine Sur Mer 44770'}
                    logementHost={'Sébastien'}
                />
            )}
        </>
    );
}
