import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FaPersonWalkingLuggage } from 'react-icons/fa6';

import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import Carrousel from '../../../components/Logements/Caroussel.jsx';
import ListingDetailsCIHome from '../../../components/Logements/CIHome/ListingDetailsCIHome.jsx';

export default function PageCIHome(props) {
    document.title = `Location '${props.title}' à Pornic 44210 | Ma Maison Sereine`;
    const translate = useSelector((store) => store.Template.language);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <main className="page_logement">
            <article className="page_logement__content">
                <Carrousel images={props.images} />
                <img
                    src={props.logo}
                    alt={props.title}
                    title={props.title}
                    loading="lazy"
                    width={'160px'}
                    className="card-location-logo"
                />
                <div className="fs-2">
                    <h1>
                        {translate === 'French' && (
                            <>Location - {props.title}</>
                        )}
                        {translate === 'English' && <>Rental - {props.title}</>}
                    </h1>
                    <h2>
                        {translate === 'French' && (
                            <>
                                Maison Complète pour 8 voyageurs - 3 chambres -
                                3 lits - 3 salles de douche
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                Complete House for 8 travelers - 3 bedrooms - 3
                                beds - 3 shower rooms
                            </>
                        )}
                    </h2>
                    <h3>
                        {translate === 'French' && (
                            <>
                                Maison familiale avec jardin à 3 min à pied de
                                la mer et du château
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                Family house with garden 3 minutes walk from the
                                sea and the castle
                            </>
                        )}
                    </h3>
                </div>
                <div className="bg-primary hr-h my-4"></div>
                {translate === 'French' && (
                    <>
                        <p>
                            Maison de famille à 400 mètres du centre historique
                            de Pornic. Accès facile aux plages, chemin côtier,
                            commerces de proximité, bars et restaurants. Maison
                            dotée d'un bel extérieur: grande terrasse d'angle et
                            grand jardin pour profiter pleinement de votre
                            séjour.
                        </p>
                        <p>
                            A votre arrivée, les lits sont faits, tapis de bain,
                            serviettes de toilette, torchons, quelques
                            ingrédients de base pour le début des vacances et
                            petites attentions mises à disposition.
                        </p>
                        <p>
                            Chaque chambre a sa propre salle d’eau avec évier
                            double vasque , douche, sèche serviette et sèches
                            cheveux.
                        </p>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <p>
                            Family house 400 meters from the historic center of
                            Pornic. Easy access to beaches, coastal path, local
                            shops, bars and restaurants. House with a beautiful
                            exterior: large corner terrace and large garden to
                            fully enjoy your stay.
                        </p>
                        <p>
                            On your arrival, the beds are made, bath mats,
                            towels, tea towels, some basic ingredients for the
                            start of the vacation and little touches provided.
                        </p>
                        <p>
                            Each bedroom has its own bathroom with double sink,
                            shower, towel dryer and hair dryer.
                        </p>
                    </>
                )}
                <div className="bg-primary hr-h my-4"></div>
                <ListingDetailsCIHome />
                <div className="bg-primary hr-h my-4"></div>
                <Carrousel images={props.images} active={true} />
                <div className="bg-primary hr-h my-4"></div>
                <div className="d-flex flex-row flex-wrap">
                    <div className="d-block d-md-none bg-primary hr-h my-4"></div>
                    <div className="calendarWidget locations__localisation mx-auto">
                        <h2 className="fs-1 mb-3 text-center">
                            {translate === 'French' && (
                                <>Calendrier de Réservation</>
                            )}
                            {translate === 'English' && <>Booking Calendar</>}
                        </h2>
                        <div>
                            <a
                                href={
                                    'https://www.abritel.fr/location-vacances/p2005662'
                                }
                                rel="noreferrer"
                            >
                                <Button variant="primary shadow m-2">
                                    <FaPersonWalkingLuggage className="fs-4 me-2" />
                                    {translate === 'French' && (
                                        <>Créer ma Réservation sur Abritel</>
                                    )}
                                    {translate === 'English' && (
                                        <>Create my Booking on Abritel</>
                                    )}
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
            </article>
            <section className="sect__widgets">
                <CardHomeWidgetsBottom />
            </section>
        </main>
    );
}
