import { useSelector } from 'react-redux';

import { Badge, Alert } from 'reactstrap';

import { useFetchGetLocationSettings } from '../../../utils/Api/FetchLocationSettings.jsx';
import WidgetPackLinge from '../WigdetPackLinge.jsx';
import WidgetPackMenage from '../WigdetPackMenage.jsx';

export default function ListingDetailsPure() {
    const translate = useSelector((store) => store.Template.language);
    const { dataLocationSettings } = useFetchGetLocationSettings();
    return (
        <div className="locations__description">
            {translate === 'French' && (
                <h2 className="fs-1">Composition du Logement</h2>
            )}
            {translate === 'English' && (
                <h2 className="fs-1">Housing Composition</h2>
            )}

            <div className="d-flex flex-row flex-wrap">
                <div className="d-flex flex-column col-12 col-lg-5 mx-auto">
                    <div className="listing__arrive">
                        {translate === 'French' && (
                            <h3 className="fs-2">
                                <u>Pour votre Arrivé :</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Inclus
                                </Badge>
                            </h3>
                        )}
                        {translate === 'English' && (
                            <h3 className="fs-2">
                                <u>For your Arrival:</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Included
                                </Badge>
                            </h3>
                        )}

                        <div className="composition__logement">
                            <div className="composition__logement_text">
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-key"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Arrivée sur RDV en présentiel avec
                                            Fabien
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Arrival by appointment in person
                                            with Fabien
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-car"></i>{' '}
                                    {translate === 'French' && (
                                        <>
                                            <span>
                                                <strong>
                                                    Place de Parkings
                                                </strong>{' '}
                                                privative dans la résidence
                                            </span>{' '}
                                            <small>
                                                Attention : pour les voitures
                                                longues l'accès au parking peut
                                                être compliqué, l'accès est
                                                étroit et la hauteur limitée.
                                            </small>
                                        </>
                                    )}
                                    {translate === 'English' && (
                                        <>
                                            <span>
                                                <strong>Parking space</strong>{' '}
                                                private in the residence
                                            </span>{' '}
                                            <small>
                                                Warning: for cars long access to
                                                the car park can be complicated,
                                                access is narrow and the height
                                                limited.
                                            </small>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listing__sejour">
                        {translate === 'French' && (
                            <h3 className="fs-2">
                                <u>Lors de votre séjour :</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Inclus
                                </Badge>
                            </h3>
                        )}
                        {translate === 'English' && (
                            <h3 className="fs-2">
                                <u>During your stay:</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Included
                                </Badge>
                            </h3>
                        )}

                        <div className="composition__logement">
                            <div className="composition__logement_text">
                                <div>
                                    <i className="fa-solid fa-house"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Appartement complet en résidence
                                            sécurisée et silencieuse
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Complete apartment in a secure and
                                            silent residence
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-map-pin me-2"></i>
                                    {translate === 'French' && (
                                        <span>En centre-ville de Pornic</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            In the city center of Pornic
                                        </span>
                                    )}
                                </div>

                                <div>
                                    <i className="fa-solid fa-panorama"></i>{' '}
                                    {translate === 'French' && (
                                        <span>Vue dégagée</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>Open View</span>
                                    )}
                                </div>

                                <div>
                                    <i className="fa-solid fa-house-signal"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Séjour/Cuisine Ouverte avec Tv &
                                            Wifi
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 Living room/Open kitchen with TV
                                            and Wifi
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-kitchen-set"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Cuisine équipée avec four,
                                            réfrigérateur, cafetière,
                                            bouilloire, micro-ondes avec
                                            lave-vaisselle et lave-linge dans la
                                            salle de bain
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 Kitchen equipped with oven,
                                            fridge, coffee maker, kettle,
                                            microwave with dishwasher and
                                            washing machine in the bathroom
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Chambre - Lit Double 140 avec
                                            Balcon
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 Bedroom - Double Bed 140 with
                                            Balcon
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>1 Chambre - Lit Double 140</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>1 Bedroom - Double Bed 140</span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>1 Chambre - 2 Lit 90</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>1 Bedroom - 2 Bed 90</span>
                                    )}
                                </div>

                                <div>
                                    {' '}
                                    <i className="fa-solid fa-shower"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Salle de bain avec Toilette
                                            indépendant
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 bathroom with separate toilet
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-hand-holding-heart"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Nous fournissons le linge (draps +
                                            serviettes) et les produits de base
                                            ( papier toilette, produits
                                            d'entretien, sel, huile etc.…), pour
                                            ainsi faciliter votre installation.
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            We provide linen (sheets + towels)
                                            and basic products (toilet paper,
                                            products maintenance, salt, oil,
                                            etc.), for thus facilitating your
                                            installation.
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column col-12 col-lg-5 mx-auto">
                    <WidgetPackLinge
                        dataLocationSettings={dataLocationSettings}
                    />
                    <WidgetPackMenage
                        dataLocationSettings={dataLocationSettings}
                        cat={2}
                    />

                    <Alert
                        className="my-3 text-center mx-auto"
                        color="primary"
                        isOpen={true}
                    >
                        <i className="fa-solid fa-circle-arrow-right"></i>
                        {translate === 'French' && (
                            <span>
                                Vous pourrez reserver nos différents services
                                lors de la réservation du logement.
                            </span>
                        )}
                        {translate === 'English' && (
                            <span>
                                You can book our different services when booking
                                accommodation.
                            </span>
                        )}
                    </Alert>
                </div>
            </div>
        </div>
    );
}
