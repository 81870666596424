import { useEffect, useState } from 'react';

import logoPecherie from '../../../assets/img/Pecherie/pecherie_logo.jpeg';
import qrcode_Pecherie from '../../../assets/qrcode/qrcode_Pecherie.png';
import qrcode_wifi_ser1 from '../../../assets/qrcode/qrcode_wifi_mms.png';

import accueil from '../../../assets/img/Pecherie/photo/maisonCoteRue.jpeg';
import accueilBoite from '../../../assets/img/Pecherie/photo/accueilBoite.jpeg';
import accueilBoite2 from '../../../assets/img/Pecherie/photo/accueilBoite2.jpeg';

import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import Header from '../../../components/Header/Header.jsx';
import TitleBienvenue from '../../../components/Logements/bienvenue/Title.bienvenue.jsx';
import GpsBienvenue from '../../../components/Logements/bienvenue/Gps.bienvenue.jsx';
import ArriveeBienvenue from '../../../components/Logements/bienvenue/Arrivee.bienvenue.jsx';
import BoiteCleBienvenue from '../../../components/Logements/bienvenue/BoiteCle.bienvenue.jsx';
import InternetBienvenue from '../../../components/Logements/bienvenue/Internet.bienvenue.jsx';
import ChauffageBienvenue from '../../../components/Logements/bienvenue/Chauffage.bienvenue.jsx';
import JardinBienvenue from '../../../components/Logements/bienvenue/Jardin.bienvenue.jsx';
import ContactUrgence from '../../../components/Logements/ContactUrgence.jsx';
import DechetsBienvenue from '../../../components/Logements/bienvenue/Dechets.bienvenue.jsx';
import DurantSejourBienvenue from '../../../components/Logements/bienvenue/DurantSejour.bienvenue.jsx';
import DepartBienvenue from '../../../components/Logements/bienvenue/Depart.bienvenue.jsx';
import MerciBienvenue from '../../../components/Logements/bienvenue/Merci.bienvenue.jsx';

export default function BienvenuePecherie() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    document.title = 'Bienvenue à "Au Bord des Pêcheries" | Ma Maison Sereine';
    document.description =
        'Découvrez les recommandations pour profiter au mieux de votre logement lors de votre séjour.';
    const [className] = useState(
        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
    );
    return (
        <>
            <Header />
            <main className="bg-primary ">
                <div className="d-flex  flex-row justify-content-around align-items-center pt-4">
                    <img
                        src={logoPecherie}
                        alt={'Logo - Pecherie'}
                        title={'Logo - Pecherie'}
                        loading="lazy"
                        width={'180px'}
                        className="card-location-logo mt-3 rounded-2 border border-1 border-primary"
                    />

                    <div className=" screen-none_qrcode">
                        <img
                            src={qrcode_Pecherie}
                            alt="QRCode Pecherie"
                            width={'190px'}
                            className="card-location-logo mt-4 pt-3"
                        />
                        <small className="text-white mt-2">
                            Version Mobile & <span>English Version</span>{' '}
                            <div className="fi fi-gb"></div>
                        </small>
                    </div>
                </div>
                <TitleBienvenue
                    className={className}
                    title={'Au Bord des Pêcheries'}
                />
                <GpsBienvenue
                    className={className + ' no-print'}
                    address={
                        '95, Boulevard de Port Giraud 44770 La Plaine Sur Mer'
                    }
                    gpsGoogle={'https://goo.gl/maps/frxFykWhJY8CXUfq9'}
                    gpsApple={
                        'https://maps.apple.com/?address=95%20Boulevard%20de%20Port%20Giraud,%2044770%20La%20Plaine-sur-Mer,%20France&ll=47.157141,-2.192215&q=95%20Boulevard%20de%20Port%20Giraud'
                    }
                    gpsWaze={
                        'https://ul.waze.com/ul?place=ChIJFU7fL1RsBUgRBHiwR0WkcXE&ll=47.15726300%2C-2.19220600&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location'
                    }
                />
                <ArriveeBienvenue
                    className={className}
                    hourFr={'17h00'}
                    hourEn={'5p.m.'}
                    parkingFr={
                        <>
                            <p>
                                Un parking privatif vous est dédiée sur le
                                devant de la maison (3 places), les portails
                                blancs ne sont pas verrouiller.
                            </p>
                        </>
                    }
                    parkingEn={
                        <>
                            <p>
                                A private car park is dedicated to you on the
                                front of the house (3 places), the white gates
                                are not locked.
                            </p>
                        </>
                    }
                />
                <BoiteCleBienvenue
                    className={className}
                    boiteFr={
                        <p>
                            Vous trouvez la boîte à clé pour votre arrivée
                            autonome. <br />
                            Le code vous a été envoyé par Sms ou par Mail
                        </p>
                    }
                    boiteEn={
                        <p>
                            You find the key box for your autonomous arrival.
                            <br />
                            The code has been sent to you by SMS or by Mail
                        </p>
                    }
                    photos={
                        <>
                            <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                <img
                                    src={accueil}
                                    alt={'Devant de la Maison'}
                                    title={'Devant de la Maison'}
                                    loading="lazy"
                                    width={'250px'}
                                    className="card-location-logo rounded-4"
                                />

                                <small>Devant de la Maison </small>
                            </div>

                            <div className="d-flex flex-row flex-wrap justify-content-around align-items-center mx-auto">
                                <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                    <img
                                        src={accueilBoite}
                                        alt={
                                            "Boite à Clé à côté de la porte d'entrée"
                                        }
                                        title={
                                            "Boite à Clé à côté de la porte d'entrée"
                                        }
                                        loading="lazy"
                                        width={'250px'}
                                        className="card-location-logo rounded-4"
                                    />

                                    <small>Devant de la Maison </small>
                                </div>
                                <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                    <img
                                        src={accueilBoite2}
                                        alt={'Boite à Clé '}
                                        title={'Boite à Clé'}
                                        loading="lazy"
                                        width={'250px'}
                                        className="card-location-logo rounded-4"
                                    />

                                    <small>Boite à Clé</small>
                                </div>
                            </div>
                        </>
                    }
                />
                <InternetBienvenue
                    className={className}
                    access={true}
                    wifiName={'Ma Maison Sereine'}
                    wifiPass={'mms44770'}
                    infoFr={
                        <>
                            <p>Vous pouvez vous connecter au réseau Wifi</p>
                        </>
                    }
                    infoEn={
                        <>
                            <p>You can connect to the Wifi network</p>
                        </>
                    }
                    qrcode={qrcode_wifi_ser1}
                />
                <ChauffageBienvenue
                    className={className}
                    dijoncteurFR={'dans le placard présent dans le salon'}
                    dijoncteurEN={'in the closet present in the living room'}
                    consigneFr={
                        <>
                            <p>
                                Le Chauffage est automatique mais vous pouvez
                                augmenter ou baisser la température de consigne
                                en cas de besoins avec les touches + et - sur
                                les radiateurs.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Merci aux petits techniciens curieux de ne pas
                                toucher aux boutons (ni aux disjoncteurs ! )
                            </p>

                            <p className="text-center fw-bold text-primary">
                                L'Energie est notre Avenir. Economisons-là
                            </p>
                        </>
                    }
                    consigneEn={
                        <>
                            <p>
                                Heating is automatic but you can increase or
                                lower the set temperature if necessary with the
                                + and - keys on the radiators.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Thank you to the curious little technicians not
                                to touch the buttons (or the circuit breakers! )
                            </p>

                            <p className="text-center fw-bold text-primary">
                                Energy is our Future. Let's save it
                            </p>
                        </>
                    }
                />
                <JardinBienvenue
                    className={className}
                    jardinFr={
                        <>
                            <p>
                                Vous avez libre accès au jardin ainsi qu'à la
                                Cuisine d'Ete (Plancha).
                            </p>
                            <p>
                                Comme dans tous les quartiers d’habitations,
                                attention aux bruits démesurés, principalement
                                dans le jardin en soirée. Je rappelle que les
                                fêtes sont interdites.
                            </p>
                        </>
                    }
                    jardinEn={
                        <>
                            <p>
                                You have free access to the garden as well as to
                                the Summer Kitchen (Plancha).
                            </p>
                            <p>
                                As in all living quarters, pay attention to
                                excessive noise, mainly in the garden in the
                                evening. I remember that the holidays are
                                prohibited.
                            </p>
                        </>
                    }
                    light={false}
                />
                <DechetsBienvenue
                    className={className}
                    dechetsFr={
                        <p>
                            Les poubelles se trouvent au niveau des portails
                            blancs. Est-ce possible de mettre les poubelles pour
                            le ramassage des ordures le vendredi soir (pour un
                            passage le samedi matin) svp ? merci.
                        </p>
                    }
                    dechetsEn={
                        <p>
                            Trash cans are located at the gates whites. Is it
                            possible to put the trash cans for garbage
                            collection on Friday evening (for a passage on
                            Saturday morning) please? Thanks.
                        </p>
                    }
                />
                <ContactUrgence className="bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 print-cut-none " />
                <DurantSejourBienvenue
                    className={
                        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
                    }
                    duringFr={
                        <>
                            <p>
                                La maisone est située à proximité de la plage de
                                Port Giraud
                            </p>
                            <p>
                                N’hésitez pas à vous déplacer jusqu’à
                                Tharon-plage. C’est un peu notre mini
                                centre-ville « estivale » avec un belle esprit
                                de vacances en famille. Vous y trouverez un
                                manège, les glace de la fraiseraie, des
                                restaurants ……… Et une immense plage parfaite
                                pour les enfants.
                            </p>
                            <p>
                                Le Marché de La Plaine Sur Mer se tient tous les
                                dimanches matin. Le magasin le plus proche pour
                                faire de petite course est L’Intermarché de La
                                Plaine Sur Mer. Pharmacie la plus proche : dans
                                le centre de la Plaine Sur Mer. L’une des
                                meilleures boulangeries du coin : L’arbre à Pain{' '}
                            </p>
                        </>
                    }
                    duringEn={
                        <>
                            <p>
                                The house is located near the beach of Port
                                Giraud
                            </p>
                            <p>
                                Do not hesitate to move to Tharon-beach. It's
                                kind of our mini "summery" town center with a
                                beautiful family holiday spirit. You there find
                                a merry-go-round, ice cream strawberry
                                plantation, restaurants ……… And a huge beach
                                perfect for children.
                            </p>
                            <p>
                                The La Plaine Sur Mer Market is held every
                                Sunday mornings. The store most close to do a
                                little shopping is The Intermarket of La Plaine
                                Sur Mer. Nearest pharmacy: in the center of la
                                Plaine Sur Mer. One of the best local bakeries:
                                L’arbre à Pain{' '}
                            </p>
                        </>
                    }
                />
                <DepartBienvenue
                    className={className}
                    hourFr={'10h00'}
                    hourEn={'10a.m.'}
                    remiseCleFr={
                        'Merci de bien tout fermer et de mettre les clés dans la boite à clés et de veiller à brouiller le code.'
                    }
                    remiseCleEn={
                        'Please close everything well and put the keys in the key box and make sure to scramble the code.'
                    }
                    alertFr={
                        <>
                            Vous devez rendre la maison propre comme à votre
                            arrivée sauf dans le cas d'un forfait ménage
                            souscrit au préalable.
                        </>
                    }
                    alertEn={
                        <>
                            You must leave the house clean as your arrival
                            except in the case of a cleaning fee subscribed to
                            the prior.
                        </>
                    }
                    forfaitMenageFr={
                        'Vous devez tout de même rendre le logement dans un état acceptable, vider vos poubelles ainsi que votre vaisselle propre et rangée. '
                    }
                    forfaitMenageEn={
                        'You must still leave the accommodation in an acceptable condition, empty your trash and your dishes clean and tidy'
                    }
                    faireMenageFr={
                        <>
                            <p>
                                Face au grand nombre d’incivilité et pour le
                                respect de nos techniciens, merci de bien
                                l’effectuer sous peine de litige.
                            </p>
                            <p>
                                « Sur une journée d’affluence un technicien peut
                                avoir jusqu’à 8 lieux à vérifier/nettoyer,
                                imaginer qu’il ne peut à chaque location refaire
                                le ménage… le tout entre 10h00 et 16h00 ! Soyons
                                donc tous vigilent et respectueux ! » Fabien.
                            </p>
                        </>
                    }
                    faireMenageEn={
                        <>
                            <p>
                                Faced with the large number of incivilities and
                                for the respect for our technicians, thank you
                                very much do so under penalty of litigation.
                            </p>
                            <p>
                                “On a busy day a technician can have up to 8
                                places to check/clean, imagine that he cannot at
                                each rental redo the housework… all between
                                10:00 a.m. and 4:00 p.m.! So let's all be
                                vigilant and respectful! » Fabian.
                            </p>
                        </>
                    }
                    basesMenageFr={
                        <>
                            Aérer chaque pièce.
                            <ul>
                                <li>
                                    Dans les chambres : merci de retirer les
                                    draps (les mettre dans un sac en tissu) et
                                    nettoyer sol * et surfaces.
                                </li>
                                <li>
                                    Dans la salle de bain : vider la poubelle,
                                    mettre les serviettes dans le sac ainsi que
                                    le tapis. Nettoyer sol * et surfaces, bien
                                    essuyer la douche, lavabo, toilettes.
                                </li>
                                <li>
                                    Dans la cuisine : vider la poubelle,
                                    nettoyer le réfrigérateur, micro-ondes,
                                    four, surfaces, sol *…… <br />
                                    Mettre toute la vaisselle utilisée propre en
                                    évidence sur le plan de travail. Rendre la
                                    cuisine propre.
                                </li>
                                <li>
                                    Dans les pièces de vie : nettoyer les
                                    surfaces et sol *.
                                </li>
                            </ul>
                            <p>
                                * : Le nettoyage des sols consiste à aspirer et
                                laver avec la serpillère.
                            </p>
                        </>
                    }
                    basesHouseholdEn={
                        <>
                            Ventilate each room.
                            <ul>
                                <li>
                                    In the rooms: please remove the sheets (put
                                    them in a cloth bag) and clean floor * and
                                    surfaces.
                                </li>
                                <li>
                                    In the bathroom: empty the trash can, put
                                    the towels in the bag as well as the carpet.
                                    Clean floor * and surfaces, wipe the shower
                                    well, sink, toilet.
                                </li>
                                <li>
                                    In the kitchen: empty the trash, clean the
                                    refrigerator, microwave, oven, surfaces,
                                    floor *…… <br />
                                    Put all the dishes used clearly visible on
                                    the plan of work. Make the kitchen clean.
                                </li>
                                <li>
                                    In the living rooms: clean the surfaces and
                                    floor *.
                                </li>
                            </ul>
                            <p>
                                *: Floor cleaning involves vacuuming and wash
                                with the mop.
                            </p>
                        </>
                    }
                />
                <MerciBienvenue
                    className={className}
                    merciFr={
                        <>
                            Mon équipe et moi-même (et oui je fais du ménage
                            aussi) vous remercions par avance de votre
                            investissement dans cette tâche qui est la moins
                            drôle des vacances, mais qui fera notre sourire
                            <br />
                            <br />
                            Merci et un bon retour à vous !
                        </>
                    }
                    merciEn={
                        <>
                            My team and myself (and yes I do cleaning too) thank
                            you in advance for your investment in this task
                            which is the less funny of the holidays, but which
                            will make our smile <br />
                            <br />
                            Thank you and welcome back !
                        </>
                    }
                />
                <section className="sect__widgets">
                    <CardHomeWidgetsBottom />
                </section>
            </main>
        </>
    );
}
