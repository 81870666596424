import { Alert } from 'reactstrap';
import { useSelector } from 'react-redux';

import { LiaPlaneArrivalSolid } from 'react-icons/lia';
import { FaParking } from 'react-icons/fa';
import { IoWarningOutline } from 'react-icons/io5';

export default function ArriveeBienvenue(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className={props.className}>
            {props.presentiel === undefined && (
                <div className="mb-4">
                    {' '}
                    <h2 className="text-center m-3">
                        <LiaPlaneArrivalSolid />
                        {translate === 'French' && 'Votre Arrivée'}
                        {translate === 'English' && 'Your Arrival'}
                    </h2>
                    {translate === 'French' && (
                        <>
                            <p>
                                À votre arrivée (vers {props.hourFr}), merci de
                                nous envoyer un SMS{' '}
                                <span className="no-display-only-print">
                                    {' '}
                                    au 07 66 79 99 50
                                </span>
                                si possible, cela nous permet de vous savoir
                                arrivé à bon port.
                            </p>
                            <p className="my-3 text-center no-print">
                                <a
                                    href="sms:+33766799950"
                                    className="btn btn-outline-primary my-3 text-center"
                                >
                                    Envoyer SMS à Fabien
                                </a>
                            </p>
                            <p>
                                « Le logement est propre à votre arrivée. Si
                                vous voyez quelques retouches pour le ménage,
                                n’hésitez pas à me le signaler. C’est avec
                                plaisir que je repasserai vous voir et arranger
                                ça ! » Fabien
                            </p>
                        </>
                    )}
                    {translate === 'English' && (
                        <>
                            <p>
                                When you arrive ({props.hourEn}), please text us
                                if possible, this allows us to know you have
                                arrived at good port.
                            </p>
                            <p className="my-3 text-center">
                                <a
                                    href="sms:+33766799950"
                                    className="btn btn-outline-primary my-3 text-center"
                                >
                                    Send SMS to Fabien at +33 7 66 79 99 50
                                </a>
                            </p>
                            <p>
                                “The accommodation is clean when you arrive. If
                                you see some alterations for the household, do
                                not hesitate not report it to me. It is with
                                pleasure that I I'll come back to see you and
                                fix it! » Fabian
                            </p>
                        </>
                    )}
                </div>
            )}

            <h2 className="text-center m-3 ">
                <span className="me-2">
                    <FaParking />
                </span>
                {translate === 'French' && 'Stationnement'}
                {translate === 'English' && 'Parking'}
            </h2>
            {translate === 'French' && <>{props.parkingFr}</>}
            {translate === 'English' && <>{props.parkingEn}</>}
            {(props.alertFr !== undefined || props.alertEn !== undefined) && (
                <Alert className="text-center fw-bold" color="warning">
                    <span className="fs-2">
                        <IoWarningOutline />
                    </span>
                    {translate === 'French' && <>{props.alertFr}</>}
                    {translate === 'English' && <>{props.alertEn}</>}
                </Alert>
            )}
            {props.photos}
        </div>
    );
}
