import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FaPlaneArrival } from 'react-icons/fa6';

import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import WidgetRGPD from '../../../components/Widget/RGPD.jsx';

export default function Sitemap() {
    document.title = 'Plan de Site | Ma Maison Sereine';
    document.description = 'Plan de site de Ma Maison Sereine';
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const translate = useSelector((store) => store.Template.language);
    const [isOpenRGPD, setIsOpenRGPD] = useState(false);

    return (
        <main className=" bg-white p-4 mt-5 vh-100">
            <div className="border border-1 border-primary rounded shadow m-3 p-2 mx-auto col-11 col-lg-8 ">
                <h1 className="text-center  fs-1">Plan de Site </h1>
                {translate === 'French' && (
                    <ul className="mx-auto col-11 col-md-10 col-xl-6">
                        <li>
                            <Link to="/">Accueil - Page Principale</Link>
                        </li>
                        <li>
                            <Link to="/connexion">Page de Connexion</Link>
                        </li>
                        <li>
                            <Link to="/deconnexion">Page de Déconnexion</Link>
                        </li>
                        <li>
                            <Link to="/voyageur">Page Mon Compte Voyageur</Link>
                        </li>
                        <li>
                            <Link to="/conciergerie">Page Conciergerie</Link>
                        </li>
                        <li>
                            <Link to="/locations">
                                Page de Nos Locations incluant :
                            </Link>
                        </li>
                        <ul>
                            <span className="text-success">
                                <FaPlaneArrival />
                                La Plaine Sur Mer 44770 :
                            </span>

                            <li className="ms-4">
                                <Link to="/locations/pecherie">
                                    Logement "Au Bord des Pêcheries"
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <span className="text-success">
                                <FaPlaneArrival />
                                Pornic 44210 :
                            </span>
                            <li className="ms-4">
                                <Link to="/locations/maison-charme-gourmalon">
                                    Logement "Maison de Charme à Gourmalon"
                                </Link>
                            </li>
                            <li className="ms-4">
                                <Link to="/locations/pure">
                                    Logement "Pure"
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <span className="text-success">
                                <FaPlaneArrival /> Préfailles 44770 :
                            </span>
                            <li className="ms-4">
                                <Link to="/locations/sous-les-pins/maison/">
                                    Logement "Sous Les Pins" - La Maison
                                </Link>
                            </li>{' '}
                        </ul>
                        <li className="mt-2">
                            <Link to="/contact">Demande de Contact</Link>
                        </li>
                        <li>
                            <Link to="/bons-plans">Bons-Plans</Link>
                        </li>
                        <li>
                            <Link to="/cgv-mentions-legales">
                                Conditions générales de Ventes - Mentions
                                Légales
                            </Link>
                        </li>
                        <li>
                            <Link to="/cgl-reglement-interieur">
                                Conditions Générales de Location - Règlement
                                Intérieur
                            </Link>
                        </li>
                        <li>
                            <Link onClick={(e) => setIsOpenRGPD(!isOpenRGPD)}>
                                Données Personnelles - RGPD
                            </Link>
                        </li>
                        <li>
                            <Link to="/plan-de-site">Plan de Site</Link>
                        </li>
                    </ul>
                )}
                {translate === 'English' && (
                    <ul className="mx-auto col-11 col-md-10 col-xl-6">
                        <li>
                            <Link to="/">Home - Main Page</Link>
                        </li>
                        <li>
                            <Link to="/connexion">Log In</Link>
                        </li>
                        <li>
                            <Link to="/deconnexion">Log out</Link>
                        </li>
                        <li>
                            <Link to="/voyageur">
                                My Traveller Account Page
                            </Link>
                        </li>
                        <li>
                            <Link to="/conciergerie">Concierge page</Link>
                        </li>
                        <li>
                            <Link to="/locations">Locations page</Link>
                        </li>
                        <ul>
                            <span className="text-success">
                                <FaPlaneArrival />
                                La Plaine Sur Mer 44770 :
                            </span>
                            <li className="ms-4">
                                <Link to="/locations/pecherie">
                                    Accommodation "Au Bord des Pêcheries"
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <span className="text-success">
                                <FaPlaneArrival />
                                Pornic 44210 :
                            </span>
                            <li className="ms-4">
                                <Link to="/locations/maison-charme-gourmalon">
                                    Accommodation "Maison de Charme à Gourmalon"
                                </Link>
                            </li>
                            <li className="ms-4">
                                <Link to="/locations/pure">
                                    Accommodation "Pure"
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <span className="text-success">
                                <FaPlaneArrival />
                                Préfailles 44770 :
                            </span>
                            <li className="ms-4">
                                <Link to="/locations/sous-les-pins/maison/">
                                    Accommodation "Under the Pines" - The House
                                </Link>
                            </li>
                        </ul>
                        <li>
                            <Link to="/bons-plans">Tips</Link>
                        </li>
                        <li>
                            <Link to="/cgv-mentions-legales">
                                General Conditions of Sale - Legal Notice
                            </Link>
                        </li>
                        <li>
                            <Link to="/cgl-reglement-interieur">
                                General Rental Conditions - Rules Interior
                            </Link>
                        </li>
                        <li>
                            <Link onClick={(e) => setIsOpenRGPD(!isOpenRGPD)}>
                                Personal Data - GDPR
                            </Link>
                        </li>
                        <li>
                            <Link to="/plan-de-site">Sitemap</Link>
                        </li>
                    </ul>
                )}
                <div className="mx-auto w-25">
                    <a
                        href="https://mamaisonsereine.fr/sitemap.xml"
                        target="_blank"
                        rel="noopener noreferrer"
                        className=""
                    >
                        SiteMap XML
                    </a>
                </div>
                <Offcanvas
                    show={isOpenRGPD}
                    onHide={(e) => setIsOpenRGPD(!isOpenRGPD)}
                    placement={'bottom'}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            {translate === 'French' && (
                                <> L’utilisation de vos données personnelles</>
                            )}
                            {translate === 'English' && (
                                <>The use of your data personal</>
                            )}
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <hr />
                    <Offcanvas.Body>
                        <WidgetRGPD />
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </main>
    );
}
