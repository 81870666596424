import { useSelector } from 'react-redux';
import fabien from '../../../assets/img/logo/fabien_hennion.png';

export default function TitleBienvenue(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className={props.className}>
            <h1 className="fs-1 text-center m-3  ">
                {translate === 'French' && <>Bienvenue à " {props.title}"</>}
                {translate === 'English' && <>Welcome to " {props.title}"</>}
            </h1>
            {translate === 'French' && (
                <div className="text-center pt-4">
                    <p>Bonjour, bienvenue chez vous !</p>
                    <p>
                        Nous sommes heureux de vous accueillir et d’avoir
                        préparé le logement pour votre séjour.
                    </p>
                    <p>
                        N’hésitez pas à nous contacter. Pour toutes questions on
                        est là !
                    </p>
                </div>
            )}
            {translate === 'English' && (
                <div className="text-center">
                    <p>Hello, welcome home!</p>
                    <p>
                        We are happy to welcome you and to have prepared the
                        accommodation for your stay.
                    </p>
                    <p>Do not hesitate to contact us. For all we are here!</p>
                </div>
            )}

            <div className="d-flex flex-row justify-content-center align-items-center pt-4">
                <img src={fabien} alt="Fabien Hennion" width={'80px'} />
                <span className="ms-2 text-primary">Fabien & son équipe</span>
            </div>
        </div>
    );
}
