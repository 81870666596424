import { useSelector } from 'react-redux';
import { BsFiletypePdf } from 'react-icons/bs';
import { FaArrowUpRightFromSquare, FaCommentSms } from 'react-icons/fa6';
import { MdPhoneInTalk } from 'react-icons/md';

import evelyne from '../../assets/img/bons-plans/photo_evelyne.jpg';
import evelyneCarteRecto from '../../assets/img/bons-plans/evelyne_carte-recto.png';
import evelyneCarteVerso from '../../assets/img/bons-plans/evelyne_carte-verso.png';

export default function MassageEvelyne() {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className="evasion-card">
            <h2>Massages - Reiki par Evelyne</h2>
            <hr className="col-6 mx-auto" />
            <div className="d-flex flex-row flex-wrap flex-md-nowrap ">
                <img
                    src={evelyne}
                    alt={'Evelyne'}
                    title={'Evelyne'}
                    loading="lazy"
                    width={'200px'}
                    className="mx-auto rounded-4"
                />
                <div className="p-1 m-1 d-flex flex-column justify-content-around align-items-center text-center flex-wrap">
                    {translate === 'French' && (
                        <>
                            <p>
                                La connexion à l’autre pendant le massage est
                                essentielle pour moi et c’est pour cela que
                                chacun de mes massages est unique et
                                personnalisé.{' '}
                            </p>
                            <p>
                                Me déplacer pour venir à vous, sur votre lieu de
                                détente, participe à cette connexion qui guidera
                                ensuite l’essence de mon massage.
                            </p>
                            <p className="mt-3">
                                Le massage est un voyage ! Prenez votre
                                réservation !
                            </p>
                        </>
                    )}
                    {translate === 'English' && (
                        <>
                            <p>
                                The connection to the other during the massage
                                is essential for me and that’s why each of my
                                massages is unique and custom.{' '}
                            </p>
                            <p>
                                Travel to come to you, at your place of
                                relaxation, participates in this connection
                                which will guide then the essence of my massage.
                            </p>
                            <p className="mt-3">
                                Massage is a journey! Take yours reservation !
                            </p>
                        </>
                    )}
                </div>
            </div>
            <div className="d-flex flex-column justify-content-center  ">
                <div className="d-flex flex-row justify-content-around no-print ">
                    <a href="tel:+33766799950" className="no-print">
                        <span className="fs-2 text-primary">
                            <MdPhoneInTalk />
                        </span>{' '}
                        {translate === 'French' ? 'Appeler' : 'Call'} Evelyne
                    </a>
                    <a href="sms:+33766799950" className="no-print">
                        <span className="fs-2 text-primary">
                            <FaCommentSms />
                        </span>{' '}
                        {translate === 'French'
                            ? 'Envoyer SMS à '
                            : 'Send SMS at'}{' '}
                        Evelyne
                    </a>
                </div>
                <div className="d-flex flex-row  flex-wrap">
                    <img
                        src={evelyneCarteRecto}
                        alt={'Evelyne Carte'}
                        loading="lazy"
                        width={'290px'}
                        className=" mx-auto  rounded-1 object-fit-contain"
                    />
                    <img
                        src={evelyneCarteVerso}
                        alt={'Evelyne Carte'}
                        loading="lazy"
                        width={'290px'}
                        className=" mx-auto rounded-1 object-fit-contain"
                    />
                </div>
            </div>
            <div className="d-flex flex-column ">
                <a
                    href="https://api.mamaisonsereine.fr/media/pdf/evelyne_flyer_2024.pdf"
                    target="_blank"
                >
                    <BsFiletypePdf className="me-2" />
                    Lien Flyer <FaArrowUpRightFromSquare className="ms-2" />
                </a>
                <a
                    href="https://api.mamaisonsereine.fr/media/pdf/evelyne_flyer_2024_complement.pdf"
                    target="_blank"
                >
                    <BsFiletypePdf className="me-2" />
                    Lien Flyer Complément{' '}
                    <FaArrowUpRightFromSquare className="ms-2" />
                </a>
                <a
                    href="https://api.mamaisonsereine.fr/media/pdf/evelyne_carte_visite_2024.pdf"
                    target="_blank"
                >
                    <BsFiletypePdf className="me-2" />
                    Lien Carte de Visite{' '}
                    <FaArrowUpRightFromSquare className="ms-2" />
                </a>
            </div>
        </div>
    );
}
