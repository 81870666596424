import { useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { LiaPlaneDepartureSolid } from 'react-icons/lia';
import { BsKey } from 'react-icons/bs';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { GiVacuumCleaner } from 'react-icons/gi';

export default function DepartBienvenue(props) {
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className={props.className}>
            <h2 className="text-center m-3">
                <span className="m-1">
                    <LiaPlaneDepartureSolid />
                </span>
                {translate === 'French' && 'Votre Départ'}
                {translate === 'English' && 'Your Départure'}
            </h2>

            <p>
                {translate === 'French' && (
                    <>
                        Toute bonne chose à une fin, ainsi votre Départ doit
                        s'effectuer avant {props.hourFr}
                    </>
                )}
                {translate === 'English' && (
                    <>
                        Toute bonne chose à une fin, ainsi votre Départ doit
                        s'effectuer avant {props.hourEn}
                    </>
                )}
            </p>

            <h2 className="text-center m-3">
                <span className="m-1">
                    <BsKey />
                </span>
                {translate === 'French' && 'Remise des Clés'}
                {translate === 'English' && 'Return the keys'}
            </h2>
            <p>
                {translate === 'French' && <>{props.remiseCleFr}</>}
                {translate === 'English' && <>{props.remiseCleEn}</>}
            </p>

            <Alert className="text-center fw-bold" color="danger">
                <span className="m-1">
                    <FaTriangleExclamation />
                </span>
                {translate === 'French' && <>{props.alertFr}</>}
                {translate === 'English' && <>{props.alertEn}</>}
                <p className="mt-4">
                    {translate === 'French' && (
                        <>Merci de laisser le réfrigérateur allumé svp</>
                    )}
                    {translate === 'English' && (
                        <>Please leave the refrigerator ON</>
                    )}
                </p>
            </Alert>
            <h2 className="text-center m-3">
                <span className="m-1 fs-1">
                    <GiVacuumCleaner />
                </span>
                {translate === 'French' && 'Ménage'}
                {translate === 'English' && 'Household'}
            </h2>
            {props.forfaitMenageFr !== undefined && (
                <>
                    <h3 className="text-primary">
                        {translate === 'French' && (
                            <>Vous avez pris le forfait Ménage :</>
                        )}
                        {translate === 'English' && (
                            <> You have taken the cleaning package:</>
                        )}
                    </h3>
                    <p className="text-start">
                        {translate === 'French' && <>{props.forfaitMenageFr}</>}
                        {translate === 'English' && (
                            <>{props.forfaitMenageEn}</>
                        )}
                    </p>
                    <h3 className="text-primary">
                        {translate === 'French' && <>Vous faites le ménage :</>}
                        {translate === 'English' && <>You do the cleaning:</>}
                    </h3>
                </>
            )}
            <p className="text-start font-bold">
                {translate === 'French' && <>{props.faireMenageFr}</>}
                {translate === 'English' && <>{props.faireMenageEn}</>}
            </p>
            <h3 className="text-primary">
                {translate === 'French' && <>Rappel des Bases en ménage :</>}
                {translate === 'English' && (
                    <>Reminder of the Basics in household :</>
                )}
            </h3>
            <p className="text-start">
                {translate === 'French' && <>{props.basesMenageFr}</>}
                {translate === 'English' && <>{props.basesMenageEn}</>}
            </p>
        </div>
    );
}
