import { useState } from 'react';
import { IoMdHelpCircleOutline } from 'react-icons/io';

import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

export default function HelpFormules() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <button className="btn btn-primary " onClick={handleShow}>
                En Savoir plus
            </button>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="text-primary">
                        Concernant les Formules
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <h2>Notice : </h2>
                    <p className="text-start">
                        Concernant la lecture du tableau, chaque colonne
                        représente une formule et chaque ligne indique si le
                        service concerné est compris ou optionnel (facturation
                        en plus) avec les modalités d’accès.
                    </p>
                    <hr />
                    <h2>Informations : </h2>
                    <p className="text-start">
                        Vous avez la possibilité de choisir parmi les trois
                        formules proposées (avec ou sans options
                        complémentaires). <br /> <br />
                        Chaque souscription de formule comprend une période
                        d’engagement durant l’année civile complète avec tacite
                        reconduction à chaque premier janvier de la nouvelle
                        année civile. <br /> <br />
                        Dans le cas où le propriétaire souhaite modifier ou
                        résilier son forfait il devra notifier MaMaisonSereine
                        durant le mois de décembre de l’année en cours par LRAR
                        au minimum avant le 20 décembre de l’année civile en
                        cours.{' '}
                    </p>
                    <hr />
                    <h2>Période de congés hebdomadaire et de congés :</h2>
                    <p className="text-start">
                        Ma maison sereine est ouverte 319 jours par année
                        civile. <br /> <br />
                        <span className="text-danger my-2">
                            Aucune location n’est possible pendant les jours de
                            fermeture : semaine 2, 3, 4, 40, 41 + 10 jours
                            libres défini à J-30
                        </span>
                        <br /> <br />
                        Départs de voyageurs interdits les mardi et mercredi
                        mais arrivées autorisées en boites à clés.
                        <br /> <br />
                        Arrivées et départs interdits pour les dates suivantes :
                        24/12, 25/12, 31/12, 01/01.
                    </p>
                    <hr />
                    <h2>Détails des formules:</h2>
                    <ul className="text-start">
                        <li>
                            Formule 1 - Forfait Eco : 65€ / réservation, plus
                            les éventuels compléments.
                        </li>
                        <br />
                        <li>
                            Formule 2 - Au pourcentage : vous devez versez 20%
                            de vos recettes brutes de vos locations pour chacun
                            de vos biens pris en charge par la conciergerie.
                        </li>
                        <br />
                        <li>
                            Formule 3 - Dite « Duo » : vous devez versez 15% de
                            vos recettes brutes de vos locations pour chacun de
                            vos biens pris en charge par la conciergerie à
                            condition que votre bien soit aussi géré par le
                            gestionnaire travaillant avec Ma Maison Sereine. Le
                            gestionnaire est libre du pourcentage fixé avec
                            vous, à vous de définir les modalités directement
                            avec lui. Les service et tarifs indiqués sont à
                            titre d’exemple.
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
