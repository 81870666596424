import { useSelector } from 'react-redux';

import { Badge, Alert } from 'reactstrap';
import { GiEntryDoor } from 'react-icons/gi';
import { MdMeetingRoom, MdOutlinePets } from 'react-icons/md';
import { FaHouse } from 'react-icons/fa6';
import { FaMapSigns } from 'react-icons/fa';

import { useFetchGetLocationSettings } from '../../../utils/Api/FetchLocationSettings.jsx';

export default function ListingDetailsCIHome() {
    const translate = useSelector((store) => store.Template.language);
    return (
        <div className="locations__description">
            {translate === 'French' && (
                <h2 className="fs-1">Composition du Logement</h2>
            )}
            {translate === 'English' && (
                <h2 className="fs-1">Housing Composition</h2>
            )}

            <div className="d-flex flex-row flex-wrap">
                <div className="d-flex flex-column col-12 col-lg-8 mx-auto">
                    <div className="listing__arrive">
                        {translate === 'French' && (
                            <h3 className="fs-2">
                                <u>Pour votre Arrivé :</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Inclus
                                </Badge>
                            </h3>
                        )}
                        {translate === 'English' && (
                            <h3 className="fs-2">
                                <u>For your Arrival:</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Included
                                </Badge>
                            </h3>
                        )}

                        <div className="composition__logement">
                            <div className="composition__logement_text">
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-key"></i>{' '}
                                    {translate === 'French' && (
                                        <span>Arrivée en boîte à clé</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>Arrival in key box</span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-car"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Stationnement facile devant la
                                            maison pour plusieurs véhicules
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Easy parking in front of the house
                                            for several vehicles
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listing__sejour">
                        {translate === 'French' && (
                            <h3 className="fs-2">
                                <u>Lors de votre séjour :</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Inclus
                                </Badge>
                            </h3>
                        )}
                        {translate === 'English' && (
                            <h3 className="fs-2">
                                <u>During your stay:</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Included
                                </Badge>
                            </h3>
                        )}

                        <div className="composition__logement">
                            <div className="composition__logement_text">
                                <div>
                                    <FaHouse />
                                    {translate === 'French' && (
                                        <span>
                                            Maison complète de 120m2 avec étage
                                            sur terrain de 500m2 et avec
                                            terrasse
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Complete house of 120m2 with floor
                                            on 500m2 land and with terrace
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <FaMapSigns />
                                    {translate === 'French' && (
                                        <span>
                                            En quartier calme de Pornic, à 5 min
                                            à pied de la plage, du chemin
                                            côtier, du château et du
                                            centre-ville de Pornic avec tous les
                                            commerces de proximité et
                                            restaurants
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            In a quiet area of Pornic, 5 minutes
                                            walk from the beach, the coastal
                                            path, the castle and the city center
                                            of Pornic with all the local shops
                                            and restaurants
                                        </span>
                                    )}
                                </div>

                                <div>
                                    <FaHouse />
                                    {translate === 'French' && (
                                        <span>
                                            La maison doit témoigner d’un
                                            entretien régulier durant le séjour.
                                            Si la maison n'est pas rendue
                                            propre, nous faisons faire le ménage
                                            par une entreprise spécialisée et
                                            déduisons le montant total de la
                                            facture sur le remboursement du
                                            dépôt de garantie.
                                            <br />
                                            Un dépôt de garantie de 800€ est
                                            demandé et restitué dans un maximum
                                            de 15 jours après votre départ.
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            The house must show regular
                                            maintenance during the stay. If the
                                            house is not returned clean, we have
                                            the cleaning done by a specialized
                                            company and deduct the total amount
                                            of the invoice from the security
                                            deposit refund.
                                            <br />A security deposit of €800 is
                                            required and refunded within a
                                            maximum of 15 days after your
                                            departure.
                                        </span>
                                    )}
                                </div>

                                <div>
                                    <MdOutlinePets />
                                    {translate === 'French' && (
                                        <span>
                                            Animaux de compagnie autorisés
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>Pets allowed</span>
                                    )}
                                </div>

                                <div>
                                    <i className="fa-solid fa-panorama"></i>{' '}
                                    {translate === 'French' && (
                                        <span>Vue dégagée</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>Open View</span>
                                    )}
                                </div>
                                {translate === 'French' && (
                                    <Badge className="" color="primary" pill>
                                        Au Rez de Chaussée
                                    </Badge>
                                )}
                                {translate === 'English' && (
                                    <Badge className="" color="primary" pill>
                                        On the ground floor
                                    </Badge>
                                )}
                                <div>
                                    <i className="fa-solid fa-house-signal"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Séjour/salon traversant : une
                                            télévision écran plat, une chaine
                                            stéréo, des livres et des jeux. Très
                                            grande luminosité. Wifi
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Crossing living room: a flat screen
                                            TV, a stereo, books and games. Very
                                            bright. Wifi
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-kitchen-set"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Cuisine ouverte équipée avec plaques
                                            de cuisson vitrocéramiques, four
                                            traditionnel , micro-ondes,
                                            frigo/congélateur, lave-vaisselle,
                                            bouilloire,, friteuse, machines à
                                            café, grille pain et tous les
                                            ustensiles de cuisine.
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Open equipped kitchen with ceramic
                                            hob, traditional oven, microwave,
                                            fridge/freezer, dishwasher, kettle,
                                            fryer, coffee machines, toaster and
                                            all kitchen utensils.
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Chambre RDC: lit 140 avec sa salle
                                            d'eau attenante
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Ground floor bedroom: 140 bed with
                                            its adjoining shower room
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <GiEntryDoor />
                                    {translate === 'French' && (
                                        <span>
                                            Entrée, sanitaires (wc séparé),
                                            buanderie (lave-linge, évier,
                                            étendoir à linge).
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Entrance, toilets (separate toilet),
                                            laundry room (washing machine, sink,
                                            clothes dryer).
                                        </span>
                                    )}
                                </div>

                                {translate === 'French' && (
                                    <Badge className="" color="primary" pill>
                                        A l'étage
                                    </Badge>
                                )}
                                {translate === 'English' && (
                                    <Badge className="" color="primary" pill>
                                        Upstairs
                                    </Badge>
                                )}
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Deux grandes chambre mansardée
                                            lumineuse Literie en 160 avec salle
                                            d'eau privative (par chambre)
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Two large bright attic bedrooms
                                            Bedding in 160 with private bathroom
                                            (per room)
                                        </span>
                                    )}
                                </div>

                                <div>
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            2 matelas d’appoint pour couchages
                                            enfants sont disponibles
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            2 extra mattresses for children's
                                            sleeping are available
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            un lit bébé parapluie est à
                                            disposition
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            an umbrella baby bed is available
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <MdMeetingRoom />
                                    {translate === 'French' && (
                                        <span>Un palier avec WC</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>A landing with WC</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
