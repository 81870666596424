import { useSelector } from 'react-redux';

import { Badge, Alert } from 'reactstrap';

import { useFetchGetLocationSettings } from '../../../utils/Api/FetchLocationSettings.jsx';
import WidgetPackLinge from '../WigdetPackLinge.jsx';
import WidgetPackMenage from '../WigdetPackMenage.jsx';

export default function ListingDetailsSousLesPins() {
    const translate = useSelector((store) => store.Template.language);
    const { dataLocationSettings } = useFetchGetLocationSettings();
    return (
        <div className="locations__description">
            {translate === 'French' && (
                <h2 className="fs-1">Composition du Logement</h2>
            )}
            {translate === 'English' && (
                <h2 className="fs-1">Housing Composition</h2>
            )}

            <div className="d-flex flex-row flex-wrap">
                <div className="d-flex flex-column col-12 col-lg-5 mx-auto">
                    <div className="listing__arrive">
                        {translate === 'French' && (
                            <h3 className="fs-2">
                                <u>Pour votre Arrivé :</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Inclus
                                </Badge>
                            </h3>
                        )}
                        {translate === 'English' && (
                            <h3 className="fs-2">
                                <u>For your Arrival:</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Included
                                </Badge>
                            </h3>
                        )}

                        <div className="composition__logement">
                            <div className="composition__logement_text">
                                <div>
                                    <i className="fa-solid fa-car"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            <strong>Place de Parkings</strong>{' '}
                                            privative sur le terrain
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            <strong>Parking space</strong>{' '}
                                            private on the ground
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-key"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Arrivée autonome avec une boîte à
                                            Clé
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Autonomous arrival with a box Key
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listing__sejour">
                        {translate === 'French' && (
                            <h3 className="fs-2">
                                <u>Lors de votre séjour :</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Inclus
                                </Badge>
                            </h3>
                        )}
                        {translate === 'English' && (
                            <h3 className="fs-2">
                                <u>During your stay:</u>
                                <Badge
                                    className="mt-1 ms-3"
                                    color="primary"
                                    pill
                                >
                                    Included
                                </Badge>
                            </h3>
                        )}

                        <div className="composition__logement">
                            <div className="composition__logement_text">
                                <div>
                                    <i className="fa-solid fa-house"></i>{' '}
                                    {translate === 'French' && (
                                        <span>Maison complète</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>Full House</span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-umbrella-beach"></i>{' '}
                                    {translate === 'French' && (
                                        <span>à 200m à pied de la plage</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>200m walk from the beach</span>
                                    )}
                                </div>

                                <div>
                                    <i className="fa-solid fa-panorama"></i>{' '}
                                    {translate === 'French' && (
                                        <span>Jardin privatif</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>Jardin balcony</span>
                                    )}
                                </div>

                                <div className="my-2"></div>
                                <div>
                                    <i className="fa-solid fa-house-signal"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Séjour avec Tv & Wifi et Cheminée
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 Living room seen with TV & Wifi
                                            and fireplace
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-kitchen-set"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Cuisine équipée avec four,
                                            réfrigérateur, cafetière,
                                            bouilloire, micro-ondes avec
                                            lave-vaisselle et lave-linge dans le
                                            sellier,
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 Kitchen equipped with oven,
                                            refrigerator, coffee maker, kettle,
                                            microwave with dishwasher and
                                            washing machine in the saddlery,
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>1 Chambre - Lit Double 140</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>1 Bedroom - Double Bed 140</span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>1 Chambre - 2 Lit 90</span>
                                    )}
                                    {translate === 'English' && (
                                        <span>1 Bedroom - 2 Bed 90</span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-bed"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Chambre supplémentaire attenante à
                                            la maison - Lit Double 140 avec
                                            salle de douche/WC
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 additional bedroom adjoining the
                                            house - Double bed 140 with shower
                                            room/WC
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    <i className="fa-solid fa-shower"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            1 Salle d'eau avec Douche & Toilette
                                            (une 2ème Salle d'eau avec Douche &
                                            Toilette dans la chambre
                                            indépendante)
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            1 bathroom with shower & toilet (a
                                            2nd Bathroom with Shower & Toilet in
                                            the room independent)
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-cloud-moon"></i>{' '}
                                    {translate === 'French' && (
                                        <span>
                                            Profitez de l'espace Jardin avec
                                            salons d'extérieur, table, pour
                                            profiter d'une soirée agréable et de
                                            détente.
                                        </span>
                                    )}
                                    {translate === 'English' && (
                                        <span>
                                            Take advantage of the garden space
                                            with outdoor lounges, table , to
                                            enjoy a enjoyable and relaxing
                                            evening.
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column col-12 col-lg-5 mx-auto">
                    <WidgetPackLinge
                        dataLocationSettings={dataLocationSettings}
                    />
                    <WidgetPackMenage
                        dataLocationSettings={dataLocationSettings}
                        cat={2}
                    />

                    <Alert
                        className="my-3 text-center mx-auto"
                        color="primary"
                        isOpen={true}
                    >
                        <i className="fa-solid fa-circle-arrow-right"></i>
                        {translate === 'French' && (
                            <span>
                                Vous pourrez reserver nos différents services
                                lors de la réservation du logement.
                            </span>
                        )}
                        {translate === 'English' && (
                            <span>
                                You can book our different services when booking
                                accommodation.
                            </span>
                        )}
                    </Alert>
                </div>
            </div>
        </div>
    );
}
