import accueil from '../../../assets/img/CIHome/photos/accueil.jpg';
import couloirEntree from '../../../assets/img/CIHome/photos/entree.jpg';

import salon1 from '../../../assets/img/CIHome/photos/salon.jpg';
import salon2 from '../../../assets/img/CIHome/photos/salon2.jpg';
import sejour from '../../../assets/img/CIHome/photos/sejour.jpg';
import cuisine from '../../../assets/img/CIHome/photos/cuisine.jpg';

import ch1 from '../../../assets/img/CIHome/photos/ch1.jpg';
import sdd1 from '../../../assets/img/CIHome/photos/sdd1.jpg';
import etagech1 from '../../../assets/img/CIHome/photos/etagech1.jpg';
import etagech2 from '../../../assets/img/CIHome/photos/etagech2.jpg';
import etagech3 from '../../../assets/img/CIHome/photos/etagech3.jpg';
import etagech4 from '../../../assets/img/CIHome/photos/etagech4.jpg';
import etagesdd2 from '../../../assets/img/CIHome/photos/etagesdd2.jpg';
import etagesdd3 from '../../../assets/img/CIHome/photos/etagesdd3.jpg';
import etagesdd4 from '../../../assets/img/CIHome/photos/etagesdd4.jpg';

import ext from '../../../assets/img/CIHome/photos/ext.jpg';
import jardin from '../../../assets/img/CIHome/photos/jardin.jpg';

export default function imagesMaisonCIHome() {
    const listing = [
        {
            original: accueil,
            description: 'Maison avec terrain',
            descriptionEn: 'House with grounds',
        },
        {
            original: couloirEntree,
            description: "Couloir d'accès aux différentes pièces ",
            descriptionEn: 'Access corridor to the different rooms ',
        },
        {
            original: salon1,
            description: "Vue d'ensemble du salon",
            descriptionEn: 'Overview of the living room',
        },
        {
            original: salon2,
            description: "Vue d'ensemble du salon ",
            descriptionEn: 'Overview of the living room',
        },
        {
            original: sejour,
            description: 'Cuisine ouverte sur le séjour',
            descriptionEn: 'Kitchen open to the living room',
        },
        {
            original: cuisine,
            description: 'Cuisine ouverte sur le séjour',
            descriptionEn: 'Kitchen open to the living room',
        },
        {
            original: ch1,
            description: "Chambre 1 - RDC - 1 lit 140 avec salle d'eau",
            descriptionEn:
                'Bedroom 1 - Ground floor - 1 bed 140 with shower room',
        },
        {
            original: sdd1,
            description: "Chambre 1 - RDC - 1 lit 140 avec salle d'eau",
            descriptionEn:
                'Bedroom 1 - Ground floor - 1 bed 140 with shower room',
        },

        {
            original: etagech1,
            description: "Chambre Etage - 1 lit 160 avec salle d'eau",
            descriptionEn: 'Bedroom Floor - 1 bed 160 with shower room',
        },
        {
            original: etagech2,
            description: "Chambre Etage - 1 lit 160 avec salle d'eau",
            descriptionEn: 'Bedroom Floor - 1 bed 160 with shower room',
        },
        {
            original: etagech3,
            description: "Chambre Etage - 1 lit 160 avec salle d'eau",
            descriptionEn: 'Bedroom Floor - 1 bed 160 with shower room',
        },
        {
            original: etagech4,
            description: "Chambre Etage - 1 lit 160 avec salle d'eau",
            descriptionEn: 'Bedroom Floor - 1 bed 160 with shower room',
        },
        {
            original: etagesdd2,
            description: "Salle d'eau à l'étage avec double vasques",
            descriptionEn: 'Shower room upstairs with double sinks',
        },
        {
            original: etagesdd3,
            description: "Salle d'eau à l'étage avec double vasques",
            descriptionEn: 'Shower room upstairs with double sinks',
        },
        {
            original: etagesdd4,
            description: "Salle d'eau à l'étage avec double vasques",
            descriptionEn: 'Shower room upstairs with double sinks',
        },
        {
            original: jardin,
            description: 'Jardin',
            descriptionEn: 'Garden',
        },
        {
            original: ext,
            description: '',
            descriptionEn: '',
        },
    ];
    return listing;
}
